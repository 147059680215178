import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { SingleVisualizationModeDto } from "../../Home/Models/SingleVisualizationModeDto";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { setActiveTab, setSingleVisualisation } from "../Redux/SettingsSlice";
import { NavLink } from "react-router-dom";

export const SingleVisualisation = () => {
    const dispatch = useAppDispatch();

    const singlevisualisation = useAppSelector(store => store.settings.chartRequestSettings.SingleVisualization?.Mode);
    const licenceData = useAppSelector(store => store.licenceService);

    const handleChangeSingleVisualisation = (event: SelectChangeEvent<unknown>) => {        
        const value = event.target.value as SingleVisualizationModeDto;
        dispatch(setSingleVisualisation(value));                
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel color="success" id="select-label-singlevisualisation">{getTranslatedText("SingleVisualisation")} </InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        className="onlinehelp-analyse-singlevisualisationSelect"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-singlevisualisation"
                        id="select-singlevisualisation"
                        name="select-singlevisualisation"
                        value={singlevisualisation}
                        label={getTranslatedText("SingleVisualisation")}
                        onChange={handleChangeSingleVisualisation}
                    >
                        <MenuItem id="singlevisualisation1" value={SingleVisualizationModeDto.Angle}>{getTranslatedText("SingleVisualisationAngle")} </MenuItem>
                        <MenuItem id="singlevisualisation2" value={SingleVisualizationModeDto.Time}>{getTranslatedText("SingleVisualisationTime")} </MenuItem>
                        <MenuItem id="singlevisualisation3" value={SingleVisualizationModeDto.Torque}>{getTranslatedText("SingleVisualisationTorque")} </MenuItem>
                    </Select>




                </FormControl>
            }
        </LanguageConsumer>
    )
}