/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { FilterEvents } from "../Model/Categories/FilterEnums";
import { reset_analyze_ApplyFilter, reset_analyze_ResetFilter } from "../Redux/AnalyticsSlice";

const PublishFilterAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();

    const applyFilterState = useAppSelector(store => store.analytics.applyFilterState)
    const resetFilterState = useAppSelector(store => store.analytics.resetFilterState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackApplyFilter = TrackEvent(FilterEvents.ApplyFilter.toString(), {})
    const trackResetFilter = TrackEvent(FilterEvents.ResetFilter.toString(), {})

    useEffect(() => {
        if (applyFilterState.toggle) {
            try{
                const mappedFilters = applyFilterState.types.map((x) => ({ Filtermode: x }))
                trackApplyFilter({ Filters: { mappedFilters } })
            }
            catch{}
            dispatch(reset_analyze_ApplyFilter())
        }
    }, [applyFilterState.toggle])

    useEffect(() => {
        if (resetFilterState.toggle) {
            try{
                trackResetFilter({})
            }
            catch{}
            dispatch(reset_analyze_ResetFilter())
        }
    }, [resetFilterState.toggle])

    return (
        <></>
    )
}

export default PublishFilterAnalytics;