import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { resetAllAnnotations } from '../ChartModifier/Redux/ChartModifierSlice';
import { resetChartRequest } from '../ChartRequestHandler/Redux/ChartRequestSlice';
import { analyze_CancelLoadFiles } from '../Analytics/Redux/AnalyticsSlice';
import { resetChartResult } from '../ChartResultHandler/Redux/ChartResultSlice';

export const ApplicationActions = ({ children }) => {

  const dispatch = useAppDispatch();
  const abortControllerForGraphViewerBackend = useAppSelector(store => store.graphViewerBackend.abortController);
  const loadingFromGraphViewerBackend = useAppSelector(store => store.chartResultData.status === "loading");

  const escFunction = useCallback((event) => {

    function canAbortLoadingFromGraphViewerBackend() {
      var canAbort = abortControllerForGraphViewerBackend &&
        !abortControllerForGraphViewerBackend?.signal.aborted &&
        loadingFromGraphViewerBackend;
      return canAbort;
    }

    if (event.key === "Escape") {    
        dispatch(resetAllAnnotations());
        if (canAbortLoadingFromGraphViewerBackend()) {
            dispatch(resetChartRequest());
            dispatch(resetChartResult());
            dispatch(analyze_CancelLoadFiles());
            abortControllerForGraphViewerBackend?.abort();
        }
    }
    
  }, [dispatch, abortControllerForGraphViewerBackend, loadingFromGraphViewerBackend]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <> {children} </>
  )
}