import { useEffect } from "react";
import { MonitorAnnotation } from "../../../Features/Annotations/Annotation";
import { updateAnnotationColor } from "../../../Features/Annotations/Redux/AnnotationMonitorSlice";
import { ChartResult } from "../../../Features/Home/Models/ChartResult";
import { SuperVisualizationModeDto } from "../../../Features/Home/Models/SuperVisualizationModeDto";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { selectSeries } from "../../ChartResultHandler/Redux/ChartResultSlice";
import { setOrUpdateColor } from "../../ChartSeriesColors/Redux/ChartSeriesColorsSlice";
import { PersistedChartSeriesColorsState, removePersitedChartSeriesColor, setPersitedChartSeriesColor } from "../Redux/PersistedChartSeriesColorsSlice";

export const usePersistedChartSeriesColors = () => {
    
    const dispatch = useAppDispatch();
    const isSuperVisualisation = useAppSelector(store => 
        store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const seriesData = useAppSelector(selectSeries);
    const persistedChartSeriesColors = useAppSelector(store => store.persistedChartSeriesColors);

    useEffect(()=>{
        seriesData.forEach((chartResult) =>{     
            if(!isSuperVisualisation){
                resetChartSeriesToDefaultColors(chartResult, persistedChartSeriesColors, dispatch);
            }
        });
    }, 
    [seriesData, isSuperVisualisation, persistedChartSeriesColors, dispatch])


    return {      
      setPersistedChartSeriesColor: ({color, title}) => { 
        if (!isSuperVisualisation){
            dispatch(setPersitedChartSeriesColor({color, title}));
        }
     },  
      removePersistedChartSeriesColor: ({title}) => { 
         if (!isSuperVisualisation){
            dispatch(removePersitedChartSeriesColor({title}));
         }
      },
   };
}

function resetChartSeriesToDefaultColors(chartResult: ChartResult, persistedChartSeriesColors: PersistedChartSeriesColorsState, dispatch) {
    if (chartResult && chartResult.ChartSeries.length > 0) {
        chartResult.ChartSeries.forEach(chartserie => {

            var persistedColor = persistedChartSeriesColors.colors.filter(x => x.title === chartserie.Title);

            if (persistedColor.length > 0) {
                dispatch(setOrUpdateColor({
                    title: chartserie.Title,
                    color: persistedColor[0].color
                }));
                dispatch(updateAnnotationColor({
                    name: chartserie.Title,
                    color: persistedColor[0].color
                } as MonitorAnnotation));
            }
        });
    }
}
