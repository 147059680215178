import { createSlice } from '@reduxjs/toolkit';
import { SeriesOrder } from '../../ChartSeriesOrder/Redux/ChartSeriesOrderSlice';

export interface PersistedSeriesOrder {
    title: string,
    position: number,
}

export interface PersistedChartSeriesOrderState {
    seriesOrder: SeriesOrder[];
  }
  
const initialState: PersistedChartSeriesOrderState = {
    seriesOrder: [],
};

  export const persistedChartSeriesOrderSlice = createSlice({
    name: 'persistedChartSeriesOrder',
    initialState,
    reducers: {
        setPersitedChartSeriesOrder: (state, action) => {
            var listOfPresentSeries = [] as PersistedSeriesOrder[];
            var updatedList = [] as PersistedSeriesOrder[];

            if (action.payload.list){
              action.payload.list.forEach((list, key) => {
              if(state.seriesOrder.filter(x => x.title === list.Title).length > 0){
                listOfPresentSeries.push({title: list.Title, position: key});
              }
            });
    
            if(listOfPresentSeries.length > 0){
               updatedList = state.seriesOrder.map(series => {
                var keyToUpdate = listOfPresentSeries.filter(x => x.title === series.title);
                if(keyToUpdate.length > 0){
                  return {
                    ...series,
                    position: keyToUpdate[0].position
                  }
                } else {
                  return series;
                }
              })
            }
    
            var newListToPush = action.payload.list.filter(x => !listOfPresentSeries.map(x => x.title).includes(x.Title));
            var finalArray = updatedList.concat(newListToPush.map((l,k) => {return {title: l.title, position: k}}));
    
            state.seriesOrder = finalArray;
            }
      },        
}});

export const { setPersitedChartSeriesOrder } = persistedChartSeriesOrderSlice.actions;
export default persistedChartSeriesOrderSlice.reducer;