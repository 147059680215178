import { createSlice } from '@reduxjs/toolkit';

export interface SeriesVisibility {
    title: string,
    visible: boolean,
}

export interface ChartSeriesVisibilityState {
  seriesVisibilities: SeriesVisibility[];
}

const initialChartSeriesVisibilityState: ChartSeriesVisibilityState = {
    seriesVisibilities: [],
};

export const chartSeriesVisibilitySlice = createSlice({
    name: 'chartSeriesVisibility',
    initialState: initialChartSeriesVisibilityState,
    reducers: {
        initialeVisibility: (state, action) =>{
            state.seriesVisibilities = action.payload.list;
        },
        changeSingleSeriesData: (state, action) => {      
            var found = state.seriesVisibilities.filter(series => series.title === action.payload.seriesTitle);
            found.forEach(serie => serie.visible = !serie.visible)        
        },
        changeSingleSeriesDataToOnlyShowSelected: (state, action) => {
            state.seriesVisibilities.forEach(serie => serie.visible = false);
            var found = state.seriesVisibilities.filter(series => series.title === action.payload.seriesTitle);
            found.forEach(serie => serie.visible = true)  
        },   
        clearChartSeriesVisibility: (state) => {
            state.seriesVisibilities = []
        }
    }
});


export const { 
    changeSingleSeriesData, 
    changeSingleSeriesDataToOnlyShowSelected, 
    initialeVisibility,
    clearChartSeriesVisibility 
} = chartSeriesVisibilitySlice.actions;

export default chartSeriesVisibilitySlice.reducer;