/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { SuperVisualizationModeDto } from "../Models/SuperVisualizationModeDto";
import { addOrUpdateYAxis } from "../SciChartHelper/Axis";

interface UseHandleChangeYAxisArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeYAxis = ({ sciChart }: UseHandleChangeYAxisArgs) => {
    const selectedYAxis = useAppSelector(store => store.settings.selectedYAxisForSingleVisualisation);
    const seriesData = useAppSelector(selectSeries);
    const isSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            addOrUpdateYAxis(seriesData, sciChart, selectedYAxis, isSuperVisualisation, isLicenced);
        }

    }, [selectedYAxis])
}