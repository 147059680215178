import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme, useTheme } from "@mui/material";
import React from "react";
import { isCustomMode, selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { RootState } from "../../../Infrastructure/Redux/store";
import { SeriesTypeDto } from "../../Home/Models/SeriesTypeDto";
import { SuperVisualizationModeDto } from "../../Home/Models/SuperVisualizationModeDto";
import { setSelectedYAxis, setSeriesType } from "../../Settings/Redux/SettingsSlice";
import { ActiveCurveFilesLengthIfIsNotLicenced } from "../Provider/NotLicencedProvider";

export const SelectBox = () => {
    const dispatch = useAppDispatch();
    const isSuperVisualisation = useAppSelector((store: RootState) => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const selectedSeriesTyp = useAppSelector((store: RootState) => store.chartRequestData.series?.Settings.SuperVisualization?.SeriesType);
    const seriesTypes = useAppSelector<[SeriesTypeDto, boolean][] | null>((store: RootState) => store.chartResultData.series[0] && store.chartResultData.series[0].SeriesTypes ? store.chartResultData.series[0].SeriesTypes : null);
    const isDeveloper = useAppSelector(store => store.developer.isDeveloper);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
    const seriesData = useAppSelector(selectSeries);
    const selectedYAxis = useAppSelector((store: RootState) => store.settings.selectedYAxisForSingleVisualisation);
    const theme = useTheme();
    const customMode = useAppSelector(isCustomMode);

    function getStyles(name: string, selectedYAxis: string, theme: Theme) {
        return {
            fontWeight:
                selectedYAxis !== name
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
            color:
                selectedYAxis !== name
                    ? (theme.palette.mode === 'dark' ? "#fff" : "#444")
                    : "#32992c",
        };
    }

    const handleChangeSeriesType = (ev: SelectChangeEvent<SeriesTypeDto>) => {
        dispatch(setSeriesType(ev.target.value));
    }

    const handleYAxisChange = (event: SelectChangeEvent<typeof selectedYAxis>) => {
        const {
            target: { value },
        } = event;
        dispatch(setSelectedYAxis(typeof value === 'string' ? value.split(',') : value));
    };

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>

                    {isSuperVisualisation && seriesTypes && seriesTypes.length > 0 && selectedSeriesTyp !== undefined && <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel color="success" id="seriesTypes-select-label">{getTranslatedText("SeriesTypes")}</InputLabel>
                        <Select
                            className="home-legendSelect onlinehelp-home-legendSelect"
                            color="success"
                            labelId="seriesTypes-select-label"
                            id="seriesTypes-select"
                            value={selectedSeriesTyp ? selectedSeriesTyp : seriesTypes[0][0]}
                            label={getTranslatedText("SeriesTypes")}
                            onChange={handleChangeSeriesType}
                        >
                            {seriesTypes && seriesTypes.map((type, key) => (
                                ((isDeveloper || !type[1]) &&
                                    <MenuItem
                                        disabled={!isLicenced}
                                        key={`supervisualization-menu-item${key}`}
                                        value={type[0]}>{getTranslatedText("FileFormat" + SeriesTypeDto[type[0]].toString())}
                                    </MenuItem>
                                )
                            ))}
                        </Select>
                    </FormControl>}

                    {!isSuperVisualisation && seriesData && seriesData[0] && seriesData[0].ChartSeries.length > 0 &&
                        <FormControl sx={{ mt: 3 }} fullWidth>
                            <InputLabel color="success" id="demo-multiple-chip-label">{getTranslatedText("SelectedYAxis")}</InputLabel>
                            <Select
                                className="home-legendSelect onlinehelp-home-legendSelect"
                                color="success"
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                value={selectedYAxis}
                                onChange={handleYAxisChange}
                                label={getTranslatedText("SelectedYAxis")}
                            >
                                {seriesData[0].ChartSeries.map((series, seriesKey) => (
                                    (isDeveloper || !series.IsInternalCurve) &&
                                    (
                                        <MenuItem
                                            disabled={!isLicenced && seriesKey > ActiveCurveFilesLengthIfIsNotLicenced}
                                            key={seriesKey}
                                            value={series.Title}
                                            style={getStyles(series.Title, selectedYAxis, theme)}
                                        >
                                            {customMode ? series.Title : getTranslatedText(series.Title)}
                                        </MenuItem>
                                    )
                                ))}
                            </Select>
                        </FormControl>}

                </React.Fragment>
            }</LanguageConsumer>
    );
}