
import { Check } from "@mui/icons-material";
import { Box, Button, Grid, ListItem, ListItemIcon, ListItemText, Modal, Typography } from "@mui/material";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { hideCockpitAdvertising } from "../Redux/AdvertisingSlice";
import ImageGallery from 'react-image-gallery';
import './Style/CockpitAdvertising.css';
import "react-image-gallery/styles/css/image-gallery.css";
import { HashLink } from 'react-router-hash-link';
import { setActiveTab } from "../../../../Features/Settings/Redux/SettingsSlice";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "100%",
    maxHeight: "95%",
    overflowY: "auto",
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
};

export const CockpitAdvertisingComponent = () => {
    const dispatch = useAppDispatch();
    const isShown = useAppSelector(store => store.advertising.cockpitAdvertisingIsShown);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === "dark");

    const handleClose = () => {
        dispatch(hideCockpitAdvertising());
    };

    const images = [
        {
            original: './AdvertisingImages/Cockpit/Cockpit_v2.jpg',
            thumbnail: ''
        }
    ];

    return (<LanguageConsumer>

        {({ getTranslatedText }) =>
            <Modal
                open={isShown}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component={'div'} sx={style} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography variant="h6" component="h2">
                                {getTranslatedText("DepragCockpitOrderTitle")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ImageGallery items={images}
                                showNav={images.length > 1}
                                additionalClass={'image-slider-custom'}
                                showThumbnails={false}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                showBullets={images.length > 1} />
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <Typography >
                                {getTranslatedText("DepragCockpitOrderText")}
                            </Typography>

                        </Grid>

                        <Grid item xs={12} >
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon><Check color="success" /></ListItemIcon>
                                <ListItemText>  {getTranslatedText("DepragCockpitOrderTextLi1")}</ListItemText>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon><Check color="success" /></ListItemIcon>
                                <ListItemText>  {getTranslatedText("DepragCockpitOrderTextLi2")}</ListItemText>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon><Check color="success" /></ListItemIcon>
                                <ListItemText>  {getTranslatedText("DepragCockpitOrderTextLi3")}</ListItemText>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon><Check color="success" /></ListItemIcon>
                                <ListItemText>  {getTranslatedText("DepragCockpitOrderTextLi4")}</ListItemText>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon><Check color="success" /></ListItemIcon>
                                <ListItemText>  {getTranslatedText("DepragCockpitOrderTextLi5")}</ListItemText>
                            </ListItem>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1, pb: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="subtitle2">
                                {getTranslatedText("DepragCockpitAdvertisingInfoText")}
                            </Typography>
                        </Grid>


                        <Grid item xs={12} sx={{ mt: 0, pb: 0 }}>
                            <Grid container spacing={1} >
                                <Grid item xs={12} xl={2} style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color={isDarkMode ? "primary" : "inherit"}
                                        onClick={() => { handleClose() }}>
                                        {getTranslatedText("Close")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Button
                                        component={HashLink} to={'/settings#loadFromCockpitSettings'}
                                        fullWidth
                                        variant="outlined"
                                        color={isDarkMode ? "primary" : "inherit"}
                                        onClick={() => { 
                                            dispatch(setActiveTab(3))
                                            handleClose() 
                                            }}>
                                        {getTranslatedText("GoToCockpitSettings")}
                                    </Button>
                                </Grid>
                                <Grid item xs={0} xl={4} />
                                <Grid item xs={12} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="success"
                                        style={{color: "white" }}
                                        onClick={() => { window.open("https://my.deprag.com/deprag-de/de/Schraubtechnik/Software-Apps/COCKPIT-Professional50/p/142979", '_blank') }}>
                                        {getTranslatedText("DepragStore")}
                                    </Button>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>}
    </LanguageConsumer>
    );
}