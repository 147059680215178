import { TorqueUnit } from "../Home/Models/ChartRequest";
import { SingleVisualizationModeDto } from "../Home/Models/SingleVisualizationModeDto";
import { SuperVisualizationModeDto } from "../Home/Models/SuperVisualizationModeDto";

export function getLanguekeyForInputfield(singleVisualisationMode, superVisualisationMode, isNotSuperVisualisation) {
  let languekeyForInputfield = "";

  if (isNotSuperVisualisation) {
    if (SingleVisualizationModeDto.Angle === singleVisualisationMode) {
      languekeyForInputfield = "Angle";
    }
    if (SingleVisualizationModeDto.Torque === singleVisualisationMode) {
      languekeyForInputfield = "Torque";
    }
  }
  else {
    if (SuperVisualizationModeDto.Angle === superVisualisationMode) {
      languekeyForInputfield = "Angle";
    }
  }
  return languekeyForInputfield;
}

export function getInputFieldAbbreviation(selectedTorqueUnit) {
  let abbreviation = "";

  if (selectedTorqueUnit === TorqueUnit.Nm) {
    abbreviation = "[Nm]";
  }
  if (selectedTorqueUnit === TorqueUnit.Ncm) {
    abbreviation = "[Ncm]";
  }
  if (selectedTorqueUnit === TorqueUnit.Ftlb) {
    abbreviation = "[ft·lb]";
  }
  if (selectedTorqueUnit === TorqueUnit.Inlb) {
    abbreviation = "[in·lb]";
  }
  if (selectedTorqueUnit === TorqueUnit.Kgm) {
    abbreviation = "[k·gm]";
  }
  if (selectedTorqueUnit === TorqueUnit.Kgcm) {
    abbreviation = "[kg·cm]";
  }

  return abbreviation;
}

export function getLanguekeyForInputfieldSecondary(singleVisualisationMode) {
  let languekey = "";

  if (SingleVisualizationModeDto.Angle === singleVisualisationMode) {
    languekey = "Torque";
  }
  if (SingleVisualizationModeDto.Torque === singleVisualisationMode) {
    languekey = "Angle";
  }

  return languekey;
}