/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { createSciChartAnnotation } from "../../Annotations/AnnotationService";
import { monitorAnnotationPrefix } from "../../Annotations/Redux/AnnotationMonitorSlice";

interface UseHandleChangeMonitorAnnotationsArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeMonitorAnnotations = ({ sciChart }: UseHandleChangeMonitorAnnotationsArgs) => {
    const monitorAnnotations = useAppSelector(store => store.annotationMonitor.annotations);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {

            var annotationsToDelete = sciChart.sciChartSurface.annotations.asArray().filter(a => a.id.startsWith(monitorAnnotationPrefix));
            for (let annotation of annotationsToDelete) {
                var annotationToRemove = sciChart.sciChartSurface.annotations.getById(annotation.id);
                sciChart.sciChartSurface.annotations.remove(annotationToRemove);
            }

            monitorAnnotations.forEach(annotation => {
                let annotationToAdd = createSciChartAnnotation(annotation);

                if (annotationToAdd) {
                    annotationToAdd.forEach(x => sciChart.sciChartSurface.annotations.add(x));
                }
            });
        }
    }, [monitorAnnotations])
}