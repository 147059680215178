import { encode } from "base-64";
import { LoadFromCockpitRequest } from "./CompleteCurveDataRequest";
import { CockpitResponse } from "./CompleteCurveDataResponse";

const simpleFetchCall = async (cockpitRequest: LoadFromCockpitRequest) => {
    var cockpitResponse = await fetch('http://' + cockpitRequest.cockpitUrl + ":8089/" + cockpitRequest.cockpitArea + "/api/v1.0/graphviewer/curvefilecontent", {
        signal: cockpitRequest.abortController.signal,
        body: JSON.stringify({ SelectedCurveFileIds: cockpitRequest.selectedCurveFileIds }),
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Basic ' + encode(cockpitRequest.username + ":" + cockpitRequest.password),
            'Content-Type': 'application/json'
        }),
    });

    return cockpitResponse.json();
}

const callCockpitApi = async (cockpitRequest: LoadFromCockpitRequest) => {
    const htmlContent = await simpleFetchCall(cockpitRequest);

    return htmlContent as CockpitResponse[];
}

export const getFileFromCockpit = async (cockpitRequest: LoadFromCockpitRequest): Promise<CockpitResponse[]> => {
    try {

        var jsonResponse = await callCockpitApi(cockpitRequest);


        return new Promise<any>((resolve, rejected) => {
            if (jsonResponse && jsonResponse.length > 0) {
                resolve(jsonResponse)
            }
            else{
                rejected()
            }
        });

    } catch (error: any) {
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);

    }
}