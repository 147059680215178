/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { DpiHelper } from "scichart/Charting/Visuals/TextureManager/DpiHelper";
import { toggleAddHorizontalLine, toggleAddVerticalLine, toggleAddDataPoint } from "../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { getAllUserAnnotationToRemove, getUserAnnotationToRemove } from "../../Annotations/AnnotationService";
import { addDataPoint, addHorizontalLine, addValuePoint, addVerticallLine, removeAnnotationById } from "../../Annotations/Redux/AnnotationUserSlice";
import { CalculateYValue, CalculateXValue } from "../SciChartHelper/ChartCoordinateCalculator";

interface UseHandleChangeChartModifierAnnotationsArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeChartModifierAnnotations = ({ sciChart }: UseHandleChangeChartModifierAnnotationsArgs) => {
    const dispatch = useAppDispatch();

    const annotations = useAppSelector(store => store.chartModifier.annotations);
    const seriesData = useAppSelector(selectSeries);
    const annotationColor = useAppSelector(store => store.settings.annotationSettings.userAnnotationColorAsHex);


    const handleAddHorizontalLine = useCallback((mouseEvent: MouseEvent) => {
        var clickedYValue = CalculateYValue(mouseEvent, sciChart.sciChartSurface.yAxes.get(0), 10);
        dispatch(addHorizontalLine(
            {
                yValue: clickedYValue,
                color: annotationColor
            }));
        dispatch(toggleAddHorizontalLine());
    }, [dispatch, sciChart,annotationColor]);

    const handleAddVerticalLine = useCallback((mouseEvent: MouseEvent) => {
        console.log("Event auch")
        var clickedXValue = CalculateXValue(mouseEvent, sciChart.sciChartSurface.xAxes.get(0), 10);
        dispatch(addVerticallLine({
            xValue: clickedXValue,
            color: annotationColor
        }));
        dispatch(toggleAddVerticalLine());
    }, [dispatch, sciChart,annotationColor]);

    const handleAddDataPoint = useCallback((mouseEvent: MouseEvent) => {
        var clickedXValue = CalculateXValue(mouseEvent, sciChart.sciChartSurface.xAxes.get(0), 15);
        var clickedYValue = CalculateYValue(mouseEvent, sciChart.sciChartSurface.yAxes.get(0), 10);
        dispatch(addDataPoint({
            color: annotationColor,
            dataPoint: {
                x1: clickedXValue,
                y1: clickedYValue
            }
        }));
        dispatch(toggleAddDataPoint());
    }, [dispatch, sciChart,annotationColor]);

    const handleAddValuePoint = useCallback((mouseEvent: MouseEvent) => {

        if (seriesData && seriesData.length > 0 && sciChart && sciChart.sciChartSurface) {
            var clickedXValue = CalculateXValue(mouseEvent, sciChart.sciChartSurface.xAxes.get(0), 15);
            var clickedYValue = CalculateYValue(mouseEvent, sciChart.sciChartSurface.yAxes.get(0), 10);

            const HIT_TEST_RADIUS = 10 * DpiHelper.PIXEL_RATIO;
            const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
            const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
            const series = sciChart.sciChartSurface.renderableSeries.asArray();

            for (let currentSeries of series) {
                var foundFromStore = seriesData[0].ChartSeries.find(x => x.Title === currentSeries.dataSeries.dataSeriesName);

                if (foundFromStore == null)
                    continue;

                const hitTestInfo = currentSeries.hitTestProvider.hitTestDataPoint(premultipliedX, premultipliedY, HIT_TEST_RADIUS)
                if (hitTestInfo.isHit) {
                    var textForAnnoation = (clickedYValue / parseFloat(foundFromStore.ScaleFactor)).toFixed(foundFromStore.DigitCount);
                    textForAnnoation += " " + foundFromStore.UnitAbbreviation;
                    dispatch(addValuePoint({
                        color: annotationColor,
                        valuePoint: {
                            formattedValue: textForAnnoation,
                            x1: clickedXValue,
                            y1: clickedYValue,
                            seriesTitle: foundFromStore.Title
                        }
                    }));
                    break;
                }
            }
        }

    }, [dispatch, seriesData, sciChart,annotationColor]);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            if (annotations.addDataPoint || annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addValuePoint) {
                sciChart.sciChartSurface.getMainCanvas().style.cursor = "crosshair";
            } else {
                sciChart.sciChartSurface.getMainCanvas().style.cursor = "default";
            }
        }
    }, [annotations]);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            if (annotations.addHorizontalLine)
                sciChart.sciChartSurface.domCanvas2D.addEventListener('pointerdown', handleAddHorizontalLine);

            return () => {
                if (annotations.addHorizontalLine)
                    sciChart.sciChartSurface.domCanvas2D.removeEventListener('pointerdown', handleAddHorizontalLine);
            }
        }
    }, [annotations.addHorizontalLine]);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            if (annotations.addVerticalLine) {
                console.log("Toggle geht noch")
                sciChart.sciChartSurface.domCanvas2D.addEventListener('pointerdown', handleAddVerticalLine);
            }
                
            return () => {
                if (annotations.addVerticalLine)
                    sciChart.sciChartSurface.domCanvas2D.removeEventListener('pointerdown', handleAddVerticalLine);
            }
        }
    }, [annotations.addVerticalLine]);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            if (annotations.addDataPoint)
                sciChart.sciChartSurface.domCanvas2D.addEventListener('pointerdown', handleAddDataPoint);

            return () => {
                if (annotations.addDataPoint)
                    sciChart.sciChartSurface.domCanvas2D.removeEventListener('pointerdown', handleAddDataPoint);
            }
        }
    }, [annotations.addDataPoint, dispatch]);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            if (annotations.addValuePoint)
                sciChart.sciChartSurface.domCanvas2D.addEventListener('pointerdown', handleAddValuePoint);

            return () => {
                if (annotations.addValuePoint)
                    sciChart.sciChartSurface.domCanvas2D.removeEventListener('pointerdown', handleAddValuePoint);
            }
        }
    }, [seriesData, annotations.addValuePoint, dispatch])

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            let annotationToRemove = getUserAnnotationToRemove(sciChart.sciChartSurface.annotations);
            if (annotationToRemove) {
                dispatch(removeAnnotationById(annotationToRemove.id));
            }
        }
    }, [annotations.deleteLastAnnotation, dispatch]);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            let annotationsToRemove = getAllUserAnnotationToRemove(sciChart.sciChartSurface.annotations);
            if (annotationsToRemove && annotationsToRemove.length > 0) {
                annotationsToRemove.forEach(anno => {
                    var idToRemove = anno;
                    var userAnnoationToRemove = sciChart.sciChartSurface.annotations.getById(idToRemove);
                    dispatch(removeAnnotationById(userAnnoationToRemove.id));
                });

            }
        }
    }, [annotations.deleteAllAnnotations, dispatch]);


}