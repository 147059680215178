import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { Chip } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { Add } from "@mui/icons-material";
import { selectSeries } from "../../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { memo } from "react";

export const NewGraphViewerTab = memo(() => {
    const seriesData = useAppSelector(selectSeries);

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                {
                    seriesData && seriesData[0] && <Chip
                        className="headerInformation"
                        component={NavLink}
                        to={"/"}
                        onClick={() => window.open(window.location.href, '_blank')}
                        sx={{ zIndex: 999, background: 'rgba(255,255,255,1)', cursor: "pointer", mr: 2, color: '#444' }}
                        icon={<Add />}
                        label={getTranslatedText("OpenNewTab")}
                        variant="filled" />
                }
            </>
        }
    </LanguageConsumer>
    );
})