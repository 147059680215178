import { Container, Grid, IconButton, Link, Typography, Chip, TextField, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import './Style/Footer.css';

import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { NavLink, useLocation } from "react-router-dom";
import { Version } from "../../Infrastructure/Versioninfo/Version";
import { toggleCustomCsvIsShown } from "../../Features/CustomCsv/Redux/CustomCsvSlice";
import { NotificationType, setNotification } from "../Notifications/Redux/NotificationsSlice";
import Popover from '@mui/material/Popover';
import PopupState, { bindPopover } from 'material-ui-popup-state';
import { postDeveloperPassword, setDeveloperPassword } from "../../Infrastructure/Developer/DeveloperSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import { getSocialIcons } from "./Models/SocialIcons";
import { useMediaQuery } from "react-responsive";
import { InstallPwaButton } from "./Components/InstallPwaButton";

const Footer = () => {
    const dispatch = useAppDispatch()
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const developerPassword = useAppSelector(store => store.developer.password);
    const isLoading = useAppSelector(store => store.developer.status === 'loading');
    const isDeveloper = useAppSelector(store => store.developer.isDeveloper);
    const location = useLocation();
    const isRunningOnExtraSmallDevice = useMediaQuery({ query: '(max-width: 600px)' })

    const socials = getSocialIcons(dispatch)

    return !location.search.includes("standalone") ? (
        <Box
        component="footer"
        sx={{
          py: 1,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            getBackgroundColor(theme),
        }}
      >
        <LanguageConsumer>

            {({ getTranslatedText }) =>
                <footer className={getFooterStyle(isDarkMode)}>
                    <Container maxWidth="xl" sx={{ mt: 1, mb: 2 }}>
                        <Grid container direction={'row'} spacing={2} justifyContent="center">
                            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                                <span>
                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                        {(popupState) => (
                                            <>
                                                <Chip onClick={(e) => {
                                                    openDevLogin(e, popupState);
                                                    }} sx={{ opacity: 0.5 }} label={`© ${new Date().getFullYear()} DEPRAG SCHULZ GMBH u. CO. KG`} />

                                                <Popover
                                                    {...bindPopover(popupState)}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    sx={{ background: 'rgba(0,0,0,0.7)' }}
                                                    PaperProps={{ sx: { borderRadius: 3 } }}
                                                >
                                                    <Grid container direction={'row'} spacing={2} sx={{ p: 5, pt: 2 }}>
                                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                                            <Typography variant="h5">{getTranslatedText("DeveloperLogin")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField fullWidth id="outlined-basic"
                                                                label={getTranslatedText("DeveloperPassword")}
                                                                variant="outlined"
                                                                color="success"
                                                                required
                                                                type={"password"}
                                                                value={developerPassword}
                                                                onChange={(e) => { dispatch(setDeveloperPassword(e.target.value)) }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <LoadingButton
                                                                loading={isLoading}
                                                                loadingPosition="start"
                                                                startIcon={<SendIcon />}
                                                                disabled={isLoading}
                                                                color="success"
                                                                variant="contained"
                                                                sx={{ color: '#fff' }}
                                                                fullWidth
                                                                onClick={() => {
                                                                    dispatch(postDeveloperPassword(developerPassword))
                                                                    popupState.close();

                                                                }}
                                                                size="large">{getTranslatedText("SendDeveloperPassword")}</LoadingButton>
                                                        </Grid>

                                                    </Grid>
                                                </Popover>
                                            </>)}
                                    </PopupState>

                                    <Chip sx={{ opacity: 0.5, userSelect: 'none', ml: 1 }} onClick={(e) => {
                                        toggleCustomCsv(e, dispatch);
                                    }} label={Version}></Chip>

                                    {isDeveloper && <Chip color="error" sx={{ opacity: 0.5, userSelect: 'none', ml: 1 }} label={'DEV'}></Chip>}

                                    <InstallPwaButton />
                                </span>
                            </Grid>

                            <Grid item xs={6} md={6} sx={{ flexGrow: 2, display: { xs: 'none', md: 'block' }, mt: 0, pt: 0 }} alignItems="start" alignSelf={'start'} textAlign="right">

                                {socials.map((social, socialKey) => {
                                    return (
                                        <Link key={socialKey} href={social.link} target={'_blank'} sx={{ borderLeft: socialKey === socials.length - 1 ? "1px solid #e3e3e3" : "0px", paddingLeft: socialKey === socials.length - 1 ? "10px" : "0", marginLeft: socialKey === socials.length - 1 ? "10px" : "0" }}>
                                            <IconButton
                                                onClick={social.trackClick}
                                                size={'small'}
                                                color="success"
                                                sx={{ mx: 1, background: getButtonStyle(isDarkMode, social) }}
                                            >
                                                {social.icon}
                                            </IconButton>
                                        </Link>
                                    );
                                })}

                                <Typography sx={{ mt: 2, mr: 1.5, color: "rgba(0, 0, 0, 0.37)" }} fontSize={'small'}>
                                    <NavLink to={'/privacy'}>
                                        <span style={{ color: getTypographyColor(isDarkMode), paddingRight: "8px" }}>
                                            {getTranslatedText("Privacy")}
                                        </span>
                                    </NavLink>
                                    -
                                    <NavLink to={'/thirdpartylicensenotes'}>
                                        <span style={{ color: getTypographyColor(isDarkMode), paddingLeft: "8px", paddingRight: "8px" }}>
                                            {getTranslatedText("ThirdPartyLicenseNotesLinkText")}
                                        </span>
                                    </NavLink>                                      
                                    -
                                    <NavLink to={'/imprint'}>
                                        <span style={{ color: getTypographyColor(isDarkMode), paddingLeft: "8px" }}>
                                            {getTranslatedText("Imprint")}
                                        </span>
                                    </NavLink>                                    
                                  
                                </Typography>

                            </Grid>

                        </Grid>
                    </Container>
                </footer>}
        </LanguageConsumer>
        </Box>
    ) : <></>;
}

export default Footer;

function openDevLogin(e, popupState) {
    if (e.detail === 2) {
        popupState.open();
    }
}

function toggleCustomCsv(e, dispatch) {
    if (e.detail === 3) {
        dispatch(setNotification({ type: NotificationType.info, text: "Stop clicking on the version number!" }));
    } else if (e.detail === 6) {
        dispatch(toggleCustomCsvIsShown());
    }
}

function getBackgroundColor(theme) {
    return theme.palette.mode === 'light'
        ? "rgba(255,255,255,.7)"
        : theme.palette.grey[900];
}

function getButtonStyle(isDarkMode: boolean, social: { icon: JSX.Element; link: string; bgLight: string; bgDark: string; trackClick: () => any; }) {
    return isDarkMode ? social.bgDark : social.bgLight;
}

function getFooterStyle(isDarkMode: boolean): string | undefined {
    return isDarkMode ? 'footer-dark' : 'footer-light';
}

function getTypographyColor(isDarkMode: boolean) {
    return isDarkMode ? "rgba(255, 255, 255, 0.37)" : "rgba(0, 0, 0, 0.37)";
}

