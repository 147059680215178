import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationType, StepNumberAnnotation } from '../Annotation';
import { makeId } from '../AnnotationService';

export interface StepNumber {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    step: number
}

export const stepNumberAnnotationPrefix: string = "stepNumberAnnotation_";

interface AnnotationBuiltInState {
    annotations: StepNumberAnnotation[];
}

export const initialAnnotationStepNumberState: AnnotationBuiltInState = {
    annotations: [],
}

const annotationStepNumberSlice = createSlice({
    name: "AnnotationStepNumber",
    initialState: initialAnnotationStepNumberState,
    reducers: {
        addStepNumber: (state, action: PayloadAction<StepNumber>) => {
            state.annotations = [...state.annotations,
            {
                color: state.annotations.length % 2 === 0 ? "#a9adde33" : "#92d18e33",
                id: stepNumberAnnotationPrefix + makeId(state.annotations.length + 1),
                x1: action.payload.x1,
                y1: action.payload.y1,
                x2: action.payload.x2,
                y2: action.payload.y2,
                stepNumber: action.payload.step,
                type: AnnotationType.stepNumber,
            } as unknown as StepNumberAnnotation]
        },
        removeAllStepNumberAnnotations: (state) => {
            state.annotations = []
        }
    }
})

export const { addStepNumber, removeAllStepNumberAnnotations} = annotationStepNumberSlice.actions;
export default annotationStepNumberSlice.reducer;