/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { saveSeriesRequestsInStore } from "../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { getSeriesForGraphDataAsync, selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { removeAllMonitors } from "../../Annotations/Redux/AnnotationMonitorSlice";
import { ChartRequest, ChartRequestFile } from "../Models/ChartRequest";
import { SingleVisualizationModeDto } from "../Models/SingleVisualizationModeDto";
import { SuperVisualizationModeDto } from "../Models/SuperVisualizationModeDto";

export const useHandleChangeChartRequestSettings = () => {
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const savedOrderedSeries = useAppSelector(store => store.chartSeriesOrder.seriesOrders);
    const languageIdentifier = useAppSelector(store => store.settings.language);
    const seriesData = useAppSelector(selectSeries);

    const dispatch = useAppDispatch();

    useEffect(() => { 
        dispatch(removeAllMonitors());
        if (!chartRequest || !seriesData || seriesData.length === 0) {
            return
        }

        if (chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Time ||
            chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Time) {
            dispatch(removeAllMonitors())
        }

        if (chartRequest.Files.length > 0) {
            var files: ChartRequestFile[] = []
            chartRequest.Files.forEach(element => {

                var isVisible = seriesData[0].ChartSeries.find(x => x.ChartRequestId === element.Id)?.Visible
                var newElement = {
                    Title: element.Title,
                    CurveRawContent: element.CurveRawContent,
                    Id: element.Id,
                    IsHidden: isVisible===undefined ? false : !isVisible
                } as ChartRequestFile
                files.push(newElement);
            });

            var updatedChartRequestData = {
                Files: files,
                Settings: chartRequestSettings,
                SortedList: savedOrderedSeries,
                LanguageIdentifier: languageIdentifier
            } as ChartRequest;

            dispatch(saveSeriesRequestsInStore(updatedChartRequestData));
            if (!chartRequestSettings.ImplicitChangeFromResult) {
                dispatch(getSeriesForGraphDataAsync(updatedChartRequestData));
            }
        }

    }, [chartRequestSettings, languageIdentifier])
}