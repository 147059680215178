import { ChartRequest } from "../../../Features/Home/Models/ChartRequest";
import { ChartResult } from "../../../Features/Home/Models/ChartResult";
import { getChart } from "../../../Features/Home/SciChartApi";
import { SeriesOrder } from "../../ChartSeriesOrder/Redux/ChartSeriesOrderSlice";

export  const getSeriesDataFromBackend = async (chartRequest: ChartRequest, abortController: AbortController) : Promise<ChartResult[]> => {
        let requestPayload = {
            Files: chartRequest.Files,
            Settings: chartRequest.Settings,
            SortedList: [] as SeriesOrder[],
            LanguageIdentifier: chartRequest.LanguageIdentifier
        }
        return await getChart(requestPayload, abortController);        
  }
  