import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { Grid, Typography } from "@mui/material";
import { LordIcon } from "../../../Infrastructure/LordIcons/LordIcon";
import { fileDialog } from "file-select-dialog";
import { SupportedFileFormats } from "../../../Infrastructure/SupportedFileFormats/SupportedFileFormats";
import { selectFilesFromDialog } from "../../../Infrastructure/ImportProvider/ImportProvider";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { SuperVisualizationModeDto } from "../../Home/Models/SuperVisualizationModeDto";
import { RootState } from "../../../Infrastructure/Redux/store";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";

export const AddCurveFiles = () => {
    const dispatch = useAppDispatch();
    const chartRequestSettings2 = useAppSelector(store => store.settings.chartRequestSettings);
    const languageIdentifier = useAppSelector(store => store.settings.language);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const savedOrderedSeries = useAppSelector(store => store.chartSeriesOrder.seriesOrders);
    const isSuperVisualisation = useAppSelector((store: RootState) => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
    const seriesData = useAppSelector(selectSeries);
    const isLoading = useAppSelector(store => store.loadingIndicator.isLoading);

    const selectFiles = async () => {
        const fileList = await fileDialog({ multiple: true, accept: SupportedFileFormats() });
        selectFilesFromDialog(
            fileList,
            dispatch,
            chartRequestSettings2,
            languageIdentifier,
            savedOrderedSeries,
            isSuperVisualisation,
            isLicenced,
            chartRequest);
    }

    return (
        (!seriesData || !seriesData[0]) && !isLoading ?
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container>
                    <Grid item xs={12} textAlign="center" sx={{ cursor: 'pointer' }} onClick={selectFiles}>
                        <LordIcon type="add" size={130} speed={0.7} />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography>{getTranslatedText("AddCurveFilesToStart")}</Typography>
                    </Grid>
                </Grid>
            }
        </LanguageConsumer>
        :
        <></>
    );
}
