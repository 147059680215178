import { FeedbackRequest } from "../Models/FeedbackRequest";
import { FeedbackResponse } from "../Models/FeedbackResponse";
import { postFeedback } from "./FeedbackPostApi";

export  const postFeedbackToBackend = async (feedbackRequest: FeedbackRequest) : Promise<FeedbackResponse> => {
    try {
        var feddbackResponse = await postFeedback(feedbackRequest);
        return Promise.resolve(feddbackResponse);
        
    } catch (error) {
        return Promise.reject(error);
    }
  }