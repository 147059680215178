import { createSlice } from '@reduxjs/toolkit';

export interface PersistedColor {
    title: string,
    color: string,
}

export interface PersistedChartSeriesColorsState {
    colors: PersistedColor[];
  }
  
  const initialState: PersistedChartSeriesColorsState = {
      colors: [],
  };

export const persistedChartSeriesColorsSlice = createSlice({
    name: 'persistedChartSeriesColors',
    initialState,
    reducers: {
        setPersitedChartSeriesColor: (state, action) => {
            let isUpdated = false;
            const newColors = state.colors.map(color => {
                if(color.title === action.payload.title){
                    isUpdated = true;
                    return {
                        ...color,
                        color: action.payload.color
                    };
                } else {
                    return color;
                }
            });
    
            !isUpdated && newColors.push(action.payload);
    
            state.colors = newColors;
          }, 
          removePersitedChartSeriesColor: (state, action) => {
            const newColors = state.colors.filter(color => color.title !== action.payload.title);
            state.colors = newColors;
          }
    }
})

export const { setPersitedChartSeriesColor, removePersitedChartSeriesColor } = persistedChartSeriesColorsSlice.actions;
export default persistedChartSeriesColorsSlice.reducer;