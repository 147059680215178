import { getTranslation, LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { toggleMasterSettings } from "../../../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { Drawer, Grid,  Typography, Chip, TextField, Box, Tooltip } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useState } from "react";
import { loadMasterSettings } from "./Redux/LicenseMasterSettingsSlice";
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import './Style/LicenseMasterSettings.css';
import { LicenseMasterSettingsInfo } from "./Components/LicenseMasterSettingsInfo";
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { object, string, TypeOf } from "zod";
import { NotificationType, setNotification } from "../../../../Layout/Notifications/Redux/NotificationsSlice";
import Skeleton from '@mui/material/Skeleton';
import { licenseBaseUrl } from "../../../../Infrastructure/Api/BaseApi";
import { postMasterSettingsInvitation } from "./Redux/LicenseMasterSettingsInvitationSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import { YesNoDialog } from "../../../../Infrastructure/YesNoDialog/YesNoDialog";

export const LicenseMasterSettings = () => {
    const dispatch = useAppDispatch();
    const showMasterSettings = useAppSelector(store => store.licenceService.showMasterSettings);
    const usages = useAppSelector(store => store.licenseMaster.usages);
    const isLoadingUsages = useAppSelector(store => store.licenseMaster.status === 'loading');
    const isDarkMode = useAppSelector(store => store.settings.appTheme === "dark");
    const licenseAuthentication = useAppSelector(store => store.licenceService.licenceAuthentication);
    const licenceInformation = useAppSelector(store => store.licenseMaster.information);
    const language = useAppSelector(store => store.settings.language);
    const isSendingInvitation = useAppSelector(store => store.licenseMasterInvitation.status === 'loading');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);  
    const [usageToDelete, setUsageToDelete] = useState<number>(0);

    const hideOrShowMasterSettings = () => {
        dispatch(toggleMasterSettings())
    }

    useEffect(() => {
        if (showMasterSettings && licenseAuthentication.clientId.trim().length > 0 && licenseAuthentication.licenceKey.trim().length > 0) {
            dispatch(loadMasterSettings({ clientId: licenseAuthentication.clientId, masterKey: licenseAuthentication.licenceKey }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMasterSettings])

    const registerSchema = object({
        email: string().min(1, getTranslation("LicenseFormValueIsRequired")).email(getTranslation("LicenseFormEmailHasToBeValid")),
    });

    type RegisterInput = TypeOf<typeof registerSchema>;

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm<RegisterInput>({
        resolver: zodResolver(registerSchema),
    });

    const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
        dispatch(postMasterSettingsInvitation({
            masterKey: licenseAuthentication.licenceKey, 
            clientId: licenseAuthentication.clientId, 
            emailRecipient: values.email, 
            childKey: licenceInformation && licenceInformation.childKey ? licenceInformation.childKey : "",
            language: language
        }));
        
        reset();
    };

    const deleteUsage = async (usageId: number) => {
        var response = await fetch(`${licenseBaseUrl}licensing/usages/${usageId}`,{              
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'masterOrChildKey': licenseAuthentication.licenceKey,
                'clientId': licenseAuthentication.clientId
            })
        });

        var jsonResponse = await response.json();

        
        if(jsonResponse.success){
            dispatch(loadMasterSettings({ clientId: licenseAuthentication.clientId, masterKey: licenseAuthentication.licenceKey }));
            dispatch(setNotification({ type: NotificationType.success, text: getTranslation("SuccessfullyDeletedLicense") }));
        } else {
            dispatch(setNotification({ type: NotificationType.error, text: getTranslation("LicenceCouldNotBeDeleted") }));
        }
    }

    const handleNoClick = () => {
        setConfirmDialogOpen(false);
    }

    const handleYesClick = () => {
        setConfirmDialogOpen(false); 
        deleteUsage(usageToDelete);
    }    

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>

                <Tooltip title={getTranslatedText("MasterSettings")}>
                    <IconButton size="small" className="onlinehelp-settings-licence-masterSettings" color="success" onClick={hideOrShowMasterSettings}
                     sx={{
                        color: (isDarkMode ? '#c2c0c0' : '#757474'),
                        background: isDarkMode ? '#424242' : '#DFE7FD'
                      }}>
                        <SettingsIcon fontSize="small" />
                    </IconButton>
                </Tooltip>

                <Drawer
                    anchor={'right'}
                    open={showMasterSettings}
                    onClose={hideOrShowMasterSettings}
                    PaperProps={{
                        sx: { minWidth: "40%" },
                    }}
                >
                        <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }} >
                            <Grid item xs={12}>
                                <Typography variant="h4" sx={{ fontWeight: "300" }}>
                                    {getTranslatedText("MasterSettingsTitle")}
                                </Typography>
                                <Typography sx={{ fontWeight: "300" }}>
                                    {getTranslatedText("MasterSettingsText")}
                                </Typography>
                            </Grid>
                        </Grid>


                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0, pt: 1 }} >
                        <Grid item xs={12}>

                            {!isLoadingUsages && licenceInformation && <LicenseMasterSettingsInfo allLicenses={licenceInformation.licenseVolume} childKey={licenceInformation.childKey} usedLicenses={usages.length} />}

                            {isLoadingUsages && <Skeleton variant="rectangular" width={'100%'} height={250} sx={{ borderRadius: 5 }} />}

                        </Grid>
                    </Grid>
                    
                    <Grid container direction={'row'} spacing={2} marginTop={1} sx={{ p: 4, pb: 0 }} >
                        <Grid item xs={12}>
                            <Typography variant="h4" sx={{ fontWeight: "300" }}>
                                {getTranslatedText("CurrentlyUsedLicensesTitle")}
                            </Typography>
                            <Typography sx={{ fontWeight: "300" }}>
                                {getTranslatedText("CurrentlyUsedLicensesContent")}
                            </Typography>                            
                        </Grid>
                    </Grid>                    

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4 }}>
                        <Grid item xs={12}>
                            <>
                                {!isLoadingUsages && usages && usages.length > 0 && usages.filter(x => x.isMasterKey).length > 0 && usages.filter(x => x.isMasterKey).map((masterUsage, masterUsageKey) => {
                                    return <Box key={masterUsageKey} component={'div'} sx={{ position: 'relative' }}>
                                        <Tooltip title={getTranslatedText("IsMasterTooltip")}>
                                        <IconButton sx={{ position: "absolute", left: 10, top: 4, color: '#ffd700' }}>
                                            <KeyIcon />
                                        </IconButton>
                                        </Tooltip>
                                        <Chip sx={{ fontWeight: 300, width: '100%', textAlign: 'left', fontSize: 20, py: 3, background: !isDarkMode ? '#f9f9f9' : '#444' }} label={masterUsage.emailAddress} />
                                    </Box>
                                })}

                                {!isLoadingUsages && usages && usages.length > 0 && usages.filter(x => !x.isMasterKey).length > 0 && usages.filter(x => !x.isMasterKey).map((usage, usageKey) => {
                                    return (
                                        
                                        <Box key={usageKey} component={'div'} sx={{ position: 'relative', mt: 2 }}>
                                            <Tooltip title={getTranslatedText("IsChildKeyTooltip")}>                                                    
                                                <IconButton sx={{ position: "absolute", left: 10, top: 4 }}>
                                                    <KeyIcon />
                                                </IconButton>
                                            </Tooltip>                                                                                            
                                            <Chip sx={{ fontWeight: 300, width: '100%', textAlign: 'left', fontSize: 20, py: 3, background: !isDarkMode ? '#f9f9f9' : '#444' }} label={usage.emailAddress} />
                                            <Tooltip title={getTranslatedText("DeleteChildKeyUsageTooltip")}>                                                    
                                                <IconButton onClick={() =>{ 
                                                    setUsageToDelete(usage.usageId)
                                                    setConfirmDialogOpen(true)}} sx={{ position: "absolute", right: 10, top: 4 }}>
                                                    <DeleteIcon />
                                                </IconButton>                                                
                                            </Tooltip>                                                                                                                                        
                                        </Box>
                                    );
                                })}

                                {isLoadingUsages && <>
                                    <Skeleton variant="rectangular" width={'100%'} height={50} sx={{ borderRadius: 10 }} />
                                    <Skeleton variant="rectangular" width={'100%'} height={50} sx={{ mt: 1, borderRadius: 10 }} />
                                </>}

                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1 },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={handleSubmit(onSubmitHandler)}
                                >
                                    <Grid container direction={'row'} spacing={2} sx={{ mt: 3 }}>
                                        <Grid item xs={12}>
                                            <TextField fullWidth id="outlined-basic"
                                                label={getTranslatedText("InviteUserLabel")}
                                                variant="outlined"
                                                color="success"
                                                required
                                                error={!!errors['email']}
                                                helperText={errors['email'] ? errors['email'].message : ''}
                                                {...register('email')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>                                           
                                        <LoadingButton
                                            type="submit" 
                                            loading={isSendingInvitation}
                                            loadingPosition="start"
                                            startIcon={<SendIcon />}
                                            disabled={isSendingInvitation}
                                            color="success"
                                            variant="contained"
                                            sx={{ color: '#fff' }}
                                            fullWidth
                                            size="large">{getTranslatedText("InviteUserButton")}</LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        </Grid>
                    </Grid>

                    <YesNoDialog 
                        title={getTranslatedText("RemoveChildLicenseDialogTitle")}
                        content={getTranslatedText("RemoveChildLicenseDialogContent")}            
                        titleYesButton={getTranslatedText("TEXT_YES")}
                        titleNoButton={getTranslatedText("TEXT_NO")}
                        open={confirmDialogOpen} 
                        onNoClick={handleNoClick} 
                        onYesClick={handleYesClick}
                    />  
                                                                    
                </Drawer>
            </>
        }
    </LanguageConsumer>
    )
}