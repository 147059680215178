import { Typography } from "@mui/material"
import { getTranslation } from "../../../Internationalisation/TranslationService"

export const getNavigationSteps = (props) => {

  return [
    // Navigation - PAGES
    {
      selector: '.onlinehelp-settings-loadFromCockpit-intro',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpIntroMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-loadFromCockpit-isUnlocked',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpIsUnlockedTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpIsUnlockedMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-loadFromCockpit-retry',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpCockpitRetryTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpCockpitRetryMessage") }}></div></div>),
    },        
    {
      selector: '.onlinehelp-settings-loadFromCockpit-selectCockpitAvailable',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpCockpitAvailableTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpCockpitAvailableMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-loadFromCockpit-cockpitServer',
      position: 'center',
      content: () => (
        <div>
          <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpCockpitServerTitle")}</Typography>
          <img
            src="./OnlineHelpImages/OnlineHelpImageLoadFromCockpitDepragCockpitServer.png"
            alt=""
            style={{ width: "100%" }} />
          <div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpCockpitServerMessage") }} />
        </div>),
    },
    {
      selector: '.onlinehelp-settings-loadFromCockpit-customerArea',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpCustomerAreaTitle")}</Typography>
        <img
          src="./OnlineHelpImages/OnlineHelpImageLoadFromCockpitCustomerArea.png"
          alt=""
          style={{ width: "100%" }}/>
        <div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpCustomerAreaMessage") }}></div>
      </div>),
    },
    {
      selector: '.onlinehelp-settings-loadFromCockpit-userName',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpUserNameTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpUserNameMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-loadFromCockpit-password',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsLoadFromCockpitOnlineHelpPasswordTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsLoadFromCockpitOnlineHelpPasswordMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-resetAllCockpit',
      position: 'left',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpResetCockpitTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpResetCockpitMessage") }}></div></div>)
    }

  ]
}
