import { NumericLabelProvider } from "scichart/Charting/Visuals/Axis/LabelProvider/NumericLabelProvider";
import { NumericAxis } from "scichart/Charting/Visuals/Axis/NumericAxis";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { NumberRange } from "scichart/Core/NumberRange";
import { ENumericFormat } from "scichart/types/NumericFormat";
import { ChartResult } from "../Models/ChartResult";

export const addAxis = (sciChart: TWebAssemblyChart, seriesData: ChartResult[]) => {
    const wasmContext = sciChart.wasmContext;

    const xAxis = new NumericAxis(wasmContext, { axisTitleStyle: { fontFamily: 'Fira sans' }, labelStyle: { fontFamily: 'Fira sans' } });
    
    sciChart.sciChartSurface.xAxes.add(xAxis);

    if (!seriesData || !seriesData[0] || seriesData[0].ChartSeries.length === 0) {
        const yAxis = new NumericAxis(wasmContext, { growBy: new NumberRange(0.05, 0.05), axisTitleStyle: { fontFamily: 'Fira sans' }, labelStyle: { fontFamily: 'Fira sans' } });
        sciChart.sciChartSurface.yAxes.add(yAxis);
    }
}


export const addOrUpdateYAxis = (seriesData: ChartResult[], sciChart: TWebAssemblyChart, selectedYAxis: string, isSuperVisualisation: boolean, isLicenced: boolean = false) => {

    if (sciChart && sciChart.sciChartSurface && seriesData[0]) {
        if (!isSuperVisualisation) {
            sciChart.sciChartSurface.yAxes.clear();

            for (let i = 0; i < seriesData[0].ChartSeries.length; i++) {

                if (selectedYAxis === seriesData[0].ChartSeries[i].Title) {
                    const yAxis = new NumericAxis(sciChart.wasmContext, { growBy: new NumberRange(0.05, 0.05), axisTitleStyle: { fontFamily: 'Fira sans' }, labelStyle: { fontFamily: 'Fira sans' } });
                    sciChart.sciChartSurface.yAxes.add(yAxis);

                    addYAxisLableProvider(yAxis, parseFloat(seriesData[0].ChartSeries[i].ScaleFactor),
                        seriesData[0].ChartSeries[i].DigitCount, seriesData[0].ChartSeries[i].UnitAbbreviation, isLicenced);
                }
            }

        } else {
            sciChart.sciChartSurface.yAxes.clear();
            const yAxis = new NumericAxis(sciChart.wasmContext, { growBy: new NumberRange(0.05, 0.05), axisTitleStyle: { fontFamily: 'Fira sans' }, labelStyle: { fontFamily: 'Fira sans' } });
            sciChart.sciChartSurface.yAxes.add(yAxis);

            if (seriesData[0] && seriesData[0].ChartSeries[0]) {
                var firstNotEmptySeries: any = seriesData[0].ChartSeries.find(x => x.HasValues);

                if (firstNotEmptySeries !== undefined) {
                    addYAxisLableProvider(yAxis, parseFloat(firstNotEmptySeries.ScaleFactor),
                        firstNotEmptySeries.DigitCount, firstNotEmptySeries.UnitAbbreviation, isLicenced);
                }
                else {
                    return String("");
                }
            }
        }
    }
}

function addYAxisLableProvider(yAxis: NumericAxis, scaleFactor: number, digitCount: number, unitAbbreviation: string, isLicenced: boolean) {
    yAxis.labelProvider = new NumericLabelProvider({
        labelFormat: ENumericFormat.Decimal,
        labelPrecision: 2,
        cursorLabelFormat: ENumericFormat.Decimal,
        cursorLabelPrecision: 2
    });
    yAxis.labelProvider.formatLabel = (dataValue) => {
        return isLicenced ? String((dataValue / scaleFactor).toFixed(digitCount)) + " " + unitAbbreviation : String((dataValue / scaleFactor).toFixed(1)) + " " + unitAbbreviation;
    };
}

