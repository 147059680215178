import { SeriesOrder } from "../../../Infrastructure/ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { FilterSettings } from "./FilterSettings";
import { SingleVisualizationDto } from "./SingleVisualizationDto";
import { SuperVisualizationDto } from "./SuperVisualizationDto";
import { SynchronizationSettings } from "./SynchronizationSettings";
import { LabellingDistinctiveValuesDto } from "./LabellingDistinctiveValuesDto";    

export interface ChartRequest{
    Settings: ChartRequestSettings,
    Files: ChartRequestFile[],
    SortedList: SeriesOrder[],
    LanguageIdentifier: string    
}

export interface ChartRequestFile {
    Title: string;
    CurveRawContent: string;    
    Id:string;
    IsHidden:boolean
}

export interface ChartRequestSettings {
    FilterSettings: FilterSettings[] | null;
    SynchronizationSettings: SynchronizationSettings | null;
    SuperVisualization: SuperVisualizationDto | null;
    TorqueUnit: TorqueUnit | null;
    SingleVisualization: SingleVisualizationDto | null;
    ImplicitChangeFromResult: boolean;
    LabellingDistinctiveValues: LabellingDistinctiveValuesDto | null;
}

export enum TorqueUnit {
    Nm,
    Ncm,
    Ftlb,
    Inlb,
    Kgcm,
    Kgm
}

