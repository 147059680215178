import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Typography } from "@mui/material";
import { getTranslation, LanguageConsumer } from '../../../../Infrastructure/Internationalisation/TranslationService';
import { useAppDispatch, useAppSelector } from '../../../../Infrastructure/Redux/hooks';
import { CockpitDeviceOrGroupTreeViewModel, loadDevicesAndGroups, updateCheckedStateOfDevice } from '../../DeviceAndGroups/Redux/LoadFromCockpitDeviceAndGroupsSlice';
import { useEffect } from 'react';
import { setNotification, NotificationType } from '../../../../Layout/Notifications/Redux/NotificationsSlice';
import CircleIcon from '@mui/icons-material/Circle';
import { PlaceholderForLoadingDevices } from './PlaceholderForLoadingDevices';

export const LoadFromCockpitDeviceAndGroupTreeView = () => {

    const dispatch = useAppDispatch();
    const devicesOrGroupsFromCockpit = useAppSelector(store => store.loadFromCockpitDeviceAndGroups.cockpitResponse);
    const status = useAppSelector(store => store.loadFromCockpitDeviceAndGroups.status);
    const loadFromCockpit = useAppSelector(store => store.loadFromCockpit);
    const success = useAppSelector(store => store.checkCockpitSettings.success);

    useEffect(() => {
        if (success === true) {
            dispatch(loadDevicesAndGroups({
                cockpitUrl: loadFromCockpit.cockpitUrl,
                cockpitArea: loadFromCockpit.cockpitArea,
                username: loadFromCockpit.credentials.username,
                password: loadFromCockpit.credentials.password
            }))
        }
    }, [dispatch, loadFromCockpit.cockpitArea, loadFromCockpit.cockpitUrl, loadFromCockpit.credentials.password, loadFromCockpit.credentials.username, success])

    useEffect(() => {
        if (status === 'failed') {
            dispatch(setNotification({ type: NotificationType.error, text: getTranslation("ErrorWhileFetchDevicesFromCockpit") }));
        }
    }, [dispatch, status])

    const checkBoxChanged = (clickedItem: CockpitDeviceOrGroupTreeViewModel) => {

        if (clickedItem) {

            var newCheckedState = !clickedItem.isChecked;
            dispatch(updateCheckedStateOfDevice(
                {
                    deviceId: clickedItem.id,
                    checkedState: newCheckedState
                }))
        }
    }

    const createTreeViewNodeChildren = (deviceOrGroup: CockpitDeviceOrGroupTreeViewModel, uniqueKeyParent: string) => {      

        if (deviceOrGroup.children && !(deviceOrGroup.children.length > 0)) {
            return
        }
        return (
            deviceOrGroup.children?.map((childDeviceOrGroup, idx) => {
                const uniqueKeyForChildren = uniqueKeyParent + '.' + idx;

                var displayDeviceOrGroup = !(!childDeviceOrGroup.isDevice && childDeviceOrGroup.children.length === 0)
                return (displayDeviceOrGroup &&
                    <TreeItem key={uniqueKeyForChildren} label={<Grid container wrap='nowrap'>
                        <Grid item sx={{ ml: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            <Checkbox
                                id={`checkbox-${childDeviceOrGroup.id}`}
                                onChange={() => (checkBoxChanged(childDeviceOrGroup))}
                                onClick={e => (e.stopPropagation())}
                                color="success"
                                checked={childDeviceOrGroup.isChecked}
                                indeterminate={childDeviceOrGroup.indeterminateState}
                            />

                        </Grid>
                        <Grid item sx={{ ml: 0 }} style={{maxWidth:'90%', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            {childDeviceOrGroup.isDevice ?
                                <Typography noWrap>
                                    <CircleIcon sx={{ height: "20px", top: "4px", position: 'relative', mr: 1 }} color={childDeviceOrGroup.isOnline ? 'success' : 'error'} />
                                    {childDeviceOrGroup.name}
                                </Typography> :
                                <Typography noWrap style={{ fontWeight: 600 }}>
                                    {childDeviceOrGroup.name}
                                </Typography>}
                        </Grid>
                    </Grid>} nodeId={childDeviceOrGroup.id}>
                        {createTreeViewNodeChildren(childDeviceOrGroup, uniqueKeyForChildren)}
                    </TreeItem>
                )
            }))
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container direction={'row'} spacing={1} >
                    <Grid item xs={12}>

                        {(status === "loading") ?
                            <PlaceholderForLoadingDevices />
                            :
                            <TreeView
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                sx={{ flexGrow: 1, overflowY: 'auto' }}>

                                {devicesOrGroupsFromCockpit && devicesOrGroupsFromCockpit?.map((deviceOrGroup, idx) => {
                                    var showCheckbox = !(!deviceOrGroup.isDevice && deviceOrGroup.children.length === 0)
                                    return (showCheckbox ?
                                        <TreeItem key={idx} label=
                                            {
                                                <Grid container wrap='nowrap'>
                                                    <Grid item sx={{ ml: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>

                                                        <Checkbox
                                                            id={`checkbox-${deviceOrGroup.id}`}
                                                            onChange={() => (checkBoxChanged(deviceOrGroup))}
                                                            onClick={e => (e.stopPropagation())}
                                                            color="success"
                                                            checked={deviceOrGroup.isChecked}
                                                            indeterminate={deviceOrGroup.indeterminateState} />



                                                    </Grid>
                                                    <Grid item sx={{ ml: 0 }} style={{ maxWidth: '90%', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                        {deviceOrGroup.isDevice ?
                                                            <Typography noWrap>
                                                                <CircleIcon sx={{ height: "20px", top: "4px", position: 'relative', mr: 1 }} color={deviceOrGroup.isOnline ? 'success' : 'error'} />
                                                                {deviceOrGroup.name}
                                                            </Typography>
                                                            :
                                                            <Typography noWrap style={{ fontWeight: 600 }}>
                                                                {deviceOrGroup.name}
                                                            </Typography>}
                                                    </Grid>
                                                </Grid>
                                            }
                                            nodeId={deviceOrGroup.id}>
                                            {
                                                createTreeViewNodeChildren(deviceOrGroup, idx.toString())
                                            }
                                        </TreeItem> : <></>
                                    );
                                })}
                            </TreeView>
                        }
                    </Grid>
                </Grid>
            }
        </LanguageConsumer >
    )

}

