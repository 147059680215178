import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ChartRequestSettings, TorqueUnit } from '../../../Features/Home/Models/ChartRequest';
import { RootState } from '../../../Infrastructure/Redux/store';
import { SingleVisualizationModeDto } from '../../../Features/Home/Models/SingleVisualizationModeDto';
import { SuperVisualizationModeDto } from '../../../Features/Home/Models/SuperVisualizationModeDto';
import { SynchronizationMode } from '../../../Features/Home/Models/SynchronizationSettings';
import { TempUnit } from '../Models/TempUnit';
import { SeriesTypeDto } from '../../../Features/Home/Models/SeriesTypeDto';
import { getSeriesForGraphDataAsync } from '../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { saveSeriesRequestsInStore } from '../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice';
import { resetLicence } from '../../../Infrastructure/LicenceService/Redux/LicenceServiceSlice';
import { LabellingDistinctiveValuesModeDto } from '../../Home/Models/LabellingDistinctiveValuesModeDto';
import { AnnotationSettings, AnnotationVisibilityEnum } from '../Models/AnnotationSettings';
import { sendLoadExampleFileToBackend } from '../../Home/HeaderInformation/LoadExampleFile/Redux/LoadExampleFileSlice';

export enum ShowCurrentValue {
  InChart,
  InSeriesLegend,
}

export enum ShareType {
  Chart,
  ChartAndLegend
}

export enum InformationType {
  Page,
  Modal
}

export interface SettingsState {
  language: string,
  chartRequestSettings: ChartRequestSettings,
  tempUnit: TempUnit,
  selectedYAxisForSingleVisualisation: string,
  appTheme: string,
  highlightedSeries: string,
  isCurveAnimationEnabled: boolean,
  showOnlineHelp: boolean,
  showCurrentValuesInChart: ShowCurrentValue,
  annotationSettings: AnnotationSettings,
  isSidebarVisible: boolean,
  activeTab: number,
  shareType: ShareType,
  informationType: InformationType
}

export const initialStateSettings: SettingsState = {
  language: "en",
  chartRequestSettings: {
    TorqueUnit: TorqueUnit.Nm,
    SuperVisualization: { Mode: SuperVisualizationModeDto.None, SeriesType: SeriesTypeDto.None },
    SingleVisualization: { Mode: SingleVisualizationModeDto.Angle },
    SynchronizationSettings: { Mode: SynchronizationMode.None, IndividualTorque: 0.00 },
    FilterSettings: null, //[{ Range1: "", Range2: "", Mode: FilterMode.Torque}]
    ImplicitChangeFromResult: false,
    LabellingDistinctiveValues: { Mode: LabellingDistinctiveValuesModeDto.ThresholdTorqueAndSeatingPoint }
  },
  tempUnit: TempUnit.Celsius,
  selectedYAxisForSingleVisualisation: 'FileFormatTorqueSeries',
  appTheme: "light",
  highlightedSeries: "",
  isCurveAnimationEnabled: true,
  showOnlineHelp: true,
  showCurrentValuesInChart: ShowCurrentValue.InChart,
  annotationSettings: {
    stepNumberVisibility: AnnotationVisibilityEnum.Visible,
    targetAreaVisibility: AnnotationVisibilityEnum.Visible,
    userAnnotationColorAsHex: "#32992C"
  },
  isSidebarVisible: true,
  activeTab: 0,
  shareType: ShareType.Chart,
  informationType: InformationType.Page
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialStateSettings,
  reducers: {
    setInformationType: (state, action) => {
      state.informationType = action.payload
    },
    setShareType: (state, action) => {
      state.shareType = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setAllSettings: (state, action) => {
      return {
        ...action.payload as SettingsState,
      }
    },
    toggleSidebarVisibility: (state) => {
      state.isSidebarVisible = !Boolean(state.isSidebarVisible);
    },
    setStepNumberVisibility: (state, action) => {
      state.annotationSettings.stepNumberVisibility = action.payload;
    },
    setTargetAreaVisibility: (state, action) => {
      state.annotationSettings.targetAreaVisibility = action.payload;
    },
    setUserAnnotationColorAsHex: (state, action) => {
      state.annotationSettings.userAnnotationColorAsHex = action.payload;
    },
    resetUserAnnotationColorAsHex: (state) => {
      state.annotationSettings.userAnnotationColorAsHex = initialStateSettings.annotationSettings.userAnnotationColorAsHex
    },
    setShowCurrentValuesInChart: (state, action) => {
      state.showCurrentValuesInChart = action.payload;
    },
    setOnlineHelp: (state) => {
      window.localStorage.removeItem("userGuide-guideKey");
      state.showOnlineHelp = true;
    },
    resetOnlineHelp: (state) => {
      window.localStorage.removeItem("userGuide-guideKey");
      state.showOnlineHelp = false;
    },
    toggleCurveAnimation: (state) => {
      state.isCurveAnimationEnabled = !Boolean(state.isCurveAnimationEnabled);
    },
    setHighlightedSeries: (state, action) => {
      state.highlightedSeries = action.payload;
    },
    setFilterSettings: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      state.chartRequestSettings.FilterSettings = action.payload;
    },
    setAppTheme: (state, action) => {
      state.appTheme = action.payload;
    },
    setLabellingDistinctiveValues: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      state.chartRequestSettings.LabellingDistinctiveValues = { Mode: action.payload };
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setTorqueUnit: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      state.chartRequestSettings.TorqueUnit = action.payload;
    },
    setSynchronizationMode: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      if (state.chartRequestSettings.SynchronizationSettings)
        state.chartRequestSettings.SynchronizationSettings.Mode = action.payload;
    },
    resetSynchronizationSettings: (state) => {
        state.chartRequestSettings.SynchronizationSettings= initialStateSettings.chartRequestSettings.SynchronizationSettings;
    },
    setSynchronizationIndividualTorque: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      if (state.chartRequestSettings.SynchronizationSettings)
        state.chartRequestSettings.SynchronizationSettings.IndividualTorque = action.payload;
    },
    setTempUnit: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      state.tempUnit = action.payload;
    },
    setSuperVisualisation: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      state.chartRequestSettings.SuperVisualization = { Mode: action.payload.mode, SeriesType: action.payload.seriestype };
    },
    setSingleVisualisation: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      state.chartRequestSettings.SingleVisualization = { Mode: action.payload };
    },
    setSeriesType: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      if (state.chartRequestSettings.SuperVisualization) {
        state.chartRequestSettings.SuperVisualization.SeriesType = action.payload;
      }
    },
    setSelectedYAxis: (state, action) => {
      state.chartRequestSettings.ImplicitChangeFromResult = false;
      state.selectedYAxisForSingleVisualisation = action.payload.length > 0 ? action.payload[0] : state.selectedYAxisForSingleVisualisation;
    },
    resetGeneralSettings: (state) => {
      state.language = initialStateSettings.language;
      state.chartRequestSettings.TorqueUnit = initialStateSettings.chartRequestSettings.TorqueUnit;
      state.appTheme = initialStateSettings.appTheme;
      state.shareType = initialStateSettings.shareType;
      state.informationType = InformationType.Page;
    },
    resetGeneralSettingsWithoutLanguage: (state) => {
      state.chartRequestSettings.TorqueUnit = initialStateSettings.chartRequestSettings.TorqueUnit;
      state.appTheme = initialStateSettings.appTheme;
      state.shareType = initialStateSettings.shareType;
      state.informationType = initialStateSettings.informationType;
    },
    resetCurveAnalysis: (state) => {
      state.chartRequestSettings.SynchronizationSettings = initialStateSettings.chartRequestSettings.SynchronizationSettings;
      state.chartRequestSettings.SuperVisualization = initialStateSettings.chartRequestSettings.SuperVisualization;
      state.chartRequestSettings.SingleVisualization = initialStateSettings.chartRequestSettings.SingleVisualization;
      state.showCurrentValuesInChart = initialStateSettings.showCurrentValuesInChart;
    },
    resetAnnotationSettings: (state) => {
      state.annotationSettings = initialStateSettings.annotationSettings;
      state.chartRequestSettings.LabellingDistinctiveValues = initialStateSettings.chartRequestSettings.LabellingDistinctiveValues
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(getSeriesForGraphDataAsync.fulfilled, sendLoadExampleFileToBackend.fulfilled), (state, action) => {
          if (action.payload[0] && action.payload[0].ChartSeries.length > 0 && state.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.None) {
            var listOfNames = action.payload[0].ChartSeries.filter(x => !x.IsInternalCurve).map(el => { return el.Title });
            var matches = listOfNames.includes(state.selectedYAxisForSingleVisualisation);

            if (!matches) {
              state.selectedYAxisForSingleVisualisation = listOfNames.includes('FileFormatTorqueSeries') ? 'FileFormatTorqueSeries' : listOfNames[0];
            }

          }

          if (action.payload && action.payload[0] && action.payload[0].IsCustom && state.isCurveAnimationEnabled) {
            state.isCurveAnimationEnabled = false;
          }

          if (action.payload && action.payload[0] && action.payload[0].Title === "SuperVisualisation" && state.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.None) {
            state.chartRequestSettings.ImplicitChangeFromResult = true;
            state.chartRequestSettings.SuperVisualization.Mode = SuperVisualizationModeDto.Angle
          }

          if (action.payload && action.payload[0] && action.payload[0].Title === "SuperVisualisation"){
            if (state && state.chartRequestSettings && state.chartRequestSettings.SuperVisualization){
              state.chartRequestSettings.SuperVisualization.SeriesType = action.payload[0].SelectedSeriesType;
            }
          }
        }
      ).addMatcher(isAnyOf(saveSeriesRequestsInStore), (state, action) => {
        if (action.payload && action.payload.Files.length === 1 && action.payload.Files[0].Title.split('.').pop() === "csv" &&
          state.chartRequestSettings && state.chartRequestSettings.SuperVisualization && state.chartRequestSettings.SuperVisualization.Mode !== SuperVisualizationModeDto.None) {
          state.chartRequestSettings.SuperVisualization.Mode = SuperVisualizationModeDto.None
        }
      }).addMatcher(isAnyOf(resetLicence), (state, action) => {
        if (state.chartRequestSettings && state.chartRequestSettings.SuperVisualization && state.chartRequestSettings.SuperVisualization.Mode !== SuperVisualizationModeDto.None) {
          state.chartRequestSettings.SuperVisualization.Mode = SuperVisualizationModeDto.None
        }
      })
  }
});

export const { setInformationType, setShareType, setActiveTab, setAllSettings, setShowCurrentValuesInChart, resetOnlineHelp, setOnlineHelp, setHighlightedSeries, setLanguage, setTempUnit, setTorqueUnit,
  setSynchronizationMode, setSynchronizationIndividualTorque, setSuperVisualisation, setSingleVisualisation, setSeriesType, setSelectedYAxis,
  setAppTheme, setLabellingDistinctiveValues, setFilterSettings, toggleCurveAnimation, setStepNumberVisibility, setTargetAreaVisibility
  , setUserAnnotationColorAsHex, resetUserAnnotationColorAsHex, toggleSidebarVisibility, resetGeneralSettings, resetGeneralSettingsWithoutLanguage, resetCurveAnalysis,resetAnnotationSettings,resetSynchronizationSettings } = settingsSlice.actions;

export const selectLanguage = (state: RootState) => state.settings.language;

export default settingsSlice.reducer;
