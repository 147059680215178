import { SeriesOrder } from "../../../../../Infrastructure/ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { ChartRequest } from "../../../Models/ChartRequest";
import { ChartResult } from "../../../Models/ChartResult";
import { postLoadExampleFile } from "./LoadExampleFilePostApi";

export const postLoadExampleFileToBackend = async (chartRequest: ChartRequest, abortController: AbortController): Promise<ChartResult[]> => {
    let requestPayload = {
        Files: chartRequest.Files,
        Settings: chartRequest.Settings,
        SortedList: [] as SeriesOrder[],
        LanguageIdentifier: chartRequest.LanguageIdentifier
    }
    try {
        let loadExampleFileResponse = await postLoadExampleFile(requestPayload, abortController);
        return Promise.resolve(loadExampleFileResponse);

    } catch (error) {
        return Promise.reject(error);
    }
}
