import React, { useState } from 'react'
import Lottie, { Options } from 'react-lottie';
import addIcon from './JSON/Add.json';
import loadIcon from './JSON/Load.json';
import errorIcon from './JSON/Error.json';

export const LordIcon = ({type, size, speed = .8}) => {
    const [speedy, setSpeed] = useState<number>(speed);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: type === "add" ? addIcon : (type=== "error" ? errorIcon : loadIcon),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      } as Options;

    return(<span onMouseOut={() => setSpeed(.8)} onMouseOver={() => setSpeed(1.2)}><Lottie speed={speedy} options={defaultOptions}
        height={size}
        width={size}
     /></span>)    
}
