import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Infrastructure/Redux/store';

export interface NavigationState {
  isChartFullscreen: boolean;
  isStandalone: boolean;
  isMyDeprag: boolean;
}

const initialState: NavigationState = {
  isChartFullscreen: false,
  isStandalone: false,
  isMyDeprag: false,
};

export const NavigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setFullscreenChart: (state) => {
      state.isChartFullscreen = true;
    },
    resetFullscreenChart: (state) => {
      state.isChartFullscreen = false;
    },
    setStandalone: (state) => {
      state.isStandalone = true;
    },
    resetStandalone: (state) => {
      state.isStandalone = false;
    },
    setIsMyDeprag: (state) => {
      state.isMyDeprag = true;
    },
    resetIsMyDeprag: (state) => {
      state.isMyDeprag = false;
    }
  },
});

export const { setIsMyDeprag, resetIsMyDeprag, setFullscreenChart, resetFullscreenChart,setStandalone,resetStandalone } = NavigationSlice.actions;

export const isChartFullscreen = (state: RootState) => state.navigation.isChartFullscreen;


export default NavigationSlice.reducer;
