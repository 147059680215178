import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { IconButton, Tooltip } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { useAppSelector } from "../../Redux/hooks";

export interface DownloadButtonProp {
    title : string,
    onClickFunction: () => void, 
  }

export const DownloadButton = (props : DownloadButtonProp) => {
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
  const size = 'small';

  const title = props.title;
  const onClickFunction = props.onClickFunction;

  const icon = <FileDownload fontSize={size} />;
  return (
    <LanguageConsumer>

      {({ getTranslatedText }) =>
       
          <Tooltip title={getTranslatedText(title)} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton
                size={size}
                color="success"
                onClick={onClickFunction}
                sx={{
                  color: isDarkMode ? '#c2c0c0' : '#757474',
                  background: isDarkMode ? '#424242' : '#DFE7FD'
                }}
              >
                {icon}
              </IconButton>
         
          </Tooltip>
       
      }

    </LanguageConsumer>
  );
}