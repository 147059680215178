import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppDispatch, useAppSelector } from '../../Infrastructure/Redux/hooks';
import { hideModal } from './Redux/ModalSlice';
import { LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxWidth: 650,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

export const ModalComponent = () => {
    const dispatch = useAppDispatch();
    const isModalOpen = useAppSelector(store => store.modal.isShown);
    const modalData = useAppSelector(store => store.modal);

    const handleClose = () => {
        dispatch(hideModal());
    };

    return (<LanguageConsumer>

        {({ getTranslatedText }) =>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component={'div'} sx={style}>

                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {getTranslatedText(modalData.titleText)}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {getTranslatedText(modalData.bodyText)}
                    </Typography>

                    {modalData.buttons && modalData.buttons}

                </Box>
            </Modal>}
    </LanguageConsumer>
    );
}