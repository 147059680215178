import styled from 'styled-components';

const getColor = (props) => {
    if (props.isDragReject || props.isDragAccept) {
        return 'rgba(255, 255, 255, 0.6)';
    }

    return 'transparent';
  }

  const getPadding = (props) => {
    if (props.isDragReject || props.isDragAccept) {
        return '20px 60px';
    }
    return '0px';
  }

  const getOverlayZIndex = (props) => {
    if (props.isDragReject || props.isDragAccept) {
        return '1';
    }
    return '-1';
  }
  
  export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${props => getPadding(props)};
    border-width: 2px;
    border-radius: 15px;
    border-color: ${props => getColor(props)};
    border-style: solid;
    background-color: transparent;
    color: #bdbdbd;
    outline: none;
    position: relative;
    transition: all .24s ease-in-out;
    &:before {
        background: ${props => getColor(props)};
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: ${props => getOverlayZIndex(props)};
        border-radius: 15px;
    }
  `;