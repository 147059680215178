import {  Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = (props) => {
    if (props.isSuperVisualisation) {
        return [
            // Navigation - PAGES
            {
                selector: '.onlinehelp-home-legendSeries',
                position: 'center',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpLegendSeriesCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpLegendSeriesCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSelect',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpLegendSelectCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpLegendSelectCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-ListItemButton',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpListItemButtonCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpListItemButtonCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serieItem',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpSerieItemCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpSerieItemCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serie-colorIcon',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpColorIconCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpColorIconCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpColorPickerCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpColorPickerCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-titleTypography',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpTitleTypographyCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpTitleTypographyCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-sketchPicker',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpSketchPickerCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpSketchPickerCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColorToStandard',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorToStandardCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorToStandardCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColor',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serie-fileName',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpFileNameCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpFileNameCurvesOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serie-iosSwitch',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpIosSwitchCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpIosSwitchCurvesOverlayMessage") }}></div></div>),
            },
        ]
    }
    else {
        return [
            // Navigation - PAGES
            {
                selector: '.onlinehelp-home-legendSeries',
                position: 'center',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpLegendSeriesSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpLegendSeriesSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSelect',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpLegendSelectSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpLegendSelectSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-ListItemButton',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpListItemButtonSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpListItemButtonSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serieItem',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpSerieItemSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpSerieItemSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serie-colorIcon',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpColorIconSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpColorIconSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpColorPickerSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpColorPickerSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-titleTypography',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpTitleTypographySingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpTitleTypographySingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-sketchPicker',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpSketchPickerSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpSketchPickerSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColorToStandard',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorToStandardSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorToStandardSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColor',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serie-fileName',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpFileNameSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpFileNameSingleCurveAnalysisMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendSeries-serie-iosSwitch',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpIosSwitchSingleCurveAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpIosSwitchSingleCurveAnalysisMessage") }}></div></div>),
            },
        ]

    }


}
