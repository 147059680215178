import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GraphViewerBackendState {
    abortController: AbortController | null;
  }

  export const initialGraphViewerBackendState: GraphViewerBackendState = {
    abortController: null,
  }

  export const graphViewerBackendSlice = createSlice({
    name: 'graphData',
    initialState: initialGraphViewerBackendState,
    reducers: {
        setAbortController: (state, action: PayloadAction<AbortController>) => {
            state.abortController = action.payload;
        }
    }
  });
  
  export default graphViewerBackendSlice.reducer;
  export const { setAbortController} = graphViewerBackendSlice.actions;