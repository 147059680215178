import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { licenseBaseUrl } from '../../../../../Infrastructure/Api/BaseApi';

export interface LicenseMasterInvitationRequest {
    masterKey: string,
    clientId: string,
    emailRecipient: string,
    childKey: string,
    language: string,
}

export interface LicenseMasterSettingsInvitation {
  status: 'idle' | 'loading' | 'failed';
}

export const initialLicenceMasterInvitationState: LicenseMasterSettingsInvitation = {
  status: "idle",
};

export const postMasterSettingsInvitation = createAsyncThunk(
  'licenseMasterInvitation/invite',
  async (request: LicenseMasterInvitationRequest, { rejectWithValue }) => {
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port;
    let url = `${protocol}//${domain}:${port? port : ""}`;
    
    const response = await fetch(licenseBaseUrl + "licensing/invitation", {              
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'masterKey': request.masterKey,
            'clientId': request.clientId
        }),
        body: JSON.stringify({graphViewerUrl: url, emailRecipient: request.emailRecipient, childKey: request.childKey, language: request.language})
    }); 
    var jsonResponse = await response.json();
    return jsonResponse;
  }
);

export const loadMasterSettingsInvitationSlice = createSlice({
  name: 'licenseMasterInvitation',
  initialState: initialLicenceMasterInvitationState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(postMasterSettingsInvitation.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(postMasterSettingsInvitation.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(postMasterSettingsInvitation.rejected, (state, action) => {
        state.status = 'failed';
      })
  }
});


export default loadMasterSettingsInvitationSlice.reducer;
