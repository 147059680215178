import { MouseWheelZoomModifier } from "scichart/Charting/ChartModifiers/MouseWheelZoomModifier";
import { RolloverModifier } from "scichart/Charting/ChartModifiers/RolloverModifier";
import { RubberBandXyZoomModifier } from "scichart/Charting/ChartModifiers/RubberBandXyZoomModifier";
import { YAxisDragModifier } from "scichart/Charting/ChartModifiers/YAxisDragModifier";
import { ZoomExtentsModifier } from "scichart/Charting/ChartModifiers/ZoomExtentsModifier";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { EDragMode } from "scichart/types/DragMode";
import { ChartModifierState, toggleResetChart, toggleResetChartFromButton, toggleZoomIn, toggleZoomOut } from "../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { MouseOverAnnotationModifier } from "./MouseOverAnnotationModifier";
import {SeriesSelectionModifier } from "scichart/Charting/ChartModifiers/SeriesSelectionModifier";
import { easing } from "scichart/Core/Animations/EasingFunctions";
import { XAxisDragModifier } from "scichart/Charting/ChartModifiers/XAxisDragModifier";
import { KeyboardZoomPanModifier } from "./KeyboardZoomPanModifier";
import { EExecuteOn } from "scichart/types/ExecuteOn";
import { ZoomPanModifier } from "scichart/Charting/ChartModifiers/ZoomPanModifier";
import {RolloverModifierEx } from "./CustomRolloverModifier"
import { AppStore } from "../../../Infrastructure/Redux/store";
import { CursorModifier } from "scichart/Charting/ChartModifiers/CursorModifier";

export const addChartModifier = (sciChart: TWebAssemblyChart, showInChart: boolean,
    store: AppStore) => {
        const keyboardZoomPanModifier = new KeyboardZoomPanModifier();
        const mouseWheelZoomModifier = new MouseWheelZoomModifier();
        const rubberBandZoomModifier = new RubberBandXyZoomModifier({executeOn: EExecuteOn.MouseLeftButton});
        const paningModifier = new ZoomPanModifier({executeOn: EExecuteOn.MouseRightButton});
        const zoomExtentsModifier = new ZoomExtentsModifier({
            isAnimated: true,
            onZoomExtents: () => {
                sciChart.sciChartSurface.zoomExtents(0,  easing.outExpo, () => {
                    sciChart.sciChartSurface.xAxes.asArray().forEach(axis => {
                        axis.zoomBy(0.05, 0.05);
                    });
        
                    sciChart.sciChartSurface.yAxes.asArray().forEach(axis => {
                        axis.zoomBy(0.05, 0.05);
                    });
                });
                return false;
            }
        });

        const rolloverModifier = new RolloverModifierEx({
            id: 'rollover',
            showRolloverLine: false,
            showTooltip: showInChart,
            snapToDataPoint: false,
            allowTooltipOverlapping: false,
            store
        }); 

        const cursorModifier = new CursorModifier({
            crosshairStroke: "#32992c",
            crosshairStrokeThickness: 1,
            tooltipContainerBackground: "#000",
            tooltipTextStroke: "#32992c",
            showTooltip: false,
            axisLabelFill: "#32992c",
            axisLabelStroke: "#fff",
            crosshairStrokeDashArray: [1, 3],
            showAxisLabels: false
        });


        const mouseAnnotationModifier = new MouseOverAnnotationModifier(store); 
        const yAxisDragModifier = new YAxisDragModifier({dragMode: EDragMode.Panning});
        const xAxisDragModifier = new XAxisDragModifier({dragMode: EDragMode.Panning}); 
        const seriesSelectionModifier = new SeriesSelectionModifier();

        sciChart.sciChartSurface.chartModifiers.add(cursorModifier);
        sciChart.sciChartSurface.chartModifiers.add(zoomExtentsModifier);
        sciChart.sciChartSurface.chartModifiers.add(rubberBandZoomModifier);
        sciChart.sciChartSurface.chartModifiers.add(mouseWheelZoomModifier);

        sciChart.sciChartSurface.chartModifiers.add(rolloverModifier);
        sciChart.sciChartSurface.chartModifiers.add(mouseAnnotationModifier);
        sciChart.sciChartSurface.chartModifiers.add(yAxisDragModifier);
        sciChart.sciChartSurface.chartModifiers.add(xAxisDragModifier);
        sciChart.sciChartSurface.chartModifiers.add(seriesSelectionModifier);
        sciChart.sciChartSurface.chartModifiers.add(keyboardZoomPanModifier);
        sciChart.sciChartSurface.chartModifiers.add(paningModifier);
}


export const checkForUserInteractionModifiers = (sciChart: TWebAssemblyChart, chartModifier: ChartModifierState, dispatch: (action: {payload: undefined; type: string;}) => {payload: undefined;type: string;}, isRedrawFinished: boolean, setRedrawFinished: React.Dispatch<React.SetStateAction<boolean>>) => {
    if(sciChart){
        if(chartModifier.resetChart && isRedrawFinished) {
            sciChart.sciChartSurface.zoomExtents(0,  easing.outExpo, () => {
                sciChart.sciChartSurface.xAxes.asArray().forEach(axis => {
                    axis.zoomBy(0.05, 0.05);
                });
    
                sciChart.sciChartSurface.yAxes.asArray().forEach(axis => {
                    axis.zoomBy(0.05, 0.05);
                });
            });
         
            dispatch(toggleResetChart())
            setRedrawFinished(false);
        }

        if(chartModifier.resetChartFromButton) {
            sciChart.sciChartSurface.zoomExtents(0,  easing.outExpo, () => {
                sciChart.sciChartSurface.xAxes.asArray().forEach(axis => {
                    axis.zoomBy(0.05, 0.05);
                });
    
                sciChart.sciChartSurface.yAxes.asArray().forEach(axis => {
                    axis.zoomBy(0.05, 0.05);
                });
            });
            dispatch(toggleResetChartFromButton())
        }

        if(chartModifier.zoomIn){
            sciChart.sciChartSurface.xAxes.asArray().forEach(axis => {
                axis.zoomBy(-0.1, -0.1);
            });

            sciChart.sciChartSurface.yAxes.asArray().forEach(axis => {
                axis.zoomBy(-0.1, -0.1);
            });
            dispatch(toggleZoomIn());
        }


        if(chartModifier.zoomOut){
            sciChart.sciChartSurface.xAxes.asArray().forEach(axis => {
                axis.zoomBy(0.1, 0.1);
            });

            sciChart.sciChartSurface.yAxes.asArray().forEach(axis => {
                axis.zoomBy(0.1, 0.1);
            });
            dispatch(toggleZoomOut());
        }
    }
}

export const updateChartModifier = (sciChart: TWebAssemblyChart, showInChart: boolean) => {
    var rolloverModifier = sciChart.sciChartSurface.chartModifiers.getById('rollover') as RolloverModifier;
    rolloverModifier.showTooltip = showInChart;
}


