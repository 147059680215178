import { decode, encode } from "messagepack";
import axios from 'axios';

export const baseUrl = (globalThis.Settings ? globalThis.Settings.graphViewerApiBaseAddress : "http://testdomain:5012") + "/api/v1.0/"
export const licenseBaseUrl = (globalThis.Settings ? globalThis.Settings.graphViewerLicensingApiBaseAddress : "http://testdomain:5144") + "/api/v1.0/"

export const messagePackClient =  axios.create({  
  baseURL: baseUrl,
  responseType: 'arraybuffer',  
  transformResponse: [function (data:any) {
      const decoded = decode(data);
      return decoded;
   }],
   transformRequest: [(data, headers: any) => {
      headers['Content-Type'] = "application/x-msgpack"; 
      headers['Accept'] = "application/x-msgpack";
      var encoded = encode(data);
      return encoded.buffer;     
   }]
});

export const client = axios.create({
    baseURL: baseUrl,

  });