import { MainCategories } from "../AnalyticsCategories";

export enum ZoomChartEvents {
    Zoom = MainCategories.ZoomChart + "Zoom"
}

export enum ZoomChartItems {
    ZoomIn = "ZoomIn",
    ZoomOut = "ZoomOut",
    ResetZoom = "ResetZoom"
}