import './Style/Navigation.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { NavLink, useLocation } from "react-router-dom";
import PageBar  from './Views/Pages';
import ToolBar from './Views/Tools';
import { useAppSelector } from '../../Infrastructure/Redux/hooks';
import { isChartFullscreen } from './Redux/NavigationSlice';


const Navigation = () => {
   
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const isChartFullscreenToggle = useAppSelector(isChartFullscreen);
    const location = useLocation();

    return !location.search.includes("standalone") ? (
        <AppBar position={ isChartFullscreenToggle ? "relative" : "sticky"} enableColorOnDark={false} elevation={0}>
            <Container maxWidth="xl">
                <Toolbar>
                    <Box component={"div"} sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {/* <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            className={isDarkMode ? "logo-dark" : "logo-light"}
                            sx={{ mr: 1, m: 1, minWidth: "220px", display: { flexGrow: 1, xs: 'flex', md: 'flex' } }}
                        >
                            <NavLink to={"/"}>
                                <span style={{color: isDarkMode ? "#fff" : "#444"}}>GRAPH</span><span>VIEWER</span>
                            </NavLink>
                        </Typography> */}

                        <Typography fontSize={27} fontFamily={'Fira Sans'} fontWeight={900} sx={{ color: '#32992c' }} color='success'>GRAPHVIEWER</Typography>
                        <Typography fontFamily={'Dancing Script'} fontSize={22} sx={{ position: 'relative', ml: -1, mt: 2 }}>Cloud</Typography>
                    </Box>
                  
                    {/* <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        className={isDarkMode ? "logo-dark" : "logo-light"}
                        sx={{ flexGrow: 1, color: '#444', display: { xs: 'flex', md: 'none' }, alignSelf: "center", alignContent: "center" }}
                    >
                        <NavLink to={"/"}>
                        <span style={{color: isDarkMode ? "#fff" : "#444"}}>GRAPH</span><span>VIEWER</span>
                        </NavLink>
                    </Typography> */}
                        <Box component={'div'} sx={{display: { xs: 'flex', md: 'none' }, flexGrow: 1}}>
                        <Typography fontSize={27} fontFamily={'Fira Sans'} fontWeight={900} sx={{color: '#32992c' }} color='success'>GRAPHVIEWER</Typography>
                        <Typography fontFamily={'Dancing Script'} fontSize={22} sx={{  position: 'relative', ml: -1, mt: 2 }}>Cloud</Typography>
                        </Box>
                   
                        <PageBar />
                   
                                                
                    {location.pathname === "/" && <ToolBar />}
                    

                </Toolbar>
            </Container>
        </AppBar>
    ) : <></>;
};

export default Navigation;