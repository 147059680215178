import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import { FooterItems } from '../../../Infrastructure/Analytics/Model/Categories/FooterEnums';
import { analyze_OpenFooter } from '../../../Infrastructure/Analytics/Redux/AnalyticsSlice';

export function getSocialIcons(dispatch) {
    return [
        { icon: <LinkedInIcon />, link: "https://de.linkedin.com/company/deprag", bgLight: "#f7f7f7", bgDark: '#444', trackClick: () => dispatch(analyze_OpenFooter({ target: FooterItems.LinkedIn })) },
        { icon: <InstagramIcon />, link: "https://www.instagram.com/deprag_amberg/", bgLight: "#f7f7f7", bgDark: '#444', trackClick: () => dispatch(analyze_OpenFooter({ target: FooterItems.Instagram })) },
        { icon: <FacebookIcon />, link: "https://www.facebook.com/people/DEPRAG/100057522254996/", bgLight: "#f7f7f7", bgDark: '#444', trackClick: () => dispatch(analyze_OpenFooter({ target: FooterItems.Facebook })) },        
        { icon: <YouTubeIcon />, link: "https://www.youtube.com/DEPRAGInternational", bgLight: "#f7f7f7", bgDark: '#444', trackClick: () => dispatch(analyze_OpenFooter({ target: FooterItems.Youtube })) },
        { icon: <img alt="DEPRAG logo" width="100" height="30" title="DEPRAG SCHULZ GMBH u. CO. KG" src="./deprag_machines.png" />, link: "https://www.deprag.com", bgLight: "transparent", bgDark: 'transparent', trackClick: () => dispatch(analyze_OpenFooter({ target: FooterItems.DepragHomepage })) },
    ];
}