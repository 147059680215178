export enum SeriesTypeDto {
    None,
    Workload,
    RotationalSpeed,
    Torque,
    Angle,
    ErrorCode,
    TemperatureMotor,
    StepNumber,
    CurrentMotor,
    TemperaturePowerUnit,
    TorqueAdditionalSensor1,
    AngleAdditionalSensor1,
    TorqueMotor,
    AngleMotor,
    TorqueAdditionalSensor2,
    AngleAdditionalSensor2,
    AnalogStop,
    DownForce,
    Position,
    FeedRate,
    DetectionOfPenetration,
    StatusJaws,
    FeedMotionCurrent,
    DownHolderPosition,
    PositionFeedRate,
    FeedRateTargetValue,
    DownForceTargetValue,
    EngagementFound,
    DownHolderTargetValue,
    MotorCurrentScrewdriver,
    Friction,
    MonitoredArea,
    SeatingPointDetection,
}