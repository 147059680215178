import React from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { Grid, Typography, Grow, Card, CardContent, Container, Button } from '@mui/material';
import { LordIcon } from "../../Infrastructure/LordIcons/LordIcon";

export class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = { 
        hasError: false 
    };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
    //  logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return (<LanguageConsumer>
            {({getTranslatedText}) => 
            <>
                <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                    <Grow in={true} appear={true}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Card elevation={12}>
                                    <CardContent>
                                        <Grid container direction={'row'} spacing={2} sx={{ textAlign: 'center' }}>
                                            <Grid item xs={12}>
                                                <LordIcon size={200} type={'error'} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography>{getTranslatedText("OpsSomethingWentWrong")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant='outlined' onClick={() => window.location.reload()} color={'success'}>{getTranslatedText("ReloadPage")}</Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grow>
                </Container>
            </>
            }
        </LanguageConsumer>
       );
      }
  
      return this.props.children; 
    }
  }