import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

interface LicenseFormHeaderProps {
    image: string,
    title: string,
    subtitle?: string,
}

export const LicenseFormHeader = ({image, title, subtitle = ""} : LicenseFormHeaderProps) => {

    return (
        <LanguageConsumer>
    {({ getTranslatedText }) =>
        <Box component={'div'} sx={{backgroundImage: `url('${image}')`, backgroundSize: 'cover', width: "100%", maxWidth: '100%', minHeight: '180px',  margin: 0, padding: 0}}>
            <Typography sx={{m: 3, mt: 5, mb: 0, color: '#fff'}} variant={'h4'}>
                {title}
            </Typography>
            <Typography sx={{m: 3, mt: 1, color: '#f9f9f9', fontWeight: 200}} variant={'h6'}>
                {subtitle}
            </Typography>
        </Box>
    }
    </LanguageConsumer>
    );
}