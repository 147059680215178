import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export interface ConfirmDialogProps{
    open: boolean;
    title: string;
    content: string;
    onOkClick: () => void;    
    titleOkButton: string;
}

export const ConfirmDialog = ({open, title, titleOkButton, onOkClick, content}: ConfirmDialogProps) => {
    return(
        <Dialog
        open={open}        
        keepMounted
        onClose={onOkClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>        
        <DialogContent>
            <div dangerouslySetInnerHTML={{__html:content}}>             
            </div>
        </DialogContent>

        <DialogActions>                  
          <Button color="info" variant="outlined" fullWidth size="large" onClick={onOkClick}>{titleOkButton}</Button>                                      
        </DialogActions>
      </Dialog>
    );
}