/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { createSciChartAnnotation } from "../../Annotations/AnnotationService";
import { labellingDistinctiveValueAreaAnnotationPrefix } from "../../Annotations/Redux/AnnotationDistinctiveValueAreaSlice";
import { LabellingDistinctiveValuesModeDto } from "../Models/LabellingDistinctiveValuesModeDto";

interface UseHandleChangeLabellingDistinctiveValueAreaAnnotationsArgs {
    sciChart: TWebAssemblyChart;
}

function shouldLabel(labellingMode, title) {
    if (labellingMode === LabellingDistinctiveValuesModeDto.ThresholdTorque) {
        return (title === 'TEXT_ProgParamStartTorqueSpeed2' || title === 'TEXT_ProgParamSwitchTorque');
    }
    else if (labellingMode === LabellingDistinctiveValuesModeDto.SeatingPoint) {
        return (title === 'TEXT_DrehmomentKopfauflage_Zurueckgerechnet');
    }
    else if (labellingMode === LabellingDistinctiveValuesModeDto.ThresholdTorqueAndSeatingPoint) {
        return true;
    }
    else {
        return false;
    }
}

export const useHandleChangeLabellingDistinctiveValueAreaAnnotations = ({ sciChart }: UseHandleChangeLabellingDistinctiveValueAreaAnnotationsArgs) => {
    const labellingDistinctiveValueAreaAnnotations = useAppSelector(store => store.annotationLabellingDistinctiveValueArea.annotations);
    const labellingMode = useAppSelector(store => store.settings.chartRequestSettings.LabellingDistinctiveValues?.Mode);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            let annotationsToDelete = sciChart.sciChartSurface.annotations.asArray().filter(a => a.id.startsWith(labellingDistinctiveValueAreaAnnotationPrefix));
            for (let annotation of annotationsToDelete) {
                let annotationToRemove = sciChart.sciChartSurface.annotations.getById(annotation.id);
                sciChart.sciChartSurface.annotations.remove(annotationToRemove);
            }
            labellingDistinctiveValueAreaAnnotations.filter(x => x.visible).forEach(annotation => {

                let annotationToAdd = createSciChartAnnotation(annotation);

                if (shouldLabel(labellingMode, annotation.title)) {
                    if (annotationToAdd) {
                        annotationToAdd.forEach(x => sciChart.sciChartSurface.annotations.add(x));
                    }
                }
            });
        }
    }, [labellingDistinctiveValueAreaAnnotations])
}
