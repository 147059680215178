import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { toggleInformationModal } from "../Redux/InformationModalSlice";
import { Dialog, DialogContent, Paper, DialogTitle, IconButton } from "@mui/material";
import { Information } from "../Information";
import Draggable from 'react-draggable';
import { createRef, useEffect } from "react";
import './Style/InformationModal.css';
import { Close } from "@mui/icons-material";

const PaperComponent = (props) => {
    return (
        <Draggable
        bounds='parent'
             cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const InformationModal = () => {
    const dispatch = useAppDispatch();
    const isModalOpen = useAppSelector(store => store.informationModal.isShown);
    var scrollRef = createRef<HTMLDivElement>();

    const handleClose = () => {
        dispatch(toggleInformationModal());
    };

    useEffect(() => {
        if (isModalOpen) {
            setTimeout(() => {
                scrollRef && scrollRef.current && scrollRef.current.scrollTo(0, 0)
            }, 100);
        }
    }, [isModalOpen])


    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>

                    <Dialog PaperComponent={PaperComponent} onClose={handleClose} open={isModalOpen} maxWidth={'lg'} PaperProps={{ sx: { height: "80%", borderRadius: 2 } }}>
                        <DialogTitle sx={{ cursor: "move"}} className='dialogTitle'>
                            {getTranslatedText("InformationsGraphViewerTitle")}
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent ref={scrollRef} sx={{pt: 3}}>

                            <Information />

                        </DialogContent>

                    </Dialog>


                </>
            }
        </LanguageConsumer>
    )
}