/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { SciChartSurface, TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import "./Style/SciChart.css";
import { Card, Box, CardContent, Grid, Grow } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Infrastructure/Redux/hooks';
import { SeriesLegend } from '../../Features/SeriesLegend/SeriesLegend';
import { getSeriesForGraphDataAsync, isCustomMode, selectSeries } from '../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { useDropzone } from 'react-dropzone'
import { saveSeriesRequestsInStore } from '../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice';
import { addChartModifier } from './SciChartHelper/ChartModifier';
import { addAxis, addOrUpdateYAxis } from './SciChartHelper/Axis';
import { addTheme } from './SciChartHelper/Theme';
import { ChartRequest } from './Models/ChartRequest';
import { resetLoadingIndicator } from '../../Infrastructure/LoadingIndicator/LoadingIndicatorSlice';
import { SuperVisualizationModeDto } from './Models/SuperVisualizationModeDto';
import { SupportedFileFormats } from '../../Infrastructure/SupportedFileFormats/SupportedFileFormats';
import { NotificationType, setNotification } from '../../Layout/Notifications/Redux/NotificationsSlice';
import { getTranslation, LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';
import { Filter } from '../../Features/Filter/Filter';
import { TimeAngleSync } from '../../Features/TimeSync/TimeAngleSync';
import { SingleVisualizationModeDto } from './Models/SingleVisualizationModeDto';
import { Share } from '../../Features/Share/Share';
import { Helmet } from "react-helmet";
import { Container } from './SciChartHelper/DragAndDropContainer/DragAndDropContainer';
import { ShareType, ShowCurrentValue } from '../../Features/Settings/Redux/SettingsSlice';
import { HeaderInformation } from './HeaderInformation/HeaderInformation';
import { LoadFromCockpit } from '../../Features/LoadFromCockpit/Components/LoadFromCockpit';
import { LoadFromController } from '../../Features/LoadFromController/LoadFromController';
import { LoadFilesItems } from '../../Infrastructure/Analytics/Model/Categories/LoadFilesEnums';
import { analyze_LoadFiles } from '../../Infrastructure/Analytics/Redux/AnalyticsSlice';
import { setUsedForSingleUseChartRequestFile } from '../../Infrastructure/Temporarily/Redux/TemporarilySlice';
import { SeriesTypeDto } from './Models/SeriesTypeDto';
import { useStore } from 'react-redux';
import { AppStore } from '../../Infrastructure/Redux/store';
import { AnalyticsLegend } from '../AnalyticsLegend/AnalyticsLegend';
import { useHandleChangeChartRequestSettings } from './Hooks/useHandleChangeChartRequestSettings';
import { useHandleChangeChartModifierAnnotations } from './Hooks/useHandleChangeChartModifierAnnotations';
import { useHandleChangeLabellingDistinctiveValueAreaAnnotations } from './Hooks/useHandleChangeLabellingDistinctiveValueAreaAnnotations';
import { useHandleChangeMonitorAnnotations } from './Hooks/useHandleChangeMonitorAnnotations';
import { useHandleChangeSeriesData } from './Hooks/useHandleChangeSeriesData';
import { useHandleChangeStepNumberAnnotations } from './Hooks/useHandleChangeStepNumberAnnotations';
import { useHandleChangeTargetAreaAnnotations } from './Hooks/useHandleChangeTargetAreaAnnotations';
import { useHandleShare } from './Hooks/useHandleShare';
import { useHandleChangeUserAnnotations } from './Hooks/useHandleChangeUserAnnotations';
import { useHandleChangeYAxis } from './Hooks/useHandleChangeYAxis';
import { useHandleIsCurveAnimationEnabled } from './Hooks/useHandleIsCurveAnimationEnabled';
import { useHandleSwitchAppTheme } from './Hooks/useHandleSwitchAppTheme';
import { useHandleSwitchShowCurrentValuesInChart } from './Hooks/useHandleSwitchShowCurrentValuesInChart';
import { useHandleReloadOfActualFromController } from './Hooks/useHandleReloadOfActualFromController';
import { useHandleNewSeriesDataChangeInColorOrLicence } from './Hooks/useHandleNewSeriesDataChangeInColorOrLicence';
import { useHandleIsRedrawFinished } from './Hooks/useHandleIsRedrawFinished';
import { useHandleChangeChartModifiers } from './Hooks/useHandleChangeChartModifiers';
import { SciChartDefaults } from "scichart/Charting/Visuals/SciChartDefaults";
import { CustomAnnotation } from 'scichart/Charting/Visuals/Annotations/CustomAnnotation';
import { useHandlePossibleSpecificDeletionOfUserAnnotation } from './Hooks/useHandlePossibleSpecificDeletionOfUserAnnotation';
import { useHandleChangeDeveloper } from './Hooks/useHandleChangeDeveloper';
import { selectFilesFromDialog } from '../../Infrastructure/ImportProvider/ImportProvider';
import { useChangeSeriesColor } from './Hooks/useChangeSeriesColor';
import { useChangeSeriesVisibility } from '../../Infrastructure/ChartSeriesVisibility/Hooks/useChartSeriesVisibility';
import { resetGraphViewerForNewFiles } from './HeaderInformation/ClearGraphViewer/ClearGraphViewer';
import { toggleShouldToggleScreenshotDrawerFromParent } from '../Share/Redux/ShareSlice';
import { useCheckApiKey } from './Hooks/useCheckApiKey';

export const SciChart = () => {

    const [sciChart, setSciChartSurface] = useState<TWebAssemblyChart>(null as unknown as TWebAssemblyChart);
    const savedOrderedSeries = useAppSelector(store => store.chartSeriesOrder.seriesOrders);

    const didMount = useRef(false);
    const sciChartCardRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const store: AppStore = useStore();
    const location = useLocation();
    const [seriesToHighlight] = useState<string>("");
    const [distinctiveValueToHighlight] = useState<string>("");
    const [isRedrawFinished, setRedrawFinished] = useState(false);
    const [gridForLegendsHeight, setGridForLegendsHeight] = useState<string>("100%");

    const seriesData = useAppSelector(selectSeries);
    const isSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const languageIdentifier = useAppSelector(store => store.settings.language);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const selectedYAxis = useAppSelector(store => store.settings.selectedYAxisForSingleVisualisation);
    const appTheme = useAppSelector(store => store.settings.appTheme);
    const isCurveVisualisationAngle = useAppSelector(store => store.settings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Angle ||
        store.settings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Torque);
    const isCurveSuperVisualisationAngle = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Angle ||
        store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Torque);
    const isCurveSuperVisualisationTime = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Time);
    const isCurveAnimationEnabled = useAppSelector(store => store.settings.isCurveAnimationEnabled);
    const isChartRequestSeriesAvailable = useAppSelector(store => store.chartRequestData.series !== null);

    const customMode = useAppSelector(isCustomMode);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
    const showCurrentValuesInChart = useAppSelector(store => store.settings.showCurrentValuesInChart === ShowCurrentValue.InChart);
    const singleUseChartRequestFile = useAppSelector(store => store.temporarily.singleUseChartRequestFile);
    const isSidebarVisible = useAppSelector(store => store.settings.isSidebarVisible);
    const isChartFullscreen = useAppSelector(store => store.navigation.isChartFullscreen);
    const seriesColors = useAppSelector(store => store.chartSeriesColors.colors);
    const seriesVisibilities = useAppSelector(store => store.chartSeriesVisibility.seriesVisibilities);
    const isShareTypeChart = useAppSelector(store => store.settings.shareType === ShareType.Chart);
    const shouldToggleScreenshotDrawerFromParent = useAppSelector(store => store.share.shouldToggleScreenshotDrawerFromParent);
    const isMyDeprag = useAppSelector(store => store.navigation.isMyDeprag);
    
    useCheckApiKey();
    useHandleChangeChartRequestSettings();
    useHandleChangeChartModifierAnnotations({ sciChart });
    useHandleChangeLabellingDistinctiveValueAreaAnnotations({ sciChart });
    useHandleChangeMonitorAnnotations({ sciChart });
    useHandleChangeSeriesData({ sciChart });
    useHandleChangeDeveloper({ sciChart });
    useHandleChangeStepNumberAnnotations({ sciChart });
    useHandleChangeTargetAreaAnnotations({ sciChart });
    useHandleChangeUserAnnotations({ sciChart });
    useHandleChangeYAxis({ sciChart });
    useHandleIsCurveAnimationEnabled({ sciChart });
    useHandleSwitchAppTheme({ sciChart });
    useHandleSwitchShowCurrentValuesInChart({ sciChart });
    useHandleReloadOfActualFromController();
    useHandleNewSeriesDataChangeInColorOrLicence({ setRedrawFinished, didMount, sciChart });
    useHandleIsRedrawFinished({ didMount, isRedrawFinished })
    useHandleChangeChartModifiers({ sciChart, isRedrawFinished, setRedrawFinished });
    useHandlePossibleSpecificDeletionOfUserAnnotation({ sciChart });
    useChangeSeriesColor(sciChart, seriesColors, seriesData);
    useChangeSeriesVisibility(sciChart, seriesVisibilities);

    const { makeScreenShot } = useHandleShare(ref.current as HTMLDivElement);

    useEffect(() => {
        if(shouldToggleScreenshotDrawerFromParent){
            makeScreenShot();
            dispatch(toggleShouldToggleScreenshotDrawerFromParent());
        }
    }, [shouldToggleScreenshotDrawerFromParent])


    const wrongFileFormat = () => {
        dispatch(setNotification({ type: NotificationType.error, text: getTranslation("SomeFilesNotSupported") }));
        dispatch(resetLoadingIndicator());
    };

    const selectFiles = async (acceptedFiles: File[]) => {
        selectFilesFromDialog(
            acceptedFiles,
            dispatch,
            chartRequestSettings,
            languageIdentifier,
            savedOrderedSeries,
            isSuperVisualisation,
            isLicenced,
            chartRequest);
    }

    const loadChartRequestFileForSingleSerie = () => {

        var chartRequestFile = {
            Settings:
            {
                FilterSettings: chartRequestSettings.FilterSettings,
                ImplicitChangeFromResult: chartRequestSettings.ImplicitChangeFromResult,
                LabellingDistinctiveValues: chartRequestSettings.LabellingDistinctiveValues,
                SingleVisualization: chartRequestSettings.SingleVisualization,
                SuperVisualization: {
                    Mode: SuperVisualizationModeDto.None,
                    SeriesType: SeriesTypeDto.None
                },
                SynchronizationSettings: chartRequestSettings.SynchronizationSettings,
                TorqueUnit: chartRequestSettings.TorqueUnit
            },
            SortedList: [],
            Files: [singleUseChartRequestFile?.ChartRequestFile],
            LanguageIdentifier: languageIdentifier
        } as ChartRequest;

        if (chartRequestFile.Files.length > 0) {
            resetGraphViewerForNewFiles(dispatch, SeriesTypeDto.Angle);
            dispatch(getSeriesForGraphDataAsync(chartRequestFile));
            dispatch(saveSeriesRequestsInStore(chartRequestFile));
            dispatch(setUsedForSingleUseChartRequestFile());
        }
    }

    const toggleStrokeWidthOnHover = (parentKey: number, childKey: number, strokeWidth: number, name: string) => {
        sciChart.sciChartSurface.renderableSeries.asArray().forEach((rs) => {
            
            if (rs.getDataSeriesName() === name) {
                rs.strokeThickness = strokeWidth;
                return
            }
            rs.strokeThickness = 1.5;
        });
    }

    const toggleDistinctiveValueWidthOnHover = (X: number, Y: number, mouseOver: boolean) => {
        sciChart.sciChartSurface.annotations.asArray()
            .filter((a) => a.id.startsWith("labellingDistinctiveValueAreaAnnotation_"))
            .forEach((a) => {
                if ((mouseOver && X === a.x1) || !mouseOver) {
                    (a as CustomAnnotation).opacity = 1;
                }
                else {
                    (a as CustomAnnotation).opacity = 0.3;
                }
            });
    }
    

    const checkFilesAndStartIfFilesAreValid = (files: File[]) => {
        if (files && files.length > 0) {
            var notValidFiles = [] as string[];
            files.forEach(element => {
                var fileEnding = "." + element.name.split(".").pop();
                var supportedFiles = SupportedFileFormats();
                if (!supportedFiles.includes(fileEnding)) {
                    notValidFiles.push(fileEnding);
                }
            });
        }
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 500,
        noDragEventsBubbling: true,
        onDropAccepted: (files) => {
            checkFilesAndStartIfFilesAreValid(files)
        },
        onDrop: (acceptedFiles) => {
            selectFiles(acceptedFiles);
            dispatch(analyze_LoadFiles({ source: LoadFilesItems.FromDragAndDrop, nrOfFiles: acceptedFiles.length }))
        },
        noClick: true,
        onDropRejected: () => wrongFileFormat()
    });

    SciChartSurface.configure({
        dataUrl: "/scichart2d.data",
        wasmUrl: "/scichart2d.wasm"
    });


    SciChartDefaults.enableResampling = true;
    SciChartDefaults.useSharedCache = true;

    const initSciChart = async () => {
        var sciChartRuntimeLicenseKey = globalThis.sciChartLicenseKey;

        SciChartSurface.setRuntimeLicenseKey(sciChartRuntimeLicenseKey);

        const theme = addTheme(appTheme);

        const sciChart = await SciChartSurface.create("sciChart", { theme });
        sciChart.sciChartSurface.rendered.subscribe(() => { handleSciChartRendered(sciChart) })
        sciChart.sciChartSurface.domChartRoot.tabIndex = sciChart.sciChartSurface.domChartRoot.tabIndex ?? 0;

        addAxis(sciChart, seriesData);
        addOrUpdateYAxis(seriesData, sciChart, selectedYAxis, isSuperVisualisation, isLicenced);
        addChartModifier(sciChart, showCurrentValuesInChart, store);

        return sciChart;
    }
    
    const handleSciChartRendered = (sciChart: TWebAssemblyChart) => {
        if (sciChart && sciChart.sciChartSurface && !singleUseChartRequestFile.ChartRequestFileUsed) {
            loadChartRequestFileForSingleSerie()
        }
        sciChart.sciChartSurface.rendered.unsubscribeAll()
    }

    useEffect(() => {
        initSciChart().then(sciChart =>
            setSciChartSurface(sciChart)
        )
    }, []);

    const clearChartHandler = () => {
        if (sciChart.sciChartSurface.xAxes.get(0)) {
            sciChart.sciChartSurface.xAxes.get(0).labelProvider.formatLabel = (dataValue) => {
                return String(dataValue.toFixed(1));
            }
        }

        if (sciChart.sciChartSurface.yAxes.get(0)) {
            sciChart.sciChartSurface.yAxes.get(0).labelProvider.formatLabel = (dataValue) => {
                return String(dataValue.toFixed(1));
            }
        }
    }

    return (<React.Fragment>
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>
                    <Helmet>
                        <title>{seriesData && seriesData[0] ? (isSuperVisualisation ? getTranslatedText("SuperVisualisation") : seriesData[0].Title) : getTranslatedText("HomeGraphViewerTitle")}</title>
                    </Helmet>
                    

                        <Container id="DropContainer" {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                            <input {...getInputProps()} />
                            <Grid ref={!isShareTypeChart ? ref : null} container alignItems={'stretch'} alignContent={'stretch'} direction={'row'} spacing={2} sx={{ display: location.pathname === "/" ? "flex" : "none" }}>
                                <Grow in={true} appear={true}>
                                    <Grid item xs={12} md={isSidebarVisible ? 9 : 12} sx={{ height: "100%" }}>
                                        <Card square={true} elevation={12} sx={{alignSelf: 'start', position: "sticky !important", top: "100px", height: "100%" }}>
                                            <CardContent ref={sciChartCardRef}>
                                                <div className={"home-scichart-container"} style={{ height: `calc(100vh - ${isMyDeprag ? 160 : 270}px)` }}>
                                                    <HeaderInformation onClear={clearChartHandler} />
                                                    <div className={'home-scichart'} id='sciChart' style={{ width: '100%', height: `calc(100vh - ${isMyDeprag ? 160 : 270}px)` }} ref={isShareTypeChart ? ref : null}>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grow>

                                {isSidebarVisible && <Grow in={true} appear={true}>
                                    <Grid item xs={12} md={3}>
                                        <Box component={"span"} className='home-legend onlinehelp-home-legend' sx={{ display: 'flex', flexGrow: 0, flexDirection: "column", height: `calc(100vh - ${isMyDeprag ? 180 : 230}px)` }}>
                                            <SeriesLegend seriesToHighlight={seriesToHighlight} toggleStrokeWidthOnHover={toggleStrokeWidthOnHover} />
                                            <AnalyticsLegend valueToHighlight={distinctiveValueToHighlight} toggleValueWidthOnHover={toggleDistinctiveValueWidthOnHover} />
                                        </Box>
                                    </Grid>
                                </Grow>}
                            </Grid>
                        </Container>

                        <Grid container direction={'row'} spacing={2} sx={{ display: location.pathname === "/" ? "flex" : "none" }}>
                            <Grid item xs={12} md={isSidebarVisible ? 9 : 12}>
                                {!customMode && isCurveAnimationEnabled && isChartRequestSeriesAvailable && (isCurveVisualisationAngle || isCurveSuperVisualisationAngle) && !isCurveSuperVisualisationTime && <TimeAngleSync isRedrawn={isRedrawFinished} />}
                            </Grid>
                        </Grid>

                
                </React.Fragment>
            }
        </LanguageConsumer>
        <Filter />
        <Share />
        <LoadFromController />
        <LoadFromCockpit />
    </React.Fragment>
    );

}