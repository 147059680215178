import { createSlice } from '@reduxjs/toolkit';

export interface ShareState {
    shouldToggleScreenshotDrawerFromParent: boolean;
}

const initialState: ShareState = {
    shouldToggleScreenshotDrawerFromParent: false,
};

export const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    toggleShouldToggleScreenshotDrawerFromParent: (state) => {
        state.shouldToggleScreenshotDrawerFromParent = !Boolean(state.shouldToggleScreenshotDrawerFromParent)
      }
  }
});

export const { toggleShouldToggleScreenshotDrawerFromParent } = shareSlice.actions;

export default shareSlice.reducer;
