import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationType, MonitorAnnotation } from '../Annotation';
import { makeId } from '../AnnotationService';

export interface AnnotationMonitor{
    color: string;
    name: string;
    visible: boolean;
    x1: number,
    y1: number,
}

interface AnnotationMonitorState{
    annotations: MonitorAnnotation[];
}

export const monitorAnnotationPrefix: string = "monitorAnnotation_";

export const initializeAnnotationMonitorState: AnnotationMonitorState = {
    annotations: []
}

const annotationMonitorSlice = createSlice({
    name: "AnnotationMonitor",
    initialState: initializeAnnotationMonitorState,
    reducers: {
        updateOrCreateMonitor: (state, action: PayloadAction<MonitorAnnotation>) =>{
            let annotationsNotUpdated = state.annotations.filter(x => x.name !== action.payload.name);
            let existingAnnotation: MonitorAnnotation | undefined = state.annotations.find(x => x.name === action.payload.name);            
            let annotationToCreateOrUpdate : MonitorAnnotation | null;

            if (existingAnnotation){
                annotationToCreateOrUpdate = {...existingAnnotation,
                    color: action.payload.color,                
                    visible: action.payload.visible,                    
                };
                state.annotations = annotationsNotUpdated.concat(annotationToCreateOrUpdate);
                return;
            }
            else{
                annotationToCreateOrUpdate = {
                    id: monitorAnnotationPrefix + makeId(state.annotations.length + 1),
                    name: action.payload.name,                    
                    color: action.payload.color,                
                    visible: action.payload.visible,
                    type: AnnotationType.monitor,
                } as MonitorAnnotation;
            }

            state.annotations = annotationsNotUpdated.concat(annotationToCreateOrUpdate);
        },
        updatePosition: (state, action: PayloadAction<MonitorAnnotation>) =>{
            let annotationToUpdate = state.annotations.filter(x => x.name === action.payload.name);
            let annotationsNotUpdated = state.annotations.filter(x => x.name !== action.payload.name);

            if (annotationToUpdate.length > 0){
            let updatedAnnoation = {...annotationToUpdate[0],
                x1: action.payload.x1,
                y1: action.payload.y1,
            };

            state.annotations = annotationsNotUpdated.concat(updatedAnnoation);
        }

        },
        removeAllMonitors: (state) =>{
            state.annotations = [];
        },
        updateAnnotationColor: (state, action: PayloadAction<MonitorAnnotation>) =>{
            let annotationsNotUpdated = state.annotations.filter(x => x.name !== action.payload.name);
            let existingAnnotation: MonitorAnnotation | undefined = state.annotations.find(x => x.name === action.payload.name);            
            let annotationToCreateOrUpdate : MonitorAnnotation | null;

            if (existingAnnotation){
                annotationToCreateOrUpdate = {...existingAnnotation,
                    color: action.payload.color,                        
                };
                state.annotations = annotationsNotUpdated.concat(annotationToCreateOrUpdate);
            }   
        },
        updateMonitorAnnotationVisibility: (state, action) => {

            var updatedVisisble = state.annotations.map(x => ({
                ...x, 
                visible: action.payload.list.find(z => z.title === x.name )?.visible 
            }))
            state.annotations = updatedVisisble;
        }
    }
})

export default annotationMonitorSlice.reducer;
export const { updateOrCreateMonitor, updatePosition, removeAllMonitors,updateAnnotationColor, updateMonitorAnnotationVisibility } = annotationMonitorSlice.actions;