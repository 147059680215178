/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import './App.css';
import Navigation from './Layout/Navigation/Navigation';
import Footer from './Layout/Footer/Footer';
import { SciChart } from './Features/Home/SciChart';
import { loadFromCockpitCrossLocalStorageAsync, loadFromSfaCrossLocalStorageAsync } from './Infrastructure/LocalStorage/Redux/CrossLocalStorageSlice';
import { useAppDispatch, useAppSelector } from './Infrastructure/Redux/hooks';
import { downloadAndConvertFiles } from './Infrastructure/FileDownloader/FileDownloader';
import { saveSeriesRequestsInStore } from './Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice';
import { getSeriesForGraphDataAsync } from './Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import LoadingIndicator from './Layout/LoadingIndicator/LoadingIndicator';
import { resetLoadingIndicator, setLoadingIndicator } from './Infrastructure/LoadingIndicator/LoadingIndicatorSlice';
import { Settings } from './Features/Settings/Settings';
import { themeDark, themeLight } from './Layout/Theme/ThemeProvider';
import { Notifications } from './Layout/Notifications/Notifications';
import { Information } from './Features/Information/Information';
import './Infrastructure/OnlineHelp/Style/OnlineHelp.css';
import { getTranslation, LanguageConsumer } from './Infrastructure/Internationalisation/TranslationService';
import { checkIfAnyFileIsCustom } from './Layout/Navigation/Views/Tools';
import { NotificationType, setNotification } from './Layout/Notifications/Redux/NotificationsSlice';
import { toggleFilter, toggleResetChart } from './Infrastructure/ChartModifier/Redux/ChartModifierSlice';
import { Box } from '@mui/material';
import { GlobalOnlineHelp } from './Infrastructure/OnlineHelp/Global/GlobalOnlineHelp';
import { isChartFullscreen, resetFullscreenChart } from './Layout/Navigation/Redux/NavigationSlice';
import fscreen from 'fscreen'
import { ModalComponent } from './Layout/Modal/Modal';
import CookieConsent from "react-cookie-consent";
import { Privacy } from './Features/Privacy/Privacy';
import { Imprint } from './Features/Imprint/Imprint';

import ScrollToTop from './Infrastructure/ScrollTopRouter/ScrollToTop';
import { loadCsv } from './Infrastructure/Internationalisation/Redux/LanguageFileSlice';
import { CockpitAdvertisingComponent } from './Layout/Modal/Advertising/CockpitAdvertising/CockpitAdvertising';
import PublishAnalytics from './Infrastructure/Analytics/PublishAnalytics';
import { Feedback } from './Features/Feedback/Feedback';
import { analyze_ExpandView, analyze_LoadFiles } from './Infrastructure/Analytics/Redux/AnalyticsSlice';
import { LoadFilesItems } from './Infrastructure/Analytics/Model/Categories/LoadFilesEnums';
import { CustomCsv } from './Features/CustomCsv/CustomCsv';
import { ApplicationActions } from './Infrastructure/ActionPanel/ApplicationActions';
import { PassivePrivacyConsentDialog } from './Features/LicenseConsent/PassivePrivacyConsentDialog';
import { LicenseForm } from './Features/LicenseForm/LicenseForm';
import { LicenceVerificationComponent } from './Infrastructure/LicenceService/Verification/LicenceVerification';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { LicenseConsentDialog } from './Features/LicenseConsent/LicenseConsentDialog';
import { DefaultPath } from './Features/DefaultPath/DefaultPath';
import { ThirdPartyLicenceNotes } from './Features/ThirdPartyLicenceNotes/ThirdPartyLicenceNotes';
import { SuperVisualizationModeDto } from './Features/Home/Models/SuperVisualizationModeDto';
import { SingleVisualizationModeDto } from './Features/Home/Models/SingleVisualizationModeDto';
import { SeriesTypeDto } from './Features/Home/Models/SeriesTypeDto';
import { RemoveQueryParameter } from './Infrastructure/RemoveQueryParameter/RemoveQueryParameter';
import { AllowReadCookieUnsafeDialog } from './Infrastructure/LocalStorage/HelpDialog/AllowReadCookieUnsafeDialog';
import { SearchEngineOptimization } from './Infrastructure/SEO/SearchEngineOptimization';
import { ErrorBoundary } from './Layout/ErrorBoundary/ErrorBoundary';
import { InformationModal } from './Features/Information/Modal/InformationModal';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { toggleShouldToggleScreenshotDrawerFromParent } from './Features/Share/Redux/ShareSlice';
import { ResponsiveDesign } from './Layout/ResponsiveDesign/ResponsiveDesign';
import { useMediaQuery } from 'react-responsive'
import { getNewFeatures } from './Features/NewFeatures/Redux/NewFeaturesSlice';
import { NewFeaturesDialog } from './Features/NewFeatures/NewFeatures';
import { selectFilesFromDialog } from './Infrastructure/ImportProvider/ImportProvider';


function App() {
  const dispatch = useAppDispatch();
  const listOfFilesFromCrossLocalStorage = useAppSelector(store => store.crossLocalStorage);
  const isLoading = useAppSelector(store => store.loadingIndicator.isLoading);
  const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
  const appTheme = useAppSelector(store => store.settings.appTheme);
  const isChartFullscreenToggle = useAppSelector(isChartFullscreen);
  const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
  const languageIdentifier = useAppSelector(store => store.settings.language);
  const [isPassivePrivacyConsentShown, setIsPassivePrivacyConsentShown] = useState(false)
  const showLicenseConsentDialog = useAppSelector(store => store.licenceConsent.showLicenseConsentDialog);
  const savedOrderedSeries = useAppSelector(store => store.chartSeriesOrder.seriesOrders);
  const isStandalone = useAppSelector(store => store.navigation.isStandalone);
  const isMyDeprag = useAppSelector(store => store.navigation.isMyDeprag);
  const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
  const isChartRequestSeriesAvailable = useAppSelector(store => store.chartRequestData.series !== null);
  const isRunningOnExtraSmallDevice = useMediaQuery({ query: '(max-width: 600px)' })
  const isSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
  const chartRequest = useAppSelector(store => store.chartRequestData.series);

  if ('launchQueue' in window) {
    (window as any).launchQueue.setConsumer(async (params) => {
      if(params && params.files && params.files.length > 0){
        const [handle] = params.files;
        if (handle) {
          var fileArray = [] as File[];
          const file = await handle.getFile() as File;
          fileArray.push(file);

          dispatch(setLoadingIndicator());
          setTimeout(() => {
            selectFilesFromDialog(fileArray, dispatch, chartRequestSettings, languageIdentifier, savedOrderedSeries, isSuperVisualisation, isLicenced, chartRequest);
          }, 2000)
          
        }
      }
    });
  }
  
  const fullScreenHandler = () => {
    if (fscreen.fullscreenElement !== null) {
      dispatch(analyze_ExpandView())
    } else {
      dispatch(resetFullscreenChart());
    }
  }

  useEffect(() => {
    dispatch(loadCsv());
    dispatch(loadFromCockpitCrossLocalStorageAsync());
    dispatch(loadFromSfaCrossLocalStorageAsync());
    dispatch(getNewFeatures());
  }, []);

  useEffect(() => {
    if (isChartFullscreenToggle) {
      if (fscreen.fullscreenEnabled) {
        fscreen.addEventListener('fullscreenchange', fullScreenHandler, false);
        fscreen.requestFullscreen(document.documentElement);
      }
    } else {
      fscreen.fullscreenElement && fscreen.exitFullscreen();
    }

  }, [isChartFullscreenToggle])

  useEffect(() => {

    const downloadAndConvert = async () => {
      dispatch(setLoadingIndicator());
      try {
        if (listOfFilesFromCrossLocalStorage) {

          var filesFromCrossLocalStorage = JSON.parse(listOfFilesFromCrossLocalStorage.keyValue) as string[];

          if (filesFromCrossLocalStorage.length > 1 && chartRequestSettings && chartRequestSettings.SuperVisualization && chartRequestSettings.SuperVisualization.Mode === SuperVisualizationModeDto.None) {
            chartRequestSettings.SuperVisualization = {
              Mode: SuperVisualizationModeDto.Angle,
              SeriesType: SeriesTypeDto.None
            };
          }
          else {
            chartRequestSettings.SuperVisualization = {
              Mode: SuperVisualizationModeDto.None,
              SeriesType: SeriesTypeDto.None
            };

            if (chartRequestSettings && chartRequestSettings.SingleVisualization && chartRequestSettings.SingleVisualization.Mode === SingleVisualizationModeDto.None) {
              chartRequestSettings.SingleVisualization = {
                Mode: SingleVisualizationModeDto.Angle
              }
            }
          }
        }

        var requestFiles = await downloadAndConvertFiles(listOfFilesFromCrossLocalStorage, chartRequestSettings, languageIdentifier);
        requestFiles.SortedList = savedOrderedSeries;

        if (requestFiles.Files.length > 0) {

          if ((requestFiles.Files.length > 1 && checkIfAnyFileIsCustom(requestFiles.Files))) {
            dispatch(setNotification({ type: NotificationType.info, text: getTranslation("NoSuperVisualisationForCustomFiles") }));
          } else {
            dispatch(saveSeriesRequestsInStore(requestFiles));
            dispatch(getSeriesForGraphDataAsync(requestFiles));
            dispatch(analyze_LoadFiles({ source: LoadFilesItems.FromExternal, nrOfFiles: requestFiles.Files.length }))
          }
          dispatch(toggleResetChart());
        }
      }
      catch {
        dispatch(resetLoadingIndicator());
        dispatch(setNotification({ type: NotificationType.error, text: getTranslation("FileCouldNotBeLoaded") }));
      }
    }

    if (listOfFilesFromCrossLocalStorage.keyValue && listOfFilesFromCrossLocalStorage.keyValue !== 'null') {

      downloadAndConvert();
    }


  }, [listOfFilesFromCrossLocalStorage])

  return (
    <Fragment>
      {languageFileWasLoaded &&
        <LanguageConsumer>
          {({ getTranslatedText }) =>
          <>
 
            <SearchEngineOptimization />

            <KeyboardEventHandler
                        handleKeys={['ctrl+i', 'ctrl+y']}
                        onKeyEvent={(key, event) => {
                            console.log("ja")
                            event.preventDefault();
                            switch (key) {
                                case 'ctrl+i':
                                    dispatch(toggleShouldToggleScreenshotDrawerFromParent());
                                    break;
                                case 'ctrl+y':
                                    isLicenced && isChartRequestSeriesAvailable && dispatch(toggleFilter());
                                    break;
                                default:
                                    break;
                            }
                        }} />
            
            <ThemeProvider theme={appTheme === 'dark' ? (isStandalone ? themeLight(isStandalone) : themeDark(isStandalone)) : themeLight(isStandalone)}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                }}
              >
                <PassivePrivacyConsentDialog open={isPassivePrivacyConsentShown} setopen={setIsPassivePrivacyConsentShown} />

                <AllowReadCookieUnsafeDialog />

                <CssBaseline />

                <Notifications />
                <Router>
                  {isLoading && <LoadingIndicator />}
                  <ScrollToTop />
                  <GlobalOnlineHelp />                  
                  <Navigation />
                  <ResponsiveDesign>                 
                    <ErrorBoundary>
                        <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                          <QueryParamProvider adapter={ReactRouter6Adapter}>
                            <Routes>
                              <Route path="/" element={<DefaultPath/>}>
                              </Route>
                              <Route path="/settings" element={<Settings />}>
                              </Route>
                              <Route path="/information" element={<Information />}>
                              </Route>
                              <Route path="/privacy" element={<Privacy />}>
                              </Route>
                              <Route path="/imprint" element={<Imprint />}>
                              </Route>
                              <Route path="/thirdpartylicensenotes" element={<ThirdPartyLicenceNotes />}>
                              </Route>
                            </Routes>
                          </QueryParamProvider>
                          <ApplicationActions >
                            <SciChart />
                          </ApplicationActions>                      
                        </Container>                    
                    </ErrorBoundary>
                  </ResponsiveDesign>                    
                  {!isMyDeprag && <Footer />}

                  <ModalComponent />
                  <NewFeaturesDialog />
                  <CockpitAdvertisingComponent />
                  <LicenceVerificationComponent />
                  <RemoveQueryParameter />

                  <PublishAnalytics />

                  <LicenseConsentDialog
                    open={showLicenseConsentDialog}
                  />
                  {!isRunningOnExtraSmallDevice && <Feedback /> }
                  <CustomCsv />
                  <LicenseForm />
                  <InformationModal />

                  {
                    !isStandalone && !isMyDeprag && <CookieConsent
                      location="bottom"
                      buttonText={getTranslatedText("CookieButtonText")}
                      cookieName="depragGraphViewerCookie"
                      style={{ background: "#5f825c", color: '#fff', zIndex: 10001 }}
                      buttonStyle={{
                        border: '1px solid #fff',
                        background: "transparent",
                        color: '#fff',
                        height: "45px",
                        borderRadius: "5px",
                        fontSize: "13px"
                      }}

                      enableDeclineButton={true}
                      setDeclineCookie={false}
                      declineButtonStyle={{
                        border: '1px solid #fff',
                        background: "transparent",
                        color: '#fff',
                        height: "45px",
                        borderRadius: "5px",
                        fontSize: "13px"
                      }}
                      onDecline={() => setIsPassivePrivacyConsentShown(true)}
                      declineButtonText={getTranslatedText("ShowPrivacyText")}
                      hideOnDecline={false}
                      expires={150}
                    >
                      {getTranslatedText("CookieText")}

                    </CookieConsent>
                  }

                </Router>
              </Box>
            </ThemeProvider>
 
            </>
          }
        </LanguageConsumer>
      }
    </Fragment>
  );

}

export default App;
