import { getAnalyseOnlineHelpSteps } from "./AnalyseOnlineHelpNavigationProvider";
import Tour from 'reactour';
import { Button } from "@mui/material";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export const AnalyseOnlineHelp = (props) => {

    const [currentStep, setCurrentStep] = useState<number>(0);
    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);


    return (
        <LanguageConsumer>

            {({ getTranslatedText }) =>
                <Tour
                    disableFocusLock
                    getCurrentStep={(curr: number) => {
                        setCurrentStep(curr)
                    }}
                    startAt={0}
                    disableInteraction={true}
                    showNavigation={false}
                    showNumber={false}
                    showButtons={true}
                    closeWithMask={true}
                    accentColor={'#32992c'}
                    steps={getAnalyseOnlineHelpSteps(props)}
                    isOpen={props.showOnlineHelp}
                    onRequestClose={() => {
                        setCurrentStep(0)
                        props.closeOnlineHelp();
                    }}
                    rounded={13}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                    prevButton={currentStep !== 0 ? <Button component="a" fullWidth variant="outlined" color='inherit' title={getTranslatedText("OnlineHelpPrev")}>{getTranslatedText("OnlineHelpPrev")}</Button> : <div></div>}
                    nextButton={<Button component="a" variant="outlined" fullWidth color='success' title={getTranslatedText("OnlineHelpNext")}>{getTranslatedText("OnlineHelpNext")}</Button>}
                    lastStepNextButton={<Button component="a" variant="outlined" onClick={() => { props.closeOnlineHelp() }} color='success' title={getTranslatedText("OnlineHelpFinish")}>{getTranslatedText("OnlineHelpFinish")}</Button>}
                    nextStep={async (step) => {
                        let nextStep = currentStep + 1
                        switch (nextStep) {
                            case 2:
                                if(!props.isSynchronizationpointIndividualTorqueSelected)
                                    nextStep += 2;
                                break;
                            case 5:
                                if (!props.isSinglevisualisationSelectVisible)
                                    nextStep += 1;
                                break;
                            default:
                                break;
                        }
                        setCurrentStep(nextStep)
                    }}
                    prevStep={async (step) => {
                        let lastStep = currentStep -1
                        switch (lastStep) {
                            case 3:
                                if(!props.isSynchronizationpointIndividualTorqueSelected)
                                    lastStep -= 2;
                                break;
                            case 5:
                                if (!props.isSinglevisualisationSelectVisible)
                                    lastStep -= 1;
                                break;
                            default:
                                break;
                        }
                        setCurrentStep(lastStep)
                    }}
                    goToStep={currentStep}
                />}
        </LanguageConsumer>
    );
}