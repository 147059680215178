import { Grid, Grow, Card, CardContent, FormControl, Button, TextField, CircularProgress, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isCustomMode } from "../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { SuperVisualizationModeDto } from "../../Features/Home/Models/SuperVisualizationModeDto";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Helmet } from "react-helmet";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import './Style/Settings.css';
import { OnlineHelpIconButton } from "../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { SettingsOnlineHelp } from "../../Infrastructure/OnlineHelp/Settings/SettingsOnlineHelp";
import { LicenceOnlineHelp } from "../../Infrastructure/OnlineHelp/Licence/LicenceOnlineHelp";
import { AnalyseOnlineHelp } from "../../Infrastructure/OnlineHelp/Analyse/AnalyseOnlineHelp";
import { LoadFromCockpitSettings } from "./Components/LoadFromCockpit";
import { analyze_ShowOnlineHelp } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { OnlineHelpItems } from "../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { Theme } from "./Components/Theme";
import { SuperVisualistion } from "./Components/SuperVisualisation";
import { DisplayCurrentValue } from "./Components/DisplayCurrentValue";
import { Synchronization } from "./Components/Synchronization";
import { SingleVisualisation } from "./Components/SingleVisualisation";
import { Language } from "./Components/Language";
import { TorqueUnitSelector } from "./Components/TorqueUnit";
import { SynchronizationMode } from "../Home/Models/SynchronizationSettings";
import { SynchronizationIndividualTorqueInput } from "./Components/SynchronizationIndividualTorqueInput";
import { rejectLicenseConsent, showLicenseConsent } from "../LicenseConsent/LicenseConsentSlice";
import { setLicence, toggleLicenseForm, setEmail } from "../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import { LicenseMasterSettings } from "./Components/LicenseMasterSettings/LicenseMasterSettings";
import { LicenseCardHeader } from "./Components/LicenseCardHeader/LicenseCardHeader";
import { deleteUsage } from "../../Infrastructure/LicenceService/Usage/Delete/Redux/DeleteUsageServiceSlice";
import { AnnotationsSettings } from "./Components/Annotations";
import { CockpitAvailable, setCockpitAvailable } from "../LoadFromCockpit/CurveData/Complete/Redux/LoadFromCockpitCompleteCurveDataSlice";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { setActiveTab } from "./Redux/SettingsSlice";
import { a11yProps, TabPanel } from "./Components/TabPanel";
import { ShareTypeSelect } from "./Components/ShareType";
import { YesNoDialog } from "../../Infrastructure/YesNoDialog/YesNoDialog";
import { DefaultSettingsSetter } from "../../Infrastructure/DefaultSettingsSetter/Components/DefaultSettingsSetter";
import { toogleAllReset, toogleAnalysisDefaultReset, toogleGeneralDefaultReset } from "../../Infrastructure/DefaultSettingsSetter/Redux/DefaultSettingsSetterSlice";
import { DefaultSettingsSetterButton } from "../../Infrastructure/DefaultSettingsSetter/Components/DefaultSettingsSetterButton";
import { InformationTypeSelect } from "./Components/InformationTypeSelect";
import { useCheckApiKey } from "../Home/Hooks/useCheckApiKey";

export const Settings = () => {
    const dispatch = useAppDispatch();
    const supervisualisationMode = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode);
    const synchronizationMode = useAppSelector(store => store.settings.chartRequestSettings.SynchronizationSettings?.Mode);
    const customMode = useAppSelector(isCustomMode);
    const licenceData = useAppSelector(store => store.licenceService);
    const licenceNumberFromStore = useAppSelector(store => store.licenceService.licenceAuthentication.licenceKey);
    const emailAddressFromStore = useAppSelector(store => store.licenceService.licenceAuthentication.emailAddress);
    const [licenceNumber, setLicenceNumber] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [isSettingsOnlineHelpEnabled, setIsSettingsOnlineHelpEnabled] = useState(false);
    const [isLicenceOnlineHelpEnabled, setIsLicenceOnlineHelpEnabled] = useState(false);
    const [isAnalysisOnlineHelpEnabled, setIsAnalysisOnlineHelpEnabled] = useState(false);
    const [isSinglevisualisationSelectVisible, setIsSinglevisualisationSelectVisible] = useState(supervisualisationMode === SuperVisualizationModeDto.None && !customMode)
    const [isSynchronizationpointIndividualTorqueSelected, setIsSynchronizationpointIndividualTorqueSelected] = useState(synchronizationMode === SynchronizationMode.IndividualTorque && !customMode)
    const [isSinglevisualisationSelectVisibleBeforeOnlineHelp, setIsSinglevisualisationSelectVisibleBeforeOnlineHelp] = useState(isSinglevisualisationSelectVisible)
    const [isSynchronisationIndividualTorqueSelectionVisible, setIsSynchronizationIndividualTorqueSelectionVisible] = useState(synchronizationMode === SynchronizationMode.IndividualTorque)
    const activeTab = useAppSelector(store => store.settings.activeTab);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    const { isExternalUser } = useCheckApiKey();

    useEffect(() => {
        setIsSinglevisualisationSelectVisible(supervisualisationMode === SuperVisualizationModeDto.None && !customMode)
    }, [supervisualisationMode, customMode])

    useEffect(() => {
        setIsSynchronizationpointIndividualTorqueSelected(synchronizationMode === SynchronizationMode.IndividualTorque && !customMode)
    }, [synchronizationMode, customMode])

    useEffect(() => {
        setLicenceNumber(licenceNumberFromStore);
    }, [licenceNumberFromStore]);

    useEffect(() => {
        setEmailAddress(emailAddressFromStore);
    }, [emailAddressFromStore]);

    useEffect(() => {
        setIsSynchronizationIndividualTorqueSelectionVisible(synchronizationMode === SynchronizationMode.IndividualTorque)
    }, [synchronizationMode])

    const closeSettingsOnlineHelp = () => {
        setIsSettingsOnlineHelpEnabled(false);
    };
    const openSettingsOnlineHelp = () => {
        setIsSettingsOnlineHelpEnabled(true);
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.DefaultSettingsOnlineHelp }))
    };

    const closeLicenceOnlineHelp = () => {
        setIsLicenceOnlineHelpEnabled(false);
    };
    const openLicenceOnlineHelp = () => {
        setIsLicenceOnlineHelpEnabled(true);
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.LicenceOnlineHelp }))
    };

    const closeAnalyseOnlineHelp = () => {
        setIsAnalysisOnlineHelpEnabled(false);
    };
    const openAnalyseOnlineHelp = () => {
        setIsAnalysisOnlineHelpEnabled(true);
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.AnalyseOnlineHelp }))
    };

    const ShowSinglevisualisationSelectForOnlineHelp = () => {
        setIsSinglevisualisationSelectVisibleBeforeOnlineHelp(isSinglevisualisationSelectVisible)
        setIsSinglevisualisationSelectVisible(true)
    }
    const ResetSinglevisualisationSelectToBeforeOnlineHelp = () => {
        setIsSinglevisualisationSelectVisible(isSinglevisualisationSelectVisibleBeforeOnlineHelp)
    }

    const getValidUntil = () => {
        if (licenceData.licenceAuthentication.validUntil)
            return new Date(licenceData.licenceAuthentication.validUntil).toLocaleDateString();
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setActiveTab(newValue));
    };

    const handleNoClick = () => {
        setConfirmDialogOpen(false);
    }

    const handleYesClick = async () => {
        setConfirmDialogOpen(false);
        await dispatch(deleteUsage({ MasterOrChildKey: licenceNumber, ClientId: licenceData.licenceAuthentication.clientId, UsageId: licenceData.licenceAuthentication.usageId }));
        dispatch(rejectLicenseConsent());
        dispatch(setCockpitAvailable(CockpitAvailable.CockpitAvailableNo));
        dispatch(toogleAllReset({ResetLanguage: false}));
    }
 
    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <React.Fragment>
                <DefaultSettingsSetter />

                <Helmet>
                    <title>{getTranslatedText("SettingsGraphViewerTitle")}</title>
                </Helmet>

                <Box component={'div'} sx={{ mb: 2 }}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={0} md={1}></Grid>
                        <Grid item xs={12} md={10} >
                            <Card elevation={12} sx={{ pt: 0 }}>
                                <Tabs TabIndicatorProps={{ style: { background: '#32992c', height: 4, borderRadius: 2 } }} indicatorColor="secondary" textColor="inherit" variant="fullWidth" value={activeTab} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={getTranslatedText("Settings")} {...a11yProps(0)} />
                                    <Tab label={getTranslatedText("CurveAnalysis")} {...a11yProps(1)} />
                                    <Tab label={getTranslatedText("AnnotationsTitle")} {...a11yProps(2)} />
                                    <Tab label={getTranslatedText("LoadFromCockpitTitleTab")} {...a11yProps(3)} />
                                   {!isExternalUser && <Tab label={getTranslatedText("LicenseCardHeader")} {...a11yProps(4)} />}
                                </Tabs>
                            </Card>
                        </Grid>
                        <Grid item xs={0} md={1}></Grid>
                    </Grid>
                </Box>

                <TabPanel value={activeTab} index={0}>
                    <Grow in={true} appear={true}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item xs={12} md={10} >
                                <Card elevation={12} className="onlinehelp-settings-intro">
                                    <LicenseCardHeader onlineHelpComponent={<OnlineHelpIconButton
                                        isInSettings={true}
                                        title={"OnlineHelp"}
                                        isOnlineHelpEnabled={false}
                                        onClickFunction={() => {
                                            openSettingsOnlineHelp();
                                        }}
                                    />} title={getTranslatedText("Settings")} subtitle={""} image={`SettingsImages/General/LicenseCardHeaderGeneral.jpg`} />
                                    <CardContent sx={{ pt: 0 }} style={{ paddingBottom: 0 }}>

                                        <SettingsOnlineHelp
                                            showOnlineHelp={isSettingsOnlineHelpEnabled}
                                            closeOnlineHelp={closeSettingsOnlineHelp}
                                            ResetSinglevisualisationSelectToBeforeOnlineHelp={ResetSinglevisualisationSelectToBeforeOnlineHelp}
                                            ShowSinglevisualisationSelectForOnlineHelp={ShowSinglevisualisationSelectForOnlineHelp} />
                                        <Language />

                                        {!customMode &&
                                            <TorqueUnitSelector />
                                        }
                                        <Theme />

                                        <ShareTypeSelect />

                                        <InformationTypeSelect />

                                        <DefaultSettingsSetterButton className={'onlinehelp-settings-resetAll'} action={toogleGeneralDefaultReset()} />

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={0} md={1} ></Grid>
                        </Grid>
                    </Grow>
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    <Grow in={true} appear={true} id="analysis">
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item xs={12} md={10} >
                                <Card elevation={12} className="onlinehelp-analyse-intro" >
                                    <LicenseCardHeader onlineHelpComponent={<OnlineHelpIconButton
                                        isInSettings={true}
                                        title={"OnlineHelp"}
                                        isOnlineHelpEnabled={false}
                                        onClickFunction={() => { openAnalyseOnlineHelp(); }}
                                    />} title={getTranslatedText("CurveAnalysis")} subtitle={getTranslatedText("LicenseCardHeaderSub")} image={`SettingsImages/Analysis/LicenseCardHeaderAnalysis.jpg`} />
                                    <CardContent sx={{ pt: 0 }} style={{ paddingBottom: 0 }}>

                                        <AnalyseOnlineHelp
                                            isSinglevisualisationSelectVisible={isSinglevisualisationSelectVisible}
                                            isSynchronizationpointIndividualTorqueSelected={isSynchronizationpointIndividualTorqueSelected}
                                            showOnlineHelp={isAnalysisOnlineHelpEnabled}
                                            closeOnlineHelp={closeAnalyseOnlineHelp}
                                        />


                                        {!customMode &&
                                            <Synchronization />
                                        }

                                        {isSynchronisationIndividualTorqueSelectionVisible && !customMode &&
                                            <SynchronizationIndividualTorqueInput />
                                        }


                                        {!customMode &&
                                            <SuperVisualistion />
                                        }

                                        {isSinglevisualisationSelectVisible &&
                                            <SingleVisualisation />
                                        }

                                        <DisplayCurrentValue />

                                        <DefaultSettingsSetterButton className={'onlinehelp-settings-resetAllAnalysis'} action={toogleAnalysisDefaultReset()} />

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grow>
                </TabPanel>

                <TabPanel value={activeTab} index={2}>
                    <AnnotationsSettings />
                </TabPanel>

                <TabPanel value={activeTab} index={3}>
                    <LoadFromCockpitSettings />
                </TabPanel>

                {!isExternalUser && <TabPanel value={activeTab} index={4}>
                    <Grow in={true} appear={true} id="license">
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item xs={12} md={10}>
                                <Card className="onlinehelp-settings-licence-intro" elevation={12}>
                                    <LicenseCardHeader onlineHelpComponent={<OnlineHelpIconButton
                                        isInSettings={true}
                                        title={"OnlineHelp"}
                                        isOnlineHelpEnabled={false}
                                        onClickFunction={() => { openLicenceOnlineHelp(); }}
                                    />}
                                        buttonComponent={<Button sx={{ background: '#fff', borderColor: '#fff' }} className="buyLicenseButton onlinehelp-settings-licence-getYourLicence" variant="outlined" color="success" onClick={() => dispatch(toggleLicenseForm())} startIcon={<CardMembershipIcon />}>
                                            {getTranslatedText("GetYourLicence")}
                                        </Button>}
                                        title={getTranslatedText("LicenseCardHeader")}

                                        image={`SettingsImages/LicenseImages/LicenseCardHeader${licenceData.licenceAuthentication.isLicenced ? '' : '_r'}.jpg`} />
                                    <CardContent id="licence">

                                        <LicenceOnlineHelp
                                            showOnlineHelp={isLicenceOnlineHelpEnabled}
                                            closeOnlineHelp={closeLicenceOnlineHelp}
                                            isLicenced={licenceData.licenceAuthentication.isLicenced}
                                        />

                                        <Grid container spacing={2}>
                                            <Grid item xs={11}>
                                                {licenceData.status !== 'loading' ?
                                                    (licenceData.licenceAuthentication.isLicenced ?
                                                        <Chip className="onlinehelp-settings-licence-isUnlocked" label={getTranslatedText("IsLicencedTooltip")} color="success" sx={{ color: '#fff' }} icon={<CheckCircleOutlineIcon sx={{ fontSize: 20, color: '#fff' }} />}></Chip>
                                                        :
                                                        <Chip className="onlinehelp-settings-licence-isUnlocked" color="error" label={getTranslatedText("IsNotLicencedTooltip")} sx={{ color: '#fff' }} icon={<ErrorOutlineIcon sx={{ fontSize: 20 }} />}></Chip>
                                                    )
                                                    :
                                                    <Chip className="onlinehelp-settings-licence-isUnlocked" label={getTranslatedText("CheckingLicence")} icon={<CircularProgress color="success" size={20} />}></Chip>
                                                }

                                                {licenceData && licenceData.licenceAuthentication.validUntil &&
                                                    <Chip className="onlinehelp-settings-licence-isValidUntil" sx={{ ml: 1 }} label={<span>{getTranslatedText("ValidUntil")} <b>{getValidUntil()}</b></span>}></Chip>
                                                }

                                            </Grid>
                                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                                {licenceData.licenceAuthentication.isMaster && <LicenseMasterSettings />}

                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <FormControl className="onlinehelp-settings-licence-emailAddress" fullWidth sx={{ mt: 3 }} >
                                                    <TextField disabled={licenceData.licenceAuthentication.isLicenced} fullWidth id="outlined-basic"
                                                        data-testid="licence-email"
                                                        value={emailAddress}
                                                        label={getTranslatedText("EmailAddress")}
                                                        variant="outlined"
                                                        color="success"
                                                        onChange={(e) => {
                                                            setEmailAddress(e.target.value);
                                                            dispatch(setEmail(e.target.value))
                                                        }
                                                        } />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl className="onlinehelp-settings-licence-licenceNumber" fullWidth sx={{ mt: 1 }}>
                                                    <TextField disabled={licenceData.licenceAuthentication.isLicenced} fullWidth id="outlined-basic"
                                                        data-testid="licence-licenceNumber"
                                                        value={licenceNumber}
                                                        label={getTranslatedText("LicenceNumber")}
                                                        variant="outlined"
                                                        color="success"
                                                        onChange={(e) => {
                                                            setLicenceNumber(e.target.value);
                                                            dispatch(setLicence(e.target.value))
                                                        }
                                                        } />
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12}>
                                                {!licenceData.licenceAuthentication.isLicenced &&
                                                    <Button
                                                        className="onlinehelp-settings-licence-submitRemoveLicence"
                                                        color="success"
                                                        variant="contained"
                                                        sx={{ color: '#fff' }}
                                                        fullWidth
                                                        size="large"
                                                        disabled={emailAddress === '' || licenceNumber === ''}
                                                        onClick={
                                                            () => {
                                                                dispatch(rejectLicenseConsent());
                                                                dispatch(showLicenseConsent());
                                                            }
                                                        }>
                                                        {getTranslatedText("SubmitLicence")}
                                                    </Button>
                                                }

                                                {licenceData.licenceAuthentication.isLicenced && <Button className="onlinehelp-settings-licence-submitRemoveLicence" color="error" variant="outlined" fullWidth size="large"
                                                    onClick={() => setConfirmDialogOpen(true)}>
                                                    {getTranslatedText("RemoveLicence")}
                                                </Button>}
                                                <YesNoDialog
                                                    title={getTranslatedText("RemoveLicenseDialogTitle")}
                                                    content={getTranslatedText("RemoveLicenseDialogContent")}
                                                    titleYesButton={getTranslatedText("TEXT_YES")}
                                                    titleNoButton={getTranslatedText("TEXT_NO")}
                                                    open={confirmDialogOpen}
                                                    onNoClick={handleNoClick}
                                                    onYesClick={handleYesClick}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grow>
                </TabPanel>}
            </React.Fragment>
        }
    </LanguageConsumer >
    );
}