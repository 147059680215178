import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { LabellingDistinctiveValuesModeDto } from "../../Home/Models/LabellingDistinctiveValuesModeDto";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { analyze_ChangeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { setActiveTab, setLabellingDistinctiveValues } from "../Redux/SettingsSlice";
import { isCustomMode } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import React from "react";
import { NavLink } from "react-router-dom";
import { toogleKeepUserAnnotations } from "../../Annotations/Redux/AnnotationUserSlice";

export const LabellingDistinctive = () => {

  const dispatch = useAppDispatch();
  const licenceData = useAppSelector(store => store.licenceService);
  const labellingDistinctiveValues = useAppSelector(store => store.settings.chartRequestSettings.LabellingDistinctiveValues?.Mode);
  const isCustom = useAppSelector(isCustomMode);

  const handleChangeLabellingDistinctive = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as LabellingDistinctiveValuesModeDto
    dispatch(toogleKeepUserAnnotations());
    dispatch(setLabellingDistinctiveValues(value));
    dispatch(analyze_ChangeDefaultSettings({ settingsType: DefaultSettingsItems.LabellingDistinctiveValues, value: value }))
  }

  return ( !isCustom ?
    <LanguageConsumer>
      {({ getTranslatedText }) =>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel color="success" id="select-label-distinctiveValues">{getTranslatedText("LabellingDistinctiveValues")} </InputLabel>
          {!licenceData.licenceAuthentication.isLicenced && 
          <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
          <Select
            className="onlinehelp-analyse-labellingDistinctiveValues"
            disabled={!licenceData.licenceAuthentication.isLicenced}
            color="success"
            labelId="labelling-distinctive-values"
            id="labelling-values"
            name="labelling-values"
            value={labellingDistinctiveValues}
            label={getTranslatedText("LabellingDistinctiveValues")}
            onChange={handleChangeLabellingDistinctive}
          >
            <MenuItem id="labelling-values0" value={LabellingDistinctiveValuesModeDto.None}>{getTranslatedText("LabellingDistinctiveValuesNone")}</MenuItem>
            <MenuItem id="labelling-values1" value={LabellingDistinctiveValuesModeDto.ThresholdTorque}>{getTranslatedText("LabellingDistinctiveValuesThresholdTorque")}</MenuItem>
            <MenuItem id="labelling-values2" value={LabellingDistinctiveValuesModeDto.SeatingPoint}>{getTranslatedText("LabellingDistinctiveValuesSeatingPoint")}</MenuItem>
            <MenuItem id="labelling-values3" value={LabellingDistinctiveValuesModeDto.ThresholdTorqueAndSeatingPoint}>{getTranslatedText("LabellingDistinctiveValuesThresholdTorqueAndSeatingPoint")}</MenuItem>
          </Select>
        </FormControl>
      }
    </LanguageConsumer> : <></>
  );
};
