import { createSlice } from '@reduxjs/toolkit';
import { ChartRequestFile } from '../../../Features/Home/Models/ChartRequest';

export type SingleUseChartRequestFile = {
    ChartRequestFile: ChartRequestFile | null,
    ChartRequestFileUsed: boolean
}

export interface TemporarilyState {
    singleUseChartRequestFile: SingleUseChartRequestFile
}

const initialState: TemporarilyState = {
    singleUseChartRequestFile: {
        ChartRequestFile: null,
        ChartRequestFileUsed: true
    }
};

export const temporarilySlice = createSlice({
    name: 'temporarily',
    initialState,
    reducers: {
        setSingleUseChartRequestFile: (state, action) => {
            state.singleUseChartRequestFile = {
                ChartRequestFile: action.payload,
                ChartRequestFileUsed: false
            }
        },
        setUsedForSingleUseChartRequestFile: (state) => {
            if (state.singleUseChartRequestFile)
                state.singleUseChartRequestFile.ChartRequestFileUsed = true;
        },
    }
});

export const { setSingleUseChartRequestFile, setUsedForSingleUseChartRequestFile } = temporarilySlice.actions;

export default temporarilySlice.reducer;
