import { Box } from "@mui/material"
import { isCustomMode, selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { LoadExampleFile } from "./LoadExampleFile/LoadExampleFile";
import { VisualizationMode } from "./VisualizationMode/VisualizationMode";
import { ClearGraphViewer } from "./ClearGraphViewer/ClearGraphViewer";
import { NewGraphViewerTab } from "./NewGraphViewerTab/NewGraphViewerTab";
import { GetLicense } from "./GetLicense/GetLicense";
import { memo } from "react";
import './Style/HeaderInformation.css'
import { isMobile } from 'react-device-detect';

interface HeaderInformationProps {
    onClear: () => void;
}

export const HeaderInformation = memo(({ onClear }: HeaderInformationProps) => {

    const customMode = useAppSelector(isCustomMode);
    const hasChartResult = useAppSelector(store => store.chartResultData.series.length > 0)
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
    const seriesData = useAppSelector(selectSeries);
    const isStandalone = useAppSelector(store => store.navigation.isStandalone);
    const isMyDeprag = useAppSelector(store => store.navigation.isMyDeprag);

    return (
        <>
            <Box component='div' sx={{ position: 'absolute', top: 20, left: 20 }}>
                {!customMode &&  <VisualizationMode />}
                {!isStandalone && !isMobile && !isMyDeprag && <NewGraphViewerTab/>}
                {(!seriesData || !seriesData[0]) && !isLicenced && !isStandalone && <LoadExampleFile />}
                {!isStandalone && !isMobile && <GetLicense/>}
            </Box>

            <Box component='div' sx={{ position: 'absolute', top: 20, right: 90 }}>
                {hasChartResult && <ClearGraphViewer onClear={onClear} />}
            </Box>
        </>

    );
})