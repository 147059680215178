import { Grid, Typography, Tooltip, IconButton, TextField, Skeleton, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { addCustomCsvSeries, removeCustomCsvSeries, updateCustomCsvSeries } from "../Redux/CustomCsvSlice";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SliderPicker } from "react-color";
import { CustomSeriesIsVisible } from "../Models/CustomSeriesIsVisible";
import { CustomCsvSeries } from "../Models/CustomCsvSeries";

export const CustomSeries = () => {
    const dispatch = useAppDispatch();
    const customSeries = useAppSelector(store => store.customCsv.series);
    const customYAxis = useAppSelector(store => store.customCsv.yAxis);

    const emptySeries = {name: "", color: "#32992c", isVisible: CustomSeriesIsVisible.Yes, yAxisId: customYAxis && customYAxis.length > 0 ? customYAxis[0].id : "Y1" } as CustomCsvSeries;

    return(customYAxis && customYAxis.length > 0 ?
        <LanguageConsumer>
        {({ getTranslatedText }) =>
        <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 1 }}>
        <Grid item xs={12}>
            <Typography variant="h6" component="h6" sx={{fontWeight: 200}}>
                {getTranslatedText("AddCustomSeries")}

               {customYAxis.length > 0 && <Tooltip title={getTranslatedText("AddCustomSeries")}>
                    <IconButton sx={{ml: 1, top: "-2px"}} onClick={() => dispatch(addCustomCsvSeries(emptySeries))}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Tooltip>}
            </Typography>
        </Grid>
        <Grid item xs={12}>
        {customSeries && customSeries.length > 0 ? customSeries.map((series, seriesIterator) => {
           return (
               <Grid key={seriesIterator} container direction={'row'} spacing={2} sx={{ p: 0, pt:seriesIterator === 0 ? 1 : 3 }}>
                   <Grid item xs={3}>
                       <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvSeries({iterator: seriesIterator,  name: e.target.value, color: series.color, isVisible: series.isVisible, yAxisId: series.yAxisId}))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvSeriesName")} value={series.name}></TextField>
                   </Grid>
                   <Grid item xs={3}>
                        <SliderPicker
                            color={series.color}
                            onChange={(color) => dispatch(updateCustomCsvSeries({iterator: seriesIterator,  name: series.name, color: color.hex, isVisible: series.isVisible, yAxisId: series.yAxisId}))}
                        />
                   </Grid>
                   <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel color="success" id="demo-simple-select-helper-label">{getTranslatedText("CustomCsvYAxisId")} </InputLabel>
                        <Select
                            size="small"
                            fullWidth
                            color="success"
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={series.yAxisId}
                            label={getTranslatedText("CustomCsvYAxisId")}
                            onChange={(e) => dispatch(updateCustomCsvSeries({ iterator: seriesIterator, name: series.name, color: series.color, isVisible: series.isVisible, yAxisId: e.target.value }))}
                            displayEmpty
                        >
                            {customYAxis && customYAxis.length > 0 && customYAxis.map((axis, axisKey) => {
                                return <MenuItem value={axis.id}>{axis.id}: {axis.name} </MenuItem>
                            })}

                        </Select>
                    </FormControl>
                   </Grid>
                   <Grid item xs={2}>
                   <FormControl fullWidth>
                        <InputLabel color="success" id="demo-simple-select-helper-label">{getTranslatedText("CustomCsvIsVisible")} </InputLabel>
                        <Select
                            size="small"
                            fullWidth
                            color="success"
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={series.isVisible}
                            label={getTranslatedText("CustomCsvIsVisible")}
                            onChange={(e) => dispatch(updateCustomCsvSeries({ iterator: seriesIterator, name: series.name, color: series.color, isVisible: e.target.value, yAxisId: series.yAxisId }))}
                            displayEmpty
                        >
                          
                            <MenuItem value={CustomSeriesIsVisible.Yes}>{getTranslatedText("CustomSeriesIsVisibleYes")} </MenuItem>
                            <MenuItem value={CustomSeriesIsVisible.No}>{getTranslatedText("CustomSeriesIsVisibleNo")} </MenuItem>
                         

                        </Select>
                    </FormControl>
                   </Grid>
                   <Grid item xs={1}>
                      <IconButton onClick={() => dispatch(removeCustomCsvSeries(series.name))}>
                        <DeleteOutlineIcon />
                      </IconButton>
                   </Grid>
               </Grid>
            )
        })
            :
            <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 1 }}>
                <Grid item xs={3}>
                    <Skeleton  onClick={() => dispatch(addCustomCsvSeries(emptySeries))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton onClick={() => dispatch(addCustomCsvSeries(emptySeries))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton onClick={() => dispatch(addCustomCsvSeries(emptySeries))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton onClick={() => dispatch(addCustomCsvSeries(emptySeries))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton onClick={() => dispatch(addCustomCsvSeries(emptySeries))} variant="circular" width={30} height={30} />
                </Grid>
            </Grid>
        }
        </Grid>

    </Grid>
}
</LanguageConsumer>
:
<></>
    );
}