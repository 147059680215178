import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { analyze_ChangeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { setLanguage } from "../Redux/SettingsSlice";

export const Language = () => {
    const dispatch = useAppDispatch();

    const language = useAppSelector(store => store.settings.language);

    const handleChangeLanguage = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string;
        dispatch(setLanguage(value));
        dispatch(analyze_ChangeDefaultSettings({ setting: DefaultSettingsItems.Language, newValue: value.toString() }))
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 4 }}>
                    <InputLabel color="success" id="demo-simple-select-helper-label">{getTranslatedText("Language")} </InputLabel>
                    <Select
                        className="onlinehelp-settings-languageSelect"
                        color="success"
                        labelId="demo-simple-select-helper-label"
                        id="select-language"
                        name="language"
                        value={language}
                        label={getTranslatedText("Language")}
                        onChange={handleChangeLanguage}
                        displayEmpty
                    >
                        <MenuItem id="language-de" value={"de"}>{getTranslatedText("German")} </MenuItem>
                        <MenuItem id="language-en" value={"en"}>{getTranslatedText("English")} </MenuItem>
                    </Select>
                </FormControl>
            }
        </LanguageConsumer>
    )
}