/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { toggleResetChart, toggleShouldZoomOut } from "../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";

interface UseHandleIsRedrawFinishedArgs {
    didMount: React.MutableRefObject<boolean>;
    isRedrawFinished: React.SetStateAction<boolean>;
}

export const useHandleIsRedrawFinished = ({ didMount, isRedrawFinished }: UseHandleIsRedrawFinishedArgs) => {
    const dispatch = useAppDispatch();

    const chartModifier = useAppSelector(store => store.chartModifier);

    useEffect(() => {
        if ((isRedrawFinished && didMount.current) || chartModifier.resetChart) {
            if (chartModifier.shouldZoomOut) {
                setTimeout(() => {
                    dispatch(toggleResetChart())
                }, 500);
            } else {
                dispatch(toggleShouldZoomOut());
            }
        }
    }, [isRedrawFinished])
}