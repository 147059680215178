export const pickTextColorBasedOnBgColorAdvanced = (bgColor: string, lightColor: string, darkColor: string): string => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
}

export const shadeColorSimple = (color: string, amount: number) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export const shadeColor = (col: any, p: number) => {
    if(col && col !== undefined){
        const R = parseInt(col.substring(1, 3), 16);
        const G = parseInt(col.substring(3, 5), 16);
        const B = parseInt(col.substring(5, 7), 16);
        const curr_total_dark = (255 * 3) - (R + G + B);
    
        // Berechne wieviel Dunkeltöne aus den einzelnen Kanälen kommt
        const RR = ((255 - R) / curr_total_dark);
        const GR = ((255 - G) / curr_total_dark);
        const BR = ((255 - B) / curr_total_dark);
    
        // Berechne wieviel Dunkelton in der neuen Farbe sein soll
        const new_total_dark = ((255 - 255 * (p / 100)) * 3);
    
        // Neue Kanäle müssen den gleichen Prozentsatz an Dunkeltönen haben wie die alten
        const NR = Math.abs(255 - Math.round(RR * new_total_dark));
        const NG = Math.abs(255 - Math.round(GR * new_total_dark));
        const NB = Math.abs(255 - Math.round(BR * new_total_dark));
    
        const RO = ((NR.toString(16).length === 1) ? "0" + NR.toString(16) : NR.toString(16));
        const GO = ((NG.toString(16).length === 1) ? "0" + NG.toString(16) : NG.toString(16));
        const BO = ((NB.toString(16).length === 1) ? "0" + NB.toString(16) : NB.toString(16));
    
        return ("#" + RO + GO + BO).replaceAll("-", "");
    } else {
        return "#32992c";
    }
   
}