import { Annotation } from "../../Features/Annotations/Annotation";
import { setAllUserAnnotations } from "../../Features/Annotations/Redux/AnnotationUserSlice";
import { resetGraphViewerForNewFiles } from "../../Features/Home/HeaderInformation/ClearGraphViewer/ClearGraphViewer";
import { ChartRequest, ChartRequestSettings } from "../../Features/Home/Models/ChartRequest";
import { SettingsState, setAllSettings } from "../../Features/Settings/Redux/SettingsSlice";
import { checkIfAnyFileIsCustom } from "../../Layout/Navigation/Views/Tools";
import { NotificationType, setNotification } from "../../Layout/Notifications/Redux/NotificationsSlice";
import { LoadFilesItems } from "../Analytics/Model/Categories/LoadFilesEnums";
import { analyze_LoadFiles } from "../Analytics/Redux/AnalyticsSlice";
import { toggleResetChart } from "../ChartModifier/Redux/ChartModifierSlice";
import { saveSeriesRequestsInStore } from "../ChartRequestHandler/Redux/ChartRequestSlice";
import { getSeriesForGraphDataAsync } from "../ChartResultHandler/Redux/ChartResultSlice";
import { SeriesOrder } from "../ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { convertGvcFileListToPreparedGraphDataForBackendCall, convertFileListToPreparedGraphDataForBackendCall } from "../FileListConverter/FileListConverter";
import { getTranslation } from "../Internationalisation/TranslationService";
import { resetLoadingIndicator, setLoadingIndicator } from "../LoadingIndicator/LoadingIndicatorSlice";
import { AppDispatch } from "../Redux/store";
import { SupportedFileFormats } from "../SupportedFileFormats/SupportedFileFormats";

const showMessageAndResetLoadingIndicator = (langKey: string, type: NotificationType, dispatch: AppDispatch) => {
    dispatch(resetLoadingIndicator());
    dispatch(setNotification({ type: type, text: getTranslation(langKey) }))
}

export const selectFilesFromDialog = async (acceptedFiles: File[] | FileList,
    dispatch: AppDispatch,
    chartRequestSettings: ChartRequestSettings,
    languageIdentifier: string,
    savedOrderedSeries: SeriesOrder[],
    isSuperVisualisation: boolean,
    isLicenced: boolean,
    chartRequest: ChartRequest | null): Promise<void> => {

    dispatch(setLoadingIndicator());

    let filtered = [...acceptedFiles].filter(x => SupportedFileFormats().includes('.' + x.name.split('.').pop()));

    let isAnyFileGvc = filtered.some(x => x.name.split('.').pop() === 'gvc');
    let listOfConvertedFiles: ChartRequest;
    let settingsToRestore = null as (null | SettingsState);
    let userAnnotationsToRestore = null as (null | Annotation[]);
    if (acceptedFiles.length === 0 || filtered.length === 0) {
        showMessageAndResetLoadingIndicator("SomeFilesNotSupported", NotificationType.error, dispatch)
    } else {

        try {
            if (isAnyFileGvc) {
                if (!isLicenced)
                    return showMessageAndResetLoadingIndicator("GvcFileCanOnlyBeImportedIfLicenced", NotificationType.info, dispatch);

                if (filtered.length === 1) {
                    let gvcImportModel = await convertGvcFileListToPreparedGraphDataForBackendCall(filtered, chartRequestSettings, languageIdentifier)
                    listOfConvertedFiles = gvcImportModel.ChartRequest;
                    settingsToRestore = gvcImportModel.Settings;
                    userAnnotationsToRestore = gvcImportModel.UserAnnotations;
                } else {
                    return showMessageAndResetLoadingIndicator("OnlyOneGvcFileCanBeImported", NotificationType.info, dispatch);
                }
            } else {
                listOfConvertedFiles = await convertFileListToPreparedGraphDataForBackendCall(filtered, chartRequestSettings, languageIdentifier);
            }

            if (!isAnyFileGvc && filtered.length !== listOfConvertedFiles.Files.length) {
                showMessageAndResetLoadingIndicator("SomeFilesNotSupported", NotificationType.error, dispatch)
                return;
            }

            listOfConvertedFiles.SortedList = savedOrderedSeries;

            if (listOfConvertedFiles.Files.length > 1 && !isLicenced) {
                showMessageAndResetLoadingIndicator("NoSuperVisualisationWithoutLicence", NotificationType.info, dispatch)
            } 
            else if ((listOfConvertedFiles.Files.length > 1 && checkIfAnyFileIsCustom(listOfConvertedFiles.Files)) || (isSuperVisualisation && chartRequest?.Files && chartRequest.Files.length > 0 && checkIfAnyFileIsCustom(listOfConvertedFiles.Files))) {
                showMessageAndResetLoadingIndicator("NoSuperVisualisationForCustomFiles", NotificationType.info, dispatch)
            } 
            else {
                isSuperVisualisation && !isAnyFileGvc && chartRequest?.Files && chartRequest.Files.length > 0 && !checkIfAnyFileIsCustom(chartRequest.Files) && listOfConvertedFiles.Files.push(...chartRequest.Files);

                if (isSuperVisualisation && isAnyFileGvc && filtered.length === 1) {
                    resetGraphViewerForNewFiles(dispatch, chartRequestSettings.SuperVisualization?.SeriesType)
                }

                dispatch(saveSeriesRequestsInStore(listOfConvertedFiles));

                settingsToRestore && await dispatch(setAllSettings(settingsToRestore));

                const langugeForThisRequest = settingsToRestore && settingsToRestore.language ? settingsToRestore.language : languageIdentifier;

                await dispatch(getSeriesForGraphDataAsync({
                    ...listOfConvertedFiles,
                    LanguageIdentifier: langugeForThisRequest
                }));

                userAnnotationsToRestore && await dispatch(setAllUserAnnotations(userAnnotationsToRestore));

                dispatch(analyze_LoadFiles({ source: LoadFilesItems.FromFileSystem, nrOfFiles: listOfConvertedFiles.Files.length }))


            }
        }
        catch (ex) {
            if (typeof ex === "string")
                showMessageAndResetLoadingIndicator(ex, NotificationType.error, dispatch)
        }

        dispatch(toggleResetChart());
    }

}