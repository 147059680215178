/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { ShowCurrentValue } from "../../Settings/Redux/SettingsSlice";
import { updateChartModifier } from "../SciChartHelper/ChartModifier";

interface UseHandleSwitchShowCurrentValuesInChartArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleSwitchShowCurrentValuesInChart = ({ sciChart }: UseHandleSwitchShowCurrentValuesInChartArgs) => {
    const showCurrentValuesInChart = useAppSelector(store => store.settings.showCurrentValuesInChart === ShowCurrentValue.InChart);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            updateChartModifier(sciChart, showCurrentValuesInChart);
        }
    }, [showCurrentValuesInChart])
}