import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAppSelector, useAppDispatch } from '../../../Infrastructure/Redux/hooks';
import { isChartFullscreen, resetFullscreenChart, setFullscreenChart } from '../Redux/NavigationSlice';
import React, { useEffect, useState } from 'react';
import { Box, Tooltip, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Grow, Badge } from '@mui/material';
import { fileDialog } from 'file-select-dialog';
import { isCustomMode } from '../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { toggleAddDataPoint, toggleAddHorizontalLine, toggleAddVerticalLine, toggleDeleteLastAnnotation, toggleFilter, toggleResetChartFromButton, toggleZoomIn, toggleZoomOut, toggleMarkingMode, toggleDeleteAllAnnotation, setTakeScreenShot } from '../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice';
import { LanguageConsumer } from '../../../Infrastructure/Internationalisation/TranslationService';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { SuperVisualizationModeDto } from '../../../Features/Home/Models/SuperVisualizationModeDto';
import { SupportedFileFormats } from '../../../Infrastructure/SupportedFileFormats/SupportedFileFormats';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AnimationIcon from '@mui/icons-material/Animation';
import { setActiveTab, setOnlineHelp, toggleCurveAnimation, toggleSidebarVisibility } from '../../../Features/Settings/Redux/SettingsSlice';
import { SingleVisualizationModeDto } from '../../../Features/Home/Models/SingleVisualizationModeDto';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LoupeIcon from '@mui/icons-material/Loupe';
import { ChartRequestFile } from '../../../Features/Home/Models/ChartRequest';
import LockIcon from '@mui/icons-material/Lock';
import { NavLink } from 'react-router-dom';
import QueueIcon from '@mui/icons-material/Queue';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { setLoadFromControllerVisibility } from "../../../Features/LoadFromController/Redux/LoadAvailableFilesFromControllerSlice";
import { startOnlineHelp } from '../../../Infrastructure/OnlineHelp/Redux/OnlineHelpSlice';
import { FileOpen, GpsNotFixed, Notifications } from '@mui/icons-material';
import PublicIcon from '@mui/icons-material/Public';
import { checkSettings } from '../../../Features/LoadFromCockpit/CheckCockpitConnection/Redux/CheckCockpitSettingsSlice';
import { CockpitAvailable, setLoadFromCockpitVisibility } from '../../../Features/LoadFromCockpit/CurveData/Complete/Redux/LoadFromCockpitCompleteCurveDataSlice';
import { showCockpitAdvertising } from '../../Modal/Advertising/Redux/AdvertisingSlice';
import { analyze_AddAnnotations, analyze_DeleteAnnotations, analyze_ShowOnlineHelp, analyze_ZoomChart } from '../../../Infrastructure/Analytics/Redux/AnalyticsSlice';
import { ZoomChartItems } from '../../../Infrastructure/Analytics/Model/Categories/ZoomChartEnums';
import { AnnotationItems } from '../../../Infrastructure/Analytics/Model/Categories/AnnotationEnums';
import { OnlineHelpItems } from '../../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportRequestToGvc } from '../../../Infrastructure/ExportProvider/FileExportGvc';
import moment from 'moment';
import { selectFilesFromDialog } from '../../../Infrastructure/ImportProvider/ImportProvider';
import browser from 'browser-detect';
import { showNewFeatures } from '../../../Features/NewFeatures/Redux/NewFeaturesSlice';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const checkIfAnyFileIsCustom = (storedFiles: ChartRequestFile[]) => {
    for (const element of storedFiles) {
        if (element && element.Title.split('.').pop() === 'csv') {
            return true
        }
    }

    return false;
}

const ToolBar = () => {
    const savedOrderedSeries = useAppSelector(store => store.chartSeriesOrder.seriesOrders);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const isSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const isToggled = useAppSelector(isChartFullscreen);
    const dispatch = useAppDispatch();
    const isFilterActive = useAppSelector(store => store.chartModifier.filter);
    const chartRequestSettings = useAppSelector(store => store.settings);
    const isChartRequestSeriesAvailable = useAppSelector(store => store.chartRequestData.series !== null);
    const annotations = useAppSelector(store => store.chartModifier.annotations);
    const isCurveAnimationEnabled = useAppSelector(store => store.settings.isCurveAnimationEnabled);
    const isShareEnabled = useAppSelector(store => store.chartModifier.share.isShown);
    const isOnlineHelpEnabled = useAppSelector(store => store.settings.showOnlineHelp);
    const [anchorElAnnotations, setAnchorElAnnotations] = React.useState<null | HTMLElement>(null);
    const [anchorElZoom, setAnchorElZoom] = React.useState<null | HTMLElement>(null);
    const [anchorElOpenFile, setAnchorElOpenFileMenu] = React.useState<null | HTMLElement>(null);
    const customMode = useAppSelector(isCustomMode);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
    const isLoadFromControllerVisible = useAppSelector(store => store.loadAvailableFilesFromController.isShown);
    const isLoadFromCockpitVisible = useAppSelector(store => store.loadFromCockpit.isShown);
    const isCockpitAvailable = useAppSelector(store => store.loadFromCockpit.cockpitAvailable === CockpitAvailable.CockpitAvailableYes);
    const loadFromCockpit = useAppSelector(store => store.loadFromCockpit);
    const isSidebarVisible = useAppSelector(store => store.settings.isSidebarVisible);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const chartRequestSettings2 = useAppSelector(store => store.settings.chartRequestSettings);
    const languageIdentifier = useAppSelector(store => store.settings.language);
    const exportSettings = useAppSelector(store => store.settings);
    const exportUserAnnotations = useAppSelector(store => store.annotationUser.annotations);
    const filtersAreSet = useAppSelector(store => store.settings.chartRequestSettings.FilterSettings && store.settings.chartRequestSettings.FilterSettings.length > 0);
    const isFirefox = browser().name === "firefox";
    const newFeatures = useAppSelector(store => store.newFeatures.newFeatures);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOpenAnnotationsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAnnotations(event.currentTarget);
    };

    const handleOpenZoomMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElZoom(event.currentTarget);
    };

    const handleOpenFileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElOpenFileMenu(event.currentTarget);
    };

    const handleCloseAnnotationsMenu = () => {
        setAnchorElAnnotations(null);
    };

    const handleCloseZoomMenu = () => {
        setAnchorElZoom(null);
    };

    const handleCloseOpenFileMenu = () => {
        setAnchorElOpenFileMenu(null);
    };

    const selectFiles = async () => {

        const fileList = await fileDialog({ multiple: true, accept: SupportedFileFormats() });

        selectFilesFromDialog(
            fileList, 
            dispatch, 
            chartRequestSettings2,
            languageIdentifier, 
            savedOrderedSeries, 
            isSuperVisualisation, 
            isLicenced, 
            chartRequest);
    }

    const zoomInButtonClick = () => {
        dispatch(toggleZoomIn());
        dispatch(analyze_ZoomChart({ action: ZoomChartItems.ZoomIn }))
    }

    const zoomOutButtonClick = () => {
        dispatch(toggleZoomOut());
        dispatch(analyze_ZoomChart({ action: ZoomChartItems.ZoomOut }))
    }

    const zoomResetButtonClick = () => {
        dispatch(toggleResetChartFromButton());
        dispatch(analyze_ZoomChart({ action: ZoomChartItems.ResetZoom }))
    }

    const addVerticalLineClick = () => {
        dispatch(toggleAddVerticalLine());
        dispatch(analyze_AddAnnotations({ action: AnnotationItems.AddVerticalLine }))
    }

    const addHorizontalLineClick = () => {
        dispatch(toggleAddHorizontalLine());
        dispatch(analyze_AddAnnotations({ action: AnnotationItems.AddHorizontalLine }))
    }

    const addAddDataPointClick = () => {
        dispatch(toggleAddDataPoint());
        dispatch(analyze_AddAnnotations({ action: AnnotationItems.AddDataPoint }))
    }

    const toogleMarkingModeClick = () => {
        dispatch(toggleMarkingMode());
        dispatch(analyze_AddAnnotations({ action: AnnotationItems.ToggleMarkingMode }))
    }

    const deleteLastAnnotationClick = () => {
        dispatch(toggleDeleteLastAnnotation());
        dispatch(analyze_DeleteAnnotations())
    }

    const deleteAllAnnotationClick = () => {
        dispatch(toggleDeleteAllAnnotation());
        dispatch(analyze_DeleteAnnotations())
    }

    const onlineHelpClick = () => {
        isCurveAnimationEnabled && dispatch(toggleCurveAnimation());
        dispatch(setOnlineHelp());
        dispatch(startOnlineHelp());
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.MainOnlineHelp }));
    }

    const shouldCurveAnimationBeInactive = () => {

        if (isSuperVisualisation) {
            if (chartRequestSettings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Angle ||
                chartRequestSettings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Torque) {
                return false;
            }
            return true;
        } else {
            if (chartRequestSettings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Angle ||
                chartRequestSettings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Torque) {
                    return false;
                }
            }
        return true;
    }

    const tools = [
        {
            title: 'ToolsOpenFilesFromSystemOrController',
            shouldAnimate: !isChartRequestSeriesAvailable && !isSidebarVisible && !anchorElOpenFile,
            visible: true,
            licenced: true,
            menu: {
                closeMenu: handleCloseOpenFileMenu,
                anchor: anchorElOpenFile,
                items: [
                    {
                        title: 'ToolsOpenFiles',
                        shouldAnimate: false,
                        licenced: true,
                        menu: null,
                        icon: <FileOpen fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />,
                        iconToggle: null,
                        toggle: null,
                        function: selectFiles,
                        color: '#ebf2ee',
                        inactive: false,
                        className: "tools-open-from-file"
                    },
                    {
                        title: 'ToolsLoadFromController',
                        shouldAnimate: false,
                        licenced: isLicenced,
                        menu: null,
                        icon: <SystemUpdateAltIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />,
                        iconToggle: <SystemUpdateAltIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />,
                        toggle: isLoadFromControllerVisible,
                        function: () => dispatch(setLoadFromControllerVisibility()),
                        color: '#ebf2ee',
                        inactive: !isLicenced,
                        className: "tools-open-from-controller"
                    },
                    {
                        title: 'ToolsLoadFromCockpit',
                        shouldAnimate: false,
                        licenced: isLicenced,
                        menu: null,
                        icon: <PublicIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />,
                        iconToggle: <PublicIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />,
                        toggle: isLoadFromCockpitVisible,
                        function: () => {
                            dispatch(checkSettings({
                                cockpitUrl: loadFromCockpit.cockpitUrl,
                                cockpitArea: loadFromCockpit.cockpitArea,
                                username: loadFromCockpit.credentials.username,
                                password: loadFromCockpit.credentials.password
                            }))
                            dispatch(setLoadFromCockpitVisibility())
                        },
                        secondaryFunction: () => {
                            dispatch(showCockpitAdvertising());
                        },
                        color: '#ebf2ee',
                        inactive: !isCockpitAvailable || !isLicenced,
                        className: "tools-open-from-cockpit"
                    },
                ]
            },
            icon: <QueueIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />,
            iconToggle: <QueueIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />,
            toggle: null,
            function: handleOpenFileMenu,
            color: '#ebf2ee',
            inactive: false,
            className: "tools-openFromSystemOrController tools-open-from-file tools-open-from-controller tools-open-from-cockpit tools-open"
        },
        { title: 'ExportAsFile', visible: true, shouldAnimate: false, licenced: isLicenced, menu: null, icon: <SaveAltIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <SaveAltIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: null, function: () => exportRequestToGvc(chartRequest?.Files, exportSettings, exportUserAnnotations, moment(new Date()).format("YYYY-MM-DD_HH-mm-ss"), isFirefox), color: '#ebf2ee', inactive: !isChartRequestSeriesAvailable || customMode, className: "tools-export" },
        {
            title: 'ToolsZoom', visible: true, shouldAnimate: false, licenced: true,
            menu: {
                closeMenu: handleCloseZoomMenu,
                anchor: anchorElZoom,
                items: [
                    { title: 'ToolsZoomIn', shouldAnimate: false, menu: null, icon: <ZoomInOutlinedIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: null, toggle: null, function: zoomInButtonClick, color: '#ebf2ee', inactive: false, className: "tools-zoomin" },
                    { title: 'ToolsZoomOut', shouldAnimate: false, menu: null, icon: <ZoomOutOutlinedIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: null, toggle: null, function: zoomOutButtonClick, color: '#ebf2ee', inactive: false, className: "tools-zoomout" },
                    { title: 'ToolsZoomReset', shouldAnimate: false, menu: null, icon: <CropFreeIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: null, toggle: null, function: zoomResetButtonClick, color: '#ebf2ee', inactive: false, className: "tools-zoomreset" },
                ]
            }, icon: <LoupeIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <LoupeIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: null, function: handleOpenZoomMenu, color: '#ebf2ee', inactive: false, className: "tools-zoom tools-zoomin tools-zoomout tools-zoomreset"
        },
        {
            title: 'ToolsAnnotations', visible: true, shouldAnimate: false, licenced: isLicenced,
            menu: {
                closeMenu: handleCloseAnnotationsMenu,
                anchor: anchorElAnnotations,
                items: [
                    { title: 'ToolsAnnotationsVerticalLine',shouldAnimate: false, function: addVerticalLineClick, icon: <MoreVertIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, color: '#ebf2ee', inactive: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint || annotations.addValuePoint) },
                    { title: 'ToolsAnnotationsHorizontalLine',shouldAnimate: false, function: addHorizontalLineClick, icon: <MoreHorizIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, color: '#ebf2ee', inactive: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint || annotations.addValuePoint) },
                    { title: 'ToolsAnnotationsAddDataPoint',shouldAnimate: false, function: addAddDataPointClick, icon: <GpsFixedIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, color: '#ebf2ee', inactive: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint || annotations.addValuePoint) },
                    { title: annotations.addValuePoint ? 'ToolsAnnotationsMarkingModeOff' : 'ToolsAnnotationsMarkingMode',shouldAnimate: false, divider: true, function: toogleMarkingModeClick, icon: <GpsNotFixed fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, color: '#ebf2ee', inactive: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint) },
                    { title: 'ToolsAnnotationsDelete',shouldAnimate: false, function: deleteLastAnnotationClick, icon: <DeleteOutlineIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, color: '#ebf2ee', inactive: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint || annotations.addValuePoint) },
                    { title: 'ToolsAnnotationsDeleteAll',shouldAnimate: false, function: deleteAllAnnotationClick, icon: <DeleteForeverIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, color: '#ebf2ee', inactive: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint || annotations.addValuePoint) },
                ]
            },
            icon: <AutoFixHighIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <AutoFixHighIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint || annotations.addValuePoint), function: handleOpenAnnotationsMenu, color: '#ebf2ee', inactive: (annotations.addHorizontalLine || annotations.addVerticalLine || annotations.addDataPoint), className: "tools-annotations tools-annotation-vertical-line tools-annotation-horizontal-line tools-annotation-data-point tools-annotation-start-marking-mode tools-annotation-delete"
        },
        { title: 'FilterSettings', visible: true, shouldAnimate: false, licenced: isLicenced, menu: null, icon: <FilterAltIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <FilterAltIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: isFilterActive || filtersAreSet, function: () => dispatch(toggleFilter()), color: '#ebf2ee', inactive: !isChartRequestSeriesAvailable || customMode, className: "tools-filter" },
        { title: 'CurveAnimation', visible: true, shouldAnimate: false, licenced: isLicenced, menu: null, icon: <AnimationIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <AnimationIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: isCurveAnimationEnabled, function: () => dispatch(toggleCurveAnimation()), color: '#ebf2ee', inactive: shouldCurveAnimationBeInactive() || !isChartRequestSeriesAvailable || customMode, className: "tools-curveanimation" },
        { title: 'ShareTool', visible: true, shouldAnimate: false, licenced: true, menu: null, icon: <IosShareIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <IosShareIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: isShareEnabled, function: () => dispatch(setTakeScreenShot()), color: '#ebf2ee', inactive: false, className: "tools-share" },
        { title: 'ToolsExpand', visible: true, shouldAnimate: false, licenced: true, menu: null, icon: <ZoomOutMapIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <ZoomInMapIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: isToggled, function: () => !isToggled ? dispatch(setFullscreenChart()) : dispatch(resetFullscreenChart()), color: '#ebf2ee', inactive: false, className: "tools-fullscreen" },
        { title: isSidebarVisible ? 'SidebarCollapse' : 'SidebarExpand', visible: true, shouldAnimate: false, licenced: true, menu: null, icon: <ViewSidebarIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <ViewSidebarIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: isSidebarVisible, function: () => dispatch(toggleSidebarVisibility()), color: '#ebf2ee', inactive: false, className: "tools-sidebar" }
    ];

    const onlineHelp = { title: 'OnlineHelp', menu: null, icon: <ContactSupportIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, iconToggle: <ContactSupportIcon fontSize={windowDimensions.width <= 1200 ? 'small' : 'medium'} />, toggle: isOnlineHelpEnabled, function: onlineHelpClick, color: '#DFE7FD', inactive: false, className: "" };


    return (<LanguageConsumer>

        {({ getTranslatedText }) =>
            <Box component="div" sx={{ px: windowDimensions.width <= 1200 ? 1 : 2, flexGrow: 0, display: { xs: 'none', md: 'inline-flex' }, alignItems: 'center', justifyContent: 'center' }}>
                {tools.map((tool, toolKey) => tool.visible ? (<div key={toolKey}>
                    <Tooltip title={getTranslatedText(tool.title)} arrow>

                        <span className="tooltipSpan">
                            {!tool.licenced && <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                            <IconButton
                                size={windowDimensions.width <= 1200 ? 'small' : 'medium'}
                                className={tool.className + (tool.shouldAnimate ? ' spin-shake' : '')}
                                disabled={tool.inactive || !tool.licenced}
                                color="success"
                                onClick={tool.function}
                                sx={{ my: 2, mx: 1.5, color: tool.toggle !== null ? (!tool.toggle ? (isDarkMode ? '#c2c0c0' : '#757474') : '#32992c') : (isDarkMode ? '#c2c0c0' : '#757474'), background: isDarkMode ? '#424242' : tool.color }}
                            >

                                {tool.toggle !== null ?
                                    (!tool.toggle ? tool.icon : tool.iconToggle)
                                    : tool.icon}
                            </IconButton>
                        </span>
                    </Tooltip>
                    {tool.menu &&
                        <Menu
                            sx={{ mt: '55px' }}
                            id="menu-appbar"
                            anchorEl={tool.menu.anchor}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(tool.menu.anchor)}
                            onClose={tool.menu.closeMenu}
                            TransitionComponent={Grow}>

                            {tool.menu.items.map((toolItem, toolItemKey) => (

                                <MenuItem sx={{ mt: toolItemKey === 0 ? 0 : 1 }} key={toolItemKey} onClick={() => {
                                    if (toolItem?.inactive && toolItem?.secondaryFunction) {
                                        toolItem.secondaryFunction()
                                    } else {
                                        toolItem.function()
                                    }
                                    tool.menu.closeMenu()
                                }} disabled={tool.inactive || (toolItem.inactive && !toolItem?.secondaryFunction)} divider={toolItem.divider}>

                                    <ListItemIcon
                                        color="success"
                                        onClick={toolItem.function}
                                        sx={{ color: isDarkMode ? '#c2c0c0' : '#757474' }}>
                                        {toolItem.icon}
                                    </ListItemIcon>
                                    <ListItemText>{getTranslatedText(toolItem.title)}</ListItemText>

                                </MenuItem>

                            ))}
                        </Menu>}

                </div>
                ) : <></>)}

                {windowDimensions.width >= 1200 && <div style={{ position: "absolute", right: "10px" }}>
                    <Tooltip title={getTranslatedText(onlineHelp.title)} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <span className="tooltipSpan">
                            <IconButton
                                size={windowDimensions.width <= 1200 ? 'small' : 'medium'}
                                className={onlineHelp.className}
                                color="success"
                                onClick={onlineHelp.function}
                                sx={{ my: 2, mx: 2, color: onlineHelp.toggle !== null ? (!onlineHelp.toggle ? (isDarkMode ? '#c2c0c0' : '#757474') : '#32992c') : (isDarkMode ? '#c2c0c0' : '#757474'), background: isDarkMode ? '#424242' : onlineHelp.color }}
                            >
                                {onlineHelp.toggle !== null ?
                                    (!onlineHelp.toggle ? onlineHelp.icon : onlineHelp.iconToggle)
                                    : onlineHelp.icon}
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>}

                {windowDimensions.width >= 1200 && newFeatures && newFeatures.length > 0 && <div style={{ position: "absolute", right: "70px" }}>
                    <Tooltip title={getTranslatedText("NewFeaturesAvailable")} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <span className="tooltipSpan">
                            <IconButton
                                size={windowDimensions.width <= 1200 ? 'small' : 'medium'}
                                color="success"
                                onClick={() => dispatch(showNewFeatures())}
                                sx={{ my: 2, mx: 2, color: onlineHelp.toggle !== null ? (!onlineHelp.toggle ? (isDarkMode ? '#c2c0c0' : '#757474') : '#32992c') : (isDarkMode ? '#c2c0c0' : '#757474'), background: isDarkMode ? '#424242' : '#ebf2ee' }}
                            >   <Badge badgeContent={newFeatures.length} color="error">
                                    <Notifications />
                                </Badge>
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>}

            </Box>
        }

    </LanguageConsumer>
    )
}

export default ToolBar;
