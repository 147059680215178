import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationBase } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { getSeriesForGraphDataAsync } from '../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { sendLoadExampleFileToBackend } from '../../Home/HeaderInformation/LoadExampleFile/Redux/LoadExampleFileSlice';
import { Annotation, AnnotationType, DataPointAnnotation, HorizontalAnnotation, TextValuePointAnnotation, ValuePointAnnotation, VerticalAnnotation } from '../Annotation';
import { makeId, updateUserAnnotation } from '../AnnotationService';
import { HorizontalLineActionPayload, VerticallLineActionPayload, DataPointActionPayload, ValuePointActionPayload } from '../Models/UserAnnotationActionPayload';

interface AnnotationUserState {
    annotations: Annotation[];
    selectedAnnotationId: string;
    keepAnnotations: boolean
}

export const initialAnnotationUserState: AnnotationUserState = {
    annotations: [],
    selectedAnnotationId: "",
    keepAnnotations: false,
}

export interface DataPoint {
    x1: number;
    y1: number;
}

export interface ValuePoint {
    formattedValue: string;
    x1: number;
    y1: number;
    seriesTitle: string;
}

export interface UserAnnotationUpdatePositionPayload {
    id: string,
    x1: number | null,
    x2: number | null,
    y1: number | null,
    y2: number | null
}

export interface UpdateSeriesAnnotationPayplad {
    visibleSeriesTitle: string[];
    existingSeriesTitle: string[]
}

export const userAnnotationPrefix: string = "userAnnotation_";

const annotationUserSlice = createSlice({
    name: "AnnotationUser",
    initialState: initialAnnotationUserState,
    reducers: {
        setAllUserAnnotations: (state, action) => {
            state.annotations = [...action.payload];
        },
        addHorizontalLine: (state, action: PayloadAction<HorizontalLineActionPayload>) => {
            state.annotations = [...state.annotations,
            {
                color: action.payload.color,
                id: userAnnotationPrefix + makeId(state.annotations.length + 1),
                y1: action.payload.yValue,
                type: AnnotationType.horizontal,
                visible: true,
                isEditable: true,
            } as unknown as HorizontalAnnotation
            ]
        },
        addVerticallLine: (state, action: PayloadAction<VerticallLineActionPayload>) => {
            state.annotations = [...state.annotations,
            {
                color: action.payload.color,
                id: userAnnotationPrefix + makeId(state.annotations.length + 1),
                x1: action.payload.xValue,
                type: AnnotationType.vertical,
                visible: true,
                isEditable: true,
            } as unknown as VerticalAnnotation
            ]
        },
        addDataPoint: (state, action: PayloadAction<DataPointActionPayload>) => {
            state.annotations = [...state.annotations,
            {
                color: action.payload.color,
                id: userAnnotationPrefix + makeId(state.annotations.length + 1),
                x1: action.payload.dataPoint.x1,
                y1: action.payload.dataPoint.y1,
                type: AnnotationType.dataPoint,
                visible: true,
                isEditable: true,
            } as unknown as DataPointAnnotation
            ]
        },
        addValuePoint: (state, action: PayloadAction<ValuePointActionPayload>) => {
            let dependandId = userAnnotationPrefix + makeId(state.annotations.length + 1);
            state.annotations = [...state.annotations,
            {
                color: action.payload.color,
                id: userAnnotationPrefix + makeId(state.annotations.length + 1),
                x1: action.payload.valuePoint.x1,
                y1: action.payload.valuePoint.y1,
                type: AnnotationType.valuePoint,
                seriesTitle: action.payload.valuePoint.seriesTitle,
                visible: true,
                isEditable: true,
                dependantAnnoationId: dependandId,
            } as unknown as ValuePointAnnotation,
            {
                color: action.payload.color,
                id: userAnnotationPrefix + makeId(state.annotations.length + 2),
                formattedValue: action.payload.valuePoint.formattedValue,
                x1: action.payload.valuePoint.x1 + 5,
                y1: action.payload.valuePoint.y1,
                type: AnnotationType.textValuePoint,
                seriesTitle: action.payload.valuePoint.seriesTitle,
                visible: true,
                isEditable: false,
                dependantAnnoationId: dependandId,
            } as unknown as TextValuePointAnnotation,
            ]
        },
        updateSeriesAnnotation: (state, action: PayloadAction<UpdateSeriesAnnotationPayplad>) => {
            let seriesIndependentAnnotations = state.annotations.filter(x => !x.seriesTitle);

            let existingAnnotations = state.annotations.filter(x => x.seriesTitle)
                .filter(x => action.payload.existingSeriesTitle.find(y => y === x.seriesTitle) !== null);

            for (let annotation of existingAnnotations) {
                annotation.visible = action.payload.visibleSeriesTitle.indexOf(annotation.seriesTitle) > -1;
            }

            state.annotations = seriesIndependentAnnotations.concat(existingAnnotations);
        },
        updateUserAnnotationPosition: (state, action: PayloadAction<AnnotationBase>) => {
            state.selectedAnnotationId = action.payload.id;
            let userAnnotationToUpdate = state.annotations.find(x => x.id === action.payload.id);
            if (userAnnotationToUpdate) {
                updateUserAnnotation(userAnnotationToUpdate, action.payload);
            }
        },
        removeAnnotationById: (state, action: PayloadAction<string>) => {
            state.annotations = [...state.annotations.filter(x => x.id !== action.payload)];
        },
        removeAllUserAnnotations: (state) => {
            state.annotations = []
        },
        setselectedAnnotationId: (state, action) => {
            state.selectedAnnotationId = action.payload
        },
        toogleKeepUserAnnotations: (state) => {
            state.keepAnnotations = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSeriesForGraphDataAsync.fulfilled, state => {
            if (state.keepAnnotations)
                state.keepAnnotations = false
            else
                state.annotations = []
        })
            .addCase(sendLoadExampleFileToBackend.fulfilled, state => {
                state.annotations = []
            });
    }
});

export const { setAllUserAnnotations, addHorizontalLine, addVerticallLine, addDataPoint, addValuePoint, removeAnnotationById,
    removeAllUserAnnotations, setselectedAnnotationId, updateSeriesAnnotation, updateUserAnnotationPosition,toogleKeepUserAnnotations } = annotationUserSlice.actions;

export default annotationUserSlice.reducer;