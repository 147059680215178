import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { SuperVisualizationModeDto } from "../../Home/Models/SuperVisualizationModeDto";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { analyze_ChangeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { resetChartRequest } from "../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { resetChartResult } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { showModal, hideModal } from "../../../Layout/Modal/Redux/ModalSlice";
import { setActiveTab, setSuperVisualisation } from "../Redux/SettingsSlice";
import { NavLink } from "react-router-dom";

export const SuperVisualistion = () => {
  const dispatch = useAppDispatch();

  const supervisualisationMode = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode);
  const chartRequest = useAppSelector(store => store.chartRequestData.series);
  const supervisualisationSeriesType = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.SeriesType);
  const licenceData = useAppSelector(store => store.licenceService);

  const handleChangeSuperVisualization = (value: SuperVisualizationModeDto, cancelText: string, confirmationText: string) => {
    if (supervisualisationMode !== SuperVisualizationModeDto.None && chartRequest?.Files && chartRequest.Files.length > 0 && value === SuperVisualizationModeDto.None) {
      dispatch(showModal({
        bodyText: "DataWillBeRemoved", titleText: "DeactivateSuperVisualisation", buttons: <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 0, mt: 3 }}>
          <Grid item xs={6}>
            <Button color="info" variant="outlined" fullWidth size="large" onClick={() => dispatch(hideModal())}>
              {cancelText}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="error" variant="outlined" fullWidth size="large" onClick={() => {
              dispatch(resetChartRequest());
              dispatch(resetChartResult());
              dispatch(setSuperVisualisation({ mode: SuperVisualizationModeDto.None, seriestype: supervisualisationSeriesType }))
              dispatch(hideModal());              
            }}>
              {confirmationText}
            </Button>
          </Grid>
        </Grid>
      }));

    } else {
      dispatch(setSuperVisualisation({ mode: value, seriestype: supervisualisationSeriesType }))
    }    
    dispatch(analyze_ChangeDefaultSettings({ setting: DefaultSettingsItems.SuperVisualizationForMultipleFiles, newValue: SuperVisualizationModeDto[value] }))          
  }

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) =>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel color="success" id="select-label-supervisualisation">
            {getTranslatedText("SuperVisualisation")}
          </InputLabel>
          {!licenceData.licenceAuthentication.isLicenced && (
            <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>
          )}
          <Select
            className="onlinehelp-analyse-supervisualisationSelect"
            disabled={!licenceData.licenceAuthentication.isLicenced}
            color="success"
            labelId="select-label-supervisualisation"
            id="select-supervisualisation"
            name="select-supervisualisation"
            value={supervisualisationMode}
            label={getTranslatedText("SuperVisualisation")}
            onChange={selectedSuperVisualisationMode => handleChangeSuperVisualization(
              selectedSuperVisualisationMode.target.value as SuperVisualizationModeDto,
              getTranslatedText("Cancel"),
              getTranslatedText("ConfirmAndRemove")
            )}
          >
            <MenuItem id="supervisualisation0" value={SuperVisualizationModeDto.None}>{getTranslatedText("SuperVisualisationNone")} </MenuItem>
            <MenuItem id="supervisualisation1" value={SuperVisualizationModeDto.Angle}>{getTranslatedText("SuperVisualisationAngle")} </MenuItem>
            <MenuItem id="supervisualisation2" value={SuperVisualizationModeDto.Time}>{getTranslatedText("SuperVisualisationTime")} </MenuItem>
            <MenuItem id="supervisualisation3" value={SuperVisualizationModeDto.Torque}>{getTranslatedText("SuperVisualisationTorque")} </MenuItem>
          </Select>
        </FormControl>
      }
    </LanguageConsumer>
  );
};
