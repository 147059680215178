/* eslint-disable react-hooks/exhaustive-deps */
import Tour from 'reactour';
import { Box, Button, Grid, Typography } from "@mui/material";
import { getTranslation, LanguageConsumer } from "../../Internationalisation/TranslationService";
import { useEffect, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useAppDispatch, useAppSelector } from '../../Redux/hooks';
import { setPopoverFeedbackLastShown } from '../Redux/PopoverSlice';
import moment from 'moment';
import { toggleShowFeedback } from '../../../Features/Feedback/Redux/FeedbackSlice';
import { numberOfDaysBeforeTheFeedbackPopoverIsShownAgain } from '../ConstValues';

export const FeedbackPopover = () => {

    const dispatch = useAppDispatch();
    const popoverFeedbackLastShown = useAppSelector(store => store.popover.popoverFeedbackLastShown);
    const showGlobalOnlineHelp = useAppSelector(store => store.settings.showOnlineHelp);

    const [showPopover, setShowPopover] = useState(false)

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

    useEffect(() => {
        
         if (showGlobalOnlineHelp || !document.cookie.match(/^(.*;)?\s*depragGraphViewerCookie\s*=\s*[^;]+(.*)?$/)) 
            return

        if (!popoverFeedbackLastShown) {
            show()
        }
        else {
            var prevDate = new Date(popoverFeedbackLastShown)
            var newDate = new Date();

            const diffDuration = moment.duration(moment(newDate).diff(moment(prevDate)));
            if (diffDuration.asDays() >= numberOfDaysBeforeTheFeedbackPopoverIsShownAgain)
                show()
        }
    }, []);

    const show = () => {
        setTimeout(async () => {
            setShowPopover(true)
            dispatch(setPopoverFeedbackLastShown());
        }, 1000);
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Tour
                    disableFocusLock
                    disableInteraction={true}
                    showNavigation={false}
                    showNumber={false}
                    showButtons={false}
                    closeWithMask={true}
                    showCloseButton={false}
                    accentColor={'#32992c'}
                    steps={
                        [{
                            selector: '.popover-feedback',
                            position: 'top',
                            style: { padding: 5 },
                            content: () => (
                                <Box component={'div'} sx={{p: 2}}>
                                    <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FeedbackTitle")}</Typography>
                                    <div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FeedbackPopoverMessage") }}></div>
                                    <Grid container spacing={2} sx={{ pt: 3 }}>
                                        <Grid xl={6} xs={12} item>
                                            <Button sx={{ pl: 0, pr: 0 }} fullWidth variant='outlined' color='inherit'  onClick={() => setShowPopover(false)}>
                                                {getTranslation("FeedbackPopoverMaybeLater")}
                                            </Button>
                                        </Grid>
                                        <Grid xl={6} xs={12} item>
                                            <Button sx={{ pl: 0, pr: 0 }} fullWidth variant='outlined' color='success' onClick={() => {
                                                setShowPopover(false)
                                                dispatch(toggleShowFeedback())
                                            }
                                            } >
                                                {getTranslation("FeedbackPopoverYesSure")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>),
                        }]}
                    isOpen={showPopover}
                    onRequestClose={() => {
                        setShowPopover(false);
                    }}
                    rounded={5}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                />}
        </LanguageConsumer>
    );
}