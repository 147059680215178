import { Button, Grid, Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = () => {
    return [
      // INTRO
      {
        position: 'center',
        stepInteraction: false,
        content: ({ goTo, close }) => (<div>
          <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("OnlineHelpInitialTitle")}</Typography>
          <div style={{color: '#444'}} dangerouslySetInnerHTML={{ __html: getTranslation("OnlineHelpInitialMessage") }}></div>
          <Grid container direction={'row'} spacing={2} sx={{mt: 2}}>
            <Grid item xs={6}>
            <Button color='error' fullWidth variant="outlined" onClick={() => close()}>{getTranslation("OnlineHelpNo")}</Button>
            </Grid>
            <Grid item xs={6}>
            <Button color='success' fullWidth variant="outlined" onClick={() => goTo(1)}>{getTranslation("OnlineHelpYes")}</Button>
            </Grid>
          </Grid>
        
        </div>)
      },
        // Navigation - PAGES
        {
            selector: '.page-home',
            position: 'bottom',
            content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpPageHomeTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpPageHomeMessage")}}></div></div>)
          },
          {
            selector: '.page-information',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpPageInformationTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpPageInformationMessage")}}></div></div>)
          },
          {
            selector: '.page-settings',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpPageSettingsTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpPageSettingsMessage")}}></div></div>)
          },
          // Navigation - TOOLS
          {
            selector: '.tools-open',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsOpenTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsOpenMessage")}}></div></div>)
          },
          {
            selector: '.tools-open-from-file',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsOpenFromFileTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsOpenFromFileMessage")}}></div></div>)
          },          
          {
            selector: '.tools-open-from-controller',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsOpenFromControllerTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsOpenFromControllerMessage")}}></div></div>)
          },                    
          {
            selector: '.tools-open-from-cockpit',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsOpenFromCockpitTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsOpenFromCockpitMessage")}}></div></div>)
          },
          {
            selector: '.tools-export',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsExportTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsExportMessage")}}></div></div>)
          },           
          {
            selector: '.tools-zoom',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsZoomTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsZoomMessage")}}></div></div>)
          },
          {
            selector: '.tools-zoomin',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsZoomInTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsZoomInMessage")}}></div></div>)
          },
          {
            selector: '.tools-zoomout',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsZoomOutTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsZoomOutMessage")}}></div></div>)
          },
          {
            selector: '.tools-zoomreset',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsZoomResetTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsZoomResetMessage")}}></div></div>)
          },
          {
            selector: '.tools-annotations',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsAnnotationsTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsAnnotationsMessage")}}></div></div>)
          },
          {
            selector: '.tools-annotation-vertical-line',
            position: 'bottom',
            content: () =>( 
              <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{
                getTranslation("OnlineHelpToolsAnnotationVerticalLineTitle")}
                </Typography>
                  <div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsAnnotationVerticalLineDescription")}}></div>
              </div>)
          },
          {
            selector: '.tools-annotation-horizontal-line',
            position: 'bottom',
            content: () =>( 
              <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{
                getTranslation("OnlineHelpToolsAnnotationHorizontalLineTitle")}
                </Typography>
                  <div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsAnnotationHorizontalLineDescription")}}></div>
              </div>)         
          },
          {
            selector: '.tools-annotation-data-point',
            position: 'bottom',
            content: () =>( 
              <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{
                getTranslation("OnlineHelpToolsAnnotationDataPointTitle")}
                </Typography>
                  <div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsAnnotationDataPointDescription")}}></div>
              </div>)  
          },
          {
            selector: '.tools-annotation-start-marking-mode',
            position: 'bottom',
            content: () =>( 
              <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{
                getTranslation("OnlineHelpToolsAnnotationStartMarkingModeTitle")}
                </Typography>
                  <div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsAnnotationStartMarkingModeDescription")}}></div>
              </div>)
          },
          {
            selector: '.tools-annotation-delete',
            position: 'bottom',
            content: () =>( 
              <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{
                getTranslation("OnlineHelpToolsAnnotationDeleteTitle")}
                </Typography>
                  <div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsAnnotationDeleteDescription")}}></div>
              </div>)
          },                          
          {
            selector: '.tools-filter',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsFilterTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsFilterMessage")}}></div></div>)
          },
          {
            selector: '.tools-curveanimation',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsCurveAnimationTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsCurveAnimationMessage")}}></div></div>)
          },
          {
            selector: '.tools-share',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsShareTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsShareMessage")}}></div></div>)
          },
          {
            selector: '.tools-fullscreen',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsFullscreenTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsFullscreenMessage")}}></div></div>)
          },
          {
            selector: '.tools-sidebar',
            position: 'bottom',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpToolsSidebarTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpToolsSidebarMessage")}}></div></div>)
          },
          // SciChart
          {
            selector: '.home-scichart',
            position: 'right',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpHomeScichartTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpHomeScichartMessage")}}></div></div>)
          },
          {
            selector: '.home-legend',
            position: 'left',
            content: () =>( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpHomeLegendSeriesTitle")}</Typography><div style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpHomeLegendSeriesMessage")}}></div></div>)
          }
    ]
}
