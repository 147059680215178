import Papa from "papaparse";
import { Matrix } from "react-spreadsheet"
import { CustomCsvSeries } from "../Models/CustomCsvSeries";
import { CustomCsvXAxis } from "../Models/CustomCsvXAxis";
import { CustomCsvXAxisMode } from "../Models/CustomCsvXAxisMode";
import { CustomCsvYAxis } from "../Models/CustomCsvYAxis";
import { CustomSeriesIsVisible } from "../Models/CustomSeriesIsVisible";


export const getHeadDataTime = (conversion: string = "X", dateTimeFormat: string = "DD.MM.yyyy HH:mm:ss", prefix: string = "") : Matrix<any> => {
    return [
        ["#XAXISCONVERSION"],
        [conversion.trim().length > 0 ? conversion : "X"],
        ["#XAXISDATETIMEFORMAT"],
        [dateTimeFormat.trim().length > 0 ? dateTimeFormat : "DD.MM.yyyy HH:mm:ss"],
        ["#XAXISPREFIX"],
        [prefix.trim().length > 0 ? prefix : ""]
    ]
}

export const getHeadDataValue = (prefix: string) : Matrix<any> => {
    return [
        ["#XAXISCONVERSION"],
        ["X"],
        ["#XAXISPREFIX"],
        [prefix]
    ]
}

export const addYAxisToMatrix = (matrix: Matrix<any>, customYAxis: CustomCsvYAxis[]) : Matrix<any> => {
    matrix.push(["#YAXIS"]);
    customYAxis.forEach(axis => {
        var newRowToAdd = [] as string[];
        newRowToAdd.push(axis.id);
        newRowToAdd.push(axis.name);
        newRowToAdd.push(axis.abbrevation);
        newRowToAdd.push(axis.digitCount.toString());
        matrix.push(newRowToAdd);
    });

    return matrix;
}

export const addSeriesToMatrix = (matrix: Matrix<any>, customSeries: CustomCsvSeries[]) : Matrix<any> => {
    matrix.push(["#SERIES"]);
    customSeries.forEach(serie => {
        var newSerieToAdd = [] as string[];
        newSerieToAdd.push(serie.name);
        newSerieToAdd.push(serie.color);
        newSerieToAdd.push(serie.isVisible === CustomSeriesIsVisible.Yes ? "1": "0");
        newSerieToAdd.push(serie.yAxisId);
        matrix.push(newSerieToAdd);
    })

    return matrix;
}

export const addSeriesDataToMatrix = (matrix: Matrix<any>, customSeries: CustomCsvSeries[], customXAxis: CustomCsvXAxis) : Matrix<any> => {
    matrix.push(["#DATA"]);

    var onlyDataArray = [] as string[];
    customSeries.forEach((serie) => {
        onlyDataArray.push(serie.data)
    });

    var newMatrix = [] as string[][]

    newMatrix.push(customXAxis.data.split("\n"))

    for(var j = 0; j < onlyDataArray.length; j++) {
        var singleDataArray = onlyDataArray[j].split("\n");
     
        newMatrix.push(singleDataArray);
    }

    var output = [] as string[][];
    for(var m = 0; m < newMatrix.length; m++){
        output = newMatrix[m].map((_, colIndex) => newMatrix.map(row => row[colIndex]));
    }

    output.forEach(element => {
        matrix.push(element);
    });

    return matrix;
}

export const generateExcelSheet = (customXAxis: CustomCsvXAxis, customYAxis: CustomCsvYAxis[], customSeries: CustomCsvSeries[]) : Blob => {
    var matrix = customXAxis.mode === CustomCsvXAxisMode.Time ? 
    getHeadDataTime(customXAxis.timeMode.xAxisConversion, customXAxis.timeMode.xAxisDateTimeFormat, customXAxis.abbrevation) : 
    getHeadDataValue(customXAxis.abbrevation);

    matrix = addYAxisToMatrix(matrix, customYAxis);

    matrix = addSeriesToMatrix(matrix, customSeries);

    matrix = addSeriesDataToMatrix(matrix, customSeries, customXAxis);

    var csv = Papa.unparse(matrix, {
        skipEmptyLines: false,
        delimiter: ",",
    });

    var BOM = "\uFEFF";
    var dataBlolb = [BOM + csv];

    var csvData = new Blob(dataBlolb, { type: 'text/csv;charset=utf-8' });

    return csvData;
}