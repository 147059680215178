import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface LocalStorageState {
  keyValue: string;
  isAllowReadCookieUnsafeDialogVisible: boolean,
}

const initialState: LocalStorageState = {
  keyValue: "",
  isAllowReadCookieUnsafeDialogVisible: false,
};

export const CrossLocalStorageSlice = createSlice({
  name: 'crosslocalstorage',
  initialState,
  reducers: {
    showAllowReadCookieUnsafeDialog: (state) => {
      state.isAllowReadCookieUnsafeDialogVisible = true;
    },
    hideAllowReadCookieUnsafeDialog: (state) => {
      state.isAllowReadCookieUnsafeDialogVisible = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFromCockpitCrossLocalStorageAsync.fulfilled, (state, action) => {
        state.keyValue = String(action.payload);
      })
      .addCase(loadFromSfaCrossLocalStorageAsync.fulfilled, (state, action) => {
        state.keyValue = String(action.payload);
      })
  },
});

export const loadFromCockpitCrossLocalStorageAsync = createAsyncThunk(
  'crosslocalstorage/initialize',
  async (_, thunkAPI) => {
    try {
      var response = await getItemFromHostAppDomainStorageWrapper();
      return response;
    }
    catch (ex) {
      thunkAPI.dispatch(showAllowReadCookieUnsafeDialog());
    }
  }
);

export const loadFromSfaCrossLocalStorageAsync = createAsyncThunk(
  'crosslocalstorage/initializeSfa',
  async () => {
    try{
      var response = await getItemFromSfaDomainStorageWrapper();
      return response;
    }catch(ex){}
  }
);

export default CrossLocalStorageSlice.reducer;

async function getItemFromHostAppDomainStorage(successCallback: any, errorCallback: any) {

  let params = new URLSearchParams(window.location.search);

  let hostAppProtocol = params.get("hostappprotocol");
  let hostAppDomain = params.get("hostappdomain");
  let hostAppPortNumber = params.get("hostappportnumber");
  let hostCustomerArea = params.get("hostcustomerarea");
  let key = params.get("key");

  if (!hostAppDomain || !hostAppPortNumber)
    return "";

  const downloadScrewdrivingResultUrl = `["${hostAppProtocol}://${hostAppDomain}:${hostAppPortNumber}/${hostCustomerArea}/System/AllScrewdrivingResults/GetResultFileForGraphViewer?finalResultId=${key}"]`;

  successCallback(downloadScrewdrivingResultUrl);
}

function getItemFromHostAppDomainStorageWrapper() {
  return new Promise(async (resolve, reject) => {

    getItemFromHostAppDomainStorage((successResponse: string) => {
      resolve(successResponse);
    }, (errorResponse: string) => {
      reject(errorResponse);
    });
  });
}

async function getItemFromSfaDomainStorage(successCallback: any, errorCallback: any) {
  let params = new URLSearchParams(window.location.search);
  let fileurl = params.get("fileurl");
  if (!fileurl)
    return "";
  
  const downloadScrewdrivingResultUrl = `["${fileurl}"]`;
  successCallback(downloadScrewdrivingResultUrl);

}

function getItemFromSfaDomainStorageWrapper() {
  return new Promise(async (resolve, reject) => {
    getItemFromSfaDomainStorage((successResponse: string) => {
      resolve(successResponse);
    }, (errorResponse: string) => {
      reject(errorResponse);
    });
  });
}

export const { showAllowReadCookieUnsafeDialog, hideAllowReadCookieUnsafeDialog } = CrossLocalStorageSlice.actions;