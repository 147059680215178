import { DeviceInformationResponseItem } from "../../CurveData/Simplified/Api/DeviceInformationResponse"
import { PreparedDeviceInformation } from "./Models/PreparedDeviceInformation"

export const prepareDeviceInformations = (curveFileResponseItemModel: DeviceInformationResponseItem[] | null,
    cutOffDateTime: Date | string): PreparedDeviceInformation[] => {

    var preparedDeviceInformations: PreparedDeviceInformation[] = []

    if (curveFileResponseItemModel && curveFileResponseItemModel.length > 0)
        curveFileResponseItemModel.forEach((item) => {

            preparedDeviceInformations.push({
                deviceId: item.deviceId,
                treeNodeId: item.deviceId.toString(),
                deviceName: item.deviceName,
                availableCurveFiles: item.availableCurveFiles,
                cutOffDateTime: cutOffDateTime,
                isChecked: false,
                isIndeterminate: false,
                cuveFileResponseItemDatas: []
            })
        })
        
    return preparedDeviceInformations
}