import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { analyze_ChangeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { setActiveTab, setAppTheme } from "../Redux/SettingsSlice";
import { NavLink } from "react-router-dom";

export const Theme = () => {
    const dispatch = useAppDispatch();

    const appTheme = useAppSelector(store => store.settings.appTheme);
    const licenceData = useAppSelector(store => store.licenceService);

    const handleChangeTheme = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string;
        dispatch(setAppTheme(value));
        dispatch(analyze_ChangeDefaultSettings({ settingsType: DefaultSettingsItems.AppTheme, value: value }))
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel color="success" id="select-label-theme">{getTranslatedText("AppTheme")}</InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                        <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        className="onlinehelp-settings-appThemeSelect"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-theme"
                        id="select-theme"
                        name="select-theme"
                        value={appTheme}
                        label={getTranslatedText("AppTheme")}
                        onChange={handleChangeTheme}
                    >
                        <MenuItem id="theme-light" value={"light"}>{getTranslatedText("ThemeLight")} </MenuItem>
                        <MenuItem id="theme-dark" value={"dark"}>{getTranslatedText("ThemeDark")} </MenuItem>
                    </Select>
                </FormControl>
            }
        </LanguageConsumer>
    )
}