import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const RemoveQueryParameter = () =>{

    const location = useLocation()
    const navigate = useNavigate() 

    useEffect(()=>{

      function removeLicenseInvitation() {
        queryParams.delete("email")
        queryParams.delete("licenceKey")
      }

      function removeCockpitQueryParams() {
        queryParams.delete("hostappprotocol")
        queryParams.delete("hostappdomain")
        queryParams.delete("hostappportnumber")
        queryParams.delete("hostcustomerarea")
        queryParams.delete("callback")      
        queryParams.delete("key")
        queryParams.delete("fileurl")
      }

      const queryParams = new URLSearchParams(location.search)
    
      removeCockpitQueryParams()       
      removeLicenseInvitation() 

      let queryParamsWithoutLicenseActivation = queryParams.toString();
      
      navigate({
        pathname: location.pathname,
        search:  queryParamsWithoutLicenseActivation      
      });           

    },[])

    return (<>
    </>);
}