import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { licenceVerification } from "./Redux/LicenceVerificationSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { setNewLicenceOfferRequested, setExpireDateAcknowledgedAt } from "../Redux/LicenceServiceSlice";
import { licenceRenewalOffer } from "../Offer/Renewal/Redux/LicenceRenewalOfferServiceSlice";

export const LicenceVerificationComponent = () => {
    const dispatch = useAppDispatch();
    const status = useAppSelector(store => store.licenceVerification.status);
    const licenceAuthentication = useAppSelector(store => store.licenceService.licenceAuthentication);
    const licenceRenewal = useAppSelector(store => store.licenceService.licenceRenewal);
    const navigate = useLocation();
    const chartModifier = useAppSelector(store => store.chartModifier);
    const isCurveAnimationEnabled = useAppSelector(store => store.settings.isCurveAnimationEnabled);
    const isChartFullscreen = useAppSelector(store => store.navigation.isChartFullscreen);
    const loadAvailableFilesFromController = useAppSelector(store => store.loadAvailableFilesFromController);
    const loadFromCockpit = useAppSelector(store => store.loadFromCockpit);
    const chartRequestData = useAppSelector(store => store.chartRequestData);
    const [licenceExpireDialogOpen, setLicenceExpireDialogOpen] = useState(false); 

    const millisecondsPerDay = 86400000

    const showUserLicenceExpireDaysBefore = 28
    const showUserLicenceExpireRepeatPerDays = 7

    const openLicenceExpiredDialogIfNeccessary = ()=>{
        if(!licenceAuthentication.isLicenced || !licenceAuthentication.isMaster || licenceRenewal.newLicenceOfferRequested){
            return;
        }

        var currentDate = new Date().getTime();
     
        var isExpirationWarningTimeRaeched = Math.floor(Date.parse(licenceAuthentication.validUntil) - currentDate)< millisecondsPerDay * showUserLicenceExpireDaysBefore; 

        var isExpirationRepeatWarningTimeReached = Math.floor(currentDate - Date.parse(licenceRenewal.expireDateAcknowledgedAt)) > millisecondsPerDay * showUserLicenceExpireRepeatPerDays;

        if((isExpirationWarningTimeRaeched && (licenceRenewal.expireDateAcknowledgedAt == "" || isExpirationRepeatWarningTimeReached))){
            setLicenceExpireDialogOpen(true)
        }
    }

    const handleCloseLicenceExpiredDialog = () => {
        dispatch(setExpireDateAcknowledgedAt(new Date().toString()))
        setLicenceExpireDialogOpen(false)
    }

    const handleRenewLicence = () => {
        dispatch(licenceRenewalOffer({MasterKey: licenceAuthentication.licenceKey, ClientId: licenceAuthentication.clientId}))
        dispatch(setNewLicenceOfferRequested(true))
        dispatch(setExpireDateAcknowledgedAt(new Date().toString()))
        setLicenceExpireDialogOpen(false)
    }

    const getDaysUntilExpiration = () => {

        var currentDate = new Date().getTime();
     
        return (Math.floor((Date.parse(licenceAuthentication.validUntil) - currentDate)/86400000)).toString(); 
    }

    useEffect(() => {

        if (!licenceAuthentication || !licenceAuthentication.isLicenced || licenceAuthentication.isMyDepragApiKey.trim().length > 0)
            return

        if (status === "loading")
            return
        
        if(licenceAuthentication.clientId.trim().length > 0 && licenceAuthentication.licenceKey.trim().length > 0){
            dispatch(licenceVerification({
                ClientId: licenceAuthentication.clientId,
                licenceKey: licenceAuthentication.licenceKey,
                trialKey: licenceAuthentication.isMyDepragApiKey
            }))
        }

        openLicenceExpiredDialogIfNeccessary();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[navigate, isCurveAnimationEnabled, isChartFullscreen, chartModifier, loadFromCockpit, loadAvailableFilesFromController, chartRequestData])
    
    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
                    <Dialog open={licenceExpireDialogOpen}>
                    <DialogTitle>{getTranslatedText("LicenseExpireDialogTitle", [getDaysUntilExpiration()])}</DialogTitle>
                    <DialogContent>
          
                      {getTranslatedText("LicenseExpireDialogBody")}
          
                    </DialogContent>
                    <DialogActions>
                      <Button color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={handleRenewLicence}>{getTranslatedText("LicenseExpireDialogExtendLicenseButton")}</Button>
                      <Button color="secondary" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={handleCloseLicenceExpiredDialog}>{getTranslatedText("LicenseExpireDialogIgnoreButton")}</Button>
                    </DialogActions>
                  </Dialog>

            }
        </LanguageConsumer>
    )
}