/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';


export interface SeriesOrder {
    title: string,
    position: number,
}

export interface ChartSeriesOrderState {
  seriesOrders: SeriesOrder[];
}

const initialState: ChartSeriesOrderState = {
    seriesOrders: [],
};

export const chartSeriesOrderSlice = createSlice({
  name: 'chartSeriesOrder',
  initialState,
  reducers: {
    setOrUpdateOrder: (state, action) => {
        var listOfPresentSeries = [] as SeriesOrder[];
        var updatedList = [] as SeriesOrder[];
        action.payload.list.map((list, key) => {
          if(state.seriesOrders.filter(x => x.title === list.title).length > 0){
            listOfPresentSeries.push({title: list.title, position: key});
          }
        });

        if(listOfPresentSeries.length > 0){
           updatedList = state.seriesOrders.map(series => {
            var keyToUpdate = listOfPresentSeries.filter(x => x.title === series.title);
            if(keyToUpdate.length > 0){
              return {
                ...series,
                position: keyToUpdate[0].position
              }
            } else {
              return series;
            }
          })
        }

        var newListToPush = action.payload.list.filter(x => !listOfPresentSeries.map(x => x.title).includes(x.title));
        var finalArray = updatedList.concat(newListToPush.map((l,k) => {return {title: l.title, position: k}}));
        finalArray.sort((a, b) => a.position - b.position);
        var finalSoredSeries = finalArray.map(x => ({title: x.title, position: x.position}));
        state.seriesOrders = finalSoredSeries;
       
      },
      setSeriesOrder: (state, action) => {
        state.seriesOrders =  action.payload;
      },
      clearChartSeriesOrders: (state) => {
        state.seriesOrders = [];
      }
  }
});

export const { setOrUpdateOrder, clearChartSeriesOrders, setSeriesOrder } = chartSeriesOrderSlice.actions;

export default chartSeriesOrderSlice.reducer;
