import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { SynchronizationMode } from "../../Home/Models/SynchronizationSettings";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { analyze_ChangeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { setActiveTab, setSynchronizationMode } from "../Redux/SettingsSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { NavLink } from "react-router-dom";

export const Synchronization = () => {
  const dispatch = useAppDispatch();

  const licenceData = useAppSelector(store => store.licenceService);
  const synchronizationPoint = useAppSelector(store => store.settings.chartRequestSettings.SynchronizationSettings?.Mode);

  const handleChangeSynchronization = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as SynchronizationMode;
    dispatch(setSynchronizationMode(value));
    dispatch(analyze_ChangeDefaultSettings({ setting: DefaultSettingsItems.SyncPoint, newValue: SynchronizationMode[value] }))
  }

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) =>
        <FormControl data-testid="synchronization-selection" fullWidth sx={{ mt: 4 }}>
          <InputLabel color="success" id="select-label-synchronizationPoint">{getTranslatedText("SynchronizationPointLabel")} </InputLabel>
          {!licenceData.licenceAuthentication.isLicenced && 
          <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
          <Select
            className="onlinehelp-analyse-syncronizationPointSelect"
            disabled={!licenceData.licenceAuthentication.isLicenced}
            color="success"
            labelId="select-label-synchronization-point"
            id="select-synchronization-point"
            name="select-synchronization-point"
            value={synchronizationPoint}
            label={getTranslatedText("SynchronizationPointLabel")}
            onChange={handleChangeSynchronization}
          >
            <MenuItem id="select-synchronization-point0" value={SynchronizationMode.None}>{getTranslatedText("SynchronizationModeNone")} </MenuItem>
            <MenuItem id="select-synchronization-point1" value={SynchronizationMode.MaxTorque}>{getTranslatedText("SynchronizationModeMaxTorque")} </MenuItem>
            <MenuItem id="select-synchronization-point3" value={SynchronizationMode.EndOfCurve}>{getTranslatedText("SynchronizationModeEndOfCurve")} </MenuItem>
            <MenuItem id="select-synchronization-point2" value={SynchronizationMode.StartOfCurve}>{getTranslatedText("SynchronizationModeStartOfCurve")} </MenuItem>
            <MenuItem id="select-synchronization-point6" value={SynchronizationMode.SeatingPoint}>{getTranslatedText("SynchronisationModeSeatingPoint")} </MenuItem>
            <MenuItem id="select-synchronization-point4" value={SynchronizationMode.ThresholdTorqueAngleSupervision}>{getTranslatedText("SynchronisationModeThresholdTorqueAngleSupervision")} </MenuItem>
            <MenuItem id="select-synchronization-point5" value={SynchronizationMode.ThresholdTorqueSpeed2}>{getTranslatedText("SynchronisationModeThresholdTorqueSpeed2")} </MenuItem>
            <MenuItem id="select-synchronization-point7" value={SynchronizationMode.IndividualTorque}>{getTranslatedText("SynchronisationModeIndividualTorque")} </MenuItem>
          </Select>
        </FormControl>
      }
    </LanguageConsumer>
  )
}