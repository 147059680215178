/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Drawer, Grid, Typography, Button, IconButton, Tooltip, Slider, FormControl, TextField, Divider, Grow } from "@mui/material";
import { resetShare } from "../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { getTranslation, LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { NotificationType, setNotification } from "../../Layout/Notifications/Redux/NotificationsSlice";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import GestureIcon from '@mui/icons-material/Gesture';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import './Style/Share.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { OnlineHelpIconButton } from "../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { ShareOnlineHelp } from "../../Infrastructure/OnlineHelp/Share/ShareOnlineHelp"
import { analyze_EditSharePicture, analyze_ExportSharePicture, analyze_ShowOnlineHelp } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { OnlineHelpItems } from "../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { EditShareItems, ExportShareItems } from "../../Infrastructure/Analytics/Model/Categories/ShareEnums";
import { CirclePicker, ColorResult } from "react-color";
import browser from 'browser-detect';


const renderCanva = (canvaRef, pencilColor, pencilSize, image, drawMode: DrawMode, textToAdd: string, isEditMode: boolean, dispatch) => {
    
    if (canvaRef && canvaRef.current) {
        const canvasElement = canvaRef.current;
        const context = canvasElement.getContext("2d");

        if (image) {
            const imageWidth = image.width * 0.85;
            const imageHeight = image.height * 0.85;

            canvasElement.width = imageWidth;
            canvasElement.height = imageHeight;
            context.drawImage(image, 0, 0, imageWidth, imageHeight);
        }

        let isDrawing;

        canvasElement.onmousedown = (e) => {
            if (isEditMode && e.which === 1) {
                var rect = canvasElement.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                if (drawMode === DrawMode.Free) {
                    isDrawing = true;
                    context.beginPath();
                    context.lineWidth = pencilSize;
                    context.strokeStyle = pencilColor;
                    context.lineJoin = "round";
                    context.lineCap = "round";
                    context.moveTo(x, y);
                } else if (drawMode === DrawMode.HorizontalLine) {
                    context.beginPath();
                    context.fillStyle = pencilColor;
                    context.fillRect(0, y, rect.width, pencilSize);
                    context.fill();
                } else if (drawMode === DrawMode.VerticalLine) {
                    context.beginPath();
                    context.fillStyle = pencilColor;
                    context.fillRect(x, 0, pencilSize, rect.height);
                    context.fill();
                } else if (drawMode === DrawMode.Text) {
                    context.font = (10 * pencilSize) + 'px Arial';
                    context.fillStyle = pencilColor;
                    context.fillText(textToAdd, x, y);
                }
                dispatch(analyze_EditSharePicture({ action: EditShareItems.Edit }))
            }
        };

        canvasElement.onmousemove = (e) => {
            if (isEditMode && e.which === 1) {
                var rect = canvasElement.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                if (isDrawing && drawMode === DrawMode.Free) {
                    context.lineTo(x, y);
                    context.stroke();
                }
            }

        };

        canvasElement.onmouseup = function () {
            if (isEditMode) {
                isDrawing = false;
                context.closePath();
            }
        };
    }

};

export enum DrawMode {
    Free,
    VerticalLine,
    HorizontalLine,
    Text,
}

export const Share = () => {
    const [isOnlineHelpEnabled, setIsOnlineHelpEnabled] = useState(false);
    const imageRef = useRef(null);
    const dispatch = useAppDispatch();
    const isShareActive = useAppSelector(store => store.chartModifier.share.isShown);
    const image = useAppSelector(store => store.chartModifier.share.image);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const [pencilSize, setPencilSize] = useState(3);
    const [pencilColor, setPencilColor] = useState("#444444");
    const [drawMode, setDrawMode] = useState<DrawMode>(DrawMode.Free);
    const [textToAdd, setTextToAdd] = useState<string>("");
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [editModeBeforOnlineHelp, setEditModeBeforOnlineHelp] = useState<boolean>(isEditMode);
    const [drawModeBeforOnlineHelp, setDrawModeBeforOnlineHelp] = useState<DrawMode>(drawMode);

    const toggleDrawer =
        () =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                dispatch(resetShare());
            };

    const makeImageAndDrawIt = (image: string) => {
        var i = new Image();

        i.onload = function () {
            renderCanva(imageRef, pencilColor, pencilSize, i, drawMode, textToAdd, isEditMode, dispatch);
        };

        i.src = image;
    }

    useEffect(() => {

        if (imageRef) {
            renderCanva(imageRef, pencilColor, pencilSize, null, drawMode, textToAdd, isEditMode, dispatch);
        }

    }, [imageRef, pencilColor, pencilSize, drawMode, textToAdd, isEditMode]);

    useEffect(() => {
        makeImageAndDrawIt(image);
    }, [image, isShareActive])


    const colorPallete = [
        { color: '#444444' },
        { color: '#44AF69' },
        { color: '#F8333C' },
        { color: '#FCAB10' },
        { color: '#2B9EB3' },
        { color: '#DBD5B5' },
    ];

    const toolPalette = [
        { text: "ShareFreeMode", value: DrawMode.Free, function: () => setDrawMode(DrawMode.Free), icon: <GestureIcon /> },
        { text: "ShareHorizontalLine", value: DrawMode.HorizontalLine, function: () => setDrawMode(DrawMode.HorizontalLine), icon: <MoreHorizIcon /> },
        { text: "ShareVerticalLine", value: DrawMode.VerticalLine, function: () => setDrawMode(DrawMode.VerticalLine), icon: <MoreVertIcon /> },
        {
            text: "ShareText", value: DrawMode.Text, function: () => {
                pencilSize > 5 && setPencilSize(5);
                setDrawMode(DrawMode.Text);
            }, icon: <TextFieldsIcon />
        },
    ];

    const isFirefox = browser().name === "firefox";

    const openFilterOnlineHelp = () => {
        setEditModeBeforOnlineHelp(isEditMode)
        setDrawModeBeforOnlineHelp(drawMode)
        setIsOnlineHelpEnabled(true)
        dispatch(analyze_ShowOnlineHelp({source: OnlineHelpItems.ShareOnlineHelp}))
    }
    const closeFilterOnlineHelp = () => {
        setIsOnlineHelpEnabled(false);
        if (isEditMode !== editModeBeforOnlineHelp)
            setEditMode(editModeBeforOnlineHelp)

        if (drawMode !== drawModeBeforOnlineHelp)
            setDrawMode(drawModeBeforOnlineHelp)
    }

    const openEditMode = () => {
        setEditMode(true)
    }

    const setDrawModeToText = () => {
        setDrawMode(DrawMode.Text)
    }

    const copyToClipboard = (notificationText) =>{
        return async () => {

            const canvasElement = imageRef.current;
            // @ts-ignore: Unreachable code error
            const img = canvasElement.toDataURL("image/png");
            var blob = await (await fetch(img)).blob();
            if (navigator.clipboard)
            {
                navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);
            }
            dispatch(setNotification({ type: NotificationType.success, text: notificationText }));
            dispatch(analyze_ExportSharePicture({ target: ExportShareItems.CopyToClipboard }));            
        };
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {        
        if(event.key === "Enter"){
            copyToClipboard(getTranslation("CopiedSuccessfull"))();            
            dispatch(resetShare());
            event.preventDefault();
        }        
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Drawer
                    disableRestoreFocus={true}
                    onKeyDown={onKeyDown}
                    anchor={'right'}
                    open={isShareActive}
                    onClose={toggleDrawer()}
                    className="onlinehelp-home-sideDrawerView-share-intro"
                >
                    <ShareOnlineHelp
                        showOnlineHelp={isOnlineHelpEnabled}
                        closeOnlineHelp={closeFilterOnlineHelp}
                        openEditMode={openEditMode}
                        setDrawModeToText={setDrawModeToText}
                    />

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }} >
                        <Grid item xs={11}>
                            <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: "300" }}>
                                {getTranslatedText("ShareScreenshot")}
                                <Tooltip title={getTranslatedText("EditScreenshot")}>
                                    <IconButton onClick={() => setEditMode(isEditMode ? false : true)}>
                                        {isEditMode ? <ExpandLessIcon className="onlinehelp-home-sideDrawerView-share-buttonEdit" /> : <ExpandMoreIcon className="onlinehelp-home-sideDrawerView-share-buttonEdit" />}
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right', }}>
                                <OnlineHelpIconButton
                                    isInSettings={false}
                                    title={"OnlineHelp"}
                                    isOnlineHelpEnabled={isOnlineHelpEnabled}
                                    onClickFunction={openFilterOnlineHelp} />
                            </div>
                        </Grid>

                    </Grid>

                    <Grow in={isEditMode}><div style={{ height: isEditMode ? "auto" : "0" }}> <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>
                        <Grid item xs={9}>
                            {toolPalette.map((tool, toolKey) => {
                                return (
                                    <Tooltip key={toolKey} title={getTranslatedText(tool.text)} className={"onlinehelp-home-sideDrawerView-share-toolPalette-" + tool.text}>
                                        <IconButton 
                                            size={'large'}
                                            color="secondary"
                                            onClick={tool.function}
                                            sx={{ mx: 1, background: '#e3e1e1', border: tool.value === drawMode ? '3px solid #32992c' : '0px' }}                                       >
                                            {tool.icon}
                                        </IconButton>
                                    </Tooltip>)
                            })}
                        </Grid>
                        
                        <Grid item xs={3} sx={{ my: 'auto' }} textAlign='left' >
                                <div className="onlinehelp-home-sideDrawerView-share-colorPallete">
                                    <CirclePicker color={pencilColor}  onChange={(color: ColorResult, event: ChangeEvent) => setPencilColor(color.hex)} colors={colorPallete.map(color => color.color)} />
                                   
                                </div>
                            </Grid>

                    </Grid>

                        <Divider sx={{ mt: 2 }} />
                        <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>

                           

                            <Grid item xs={drawMode === DrawMode.Text ? 6 : 11} sx={{ my: 'auto' }} textAlign='right' >
                                <Grid container direction={'row'} spacing={2}>
                                   
                                    <Grid item xs sx={{ my: 'auto' }} >
                                        <Slider
                                            color={isDarkMode ? "primary" : "secondary"}
                                            aria-label={getTranslatedText("PencilSize")}
                                            value={pencilSize}
                                            valueLabelDisplay="auto"
                                            onChange={(e: Event, value: number | number[]) => setPencilSize(value as number)}
                                            step={1}
                                            marks
                                            min={1}
                                            max={drawMode === DrawMode.Text ? 5 : 10}
                                            className="onlinehelp-home-sideDrawerView-share-slider"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {drawMode === DrawMode.Text &&
                                <Grid item xs={5} sx={{ my: 'auto' }} textAlign='center' alignContent={'center'} justifyContent='center'>
                                    <FormControl fullWidth>
                                        <TextField className="onlinehelp-home-sideDrawerView-share-toolPalette-textBoxShareText" fullWidth id="outlined-basic" value={textToAdd} label={getTranslatedText("TextToAdd")} variant="outlined" color="success" onChange={(e) => setTextToAdd(e.target.value)} />
                                    </FormControl>
                                </Grid>
                            }

                            <Grid item xs={1} sx={{ my: 'auto' }} textAlign='right'>
                                <Tooltip title={getTranslatedText("ClearCanvas")}>
                                    <IconButton
                                        className="onlinehelp-home-sideDrawerView-share-removeDrawings"
                                        size={'small'}
                                        color="secondary"
                                        onClick={() => {
                                            const canvasElement = imageRef.current;
                                            // @ts-ignore: Unreachable code error
                                            const context = canvasElement.getContext("2d");
                                            // @ts-ignore: Unreachable code error
                                            context.clearRect(0, 0, canvasElement.width, canvasElement.height);
                                            makeImageAndDrawIt(image);
                                        }}
                                        sx={{ mx: 1, background: '#e3e3e3' }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid></div></Grow>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pt: 1 }}>

                        <Grid item xs={12}>
                            <canvas
                                className="onlinehelp-home-sideDrawerView-share-canvas"
                                ref={imageRef}
                                id={isEditMode ? "canvas" : "canvas-noedit"}
                                width="700"
                                height="500"
                            ></canvas>

                        </Grid>

                    </Grid>


                    <Grid container direction={'row'} spacing={1} sx={{ p: 4, pt: 0 }}>

                        <Grid item xs={12} alignContent="center" textAlign={'center'}>
                            <Button disabled={isFirefox} color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" className="onlinehelp-home-sideDrawerView-share-copieToClipBoard" 
                            onClick={copyToClipboard(getTranslatedText("CopiedSuccessfull"))}>
                                {getTranslatedText("CopyToClipboard")}
                            </Button>
                        </Grid>

                        <Grid item xs={12} alignContent="center" textAlign={'center'}>
                            <Button disabled={isFirefox} color="success" variant="outlined" fullWidth size="large" className="onlinehelp-home-sideDrawerView-share-sendAsEmail" onClick={async () => {
                                 const canvasElement = imageRef.current;
                                 // @ts-ignore: Unreachable code error
                                 const img = canvasElement.toDataURL("image/png");
                                 var blob = await (await fetch(img)).blob();
                                 if (navigator.clipboard)
                                 {
                                    navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);
                                    dispatch(setNotification({ type: NotificationType.success, text: getTranslatedText("CopiedSuccessfull") }))
                                    document.location = "mailto:?subject=" + getTranslatedText("EmailFromGraphViewerCloud");
                                    dispatch(analyze_ExportSharePicture({ target: ExportShareItems.SendAsMail }))
                                 }
                                
                                
                            }}>
                                {getTranslatedText("SendAsMail")}
                            </Button>
                        </Grid>
                    </Grid>

                    {isFirefox && <Grid container direction={'row'} spacing={1} sx={{ p: 4, pt: 0 }}>
                            <Grid item xs={12}>
                                <Typography color={'#f03e3e'}  sx={{maxWidth: "700px"}}>
                                    {getTranslatedText("FirefoxDoesNotSupportCopy")}
                                </Typography>
                            </Grid>
                            
                        </Grid>}

                </Drawer>
            }
        </LanguageConsumer>
    );
}