import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = (props) => {
    if (props.valuesAvailable) {
        return [
            // Navigation - PAGES
            {
                selector: '.onlinehelp-home-legendDistinctiveValues',
                position: 'center',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendDistinctiveValuesOnlineHelpOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendDistinctiveValuesOnlineHelpOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendDistinctiveValues-ListItemButton',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendDistinctiveValuesOnlineHelpItemButtonOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendDistinctiveValuesOnlineHelpItemButtonOverlayMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-legendDistinctiveValues-colorIcon',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpColorIconCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendDistinctiveValueOnlineHelpColorIconMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpColorPickerCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpColorPickerDistinctiveValueMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-titleTypography',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpTitleTypographyDistinctiveValueTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpTitleTypographyDistinctiveValueMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-sketchPicker',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpSketchPickerCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpSketchPickerDistinctiveValueMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColorToStandard',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorToStandardCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorToStandardDistinctiveValueMessage") }}></div></div>),
            },
            {
                selector: '.onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColor',
                position: 'left',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorCurvesOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendSeriesOnlineHelpButtonSetSeriesColorDistinctiveValueMessage") }}></div></div>),
            },
        ]
    }
    else {
        return [
            {
                selector: '.onlinehelp-home-legendDistinctiveValues',
                position: 'center',
                content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LegendDistinctiveValuesOnlineHelpOverlayTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LegendDistinctiveValuesOnlineHelpOverlayMessage") }}></div></div>),
            },
        ]
    }
}