import { Snackbar, Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { hideNotifications } from "./Redux/NotificationsSlice";

export const Notifications = () => {
    const notifications = useAppSelector(store => store.notifications);
    const dispatch = useAppDispatch();

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        dispatch(hideNotifications());
      };

    return (
        <Snackbar open={notifications.isShown} autoHideDuration={notifications.autoHide?6000:null} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
          <Alert 
            onClose={handleClose} 
            severity={notifications.type} 
            sx={{ width: '100%', borderRadius: "8px", padding: "18px", boxShadow: "-10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12) !important" }}
            >
          <span dangerouslySetInnerHTML={{__html:notifications.text}}></span>
          </Alert>
        </Snackbar>
    );
}