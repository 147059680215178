import { useEffect } from "react";
import { SuperVisualizationModeDto } from "../../../Features/Home/Models/SuperVisualizationModeDto";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { setSeriesOrder } from "../../ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { setPersitedChartSeriesOrder } from "../Redux/PersistedChartSeriesOrderSlice";

export const usePersistedChartSeriesOrder = () => {
    
    const dispatch = useAppDispatch();
    const isSuperVisualisation = useAppSelector(store => 
        store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);    
    const persistedChartSeriesOrder = useAppSelector(store => store.persistedChartSeriesOrder);    
    const isSeriesLoading = useAppSelector(store => store.chartResultData.status=== "loading");

    useEffect(()=>{         
        if(!isSuperVisualisation){
            if (isSeriesLoading){
                if (persistedChartSeriesOrder.seriesOrder.length > 0){
                    dispatch(setSeriesOrder([...persistedChartSeriesOrder.seriesOrder].sort((a,b) => a.position - b.position))); 
                }
            }
        }        
    }, 
    [isSeriesLoading, isSuperVisualisation, persistedChartSeriesOrder, dispatch]) 

    return { updatePersistedChartSeriesOrder: (seriesOrder) => {
        if (!isSuperVisualisation){
            if (seriesOrder && seriesOrder.length > 0){
                dispatch(setPersitedChartSeriesOrder({ 
                    list: seriesOrder.map((serie) => ( { 
                        title: serie.title,
                        position: serie.position                 
                    }))
                }));
            }
        }}
    }     
}