import { Typography } from "@mui/material"
import { getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService"

export const getNavigationSteps = () =>{
    return [{
        selector: '.onlinehelp-home-load-from-cockpit',
        position: 'center',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromCockpitOnlineHelpTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromCockpitOnlineHelpMessage") }}>
            </div>
        </div>),
    },
    {
        selector: '.onlinehelp-home-load-from-cockpit-select-devices-and-groups',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromCockpitOnlineHelpSelectDevicesAndGroupsTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromCockpitOnlineHelpSelectDevicesAndGroupsMessage") }}>
            </div>
        </div>),
    },
    {
        selector: '.onlinehelp-home-load-from-cockpit-filter',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromCockpitOnlineHelpFilterTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromCockpitOnlineHelpFilterMessage") }}>
            </div>
        </div>),
    },
    {
        selector: '.onlinehelp-home-load-from-cockpit-select-files',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromCockpitOnlineHelpSelectFilesTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromCockpitOnlineHelpSelectFilesMessage") }}>
            </div>
        </div>),
    }]
}