import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { AnnotationVisibilityEnum } from "../../Models/AnnotationSettings";
import { setActiveTab, setTargetAreaVisibility } from "../../Redux/SettingsSlice";
import { NavLink } from "react-router-dom";

export const TargetAreaVisibility = () => {
    const dispatch = useAppDispatch();

    const licenceData = useAppSelector(store => store.licenceService);
    const targetAreaVisibility = useAppSelector(store => store.settings.annotationSettings.targetAreaVisibility);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 3 }} className="onlinehelp-annotations-targetAreaVisibility">
                    <InputLabel color="success" id="select-label-TargetAreaVisibility">{getTranslatedText("TargetAreaVisibility")}</InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        data-testid="settings-annotations-targetAreaVisibility"
                        fullWidth
                        className="onlinehelp-settings-targetAreaVisibilitySelect"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-targetAreaVisibility"
                        id="select-targetAreaVisibility"
                        name="select-targetAreaVisibility"
                        value={targetAreaVisibility}
                        label={getTranslatedText("TargetAreaVisibility")}
                        onChange={(targetAreaVisibility) => {
                            var value = targetAreaVisibility.target.value as AnnotationVisibilityEnum
                            dispatch(setTargetAreaVisibility(value))
                        }}
                    >
                        <MenuItem id="targetAreaVisibility0" value={AnnotationVisibilityEnum.Visible}>{getTranslatedText("AnnotationVisible")} </MenuItem>
                        <MenuItem id="targetAreaVisibility1" value={AnnotationVisibilityEnum.Hidden}>{getTranslatedText("AnnotationHidden")}</MenuItem>
                    </Select>


                </FormControl>
            }
        </LanguageConsumer>
    )
}






 



