import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { licenseBaseUrl } from '../../../../../Infrastructure/Api/BaseApi';

export interface LicenceInformation {
  childKey: string,
  licenseVolume: number,
  masterKey: string,
  validUntil: Date
}

export interface LicenseMasterRequest {
    masterKey: string,
    clientId: string,
}

export interface LicenseUsages {
    usageId: number,
    clientId: string,
    isMasterKey: boolean,
    emailAddress: string
}

export interface LicenseMasterSettings {
  status: 'idle' | 'loading' | 'failed';
  usages: LicenseUsages[],
  information: LicenceInformation | null;
}

export const initialLicenceMasterState: LicenseMasterSettings = {
  status: "idle",
  usages: [],
  information: null
};

export const loadMasterSettings = createAsyncThunk(
  'licenseMaster/initialize',
  async (request: LicenseMasterRequest, { rejectWithValue }) => {
    const response = await fetch(licenseBaseUrl + "licensing/usages", {              
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'masterKey': request.masterKey,
            'clientId': request.clientId
        }),
    }); 
    var jsonResponse = await response.json();
    return jsonResponse;
  }
);

export const loadMasterSettingsSlice = createSlice({
  name: 'licenseMaster',
  initialState: initialLicenceMasterState,
  reducers: {
   
  },
  extraReducers: builder => {
    builder.addCase(loadMasterSettings.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(loadMasterSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        if(action.payload.success){
            state.usages = action.payload.payload.usages;
            state.information = action.payload.payload.licenseInformation;
        }
      })
      .addCase(loadMasterSettings.rejected, (state, action) => {
        state.status = 'failed';
      })
  }
});

export default loadMasterSettingsSlice.reducer;
