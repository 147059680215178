/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { FooterEvents } from "../Model/Categories/FooterEnums";
import { reset_analyze_OpenFooter } from "../Redux/AnalyticsSlice";

const PublishFooterAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();

    const openFooterState = useAppSelector(store => store.analytics.openFooterState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackOpenFooterLink = TrackEvent(FooterEvents.Open.toString(), {})

    useEffect(() => {
        if (openFooterState.toggle) {
            try{
                trackOpenFooterLink({Target: openFooterState.target.toString()})
            }
            catch{}
            dispatch(reset_analyze_OpenFooter())
        }
    }, [openFooterState.toggle])

    return (
        <></>
    )
}

export default PublishFooterAnalytics;