/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeviceInformationResponseItem } from "../Api/DeviceInformationResponse";
import { DevicesInformationRequest } from "../Api/DevicesInformationRequest";
import { getDeviceInformationsFromCockpit } from "../Api/GetDeviceInformations";

export interface LoadFromCockpitState {

  cockpitResponse: DeviceInformationResponseItem[] | null,
  status: 'idle' | 'loading' | 'failed';
  cutOffDateTime: string
}

export const initialLoadFromCockpitState: LoadFromCockpitState = {
  cockpitResponse: null,
  status: "idle",
  cutOffDateTime: ""
};

export const loadDeviceInformations = createAsyncThunk('loadFromCockpitDeviceInformations', async (request: DevicesInformationRequest, { rejectWithValue }) => {
  const response = await getDeviceInformationsFromCockpit(request);
  return response;
}
);

export const loadFromCockpitDeviceInformationsSlice = createSlice({
  name: 'loadFromCockpitDeviceInformations',
  initialState: initialLoadFromCockpitState,
  reducers: {
    setCutOffDateTime: (state,action) => {
      state.cutOffDateTime = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(loadDeviceInformations.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(loadDeviceInformations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cockpitResponse = action.payload
      })
      .addCase(loadDeviceInformations.rejected, (state) => {
        state.status = 'failed';
      })
  }
});

export const { setCutOffDateTime } = loadFromCockpitDeviceInformationsSlice.actions;

export default loadFromCockpitDeviceInformationsSlice.reducer;
