/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { createSciChartAnnotation } from "../../Annotations/AnnotationService";
import { stepNumberAnnotationPrefix } from "../../Annotations/Redux/AnnotationStepNumberSlice";
import { AnnotationVisibilityEnum } from "../../Settings/Models/AnnotationSettings";

interface UseHandleChangeStepNumberAnnotationsArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeStepNumberAnnotations = ({ sciChart }: UseHandleChangeStepNumberAnnotationsArgs) => {
    const stepNumberAnnotations = useAppSelector(store => store.annotationStepNumber.annotations);
    const stepNumberVisibility = useAppSelector(store => store.settings.annotationSettings.stepNumberVisibility);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            var annotationsToDelete = sciChart.sciChartSurface.annotations.asArray().filter(a => a.id.startsWith(stepNumberAnnotationPrefix));
            for (let annotation of annotationsToDelete) {
                var annotationToRemove = sciChart.sciChartSurface.annotations.getById(annotation.id);
                sciChart.sciChartSurface.annotations.remove(annotationToRemove);
            }
            if (stepNumberVisibility === AnnotationVisibilityEnum.Visible) {
                stepNumberAnnotations.forEach(stepNumberAnnotation => {
                    let annotationToAdd = createSciChartAnnotation(stepNumberAnnotation);

                    if (annotationToAdd) {
                        annotationToAdd.forEach(x => sciChart.sciChartSurface.annotations.add(x));
                    }
                });
            }
        }
    }, [stepNumberAnnotations, stepNumberVisibility])
}