import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postFeedbackToBackend } from '../Api/FeedbackPost';
import { FeedbackRequest } from '../Models/FeedbackRequest';
import { MessageType } from '../Models/MessageType';

export interface FeedbackState {
    status: 'idle' | 'loading' | 'failed',
    showFeedback: boolean,
    name: string,
    lastName: string,
    company: string,
    email: string,
    message: string,
    messageType: MessageType,
    attachements: string[] | null,
    showErrors: boolean,
}

const initialState: FeedbackState = {
    status: 'idle',
    showFeedback: false,
    name: "",
    lastName: "",
    company: "",
    email: "",
    message: "",
    messageType: MessageType.Suggestion,
    attachements: null,
    showErrors: false,
};


export const sendFeedbackFormToBackend = createAsyncThunk(
    'feedback/initialize',
    async (request: FeedbackRequest, {rejectWithValue}) => {    
      const response = await postFeedbackToBackend(request);
      return response;
    }
  );


export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setShowErrors: (state) => {
      state.showErrors = true;
    },
    resetShowErrors: (state) => {
      state.showErrors = false;
    },
    setFeedbackName: (state,action) => {
      state.name = action.payload
    },
    setFeedbackLastName: (state,action) => {
      state.lastName = action.payload
    },
    setFeedbackCompany: (state,action) => {
      state.company = action.payload
    },
    setFeedbackEmail: (state,action) => {
      state.email = action.payload
    },
    setFeedbackMessage: (state,action) => {
      state.message = action.payload
    },
    setFeedbackMessageType: (state,action) => {
      state.messageType = action.payload
    },
    setFeedbackAttachements: (state,action) => {
      state.attachements = (state.attachements  && state.attachements.length > 0) ? [...state.attachements , action.payload] : [action.payload];
    },
    removeFeedbackAttachements: (state, action) => {
      state.attachements = (state.attachements && state.attachements.length > 0) ? [...state.attachements.filter(x => x !== action.payload)] : null;
    },
    toggleShowFeedback: (state) => {
        state.showFeedback = !Boolean(state.showFeedback);
    },
    resetFeedbackFormAfterSubmit: (state) => {
        state.message = "";
        state.messageType = MessageType.Suggestion;
        state.attachements = null;
        state.showErrors = false;
    }
  },
  extraReducers: builder => {
      builder.addCase(sendFeedbackFormToBackend.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendFeedbackFormToBackend.fulfilled, (state, action) => {
        state.status = 'idle';
        state.showFeedback = false;
        if(action.payload.success){
            state.message = "";
            state.messageType = MessageType.Suggestion;
            state.attachements = null;
            state.showErrors = false;
        }
      })
      .addCase(sendFeedbackFormToBackend.rejected, (state, action) => {
        state.status = 'failed';
       
      })      
  }
});

export const { setShowErrors, resetShowErrors, resetFeedbackFormAfterSubmit, toggleShowFeedback, setFeedbackName, setFeedbackLastName, setFeedbackCompany, setFeedbackEmail, setFeedbackMessage, setFeedbackMessageType, setFeedbackAttachements, removeFeedbackAttachements } = feedbackSlice.actions;

export default feedbackSlice.reducer;
