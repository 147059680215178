/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, Box, TextField, Grid, Typography } from "@mui/material";
import { createRef, useEffect, useState } from 'react';
import { getTranslation, LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';
import { toggleLicenseForm } from "../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { LicenseFormHeader } from "./Components/LicenseFormHeader";
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { object, string, TypeOf } from "zod";
import CardContent from '@mui/material/CardContent';
import { getLicenseModels } from "./Redux/LicenseFormLicenseModelSlice";
import { licenceOffer } from "../../Infrastructure/LicenceService/Offer/Regular/Redux/LicenceOfferServiceSlice";

export const LicenseForm = () => {
    const dispatch = useAppDispatch();
    const licenceModels = useAppSelector(store => store.licenseModel.licenseModels);
    const open = useAppSelector(store => store.licenceService.showForm);
    let scrollRef = createRef<HTMLDivElement>();
    const [selectedModelId, setSelectedModelId] = useState<number>(1);
    let submitButtonRef = createRef<HTMLButtonElement>();
    const language = useAppSelector(store => store.settings.language);

    const onDialogClose = () => {
        dispatch(toggleLicenseForm());
    }

    const registerSchema = object({
        name: string()
            .min(1, getTranslation("LicenseFormValueIsRequired"))
            .max(100, getTranslation("LicenseFormValueNotMoreThan100Characters")),
        lastName: string()
            .min(1, getTranslation("LicenseFormValueIsRequired"))
            .max(100, getTranslation("LicenseFormValueNotMoreThan100Characters")),
        email: string().min(1, getTranslation("LicenseFormValueIsRequired")).email(getTranslation("LicenseFormEmailHasToBeValid")),
        phone: string().refine((val) => !Number.isNaN(parseInt(val)), { message: getTranslation("LicenseFormValueHasToBeAValidNumber") }),
        customerNumber: string().optional(),
        company: string().min(1, getTranslation("LicenseFormValueIsRequired")).max(100, getTranslation("LicenseFormValueNotMoreThan100Characters")),
        country: string().min(1, getTranslation("LicenseFormValueIsRequired")).max(100, getTranslation("LicenseFormValueNotMoreThan100Characters")),
        address: string().min(1, getTranslation("LicenseFormValueIsRequired")).max(100, getTranslation("LicenseFormValueNotMoreThan100Characters")),
        addressNumber: string().refine((val) => !Number.isNaN(parseInt(val)) && !Number.isNaN(Number(val)), { message: getTranslation("LicenseFormValueHasToBeAValidNumber") }),
        licenceCount: string()
                    .refine((val) => !Number.isNaN(parseInt(val)) && !Number.isNaN(Number(val)) && parseInt(val) === parseFloat(val) , { message: getTranslation("LicenseFormValueHasToBeAValidNumber") })
                    .refine((val) => parseInt(val) > 0, {message: getTranslation("LicenseFormValueHasToBeGreaterThanZero")})
                    .refine((val) => parseInt(val) < 1000, {message: getTranslation("LicenseFormValueHasToBeLessThanThousand")})
    });

    type RegisterInput = TypeOf<typeof registerSchema>;

    const {
        register,
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
        setValue,
    } = useForm<RegisterInput>({
        resolver: zodResolver(registerSchema),
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccessful]);

    useEffect(() => {
        if (open) {
            dispatch(getLicenseModels());
            setValue("licenceCount", "1")
            setTimeout(() => {
                scrollRef && scrollRef.current && scrollRef.current.scrollTo(0, 0)
            }, 100);
        }
    }, [open])

    const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
            dispatch(licenceOffer({
                CustomerName: values.name,
                CustomerLastName: values.lastName,
                CustomerAddress: values.address,
                CustomerAddressNumber: values.addressNumber,
                CustomerCompany: values.company,
                CustomerCountry: values.country,
                CustomerEmail: values.email,
                CustomerNumber: values.customerNumber,
                CustomerPhone: values.phone,
                LicenceCount: values.licenceCount,
                LicenceTypeId: selectedModelId,
                Language: language
            }))
            onDialogClose();    
    };


    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>

                <Dialog onClose={onDialogClose} open={open} maxWidth={'lg'} PaperProps={{ sx: { height: "95%", width: "50%", borderRadius: 3 } }}>

                    <LicenseFormHeader title={getTranslatedText("LicenseFormHeader")} subtitle={getTranslatedText("LicenseFormHeaderSub")} image={"SettingsImages/LicenseImages/license_bg2.jpg"} />
                    <DialogContent ref={scrollRef} className="verticalScroller" sx={{ overflowX: 'auto' }}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, ml: 0 },
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmitHandler)}
                        >

                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['name']}
                                        helperText={errors['name'] ? errors['name'].message : ''}
                                        {...register('name')}
                                        label={getTranslatedText("LicenseFormName")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['lastName']}
                                        helperText={errors['lastName'] ? errors['lastName'].message : ''}
                                        {...register('lastName')}
                                        label={getTranslatedText("LicenseFormLastName")}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['email']}
                                        helperText={errors['email'] ? errors['email'].message : ''}
                                        {...register('email')}
                                        label={getTranslatedText("LicenseFormEmail")}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['phone']}
                                        helperText={errors['phone'] ? errors['phone'].message : ''}
                                        {...register('phone')}
                                        label={getTranslatedText("LicenseFormPhone")}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        error={!!errors['customerNumber']}
                                        helperText={errors['customerNumber'] ? errors['customerNumber'].message : ''}
                                        {...register('customerNumber')}
                                        label={getTranslatedText("LicenseFormCustomerNumber") + " (opt.)"}
                                    />
                                </Grid>
                            </Grid>


                            <Divider sx={{ my: 2 }} />

                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['company']}
                                        helperText={errors['company'] ? errors['company'].message : ''}
                                        {...register('company')}
                                        label={getTranslatedText("LicenseFormCompany")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['country']}
                                        helperText={errors['country'] ? errors['country'].message : ''}
                                        {...register('country')}
                                        label={getTranslatedText("LicenseFormCompanyCountry")}
                                    />
                                </Grid>
                            </Grid>



                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={9.5}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['address']}
                                        helperText={errors['address'] ? errors['address'].message : ''}
                                        {...register('address')}
                                        label={getTranslatedText("LicenseFormAddressAddress")}
                                    />
                                </Grid>
                                <Grid item xs={2.5}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        error={!!errors['addressNumber']}
                                        helperText={errors['addressNumber'] ? errors['addressNumber'].message : ''}
                                        {...register('addressNumber')}
                                        label={getTranslatedText("LicenseFormAddressStreetNumber")}
                                    />
                                </Grid>
                            </Grid>

                            <Divider sx={{ my: 2 }} />

                            <Typography sx={{ mb: 3 }}>{getTranslatedText("LicenseFormModelHeader")}</Typography>

                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12}>
                                    <Card sx={{ cursor: "pointer", borderWidth: licenceModels && licenceModels.length > 0 && licenceModels[0].licenceTypeId === selectedModelId ? "3px" : 0, borderColor: '#32992c', borderStyle: 'solid' }} onClick={() => setSelectedModelId(licenceModels[0].licenceTypeId)}>
                                        <CardContent>
                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item xs={9} my={'auto'}>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        {getTranslatedText("DepragGraphViewerCloud")}
                                                    </Typography>
                                                    <Typography variant="h5" component="div">
                                                    {getTranslatedText("DepragGraphViewerCloudLicenseName")}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={2} my={'auto'}>
                                                    <TextField
                                                        inputProps={{style: {textAlign: 'center', fontSize: 27, border: 0}}}
                                                        InputLabelProps={{ shrink: false, placeholder: "" }}
                                                        variant="outlined"
                                                        color="success"
                                                        sx={{ textAlign: 'center', border: 0}}
                                                        required
                                                        error={!!errors['licenceCount']}
                                                        helperText={errors['licenceCount'] ? errors['licenceCount'].message : ''}
                                                        {...register('licenceCount')}
                                                    />

                                                </Grid>
                                                <Grid item xs={1} my={'auto'}>
                                                    <Typography sx={{fontSize: 22}}>x</Typography>
                                                </Grid>
                                            </Grid>

                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>
                            <Button type="submit" ref={submitButtonRef} sx={{ display: "none" }}></Button>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={onDialogClose}>{getTranslatedText("Cancel")}</Button>
                        <Button color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={() => submitButtonRef.current?.click()} disabled={selectedModelId === 0 ? true : false}>{getTranslatedText("LicenseFormSendOfferForm")}</Button>
                    </DialogActions>

                </Dialog>
            </>}
    </LanguageConsumer>
    );
}