/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { OnlineHelpEvents } from "../Model/Categories/OnlineHelpEnums";
import { reset_analyze_ShowOnlineHelp } from "../Redux/AnalyticsSlice";

const PublishOnlineHelpAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();
  
    const showOnlineHelpState = useAppSelector(store => store.analytics.showOnlineHelpState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackShowOnlineHelp = TrackEvent(OnlineHelpEvents.Show.toString(), {})

    useEffect(() => {
        if (showOnlineHelpState.toggle) {
            try{
                trackShowOnlineHelp({Source: showOnlineHelpState.source.toString()})
            }
            catch{}
            dispatch(reset_analyze_ShowOnlineHelp())
        }
    }, [showOnlineHelpState.toggle])

    return (
        <></>
    )
}

export default PublishOnlineHelpAnalytics;