/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { removeAnnotationById, setselectedAnnotationId } from "../../Annotations/Redux/AnnotationUserSlice";

interface UseHandlePossibleSpecificDeletionOfUserAnnotationsArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandlePossibleSpecificDeletionOfUserAnnotation = ({ sciChart }: UseHandlePossibleSpecificDeletionOfUserAnnotationsArgs) => {
    const selectedAnnotationId = useAppSelector(store => store.annotationUser.selectedAnnotationId);

    const dispatch = useAppDispatch();

    const deleteSpecificAnnotation = (event) => {
        if (event.key !== "Delete" || selectedAnnotationId === "") {
            return;
        }
        
        var annotationToRemove = sciChart.sciChartSurface.annotations.getById(selectedAnnotationId);
        sciChart.sciChartSurface.annotations.remove(annotationToRemove);

        dispatch(removeAnnotationById(selectedAnnotationId))
        dispatch(setselectedAnnotationId(""))
        
        document.removeEventListener('keydown', deleteSpecificAnnotation)
    }

    const resetselectedAnnotationId = (event) => {
        if (event.button !== 0) {
            return;
        }

        dispatch(setselectedAnnotationId(""))
        
        document.removeEventListener('keydown', deleteSpecificAnnotation)
        document.getElementById('sciChart')?.removeEventListener('mousedown', resetselectedAnnotationId)
    }

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface && selectedAnnotationId !== "") {
            sciChart.sciChartSurface.annotations.getById(selectedAnnotationId).isSelected = true;
 
            document.addEventListener('keydown', deleteSpecificAnnotation)
            document.getElementById('sciChart')?.addEventListener('mousedown', resetselectedAnnotationId)
        }
    }, [selectedAnnotationId]);
}