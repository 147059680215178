import { ChartModifierBase2D, IChartModifierBaseOptions } from "scichart/Charting/ChartModifiers/ChartModifierBase2D";
import { ModifierMouseArgs } from "scichart/Charting/ChartModifiers/ModifierMouseArgs";
import { DpiHelper } from "scichart/Charting/Visuals/TextureManager/DpiHelper";
import { AppStore } from "../../../Infrastructure/Redux/store";

export class MouseOverAnnotationModifier extends ChartModifierBase2D {
    public readonly type = 'MouseOverAnnotationModifier';
    public isHandCursor: boolean;
    private store: AppStore;

    constructor(store: AppStore,  options?: IChartModifierBaseOptions) {
        super(options);
        this.isHandCursor = false;
        this.store= store;
    }

    public modifierMouseMove(args: ModifierMouseArgs): void {
        super.modifierMouseMove(args);
        const canvas = this.parentSurface.getMainCanvas();

        const x = args.mousePoint.x / DpiHelper.PIXEL_RATIO;
        const y = args.mousePoint.y / DpiHelper.PIXEL_RATIO;
        

        let isCursorOverUserAnnotation = false;

        for (let i = 0; i < this.parentSurface.annotations.size(); i++) {
            const an = this.parentSurface.annotations.get(i);
            if (an.checkIsClickedOnAnnotation(x, y) && (an.id.startsWith("userAnnotation") && (an.isEditable))){
                isCursorOverUserAnnotation = true;
                    break;
                }
            }        

            canvas.style.cursor = 'auto';

            if (isCursorOverUserAnnotation) {
                canvas.style.cursor = 'grab';
            }            

            const state = this.store.getState();
            if (state.chartModifier.annotations.addDataPoint ||                 
                state.chartModifier.annotations.addHorizontalLine ||
                state.chartModifier.annotations.addVerticalLine ||                
                state.chartModifier.annotations.addValuePoint){
                    canvas.style.cursor = 'crosshair';
            }                
    }
}