import { MainCategories } from "../AnalyticsCategories";

export enum FilterEvents {
    ApplyFilter = MainCategories.Filter + "ApplyFilter",
    ResetFilter = MainCategories.Filter + "ResetFilter"
}

export enum FilterTypes {
    Torque = "Torque",
    Step = "Step"
}
