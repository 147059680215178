import { MainCategories } from "../AnalyticsCategories";

enum SettingsCategories {
    Default = MainCategories.Settings + "Default_",
    Cockpit = MainCategories.Settings + "Cockpit_",
    Licence = MainCategories.Settings + "Licence_"
}

export enum DefaultSettingsEvents {
    Change = SettingsCategories.Default + "Change",
}

export enum CockpitSettingsEvents {
}

export enum LicenceSettingsEvents {
}

export enum DefaultSettingsItems {
    Language = "Language",
    TorqueUnit = "TorqueUnit",
    SyncPoint = "SyncPoint",
    SuperVisualizationForMultipleFiles = "SuperVisualizationForMultipleFiles",
    SingleCurveVisualization = "SingleCurveVisualization",
    DisplayPositionCurrValues = "DisplayPositionCurrValues",
    AppTheme = "AppTheme",
    LabellingDistinctiveValues = "LabellingDistinctiveValues",
    IndividualTorque = "IndividualTorque"
}