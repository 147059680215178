import { Card, CardContent, Grid, Grow } from "@mui/material"
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { LicenseCardHeader } from "./LicenseCardHeader/LicenseCardHeader";
import { StepNumberVisibility } from "./AnnotationsComponents/StepNumberVisibility";
import { TargetAreaVisibility } from "./AnnotationsComponents/TargetAreaVisibility";
import { UserAnnotationColor } from "./AnnotationsComponents/UserAnnotationColor";
import { AnnotationsOnlineHelp } from "./AnnotationsComponents/AnnotationsOnlineHelp";
import { OnlineHelpIconButton } from "../../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { useState } from "react";
import { analyze_ShowOnlineHelp } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { useAppDispatch } from "../../../Infrastructure/Redux/hooks";
import { OnlineHelpItems } from "../../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { LabellingDistinctive } from "./LabellingDistinctive";
import { toogleAnnotationsDefaultReset } from "../../../Infrastructure/DefaultSettingsSetter/Redux/DefaultSettingsSetterSlice";
import { DefaultSettingsSetterButton } from "../../../Infrastructure/DefaultSettingsSetter/Components/DefaultSettingsSetterButton";


export const AnnotationsSettings = () => {

    const dispatch = useAppDispatch();
    const [isSAnnotationsOnlineHelpEnabled, setIsAnnotationsOnlineHelpEnabled] = useState(false);

    const closeAnnotationsOnlineHelp = () => {
        setIsAnnotationsOnlineHelpEnabled(false);
    };
    const openAnnotationsOnlineHelp = () => {
        setIsAnnotationsOnlineHelpEnabled(true);
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.AnnotationsOnlineHelp }))
    };


    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            < Grow in={true} appear={true}>
                <Grid container direction={'row'} spacing={2}>
                    <AnnotationsOnlineHelp
                        showOnlineHelp={isSAnnotationsOnlineHelpEnabled}
                        closeOnlineHelp={closeAnnotationsOnlineHelp} />

                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={10}>
                        <Card elevation={12} className="onlinehelp-annotations-intro">
                            <LicenseCardHeader onlineHelpComponent={<OnlineHelpIconButton
                                isInSettings={true}
                                title={"OnlineHelp"}
                                isOnlineHelpEnabled={false}
                                onClickFunction={() => { openAnnotationsOnlineHelp() }}
                            />}
                                title={getTranslatedText("AnnotationsTitle")} subtitle={getTranslatedText("LicenseCardHeaderSub")} image={`SettingsImages/Annotations/LicenseCardHeaderAnnotations.jpg`} />
                            <CardContent  style={{ paddingBottom: 0 }}>
                                <UserAnnotationColor />
                                <StepNumberVisibility />
                                <TargetAreaVisibility />
                                <LabellingDistinctive />

                                <DefaultSettingsSetterButton className={'onlinehelp-settings-resetAllAnnotations'} action={toogleAnnotationsDefaultReset()} />

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grow>
        }
    </LanguageConsumer>
    )
}




