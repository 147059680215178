import { resetOnlineHelp, toggleSidebarVisibility } from "../../../Features/Settings/Redux/SettingsSlice";
import { getNavigationSteps } from "./GlobalOnlineHelpNavigationProvider";
import Tour from 'reactour';
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { Button } from "@mui/material";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { useState } from "react";
import { setOnlineHelpState, HelpCommandType } from "../Redux/OnlineHelpSlice";

export const GlobalOnlineHelp = () => {

    const delayForRenderRefresh = 500;

    const dispatch = useAppDispatch();
    const showOnlineHelp = useAppSelector(store => store.settings.showOnlineHelp);
    const isSidebarVisible = useAppSelector(store => store.settings.isSidebarVisible);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [hideSidebarOnClose, setHideSidebarOnClose] = useState<boolean>(false);

    return (
        <LanguageConsumer>

            {({ getTranslatedText }) =>
                <Tour
                    disableFocusLock
                    getCurrentStep={(curr: number) => {
                        setCurrentStep(curr)
                    }}
                    startAt={0}
                    disableInteraction={true}
                    showNavigation={false}
                    showNumber={false}
                    showButtons={currentStep !== 0 ? true : false}
                    closeWithMask={true}
                    accentColor={'#32992c'}
                    steps={getNavigationSteps()}
                    isOpen={showOnlineHelp}
                    onRequestClose={() => {
                        if (isSidebarVisible && hideSidebarOnClose) {
                            setHideSidebarOnClose(false)
                            dispatch(toggleSidebarVisibility())
                        }

                        dispatch(resetOnlineHelp())
                        setCurrentStep(0)
                    }}
                    rounded={13}
                    prevButton={currentStep !== 1 ? <Button component="a" fullWidth variant="outlined" color='inherit' title={getTranslatedText("OnlineHelpPrev")}>{getTranslatedText("OnlineHelpPrev")}</Button> : <div></div>}
                    nextButton={<Button component="a" variant="outlined" fullWidth color='success' title={getTranslatedText("OnlineHelpNext")}>{getTranslatedText("OnlineHelpNext")}</Button>}
                    lastStepNextButton={<Button component="a" variant="outlined" onClick={() => dispatch(setOnlineHelpState(HelpCommandType.Finshed))} color='success' title={getTranslatedText("OnlineHelpFinish")}>{getTranslatedText("OnlineHelpFinish")}</Button>}
                    nextStep={async () => {
                        let nextStep = currentStep + 1
                        if (nextStep === 25 && !isSidebarVisible) {
                            setHideSidebarOnClose(true)
                            dispatch(toggleSidebarVisibility())
                            await new Promise(resolve => setTimeout(resolve, delayForRenderRefresh));
                        }
                        setCurrentStep(nextStep)
                    }}
                    prevStep={async () => {
                        let lastStep = currentStep - 1
                        if (lastStep > 0) {
                            setCurrentStep(lastStep)
                        }
                    }}
                    goToStep={currentStep}
                />}
        </LanguageConsumer>
    );
}