import { Typography } from "@mui/material"
import { getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService"

export const getDefaultSettingsOnlineHelpSteps = (props) => {
  return [
    // Navigation - PAGES
    {
      selector: '.onlinehelp-annotations-intro',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("AnnotationOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("AnnotationOnlineHelpIntroMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-annotations-userAnnotationsColorSelect',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("AnnotationColorSelectTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("AnnotationColorSelectMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-annotations-stepnumberVisibility',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("AnnotationStepnumberVisibilitySelectTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("AnnotationStepnumberVisibilitySelectMessage") }}></div></div>),
    },
   
    {
      selector: '.onlinehelp-annotations-targetAreaVisibility',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("AnnotationTargetAreaVisibilitySelectTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("AnnotationTargetAreaVisibilitySelectMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-analyse-labellingDistinctiveValues',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectLabellingDistinctiveValuesTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectLabellingDistinctiveValuesMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-settings-resetAllAnnotations',
      position: 'left',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpResetAnnotationsTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpResetAnnotationsMessage") }}></div></div>)
    }
  ]
}
