/* eslint-disable array-callback-return */
import { CockpitDeviceAndGroupsResponse } from "../Api/DeviceAndGroupsResponse";
import { CockpitDeviceOrGroup } from "../Model/CockpitDeviceOrGroup";
import { CockpitDeviceOrGroupTreeViewModel } from "./LoadFromCockpitDeviceAndGroupsSlice";

export const findDeviceOrGroupById = (id, cockpitDevices: CockpitDeviceOrGroupTreeViewModel[]) => {
  var device = cockpitDevices.find(x => x.id === id) as CockpitDeviceOrGroupTreeViewModel;
  if (device) {
    return device
  }
  else {
    var result
    cockpitDevices.forEach((d) => {
      var clickedDevice = findDeviceOrGroupById(id, d.children)
      if (clickedDevice) {
        result = clickedDevice
      }
    })
    return result
  }
}

export const getParsedDeviceAndGroups = (cockpitDeviceResponse: CockpitDeviceAndGroupsResponse) => {

  const cockpitDevices = cockpitDeviceResponse.cockpitDevicesAndGroups
  var cockpitDeviceTreeViewModels: Array<CockpitDeviceOrGroupTreeViewModel> = [];

  cockpitDevices?.forEach((deviceFromCockpit) => {

    var id = (deviceFromCockpit.isDevice ? "D" : "G") + deviceFromCockpit.deviceOrGroupId;

    var device: CockpitDeviceOrGroupTreeViewModel = {
      deviceOrGroupId: deviceFromCockpit.deviceOrGroupId,
      displayPosition: deviceFromCockpit.displayPosition,
      isDevice: deviceFromCockpit.isDevice,
      isOnline: deviceFromCockpit.isOnline,
      name: deviceFromCockpit.name,
      remoteAddress: deviceFromCockpit.remoteAddress,
      supportedDeviceTypeId: deviceFromCockpit.supportedDeviceTypeId,
      isChecked: false,
      indeterminateState: false,
      children: getAndParseChildren(deviceFromCockpit, id),
      id: id,
      parentId: null
    };
    cockpitDeviceTreeViewModels.push(device);
  });

  return cockpitDeviceTreeViewModels
}


export const setCheckedStateForChildrenOf = (clickedDevice: CockpitDeviceOrGroupTreeViewModel, newCheckedState: boolean) => {

  clickedDevice.children.forEach((child) => {
    child.isChecked = newCheckedState
    child.indeterminateState = false
    setCheckedStateForChildrenOf(child, newCheckedState)
  })
}

export const setCheckedStateForParentOf = (clickedDevice: CockpitDeviceOrGroupTreeViewModel, devicesFromCockpit: CockpitDeviceOrGroupTreeViewModel[]) => {
  var parent = getParentDeviceOrGroupFromCockpit(devicesFromCockpit, clickedDevice)
  if (parent) {
    var availableChildrenCount = parent.children.length
    var availableChildrenThatAreGroupsAndHaveNoChildrenCount = 0
    parent.children.forEach((x) => { if (!x.isDevice && !(x.children.length > 0)) { availableChildrenThatAreGroupsAndHaveNoChildrenCount++ } })
    var checkedChildrenCount = parent.children.filter(x => x.isChecked === true).length
    var childrenInIndeterminateStateCount = parent.children.filter(x => x.indeterminateState === true).length

    if (availableChildrenCount > 0) {

      if ((availableChildrenCount === checkedChildrenCount) ||
        (availableChildrenCount - availableChildrenThatAreGroupsAndHaveNoChildrenCount === checkedChildrenCount)) {
        parent.isChecked = true
        parent.indeterminateState = false
      }
      else if (childrenInIndeterminateStateCount > 0 || checkedChildrenCount > 0) {
        parent.isChecked = false
        parent.indeterminateState = true
      }
      else {
        parent.isChecked = false
        parent.indeterminateState = false
      }
    }
    setCheckedStateForParentOf(parent, devicesFromCockpit)
  }
}

export const getDevicesAndGroupsAsList = (devicesOrGroupsFromCockpit: CockpitDeviceOrGroupTreeViewModel[]) => {
  var devicesFromCockpitAsList: CockpitDeviceOrGroupTreeViewModel[] = []

  devicesOrGroupsFromCockpit.forEach((deviceOrGroup) => {
    if (deviceOrGroup.children && deviceOrGroup.children.length > 0)
      getChildDevicesAndGroupsAsList(deviceOrGroup, devicesFromCockpitAsList)

    devicesFromCockpitAsList.push(deviceOrGroup)
  })
  return devicesFromCockpitAsList
}

export const getListOfIdsFromCheckedDevices = (devicesOrGroupsFromCockpit: CockpitDeviceOrGroupTreeViewModel[]) => {

  var listOfCheckedDevices: number[] = []

  if (!devicesOrGroupsFromCockpit)
    return listOfCheckedDevices

  var listOfDevicesAndGroups = getDevicesAndGroupsAsList(devicesOrGroupsFromCockpit)

  listOfDevicesAndGroups.forEach((x) => {
    if (x.isDevice && x.isChecked)
      listOfCheckedDevices.push(x.deviceOrGroupId)
  })
  return listOfCheckedDevices
}

export const sortDevicesAndGroups = (devicesOrGroupsFromCockpit: CockpitDeviceOrGroupTreeViewModel[]) => {

  var sortedList: CockpitDeviceOrGroupTreeViewModel[] = []

  sortedList = devicesOrGroupsFromCockpit.sort((a,b) => a.displayPosition - b.displayPosition)

  sortedList.forEach(element => {
    element.children = sortDevicesAndGroups(element.children)
  });

  return sortedList;

}

const getChildDevicesAndGroupsAsList = (device: CockpitDeviceOrGroupTreeViewModel, devicesFromCockpitAsList: CockpitDeviceOrGroupTreeViewModel[]) => {

  device.children.forEach((childDevice) => {
    if (device.children && device.children.length > 0)
      getChildDevicesAndGroupsAsList(childDevice, devicesFromCockpitAsList)

    devicesFromCockpitAsList.push(childDevice)
  })
}

const getParentDeviceOrGroupFromCockpit = (cockpitDeviceOrGroups: CockpitDeviceOrGroupTreeViewModel[], cockpitDeviceOrGroup: CockpitDeviceOrGroupTreeViewModel) => {

  var parent;
  cockpitDeviceOrGroups.forEach((deviceOrGroup) => {

    if (deviceOrGroup.children.find(x => x.id === cockpitDeviceOrGroup.id)) {
      parent = deviceOrGroup
    }
    else {
      var result = getParentDeviceOrGroupFromCockpit(deviceOrGroup.children, cockpitDeviceOrGroup)
      if (result) {
        parent = result
      }
    }
  })
  return parent
}

const getAndParseChildren = (deviceFromCockpit: CockpitDeviceOrGroup, parentId: string) => {
  var cockpitDeviceTreeViewModels: Array<CockpitDeviceOrGroupTreeViewModel> = [];

  deviceFromCockpit.children.forEach((childDeviceFromCockpit) => {

    var childId = parentId + "-" + (childDeviceFromCockpit.isDevice ? "D" : "G") + childDeviceFromCockpit.deviceOrGroupId;

    var device: CockpitDeviceOrGroupTreeViewModel = {
      deviceOrGroupId: childDeviceFromCockpit.deviceOrGroupId,
      displayPosition: childDeviceFromCockpit.displayPosition,
      isDevice: childDeviceFromCockpit.isDevice,
      isOnline: childDeviceFromCockpit.isOnline,
      name: childDeviceFromCockpit.name,
      remoteAddress: childDeviceFromCockpit.remoteAddress,
      supportedDeviceTypeId: childDeviceFromCockpit.supportedDeviceTypeId,
      isChecked: false,
      indeterminateState: false,
      children: getAndParseChildren(
        childDeviceFromCockpit,
        childId
      ),
      id: childId,
      parentId: parentId
    };
    cockpitDeviceTreeViewModels.push(device);
  });
  return cockpitDeviceTreeViewModels;
};