import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { Chip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { sendLoadExampleFileToBackend } from "./Redux/LoadExampleFileSlice";
import { ChartRequest } from "../../Models/ChartRequest";
import { SuperVisualizationModeDto } from "../../Models/SuperVisualizationModeDto";
import { SeriesTypeDto } from "../../Models/SeriesTypeDto";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { memo } from "react";

export const LoadExampleFile = memo(() => {

    const dispatch = useAppDispatch();
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const singleUseChartRequestFile = useAppSelector(store => store.temporarily.singleUseChartRequestFile);
    const languageIdentifier = useAppSelector(store => store.settings.language);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark')

    const handleLoadExampleFileClick = async () => {
        let chartRequestFile = {
            Settings:
            {
                FilterSettings: chartRequestSettings.FilterSettings,
                ImplicitChangeFromResult: chartRequestSettings.ImplicitChangeFromResult,
                LabellingDistinctiveValues: chartRequestSettings.LabellingDistinctiveValues,
                SingleVisualization: chartRequestSettings.SingleVisualization,
                SuperVisualization: {
                    Mode: SuperVisualizationModeDto.None,
                    SeriesType: SeriesTypeDto.None
                },
                SynchronizationSettings: chartRequestSettings.SynchronizationSettings,
                TorqueUnit: chartRequestSettings.TorqueUnit
            },
            SortedList: [],
            Files: [singleUseChartRequestFile?.ChartRequestFile],
            LanguageIdentifier: languageIdentifier
        } as ChartRequest;

        

        await dispatch(sendLoadExampleFileToBackend(chartRequestFile));
    }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <Chip
                className="headerInformation"
                sx={{ zIndex: 999, background: 'rgba(255,255,255,1)', cursor: "pointer", color: isDarkMode ? '#444' : '#444',mr: 2 }}
                icon={<AutoAwesomeIcon />}
                label={getTranslatedText("LoadExampleFile")}
                variant="filled"
                onClick={handleLoadExampleFileClick} />
        }
    </LanguageConsumer>
    );
})