import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { updateDistinctiveValueAreaAnnotation } from "../../../Features/Annotations/Redux/AnnotationDistinctiveValueAreaSlice";
import { updateMonitorAnnotationVisibility } from "../../../Features/Annotations/Redux/AnnotationMonitorSlice";
import { removeAllUserAnnotations, updateSeriesAnnotation } from "../../../Features/Annotations/Redux/AnnotationUserSlice";
import { SuperVisualizationModeDto } from "../../../Features/Home/Models/SuperVisualizationModeDto";
import { selectSeries } from "../../ChartResultHandler/Redux/ChartResultSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { SeriesVisibility } from "../Redux/ChartSeriesVisibilitySlice";


export const useChangeSeriesVisibility = (sciChart: TWebAssemblyChart, seriesVisibilities: SeriesVisibility[]) =>{

    const dispatch = useAppDispatch();
    const seriesData = useAppSelector(selectSeries);
    const isSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const isSidebarVisible = useAppSelector(store => store.settings.isSidebarVisible);

    useEffect(() => {
        if (seriesData && seriesData.length > 0) {
            dispatch(updateSeriesAnnotation({
                visibleSeriesTitle: seriesVisibilities.filter(x => x.visible).map(x => { return x.title }),
                existingSeriesTitle: seriesVisibilities.map(x => { return x.title })
            }));
            dispatch(updateDistinctiveValueAreaAnnotation({
                visibleSeriesTitle: seriesVisibilities.filter(x => x.visible).map(x => { return x.title }),
                existingSeriesTitle: seriesData[0].ChartSeries.map(x => { return x.Title }),
                seriesWithValues: seriesData[0].ChartSeries.filter(x => x.HasValues).map(x => { return x.Title }),
                superVisualisationMode: isSuperVisualisation, 
                isSidebarVisible: isSidebarVisible
            }));    
            dispatch(updateMonitorAnnotationVisibility({
                list: seriesVisibilities
            }));    
        }      
        else {
            dispatch(removeAllUserAnnotations());
        }
    },[seriesData, isSuperVisualisation, seriesVisibilities, dispatch]);


    seriesVisibilities.forEach(item => {
        if (sciChart && sciChart.sciChartSurface){
            var seriesAsArray = sciChart.sciChartSurface.renderableSeries.asArray();
            var foundSerie = seriesAsArray.filter(serie => serie.getDataSeriesName() === item.title);
                foundSerie.forEach( seriesInChart => { seriesInChart.isVisible = item.visible})                
        }
    });
}