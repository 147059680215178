/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid, LinearProgress, Skeleton, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toggleResetChart } from "../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { saveSeriesRequestsInStore } from "../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { getSeriesForGraphDataAsync } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { showHelpModal } from "../../LoadFromController/Redux/LoadAvailableFilesFromControllerSlice";
import { setLoadingIndicator } from "../../../Infrastructure/LoadingIndicator/LoadingIndicatorSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { LoadFromCockpitCurveDataFilter } from "../Components/LoadFromCockpitCurveDataFilter/LoadFromCockpitCurveDataFilter";
import { LoadFromCockpitFinalResultStateFilter } from "../Components/LoadFromCockpitCurveDataFilter/LoadFromCockpitFinalResultStateFilter";
import { LoadFromCockpitCurveDataSelection } from "../Components/LoadFromCockpitCurveDataSelection/LoadFromCockpitCurveDataSelection";
import { LoadFromCockpitDeviceAndGroupTreeView } from "../Components/LoadFromCockpitDeviceAndGroupTreeView/LoadFromCockpitDeviceAndGroupTreeView";
import { CreateLoadFromCockpitDeviceInformationsRequest } from "./Logic/LoadFromCockpit_Logic";
import './Style/LoadFromCockpit.css';
import InfoIcon from '@mui/icons-material/Info';
import { HashLink } from 'react-router-hash-link';
import { resetLoadFromCockpitVisibility, loadAvailableFilesFromCockpit, hideCockpitHelpDialog } from "../../LoadFromCockpit/CurveData/Complete/Redux/LoadFromCockpitCompleteCurveDataSlice";
import { CockpitStepIcon } from "./Style/CockpitStepper";
import { ChartRequest } from "../../Home/Models/ChartRequest";
import { HelpDialog } from "../../LoadFromController/HelpDialog/HelpDialog";
import { analyze_LoadFiles, analyze_ShowOnlineHelp } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LoadFilesItems } from "../../../Infrastructure/Analytics/Model/Categories/LoadFilesEnums";
import { nanoid } from "@reduxjs/toolkit";
import { loadDeviceInformations, setCutOffDateTime } from "../CurveData/Simplified/Redux/LoadFromCockpitDeviceInformationsSlice";
import { PlaceholderForLoadingDevices } from "./LoadFromCockpitDeviceAndGroupTreeView/PlaceholderForLoadingDevices";
import { LoadFromCockpitOnlineHelp } from "./LoadFromCockpitOnlineHelp";
import { OnlineHelpItems } from "../../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { OnlineHelpIconButton } from "../../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { setActiveTab } from "../../Settings/Redux/SettingsSlice";
import { SuperVisualizationModeDto } from "../../Home/Models/SuperVisualizationModeDto";

export const LoadFromCockpit = () => {

    const [abortController, setAbortController] = useState<AbortController>();
    const [loadFromCockpitDeviceInformationsAbortController, setLoadDeviceInformationAbortController] = useState<AbortController>();
    const [onlineHelpVisible, setOnlineHelpVisible] = useState<boolean>(false);    

    const dispatch = useAppDispatch();

    const loadFromCockpit = useAppSelector(store => store.loadFromCockpit);    
    const loadedCurveFiles = useAppSelector(store => store.loadFromCockpit.cockpitResponse);
    const isLoadingDevicesOrGroupsFromCockpit = useAppSelector(store => store.loadFromCockpitDeviceAndGroups.status === "loading");


    const loadedDeviceInformationItem = useAppSelector(store => store.loadFromCockpitDeviceInformations.cockpitResponse);

    const selectedCurveFileIds = useAppSelector(store => store.loadFromCockpitFilter.SelectedCurveFileIds)
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const languageIdentifier = useAppSelector(store => store.settings.language);    
    const selectedDeviceIds = useAppSelector(store => store.loadFromCockpitDeviceAndGroups.DeviceSelectedIds)

    const dateRangeFilterItems = useAppSelector(store => store.loadFromCockpitFilter.DateRanges);
    const filterByState = useAppSelector(store => store.loadFromCockpitFilter.FinalResultState);
    const cockpitConnected = useAppSelector(store => store.checkCockpitSettings.success);
    const isLoadingCurveContentFromCockpit = useAppSelector(store => store.loadFromCockpit.status === 'loading');    
    const isLoadingDeviceInformationStatus = useAppSelector(store => store.loadFromCockpitDeviceInformations.status === 'loading');
    const loadFromCockpitSimplifiedfileAbortController = useAppSelector(store => store.loadFromCockpitSimplifiedCurveDatas.abortController);    
    const isloadingSmplifiedCurve = useAppSelector(store => store.loadFromCockpitSimplifiedCurveDatas.status === 'loading');
    const [activeStep, setActiveStep] = React.useState(0);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);

     useEffect(() => {
         setActiveStep(0)
     }, [loadFromCockpit.isShown])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        activeStep !== 0 && setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const openLoadFromCockpitOnlineHelp = () => {
        setOnlineHelpVisible(true)
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.LoadFromCockpitOnlineHelp }))
    }  
    
    const closeOnlineHelp = () => {
        setOnlineHelpVisible(false)
    }

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            if (!isLoadingCurveContentFromCockpit && !isLoadingDeviceInformationStatus && !isloadingSmplifiedCurve) {
                dispatch(resetLoadFromCockpitVisibility());
                setOnlineHelpVisible(false);
            }
        }; 

    useEffect(() => {
        if (loadedCurveFiles && loadedCurveFiles.length > 0) {
            dispatch(setLoadingIndicator());

            var loadedCurveFileList = loadedCurveFiles.map((loadedCurveFile) => {

                if (loadedCurveFile && loadedCurveFile.curveFile && loadedCurveFile.curveFile.fileName && loadedCurveFile.curveFile.fileContent) {
                    let fileName = loadedCurveFile.curveFile.fileName.split("\\").pop();
                    var decoded = atob(loadedCurveFile.curveFile.fileContent);

                    var bytes = new Array(decoded.length);
                    for (var i = 0; i < decoded.length; i++) {
                        bytes[i] = decoded.charCodeAt(i);
                    }

                    return {
                        Title: fileName, CurveRawContent: JSON.stringify(bytes), Id: nanoid(), IsHidden: false
                    }
                }
            });

            var allFiles = loadedCurveFileList;

            var chartRequestForCockpitFile = {
                Settings: chartRequestSettings,
                SortedList: [],
                Files: allFiles,  
                LanguageIdentifier: languageIdentifier
            } as ChartRequest;

            if (chartRequestForCockpitFile.Files.length > 0) {


                if (chartRequest && chartRequest.Files && 
                    chartRequestForCockpitFile.Files.length === 1 && 
                    chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None){
                    chartRequestForCockpitFile.Files.push(...chartRequest?.Files);
                }

                dispatch(saveSeriesRequestsInStore(chartRequestForCockpitFile));
                dispatch(getSeriesForGraphDataAsync(chartRequestForCockpitFile));
                dispatch(analyze_LoadFiles({ source: LoadFilesItems.FromCockpit, nrOfFiles: chartRequestForCockpitFile.Files.length }))

                dispatch(toggleResetChart());
            }
            dispatch(resetLoadFromCockpitVisibility());
        }

    }, [loadedCurveFiles])

    useEffect(() => {
        if (!abortController)
            return;

        dispatch(loadAvailableFilesFromCockpit({
            cockpitUrl: loadFromCockpit.cockpitUrl,
            cockpitArea: loadFromCockpit.cockpitArea,
            username: loadFromCockpit.credentials.username,
            password: loadFromCockpit.credentials.password,
            selectedCurveFileIds: selectedCurveFileIds,
            abortController: abortController
        }))

    }, [abortController])

    useEffect(() => {
        if (!loadFromCockpitDeviceInformationsAbortController)
            return;

        var cutOffDateTime = new Date();
        dispatch(setCutOffDateTime(cutOffDateTime))

        var deviceInformationsRequest = CreateLoadFromCockpitDeviceInformationsRequest(
            selectedDeviceIds,
            dateRangeFilterItems,
            loadFromCockpit,
            filterByState,
            loadFromCockpitDeviceInformationsAbortController,
            cutOffDateTime
        )
        dispatch(loadDeviceInformations(deviceInformationsRequest))

    }, [loadFromCockpitDeviceInformationsAbortController])

    useEffect(() => {
        if (loadedDeviceInformationItem)
            setActiveStep(2);

    }, [loadedDeviceInformationItem])

    return cockpitConnected ? (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <HelpDialog />

                <Dialog
                    open={loadFromCockpit.showHelp}
                    onClose={() => dispatch(hideCockpitHelpDialog())}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {
                            getTranslatedText("YouHavetoAdjustYourBrowserSettings")
                        }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getTranslatedText("YouHavetoAdjustYourBrowserSettingsBody")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" variant="outlined" onClick={() => dispatch(hideCockpitHelpDialog())}>{getTranslatedText("NoThankYou")}</Button>
                        <Button color="success" variant="outlined" onClick={() => {
                            dispatch(hideCockpitHelpDialog())
                            dispatch(showHelpModal())
                        }} autoFocus>
                            {getTranslatedText("ShowHelpModal")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <LoadFromCockpitOnlineHelp
                    closeOnlineHelp={closeOnlineHelp}
                    isOpen={onlineHelpVisible}
                    />                

                <Drawer   
                    className="onlinehelp-home-load-from-cockpit"                 
                    anchor={'right'}
                    open={loadFromCockpit.isShown}
                    onClose={toggleDrawer()}
                    PaperProps={{
                        sx: {
                            maxWidth: 750,
                            minWidth: 700
                        }
                    }}>
                    <Grid                         
                        container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>
                                    
                        <Grid item xs={11}>
                            <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: "300" }}>
                                {getTranslatedText("LoadFromCockpitTitle")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                        <OnlineHelpIconButton
                                isInSettings={false}
                                title={"OnlineHelp"}
                                isOnlineHelpEnabled={false}
                                onClickFunction={() => {
                                    openLoadFromCockpitOnlineHelp()
                                }} />
                        </Grid>
                    </Grid>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>
                        <Grid item xs={12}>

                            <Stepper alternativeLabel activeStep={activeStep} orientation="vertical" connector={null} >

                                <Step sx={{ width: "100%", textAlign: "left", flexDirection: 'row', alignItems: 'left' }}
                                    className="onlinehelp-home-load-from-cockpit-select-devices-and-groups">
                                    <StepLabel StepIconComponent={CockpitStepIcon} onClick={
                                            () => !isLoadingCurveContentFromCockpit && setActiveStep(0)
                                        }>
                                        {getTranslatedText("SelectDeviceFromCockpit")}
                                    </StepLabel>
                                    <StepContent>                                        
                                        <LoadFromCockpitDeviceAndGroupTreeView />                                         
                                        <Box component={'div'} sx={{ mb: 2, mt: 1 }}>
                                            <div>
                                                <Button
                                                    fullWidth
                                                    disabled={!selectedDeviceIds || selectedDeviceIds.length === 0 || isLoadingDevicesOrGroupsFromCockpit}
                                                    variant="outlined"
                                                    color="success"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {getTranslatedText('Continue')}
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>

                                <Step sx={{ width: "100%", textAlign: "left" }}
                                    className="onlinehelp-home-load-from-cockpit-filter">
                                    <StepLabel StepIconComponent={CockpitStepIcon} onClick={
                                        () => (selectedDeviceIds && selectedDeviceIds.length > 0) && !isLoadingCurveContentFromCockpit && setActiveStep(1)}>
                                        {getTranslatedText("AddFilterOptional")}
                                    </StepLabel>
                                    <StepContent>
                                        <Grid container direction={'row'} spacing={2}>
                                            <Grid item xs={12}>
                                                <LoadFromCockpitCurveDataFilter />
                                            </Grid>
                                        </Grid>

                                        <Grid container direction={'row'} spacing={2} sx={{ mt: 2 }}>
                                            <Grid item xs={12}>
                                                <LoadFromCockpitFinalResultStateFilter />
                                            </Grid>
                                        </Grid>


                                        <Box component={'div'} sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    disabled={isLoadingDeviceInformationStatus}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {getTranslatedText('Back')}
                                                </Button>
                                                <Button
                                                    disabled={isLoadingDeviceInformationStatus}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="success"
                                                    onClick={() => {
                                                        setLoadDeviceInformationAbortController(new AbortController());
                                                    }}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {getTranslatedText('Continue')}
                                                </Button>
                                                {isLoadingDeviceInformationStatus &&
                                                    <>
                                                        <Box component={"div"} sx={{ width: '100%', mt: 1, mr: 1 }}>
                                                            <LinearProgress color="success" />
                                                        </Box>
                                                        <Button
                                                            sx={{ mt: 1, mr: 1 }}
                                                            color="info"
                                                            variant="outlined"
                                                            fullWidth size="large"
                                                            onClick={() => {
                                                                loadFromCockpitDeviceInformationsAbortController?.abort();
                                                            }}>
                                                            {getTranslatedText("Cancel")}
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>

                                <Step sx={{ width: "100%", textAlign: "left" }}
                                    className="onlinehelp-home-load-from-cockpit-select-files">
                                    <StepLabel StepIconComponent={CockpitStepIcon} onClick={() => (selectedDeviceIds && selectedDeviceIds.length > 0) && setActiveStep(2)}>
                                        {getTranslatedText("SelectFilesFromCockpit")}
                                    </StepLabel>
                                    <StepContent>
                                        <Box component={'div'} sx={{ mb: 2 }}>
                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item xs={12}>
                                                    {isLoadingDeviceInformationStatus ?
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <PlaceholderForLoadingDevices />
                                                        </div> :
                                                        loadedDeviceInformationItem && loadedDeviceInformationItem.length > 0 ? <LoadFromCockpitCurveDataSelection />
                                                            :
                                                            (
                                                                isLoadingDeviceInformationStatus ?
                                                                    <Skeleton animation={'wave'} variant="rectangular" width={'100%'} height={50} />
                                                                    :
                                                                    <Box component="div" sx={{ textAlign: "center", borderRadius: "3px", width: "100%", p: 2, border: '1px dashed #f03e3e', color: '#f03e3e' }}>
                                                                        <InfoIcon />
                                                                        <Typography>{getTranslatedText("NoCurveFilesAvailable")}</Typography>
                                                                    </Box>
                                                            )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container direction={'row'}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                disabled={isLoadingCurveContentFromCockpit}
                                                color="secondary"
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {getTranslatedText('Back')}
                                            </Button>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                disabled={isLoadingCurveContentFromCockpit || !selectedCurveFileIds || selectedCurveFileIds.length === 0}
                                                color="success"
                                                onClick={() => {
                                                    setAbortController(new AbortController());
                                                }}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {getTranslatedText('SelectFilesFromCockpit')}
                                            </Button>

                                            {isLoadingCurveContentFromCockpit &&
                                                <>
                                                    <Box component={"div"} sx={{ width: '100%', mt: 1, mr: 1 }}>
                                                        <LinearProgress color="success" />
                                                    </Box>
                                                    <Button
                                                        sx={{ mt: 1, mr: 1 }}
                                                        color="info"
                                                        variant="outlined"
                                                        fullWidth size="large"
                                                        onClick={() => {
                                                            abortController?.abort();
                                                            loadFromCockpitDeviceInformationsAbortController?.abort();
                                                        }}>
                                                        {getTranslatedText("Cancel")}
                                                    </Button>
                                                </>
                                            }

                                            {isloadingSmplifiedCurve &&
                                                <>
                                                    <Box component={"div"} sx={{ width: '100%', mt: 1, mr: 1 }}>
                                                        <LinearProgress color="success" />
                                                    </Box>
                                                    <Button
                                                        sx={{ mt: 1, mr: 1 }}
                                                        color="info"
                                                        variant="outlined"
                                                        fullWidth size="large"
                                                        onClick={() => {
                                                            if (loadFromCockpitSimplifiedfileAbortController){
                                                                loadFromCockpitSimplifiedfileAbortController?.abort();
                                                            }
                                                        }}>
                                                        {getTranslatedText("Cancel")}
                                                    </Button>
                                                </>
                                            }                                            
                                        </Grid>


                                    </StepContent>
                                </Step>

                            </Stepper>
                        </Grid>
                    </Grid>
                </Drawer>
            </>
        }
    </LanguageConsumer >
    )
        :
        (
            <LanguageConsumer>
                {({ getTranslatedText }) =>
                    <>
                        <Drawer
                            anchor={'right'}
                            open={loadFromCockpit.isShown}
                            onClose={toggleDrawer()}
                            PaperProps={{
                                sx: {
                                    maxWidth: 750,
                                }
                            }}>

                            <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>
                                <Grid item xs={12} textAlign="center">
                                    <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: "300" }}>
                                        {getTranslatedText("LoadFromCockpitTitle")}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>
                                <Grid item xs={12} textAlign="center">
                                    <InfoIcon sx={{ fontSize: "5em", opacity: .3 }} />
                                </Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2} sx={{ p: 4, pt: 2, pb: 0 }}>
                                <Grid item xs={12} textAlign="center">
                                    <Typography sx={{ fontSize: "1.4em", opacity: .3 }}>{getTranslatedText("CockpitConnectionCurrentlyNotPresent")}</Typography>
                                </Grid>

                                <Grid item xs={12} textAlign="center">
                                    <Button component={HashLink} to={'/settings#loadFromCockpitSettings'} onClick={() => {
                                        dispatch(setActiveTab(3))
                                        dispatch(resetLoadFromCockpitVisibility())
                                        }} color="secondary" variant="outlined">{getTranslatedText("GoToCockpitSettings")}</Button>
                                </Grid>
                            </Grid>

                        </Drawer>
                    </>
                }
            </LanguageConsumer>
        );

}



