import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = (props) => {
  return [
    // Navigation - PAGES
    {
      selector: '.onlinehelp-home-sideDrawerView-share-intro',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpIntroMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-buttonEdit',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpButtonEditTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpButtonEditMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-toolPalette-ShareFreeMode',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpShareFreeModeTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpShareFreeModeMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-toolPalette-ShareHorizontalLine',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpShareHorizontalLineTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpShareHorizontalLineMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-toolPalette-ShareVerticalLine',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpShareVerticalLineTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpShareVerticalLineMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-toolPalette-ShareText',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpShareTextTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpShareTextMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-colorPallete',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpColorPalleteTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpColorPalleteMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-slider',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpSliderTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpSliderMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-toolPalette-textBoxShareText',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpTextBoxShareTextTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpTextBoxShareTextMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-removeDrawings',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpRemoveDrawingsTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpRemoveDrawingsMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-canvas',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpCanvasTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpCanvasMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-copieToClipBoard',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpCopieToClipBoardTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpCopieToClipBoardMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-share-sendAsEmail',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("ShareOnlineHelpSendAsEmailTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("ShareOnlineHelpSendAsEmailMessage") }}></div></div>)
    },
  ]
}
