import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { analyze_ChangeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { setShowCurrentValuesInChart, ShowCurrentValue } from "../Redux/SettingsSlice";

export const DisplayCurrentValue = () => {
  const dispatch = useAppDispatch();
  const showCurrentValue = useAppSelector(store => store.settings.showCurrentValuesInChart);

  const handleChangeDisplayCurrentValue = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as ShowCurrentValue;
    dispatch(setShowCurrentValuesInChart(value));
    dispatch(analyze_ChangeDefaultSettings({ setting: DefaultSettingsItems.DisplayPositionCurrValues, newValue: ShowCurrentValue[value] }))
  }

  return (<LanguageConsumer>
    {({ getTranslatedText }) =>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel color="success" id="select-label-currentValues">
          {getTranslatedText("ShowCurrentValuesInChartOrSeries")}
        </InputLabel>
        <Select
          className="onlinehelp-analyse-currentValuesSelect" 
          color="success"
          labelId="select-label-currentValues"
          id="select-currentValues"
          name="select-currentValues"
          value={showCurrentValue}
          label={getTranslatedText("ShowCurrentValuesInChartOrSeries")}
          onChange={handleChangeDisplayCurrentValue}
        >
          <MenuItem id="currentValues0" value={ShowCurrentValue.InChart}>{getTranslatedText("ShowInChart")}</MenuItem>
          <MenuItem id="currentValues1" value={ShowCurrentValue.InSeriesLegend}>{getTranslatedText("ShowInSeriesLegend")}</MenuItem>
        </Select>
      </FormControl>
    }
  </LanguageConsumer >
  );
};
