import { Chip } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { toggleLicenseForm } from "../../../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import LockIcon from '@mui/icons-material/Lock';
import { memo } from "react";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { setActiveTab } from "../../../Settings/Redux/SettingsSlice";

export const GetLicense = memo(() => {
    const dispatch = useAppDispatch();
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                {!isLicenced && <Chip
                    className="headerInformation"
                    component={NavLink}
                    to={"/settings"}
                    onClick={async () => {
                        dispatch(setActiveTab(4))
                        await new Promise(resolve => setTimeout(resolve, 500));
                        dispatch(toggleLicenseForm())
                    }}
                    sx={{ zIndex: 999, background: 'rgba(255,255,255,1)', cursor: "pointer", color: '#444'}}
                    icon={<LockIcon style={{ color: "#fcba03" }} />}
                    label={getTranslatedText("GetYourLicence")}
                    variant="filled" />
                }
            </>
        }
    </LanguageConsumer>
    );


})

