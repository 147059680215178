import {
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";

import {
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeItem } from "./DateRangeItem";
import uuid from "react-uuid";
import { updateDateRanges, updateFromDateRange, updateToDateRange } from "../../../LoadFromCockpit/Filter/Redux/LoadFromCockpitFilterSlice";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export type DateRangeFilterItem = {
  from: any;
  to: any;
  guid: string;
};


export const LoadFromCockpitCurveDataFilter = () => {

  const dateRangeFilterItems = useAppSelector(state => state.loadFromCockpitFilter.DateRanges);

  const dispatch = useAppDispatch();

  function handleAddButtonClick() {
    var newDateRange = { from: new Date(), to: new Date(), guid: uuid() };
    dispatch(updateDateRanges({
      DateRanges: [...dateRangeFilterItems, newDateRange]
    }));
  }

  const handleDeleteButtonClick = (guid: string) => {
    var dateRangeFiltered = dateRangeFilterItems.filter((x) => x.guid !== guid);
    dispatch(updateDateRanges({
      DateRanges: [...dateRangeFiltered]
    }));
  };

  const handleChangeFromDate = (guid: string, newDate: any) => {
    dispatch(updateFromDateRange({
      guid,
      newDate: newDate
    }));
  };

  const handleChangeToDate = (guid: string, newDate: any) => {
    dispatch(updateToDateRange({
      guid,
      newDate: newDate
    }));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <LanguageConsumer>
            {({ getTranslatedText }) => (
          <>
              <Grid item xs={12}>
              <Typography>
                {getTranslatedText("TimeFilter")}

                <Tooltip title={getTranslatedText("AddDateRangeFilterItem")}>
                <IconButton
                  color="success"
                  size="large"
                  sx={{top: "-2px"}}
                  onClick={() => {
                    handleAddButtonClick();
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
                </Tooltip>
              </Typography>
                 
              </Grid>
            <Grid
              container
              direction={"row"}
              spacing={2}
            >
              {dateRangeFilterItems && dateRangeFilterItems.length > 0 ?
                dateRangeFilterItems.map((dateRange, idx) => {
                  return (
                    <Grid key={idx} item xs={12}>
                      <DateRangeItem                        
                        from={dateRange.from}
                        to={dateRange.to}
                        guid={dateRange.guid}
                        deleteCallback={handleDeleteButtonClick}
                        updateFromDateCallback={handleChangeFromDate}
                        updateToDateCallback={handleChangeToDate}
                      />
                    </Grid>
                  );
                }) : 
                <Grid item xs={12}>
                <Grid container direction={'row'} spacing={2}>
                <Grid item xs={5}>
                  <Skeleton onClick={() => {
                    handleAddButtonClick();
                  }} animation={'wave'} variant="rectangular" width={'100%'} height={50} />
                </Grid>
                <Grid item xs={5}>
                  <Skeleton onClick={() => {
                    handleAddButtonClick();
                  }} animation={'wave'} variant="rectangular"  width={'100%'} height={50} />
                </Grid>
                <Grid item xs={2}>
                  <Skeleton onClick={() => {
                    handleAddButtonClick();
                  }} animation={'wave'} variant="rectangular" width={'100%'} height={50} />
                </Grid>
                </Grid>
                </Grid>
                }
            </Grid>
        
          </>
        )}
        </LanguageConsumer>
    </LocalizationProvider>
  );
};
