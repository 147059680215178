import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationType, LabellingDistinctiveValueAnnotation } from '../Annotation';
import { makeId } from '../AnnotationService';

export interface DistinctiveValueArea {
    title: string;
    seriesTitle: string;
    color: string;
    stepnumber: number;
    x: number;
    y: number;
    yscalefactor: number;
}

interface InitialAnnotationLabellingDistinctiveValueAreaState {
    annotations: LabellingDistinctiveValueAnnotation[];
}

export const labellingDistinctiveValueAreaAnnotationPrefix: string = "labellingDistinctiveValueAreaAnnotation_";

export const initialAnnotationLabellingDistinctiveValueState: InitialAnnotationLabellingDistinctiveValueAreaState = {
    annotations: []
}

export interface UpdateLabellingDistinctiveValuesPayplad {
    visibleSeriesTitle: string[];
    existingSeriesTitle: string[];
    seriesWithValues: string[];
    superVisualisationMode: boolean;
    isSidebarVisible: boolean;
}


const annotationLabellingDistinctiveValueAreaSlice = createSlice({
    name: "AnnotationDistinctiveValueArea",
    initialState: initialAnnotationLabellingDistinctiveValueState,
    reducers: {
        addDistinctiveValueArea: (state, action: PayloadAction<DistinctiveValueArea>) => {
            state.annotations = [...state.annotations,
            {
                color: action.payload.color,
                strokeColor: action.payload.color,
                id: labellingDistinctiveValueAreaAnnotationPrefix + makeId(state.annotations.length + 1),
                x1: action.payload.x,
                y1: action.payload.y * action.payload.yscalefactor,
                type: AnnotationType.labellingDistinctiveValueArea,
                title: action.payload.title,
                seriesTitle: action.payload.seriesTitle,
                visible: true
            } as unknown as LabellingDistinctiveValueAnnotation]
        },
        updateDistinctiveValueAreaAnnotation: (state, action: PayloadAction<UpdateLabellingDistinctiveValuesPayplad>) => {
            let seriesIndependentAnnotations = state.annotations.filter(x => !x.seriesTitle);

            let existingAnnotations = state.annotations.filter(x => x.seriesTitle)
                .filter(x => action.payload.existingSeriesTitle.find(y => y === x.seriesTitle) !== null);

            for (let annotation of existingAnnotations) {
                let isVisible = (action.payload.visibleSeriesTitle.indexOf(annotation.seriesTitle) > -1) || !action.payload.isSidebarVisible;
                let hasValues = action.payload.seriesWithValues.indexOf(annotation.seriesTitle) > -1;
                annotation.visible = isVisible && hasValues;
            }

            state.annotations = seriesIndependentAnnotations.concat(existingAnnotations);
        },
        removeAllDistinctiveValueAreaAnnotations: (state) => {
            state.annotations = []
        }
    }
})

export const { addDistinctiveValueArea, updateDistinctiveValueAreaAnnotation, removeAllDistinctiveValueAreaAnnotations } = annotationLabellingDistinctiveValueAreaSlice.actions;
export default annotationLabellingDistinctiveValueAreaSlice.reducer;