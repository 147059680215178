import { Fragment, useEffect } from "react";
import { useAppSelector } from "../Redux/hooks";
import PublishAnnotationAnalytics from "./Components/PublishAnnotationAnalytics";
import PublishCurveControlAnalytics from "./Components/PublishCurveControlAnalytics";
import PublishExpandViewAnalytics from "./Components/PublishExpandViewAnalytics";
import PublishFeedbackAnalytics from "./Components/PublishFeedbackAnalytics";
import PublishFilterAnalytics from "./Components/PublishFilterAnalytics";
import PublishFooterAnalytics from "./Components/PublishFooterAnalytics";
import PublishLicenseActivationAnalytics from "./Components/PublishLicenseActivationAnalytics";
import PublishLoadFilesAnalytics from "./Components/PublishLoadFilesAnalytics";
import PublishOnlineHelpAnalytics from "./Components/PublishOnlineHelpAnalytics";
import PublishSettingsAnalytics from "./Components/PublishSettingsAnalytics";
import PublishShareAnalytics from "./Components/PublishShareAnalytics";
import PublishZoomChartAnalytics from "./Components/PublishZoomChartAnalytics";
import { appInsights } from "./Provider/AppInsights";


const PublishAnalytics = () => {
    const licenceKey = useAppSelector(store => store.licenceService.licenceAuthentication.licenceKey);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);

    useEffect(() =>{
        if(isLicenced) {
            appInsights.setAuthenticatedUserContext(licenceKey,undefined,true);
        }
        else {
            appInsights.clearAuthenticatedUserContext();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLicenced])

    return (
        <Fragment>
            <PublishAnnotationAnalytics />            
            <PublishCurveControlAnalytics />
            <PublishExpandViewAnalytics />
            <PublishFeedbackAnalytics />
            <PublishFilterAnalytics />
            <PublishFooterAnalytics />
            <PublishLoadFilesAnalytics />
            <PublishOnlineHelpAnalytics />
            <PublishSettingsAnalytics />
            <PublishShareAnalytics />
            <PublishZoomChartAnalytics />
            <PublishLicenseActivationAnalytics />
        </Fragment>
    );
}

export default PublishAnalytics;