/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../Infrastructure/Redux/store";
import { getSimplifiedCurveFilesFromCockpit } from "../Api/GetSimplifiedCurveFiles";
import { SimplifiedCurveDataRequest } from "../Api/SimplifiedCurveDataRequest";
import { SimplifiedCurveDataResponse } from "../Api/SimplifiedCurveDataResponse";

export interface LoadFromCockpitState {
  cockpitResponse: SimplifiedCurveDataResponse[] | null,
  status: 'idle' | 'loading' | 'failed',
  abortController: AbortController | null,
}

export const initialLoadSimplifiedCurveState: LoadFromCockpitState = {
  cockpitResponse: null,
  status: "idle",
  abortController: null
};

export const loadSimplifiedCurveDatas = createAsyncThunk('LoadFromCockpitLoadSimplifiedCurveDatas',
  async (request: SimplifiedCurveDataRequest, { getState, rejectWithValue }) => {

    const currentState = getState() as RootState;
    const abortController = currentState.loadFromCockpitSimplifiedCurveDatas.abortController as AbortController;
    const requestWithAbortController: SimplifiedCurveDataRequest = {
      ...request,
      abortController: abortController,
    };

    const response = await getSimplifiedCurveFilesFromCockpit(requestWithAbortController);
    return response;
  }
);

export const loadFromCockpitLoadSimplifiedCurveDatasSlice = createSlice({
  name: 'LoadFromCockpitLoadSimplifiedCurveDatas',
  initialState: initialLoadSimplifiedCurveState,
  reducers: {
    setLoadFromCockpitSimplifiedFileAbortController: (state: LoadFromCockpitState, action: PayloadAction<AbortController>) => {
      if (state.abortController == null) {
        state.abortController = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(loadSimplifiedCurveDatas.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(loadSimplifiedCurveDatas.fulfilled, (state, action) => {
        state.status = 'idle';
        state.abortController = null;
        state.cockpitResponse = action.payload
      })
      .addCase(loadSimplifiedCurveDatas.rejected, (state) => {
        state.status = 'failed';
        state.abortController = null;
      })
  }
});

export const { setLoadFromCockpitSimplifiedFileAbortController } = loadFromCockpitLoadSimplifiedCurveDatasSlice.actions;

export default loadFromCockpitLoadSimplifiedCurveDatasSlice.reducer;
