import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CheckCockpitSettings } from "../Api/CheckCockpitSettings";
import { CheckCockpitSettingsRequest } from "../Model/CheckCockpitSettingsRequest";

export interface CheckCockpitSettingsState{
    success: boolean;
    status: string;
    showHelp: boolean;
    showHelpWasShown: boolean;
}

export const cockpitSettingsInitialState: CheckCockpitSettingsState = {
    status: "idle",
    success: false,
    showHelp: false,
    showHelpWasShown: false,
}

export const checkSettings = createAsyncThunk("checkcockpitsettings/initialize",async (request: CheckCockpitSettingsRequest) => {
    var response = await CheckCockpitSettings(request);
    return response;
})

export const checkSettingsSlice = createSlice({
    name: "checksettings",
    initialState: cockpitSettingsInitialState,
    reducers: {
      hideCockpitConnectionHelpDialog: (state) => {
        state.showHelp = false;
      }
    },
    extraReducers: builder => {
        builder.addCase(checkSettings.pending, (state) => {
          state.status = 'loading';
        })
          .addCase(checkSettings.fulfilled, (state, action) => {
            state.status = 'idle';
            state.success = action.payload.success;
            state.showHelp = false;
          })
          .addCase(checkSettings.rejected, (state, action) => {
            state.status = 'failed';
            state.success = false;
            if (!state.showHelpWasShown && !state.success && (action.error.message === "Failed to fetch" || action.error.message === "NetworkError when attempting to fetch resource.")) {
              state.showHelp = !state.success;
              state.showHelpWasShown = true;
            }            
          })                 
      }      

})

export const { hideCockpitConnectionHelpDialog } = checkSettingsSlice.actions;

export default checkSettingsSlice.reducer;