
import { getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService";
import { HardwareType } from "../Models/HardwareType";
import { LoadFromControllerRequest } from "../Models/LoadFromControllerRequest";

const getFilesFromAST12 = async (ip: string, controller) => {

    try{
        const url = 'https://' + ip + ':8082/api/v1.0/processdata/measurements';
        const response = await fetch(url, {
            method: "GET",
            signal: controller.signal
        });

        return response.json();
    }
    catch{
        const url = 'http://' + ip + ':8081/api/v1.0/processdata/measurements';
        const response = await fetch(url, {
            method: "GET",
            signal: controller.signal
        });

        return response.json();
    }
}

export  const getAvailableFiles = async (chartRequest: LoadFromControllerRequest) : Promise<string[]> => {
    try {    
    
         var files = [];
        
        var hardwareTyp =HardwareType.Ast12;
        if(hardwareTyp === HardwareType.Ast12){
            var apiResult = await getFilesFromAST12(chartRequest.ipAddress, chartRequest.controller);
            files = apiResult.Measurements;
        }


        return new Promise<any>((resolve, rejected) =>{ 
            if(files.length > 0){
                resolve(files.map((x: any) => {
                    return x.Url
                }))
            } else {     
                if(hardwareTyp !== HardwareType.Ast12){
                    rejected(getTranslation("ControllerNotSupported"))
                }  else {
                    rejected(getTranslation("NoDataFoundOnAST"))
                }    
            }
        });
        
    } catch (error: any) {        
        return new Promise<any>((resolve, rejected) => {
            rejected(error.response && error.response.data ? error.response.data.MessageText : error);
            }
        );
        
    }
  }