import Papa from 'papaparse';
import { LanguageLine } from './Model/LanguageLine';
const urlToGetLanguageFile = globalThis && globalThis.Settings ? globalThis.Settings.languageFileAdress : './Language-AutoGen.csv'


export const readLanguageFile = async ()  : Promise<LanguageLine[]> => { 
  var content = await downloadContent();
  
  return new Promise(resolve => {
    Papa.parse(content, {
      header: true,
      complete: results => {
        var returnValue = results.data as LanguageLine[]; 
        resolve(returnValue);
      }
    });
  });
};

async function downloadContent() {
  const file = await fetch(urlToGetLanguageFile);
  var content = await file.text();
  return content;
}

