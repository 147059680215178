import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { AnnotationVisibilityEnum } from "../../Models/AnnotationSettings";
import { setActiveTab, setStepNumberVisibility } from "../../Redux/SettingsSlice";
import { NavLink } from "react-router-dom";

export const StepNumberVisibility = () => {
    const dispatch = useAppDispatch();

    const licenceData = useAppSelector(store => store.licenceService);
    const stepNumberVisibility = useAppSelector(store => store.settings.annotationSettings.stepNumberVisibility);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 3 }} className="onlinehelp-annotations-stepnumberVisibility">
                    <InputLabel color="success" id="select-label-StepNumberVisibility">{getTranslatedText("StepNumberVisibility")}</InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        data-testid="settings-annotations-stepNumberVisibility"
                        fullWidth
                        className="onlinehelp-settings-stepNumberVisibilitySelect"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-StepNumberVisibility"
                        id="select-StepNumberVisibility"
                        name="select-StepNumberVisibility"
                        value={stepNumberVisibility}
                        label={getTranslatedText("StepNumberVisibility")}
                        onChange={(stepNumberVisibility) => {
                            var value = stepNumberVisibility.target.value as AnnotationVisibilityEnum
                            dispatch(setStepNumberVisibility(value))
                        }}
                    >
                        <MenuItem id="StepNumberVisibility0" value={AnnotationVisibilityEnum.Visible}>{getTranslatedText("AnnotationVisible")} </MenuItem>
                        <MenuItem id="StepNumberVisibility1" value={AnnotationVisibilityEnum.Hidden}>{getTranslatedText("AnnotationHidden")}</MenuItem>
                    </Select>


                </FormControl>
            }
        </LanguageConsumer>
    )
}










