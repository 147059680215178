/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { SuperVisualizationModeDto } from "../../../Features/Home/Models/SuperVisualizationModeDto";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { selectSeries } from "../../ChartResultHandler/Redux/ChartResultSlice";
import { initialeVisibility, SeriesVisibility } from "../../ChartSeriesVisibility/Redux/ChartSeriesVisibilitySlice";
import { setPersitedChartSeriesVisibility } from "../Redux/PersistedChartSeriesVisibilitySlice";

export const usePersistedChartSeriesVisibility = () => {
    
    const dispatch = useAppDispatch();
    const isSuperVisualisation = useAppSelector(store => 
        store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const seriesData = useAppSelector(selectSeries);
    const persistedSeriesVisibilities = useAppSelector(store =>  store.persistedChartSeriesVisibility.visibilities);
    const seriesVisibilities = useAppSelector(store =>  store.chartSeriesVisibility.seriesVisibilities);    
    

    useEffect(()=>{

        if (isSuperVisualisation && seriesData && seriesData.length > 0){
            var chartSeriesVisibilities : SeriesVisibility[] = [];
            seriesData[0].ChartSeries.forEach(chartserie =>{

                var foundPersistedColor = seriesVisibilities.filter(x => x.title === chartserie.Title);
                if (foundPersistedColor.length > 0){
                    foundPersistedColor.forEach((item) => {
                        chartSeriesVisibilities.push({title: item.title, visible: item.visible});
                    });
                }
                else{
                    chartSeriesVisibilities.push({title: chartserie.Title, visible: chartserie.Visible});
                }
            }); 
            dispatch(initialeVisibility({list: chartSeriesVisibilities}));       
            return;
        }

        if (persistedSeriesVisibilities.length === 0){
            if (seriesData && seriesData.length >0){
            var seriesVisibilityList = seriesData[0].ChartSeries.map(serie => ({
                title: serie.Title, 
                visible: serie.Visible
                }));
            dispatch(initialeVisibility({list: seriesVisibilityList as SeriesVisibility[]}));    
            }
        }
            
            if(!isSuperVisualisation){
                if (seriesData && seriesData.length > 0){

                    var chartSeriesVisibilitiesToDispatch : SeriesVisibility[] = [];
                    seriesData[0].ChartSeries.forEach(chartserie =>{

                        var foundPersistedColor = persistedSeriesVisibilities.filter(x => x.title === chartserie.Title);
                        if (foundPersistedColor.length > 0){
                            foundPersistedColor.forEach((item) => {
                                chartSeriesVisibilitiesToDispatch.push({title: item.title, visible: item.visible});
                            });
                        }
                        else{
                            chartSeriesVisibilitiesToDispatch.push({title: chartserie.Title, visible: chartserie.Visible});
                        }
                    }); 
                    dispatch(initialeVisibility({list: chartSeriesVisibilitiesToDispatch}));                                                   
                }
            }
    }, 
    [seriesData, isSuperVisualisation, dispatch])

    useEffect(()=>{
 
            if(!isSuperVisualisation && seriesData && seriesData.length > 0){               
                seriesVisibilities.forEach(item => {dispatch(setPersitedChartSeriesVisibility(item))})                      
            }            
    }, 
    [seriesVisibilities, isSuperVisualisation, dispatch])      
}