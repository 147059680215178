/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { AnnotationsEvent } from "../Model/Categories/AnnotationEnums";
import { reset_analyze_AddAnnotations, reset_analyze_DeleteAnnotations } from "../Redux/AnalyticsSlice";

const PublishAnnotationAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();
  
    const addAnnotationsState = useAppSelector(store => store.analytics.addAnnotationsState)
    const deleteAnnotationsState = useAppSelector(store => store.analytics.deleteAnnotationsState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackAddAnnotations = TrackEvent(AnnotationsEvent.AddAnnotation.toString(), {})
    const trackDeleteAnnotations = TrackEvent(AnnotationsEvent.DeleteAnnotation.toString(), {})

    useEffect(() => {
        if (addAnnotationsState.toggle) {
            try{
                trackAddAnnotations({ Action: addAnnotationsState.action.toString() })
            }
            catch{}
            dispatch(reset_analyze_AddAnnotations())
        }
    }, [addAnnotationsState.toggle])

    useEffect(() => {
        if (deleteAnnotationsState.toggle) {
            try{
                trackDeleteAnnotations({})
            }
            catch{}
            dispatch(reset_analyze_DeleteAnnotations())
        }
    }, [deleteAnnotationsState.toggle])

    return (
        <></>
    )
}

export default PublishAnnotationAnalytics;