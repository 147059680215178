import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './Infrastructure/Redux/store';
import App from './App';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/comfortaa/300.css';
import '@fontsource/comfortaa/400.css';
import '@fontsource/comfortaa/500.css';
import '@fontsource/comfortaa/600.css';
import '@fontsource/comfortaa/700.css';
import { LanguageProvider } from './Infrastructure/Internationalisation/TranslationService';
import {
  persistStore
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './Infrastructure/Analytics/Provider/AppInsights';
import * as serviceWorker from './serviceWorkerRegistration';
import "typeface-fira-sans";
import "typeface-dancing-script";

const container = document.getElementById('root')!;
const root = createRoot(container);
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <LanguageProvider>
            <App />
        </LanguageProvider>
      </AppInsightsContext.Provider>
    </PersistGate>
  </Provider>
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
