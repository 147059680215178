import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ChartRequest } from '../../../Models/ChartRequest';
import { postLoadExampleFileToBackend } from '../API/LoadExampleFilePost';

export interface LoadExampleFileState {
    status: 'idle' | 'loading' | 'failed',
}

const initialState: LoadExampleFileState = {
    status: 'idle'
}

export const sendLoadExampleFileToBackend = createAsyncThunk(
    'chart/converttemplate',
    async (request: ChartRequest) => {
        const abortControllerForThisRequest = new AbortController();    
        const response = await postLoadExampleFileToBackend(request, abortControllerForThisRequest); 
        return response;
    }
);

export const loadExampleFileSlice = createSlice({
    name: 'loadexamplefile',
    initialState,
    reducers:{},
    extraReducers: builder => {
        builder
            .addCase(sendLoadExampleFileToBackend.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendLoadExampleFileToBackend.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(sendLoadExampleFileToBackend.rejected, (state) => {
                state.status = 'failed';
            })
    }
});