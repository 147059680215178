import { Typography } from "@mui/material"
import { getTranslation } from "../../Infrastructure/Internationalisation/TranslationService"

export const getNavigationSteps = () =>{
    return [
        {
        selector: '.onlinehelp-home-load-from-controller',
        position: 'center',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromControllertOnlineHelpTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromControllerOnlineHelpMessage") }}>
            </div>
        </div>),
    },
    {
        selector: '.onlinehelp-home-load-from-controller-select-ip-address',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromControllerOnlineHelpSelectIpAddressTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromControllerOnlineHelpSelectIpAddressMessage") }}>
            </div>
        </div>),
    },    
    {
        selector: '.onlinehelp-home-load-from-controller-load-available-files',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromControllerOnlineHelpLoadAvailableFilesTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromControllerOnlineHelpLoadAvailableFilesMessage") }}>
            </div>
        </div>),
    },
    {
        selector: '.onlinehelp-home-load-from-controller-select-available-files',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromControllerOnlineHelpSelectAvailableFilesTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromControllerOnlineHelpSelectAvailableFilesMessage") }}>
            </div>
        </div>),
    },    
    {
        selector: '.onlinehelp-home-load-from-controller-update-interval',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromControllerOnlineHelpUpdateIntervalTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromControllerOnlineHelpUpdateIntervalMessage") }}>
            </div>
        </div>),
    },
    {
        selector: '.onlinehelp-home-load-from-controller-load-selected-files',
        position: 'left',
        content: () => (
        <div>
            <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>
                {getTranslation("LoadFromControllerOnlineHelpLoadSelectedFilesTitle")}
            </Typography>
            <div style={{ color: '#444' }} 
                dangerouslySetInnerHTML={{ __html: getTranslation("LoadFromControllerOnlineHelpLoadSelectedFilesMessage") }}>
            </div>
        </div>),
    }]
}