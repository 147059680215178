import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetNewFeatures } from "../API/GetNewFeatures";
import { Feature } from "../Models/Feature";

export interface NewFeaturesState{
    status: string;
    showNewFeatures: boolean;
    newFeatures: Feature[]
}

export const newFeaturesInitialState: NewFeaturesState = {
    status: "idle",
    showNewFeatures: false,
    newFeatures: []
}

export const getNewFeatures = createAsyncThunk("newFeatures/initialize",async () => {
    var response = await GetNewFeatures();
    return response;
})

export const newFeaturesSlice = createSlice({
    name: "newFeatures",
    initialState: newFeaturesInitialState,
    reducers: {
      showNewFeatures: (state) => {
            state.showNewFeatures = true;
      },
      hideNewFeatures: (state) => {
        state.showNewFeatures = false;
      }
    },
    extraReducers: builder => {
        builder.addCase(getNewFeatures.pending, (state) => {
          state.status = 'loading';
        })
          .addCase(getNewFeatures.fulfilled, (state, action) => {
            state.status = 'idle';
            state.newFeatures = action.payload;
          })
          .addCase(getNewFeatures.rejected, (state, action) => {
            state.status = 'failed';
            state.newFeatures = [];
            state.showNewFeatures = false;
          })                 
      }      

})

export const { showNewFeatures, hideNewFeatures } = newFeaturesSlice.actions;

export default newFeaturesSlice.reducer;