import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NotificationType, setNotification } from '../../../Layout/Notifications/Redux/NotificationsSlice';
import { MetaDataDto } from '../../Home/Models/MetaDataDto';
import { metaDataCompareApiCall, MetadataCompareRequest } from '../Api/MetaDataCompareApi';
import { getTranslation } from '../../../Infrastructure/Internationalisation/TranslationService';

export interface MetadataCompareState {
  status: string;
  canCompareDetails: boolean;
  content: MetaDataDto | null;  
  success: boolean;
  titles: string[] | null;
}

export const metaDataCompareIitialState: MetadataCompareState = {
  status: "idle",
  canCompareDetails: false,
  content: null,  
  success: true,
  titles: []
};


export const metaDataCompareAsync = createAsyncThunk(
  'metaDataCompare/initialize',
  async (request: MetadataCompareRequest, thunkAPI) => {
    try {
      const response = await metaDataCompareApiCall(request);
      if (!response.success){
       
          thunkAPI.dispatch(setNotification({
               type: NotificationType.error, 
               text: getTranslation("ErrorWhileComparingMetaData") 
          }));
      }        
      
      return response;
    }
    catch (error: any) {
      throw error;
    }
  }
);

export const metaDataCompareSlice = createSlice({
  name: 'metaDataCompare',
  initialState: metaDataCompareIitialState,
  reducers: {
    metaDataCompareSourceChanged: (state, action) => {
      state.canCompareDetails = true;
      state.content = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(metaDataCompareAsync.pending, (state) => {
        state.status = 'loading';
        state.success = true;
      })
      .addCase(metaDataCompareAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.canCompareDetails = action.payload.canCompareDetails;
        state.content = action.payload.content;
        state.success = true;      
        state.titles = action.payload.titles;  
      })
      .addCase(metaDataCompareAsync.rejected, (state, action) => {
        state.status = 'failed';                
        state.success = false;        
      });
  },
});

export default metaDataCompareSlice.reducer;

export const { metaDataCompareSourceChanged } = metaDataCompareSlice.actions;