/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { saveSeriesRequestsInStore } from "../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { getSeriesForGraphDataAsync } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { downloadAndConvertSingleFile } from "../../../Infrastructure/FileDownloader/FileDownloader";

export const useHandleReloadOfActualFromController = () => {
    const dispatch = useAppDispatch();

    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const activeFileUrlsFromController = useAppSelector(store => store.loadAvailableFilesFromController.activeFileUrls);
    const updateActualFileFromController = useAppSelector(store => store.loadAvailableFilesFromController.updateActual);
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const languageIdentifier = useAppSelector(store => store.settings.language);    

    let loadActualInterval: { current: NodeJS.Timeout | null } = useRef(null);

    useEffect(() => {
        if (activeFileUrlsFromController.length === 1 &&
            activeFileUrlsFromController[0].includes("actual") &&
            updateActualFileFromController.isEnabled &&
            (chartRequest && chartRequest.Files && chartRequest.Files.length === 1 && chartRequest.Files[0].Title.includes("actual"))) {

            loadActualInterval.current = setInterval(async () => {

                var requestFiles = await downloadAndConvertSingleFile(activeFileUrlsFromController, chartRequestSettings, languageIdentifier);
                if (requestFiles && requestFiles.Files.length > 0 && (requestFiles.Files[0].CurveRawContent !== chartRequest.Files[0].CurveRawContent)) {
                    dispatch(saveSeriesRequestsInStore(requestFiles));
                    dispatch(getSeriesForGraphDataAsync(requestFiles));
                }

            }, updateActualFileFromController.interval)
        } else {
            clearInterval(loadActualInterval.current as NodeJS.Timeout);
        }
        return () => clearInterval(loadActualInterval.current as NodeJS.Timeout);
    }, [activeFileUrlsFromController, chartRequest?.Files, updateActualFileFromController.isEnabled])
}