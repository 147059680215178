import { createSlice } from '@reduxjs/toolkit';


export interface Color {
    title: string,
    color: string,
}

export interface ChartSeriesColorsState {
  colors: Color[];
}

const initialState: ChartSeriesColorsState = {
    colors: [],
};



export const chartSeriesColorsSlice = createSlice({
  name: 'seriescolors',
  initialState,
  reducers: {
    setOrUpdateColor: (state, action) => {
        let isUpdated = false;
        const newColors = state.colors.map(color => {
            if(color.title === action.payload.title){
                isUpdated = true;
                return {
                    ...color,
                    color: action.payload.color
                };
            } else {
                return color;
            }
        });

        !isUpdated && newColors.push(action.payload);

        state.colors = newColors;
      },
      resetSeriesColorToStandard: (state, action) => {
          const newColors = state.colors.filter(color => color.title !== action.payload);
          state.colors = newColors;
      },
      clearChartSeriesColors: (state) => {
        state.colors = []
      },
      createNotExisting: (state, action) => {
        action.payload.list.forEach(item => {
          let existingSeries = state.colors.find(x => x.title === item.title);
          if (!existingSeries){
            state.colors = [...state.colors, item as Color] 
          }
        })
    },
  }
});

export const { setOrUpdateColor, resetSeriesColorToStandard, clearChartSeriesColors, createNotExisting } = chartSeriesColorsSlice.actions;

export default chartSeriesColorsSlice.reducer;
