import { licenseBaseUrl } from "../../../../Api/BaseApi";
import { DeleteUsageRequest } from "../Models/DeleteUsageRequest";
import { DeleteUsageResponse } from "../Models/DeleteUsageResponse";

const simpleFetchCall = async (request: DeleteUsageRequest) => {
    var response = await fetch(licenseBaseUrl + 'licensing/usages/' + request.UsageId, {
        headers: new Headers({
            'Content-Type': 'application/json',
            masterOrChildKey:request.MasterOrChildKey,
            clientId:request.ClientId
        }),
        method: 'DELETE',
    });
    return response.json();
}

const getResponse = async (request: DeleteUsageRequest) => {
    const response = await simpleFetchCall(request);
    return response as DeleteUsageResponse;
}

export const deleteUsageInBackend = async (request: DeleteUsageRequest): Promise<DeleteUsageResponse> => {
    try {

        var response = await getResponse(request);

        return new Promise<any>((resolve, reject) => {
            if (response && response.success) {
                resolve(response)
            }
            else {
                reject(response.errorMessage)
            }
        });

    } catch (error: any) {
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);
    }
}