import { Zoom, Fab, Drawer, Grid, IconButton, Tooltip, Typography, TextField, FormControl, InputLabel, MenuItem, Select, Card, CardMedia } from "@mui/material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { fileDialog } from "file-select-dialog";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './Style/Feedback.css';
import { removeFeedbackAttachements, sendFeedbackFormToBackend, setFeedbackAttachements, setFeedbackCompany, setFeedbackEmail, setFeedbackLastName, setFeedbackMessage, setFeedbackMessageType, setFeedbackName, setShowErrors, toggleShowFeedback } from "./Redux/FeedbackSlice";
import { MessageType } from "./Models/MessageType";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { analyze_SendFeedback } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { FeedbackPopover } from "../../Infrastructure/Popover/Feedback/FeedbackPopover";

const toBase64 = file => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
})

export const Feedback = () => {
    const dispatch = useAppDispatch();
    const licenceNumber = useAppSelector(store => store.licenceService.licenceAuthentication.licenceKey);
    const showFeedback = useAppSelector(store => store.feedback.showFeedback);
    const isSending = useAppSelector(store => store.feedback.status === 'loading');
    const name = useAppSelector(store => store.feedback.name);
    const lastName = useAppSelector(store => store.feedback.lastName);
    const company = useAppSelector(store => store.feedback.company);
    const message = useAppSelector(store => store.feedback.message);
    const email = useAppSelector(store => store.feedback.email);
    const messageType = useAppSelector(store => store.feedback.messageType);
    const attachements = useAppSelector(store => store.feedback.attachements);
    const showErrors = useAppSelector(store => store.feedback.showErrors);
    const language = useAppSelector(store => store.settings.language);

    const selectFiles = async () => {
        const image = await fileDialog({ multiple: false, accept: ['.jpg', '.jpeg', '.png'], strict: true });
        var fileEnding = image.name.split('.').pop();
        if (fileEnding === 'jpg' || fileEnding === 'png' || fileEnding === 'JPG' || fileEnding === 'PNG' || fileEnding === 'jpeg' || fileEnding === 'JPEG') {
            var imageBase64 = await toBase64(image);
            dispatch(setFeedbackAttachements(imageBase64));
        }
    }

    const validateEmail = (email: string) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const enableShowErrors = () => {
        dispatch(setShowErrors());
    }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <Drawer                 
                    anchor={'right'}
                    open={showFeedback}
                    onClose={() => dispatch(toggleShowFeedback())}
                >
                    <div className="feedbackDrawerContent">
                        <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }} >
                            <Grid item xs={12}>
                                <Typography variant="h4" component="div" sx={{ fontWeight: "300" }}>
                                    {getTranslatedText("FeedbackTitle")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="div" sx={{ fontWeight: "300" }}>
                                    {getTranslatedText("FeedbackCustomerText")}
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container direction={'row'} spacing={1} sx={{ p: 4, pb: 0, pt: 5 }} >
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={enableShowErrors}
                                    disabled={isSending}
                                    error={name.trim().length === 0 && showErrors}
                                    helperText={name.trim().length === 0 && showErrors ? getTranslatedText("FeedbackNameError") : ''}
                                    fullWidth
                                    color="success"
                                    value={name}
                                    onChange={(e) => dispatch(setFeedbackName(e.target.value))}
                                    label={getTranslatedText("FeedbackName")} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={enableShowErrors}
                                    disabled={isSending}
                                    error={lastName.trim().length === 0 && showErrors}
                                    helperText={lastName.trim().length === 0 && showErrors ? getTranslatedText("FeedbackLastNameError") : ''}
                                    fullWidth
                                    color="success"
                                    value={lastName}
                                    onChange={(e) => dispatch(setFeedbackLastName(e.target.value))}
                                    label={getTranslatedText("FeedbackLastName")} />
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={1} sx={{ p: 4, pb: 0, pt: 2 }} >
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={enableShowErrors}
                                    disabled={isSending}
                                    error={company.trim().length === 0 && showErrors}
                                    helperText={company.trim().length === 0 && showErrors ? getTranslatedText("FeedbackCompanyError") : ''}
                                    fullWidth
                                    color="success"
                                    value={company}
                                    onChange={(e) => dispatch(setFeedbackCompany(e.target.value))}
                                    label={getTranslatedText("FeedbackCompany")} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={enableShowErrors}
                                    disabled={isSending}
                                    error={(email.trim().length === 0 || !validateEmail(email)) && showErrors}
                                    helperText={((email.trim().length === 0 || !validateEmail(email)) && showErrors) ? getTranslatedText("FeedbackEmailError") : ''}
                                    fullWidth
                                    color="success"
                                    value={email}
                                    onChange={(e) => dispatch(setFeedbackEmail(e.target.value))}
                                    label={getTranslatedText("FeedbackEmail")} />
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={1} sx={{ p: 4, pb: 0, pt: 2 }} >
                            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <InputLabel color="success" id="demo-simple-select-helper-label">{getTranslatedText("MessageType")} </InputLabel>
                                    <Select
                                        disabled={isSending}
                                        fullWidth
                                        className="onlinehelp-settings-languageSelect"
                                        color="success"
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={messageType}
                                        label={getTranslatedText("MessageType")}
                                        onChange={(e) => {
                                            dispatch(setFeedbackMessageType(e.target.value as MessageType))
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem value={MessageType.Suggestion}>{getTranslatedText("MessageTypeSuggestion")} </MenuItem>
                                        <MenuItem value={MessageType.Question}>{getTranslatedText("MessageTypeQuestion")} </MenuItem>
                                        <MenuItem value={MessageType.BugReport}>{getTranslatedText("MessageTypeBugReport")} </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={1} sx={{ p: 4, pb: 0, pt: 2 }} >
                            <Grid item component={'div'} xs={12}>
                                <TextField
                                    onFocus={enableShowErrors}
                                    disabled={isSending}
                                    error={message.trim().length === 0 && showErrors}
                                    helperText={message.trim().length === 0 && showErrors ? getTranslatedText("FeedbackMessageError") : ''}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    color="success"
                                    value={message}
                                    onChange={(e) => dispatch(setFeedbackMessage(e.target.value))}
                                    label={getTranslatedText("FeedbackMessage")} />
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={1} sx={{ mt: 0, p: 4, pb: 0}}   >
                            {attachements && attachements.length > 0 && attachements.map((att, attIdx) => {
                                return (<Grid item xs={4} key={attIdx}>
                                    <Card>
                                        <div className="cardWrapper">
                                            <CardMedia
                                                alt="My cool img"
                                                component="img"
                                                sx={{ width: '100%', maxHeight: "100px" }}
                                                image={att}
                                            />
                                            <div className="layer">
                                                <IconButton title="test" color="primary" onClick={() => dispatch(removeFeedbackAttachements(att))}>
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                                )
                            })
                            }
                        </Grid>

                        {(!attachements || attachements.length < 3) && <Grid container direction={'row'} spacing={1} sx={{ mt: 0, p: 4, pb: 0, pt: 1 }} >
                            <Grid item component={'div'} xs={12}>
                                <Tooltip title={getTranslatedText("AddAttachment")}>
                                    <IconButton disabled={isSending} onClick={selectFiles} color="success" sx={{ background: '#32992c', color: '#fff' }}>
                                        <AttachFileIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item style={{ paddingTop: 0 }}>
                                <Typography variant="caption" >
                                    {
                                        getTranslatedText("FeedbackValidImageFormats")
                                    }
                                </Typography>
                            </Grid>
                        </Grid>}

                        <Grid container direction={'row'} spacing={1} sx={{ mt: 2, p: 4, pt: 0 }}>

                            <Grid item xs={12} alignContent="center" textAlign={'center'}>
                                <LoadingButton
                                    loading={isSending}
                                    loadingPosition="start"
                                    startIcon={<SendIcon />}
                                    disabled={isSending || name.trim().length === 0 || lastName.trim().length === 0 || email.trim().length === 0 || !validateEmail(email) || company.trim().length === 0 || message.trim().length === 0}
                                    color="success"
                                    variant="contained"
                                    sx={{ color: '#fff' }}
                                    fullWidth
                                    size="large"
                                    onClick={() => {
                                        dispatch(sendFeedbackFormToBackend({
                                            Name: name,
                                            LastName: lastName,
                                            Company: company,
                                            Message: message,
                                            Email: email,
                                            LicenceNumber: licenceNumber !== "" ? licenceNumber : "not-licenced",
                                            Attachements: attachements ?? [],
                                            MessageType: messageType,
                                            Language: language
                                        }))
                                        dispatch(analyze_SendFeedback())
                                    }}>
                                    {getTranslatedText("SendFeedback")}
                                </LoadingButton>
                            </Grid>

                        </Grid>
                    </div>

                </Drawer>


                <FeedbackPopover />
                <Zoom
                    in={true}
                    unmountOnExit
                >
                    <Fab className="popover-feedback" size="small" sx={{ position: 'fixed', bottom: 20, right: 20 }} onClick={() => dispatch(toggleShowFeedback())} aria-label={'Feedback'} color={'default'}>
                        <FeedbackIcon fontSize="small" />
                    </Fab>
                </Zoom>
            </>}
    </LanguageConsumer >
    );
}