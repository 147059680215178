import { CheckCockpitSettingsRequest } from "../Model/CheckCockpitSettingsRequest";
import { encode } from "base-64";
import { CheckCockpitSettingsResult } from "../Model/CheckCockpitSettingResult";

export const CheckCockpitSettings = async (checkCockpitSettingsRequest: CheckCockpitSettingsRequest) : Promise<CheckCockpitSettingsResult> =>{
  
    const response = await fetch('http://' + checkCockpitSettingsRequest.cockpitUrl + ":8089/" + 
                    checkCockpitSettingsRequest.cockpitArea + "/api/v1.0/graphviewer/settingscheck/", {                        
        method: 'POST',
        body: JSON.stringify(
            {
                CustomerArea: checkCockpitSettingsRequest.cockpitArea
            }),        
        headers: new Headers({
            'Authorization': 'Basic ' + encode(checkCockpitSettingsRequest.username + ":" + checkCockpitSettingsRequest.password),
            'Content-Type': 'application/json'
        }),
    });
    const responseAsJson = await response.json();
    const responseAsCheckResult = responseAsJson as CheckCockpitSettingsResult;
    return responseAsCheckResult;
}