import { LanguageConsumer } from "../Internationalisation/TranslationService"
import { SuperSEO } from "react-super-seo";
import { useAppSelector } from "../Redux/hooks";

export const SearchEngineOptimization = () => {
    const lang = useAppSelector(store => store.settings.language);

    return (
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <SuperSEO
                title="DEPRAG GraphViewer Cloud"
                description={getTranslatedText("SeoDescription")}
                lang={lang}
                openGraph={{
                    ogImage: {
                    ogImage: "https://graphviewer.deprag.com/SEO/opengraph.jpg",
                    ogImageAlt: "DEPRAG GraphViewer Cloud",
                    ogImageWidth: 1200,
                    ogImageHeight: 600,
                    ogImageType: "image/jpeg",
                    },
                }}
                twitter={{
                    twitterSummaryCard: {
                    summaryCardImage: "https://graphviewer.deprag.com/SEO/twitter.jpg",
                    summaryCardImageAlt: "DEPRAG GraphViewer Cloud",
                    summaryCardSiteUsername: "deprag",
                    },
                }}
                >
                    
                </SuperSEO>
            </>
            }
        </LanguageConsumer>
    )
}