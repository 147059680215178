export interface AnnotationSettings {
    userAnnotationColorAsHex: string,
    targetAreaVisibility: AnnotationVisibilityEnum
    stepNumberVisibility: AnnotationVisibilityEnum
}

export enum AnnotationVisibilityEnum {
    Visible,
    Hidden
}

