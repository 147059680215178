import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { InformationType, setActiveTab, setInformationType } from "../Redux/SettingsSlice";
import { NavLink } from "react-router-dom";

export const InformationTypeSelect = () => {
    const dispatch = useAppDispatch();

    const informationType = useAppSelector(store => store.settings.informationType);
    const licenceData = useAppSelector(store => store.licenceService);

    const handleChangeInformationType = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as InformationType;
        dispatch(setInformationType(value));
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel color="success" id="select-label-theme">{getTranslatedText("InformationType")}</InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        className="onlinehelp-settings-informationType"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-theme"
                        id="select-information-type"
                        name="select-information-type"
                        value={informationType}
                        label={getTranslatedText("InformationType")}
                        onChange={handleChangeInformationType}
                    >
                        <MenuItem id="information-type-0" value={InformationType.Page}>{getTranslatedText("InformationTypePage")} </MenuItem>
                        <MenuItem id="information-type-1" value={InformationType.Modal}>{getTranslatedText("InformationTypeModal")} </MenuItem>
                    </Select>
                </FormControl>
            }
        </LanguageConsumer>
    )
}