import { SciChartJSLightTheme } from "scichart/Charting/Themes/SciChartJSLightTheme";
import { SciChartJSDarkv2Theme } from "scichart/Charting/Themes/SciChartJSDarkv2Theme";
import { IThemeProvider } from "scichart/Charting/Themes/IThemeProvider";

export const addTheme = (appTheme: string, isClear: boolean = true) => {
    if(appTheme === 'light'){
        let themeLight = { ...new SciChartJSLightTheme(), 
             sciChartBackground: "transparent",
             shadowEffectColor: "rgba(255,255,255,0)",
        } as IThemeProvider;
       
        return themeLight;
    } else {
        let themeDark = {...new SciChartJSDarkv2Theme(),
             sciChartBackground: "transparent",
             shadowEffectColor: "rgba(255,255,255,0)",
         } as IThemeProvider;
        
        return themeDark;
    }
 
}