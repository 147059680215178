import { createSlice, nanoid } from '@reduxjs/toolkit';

export enum HelpCommandType{
    None = "None",
    Started = "Started",
    Finshed = "Finished",
    Aborted = "Aborted"
}

export interface OnlineHelpState {
    id: string,
    stateType: HelpCommandType
}

const initialState: OnlineHelpState = {
    id: '',
    stateType: HelpCommandType.None
};

export const onlineHelpSlice = createSlice({
  name: 'onlineHelp',
  initialState,
  reducers: {
    startOnlineHelp: (state) => {
        state.id = nanoid();
        state.stateType = HelpCommandType.Started;
      },
    setOnlineHelpState: (state, action) => {
        state.stateType = action.payload;
      },
  }
});

export const { startOnlineHelp, setOnlineHelpState } = onlineHelpSlice.actions;

export default onlineHelpSlice.reducer;
