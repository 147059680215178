import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = (props) => {

    return [
        // Navigation - PAGES
        {     
            selector: '.onlinehelp-TimeAngleSync-intro',      
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("TimeAngleSyncOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("TimeAngleSyncOnlineHelpIntroMessage") }}></div></div>),
           
        },
        {
            selector: '.onlinehelp-timeAngleSync-timeTextField',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("TimeAngleSyncOnlineHelpTimeTextFieldTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("TimeAngleSyncOnlineHelpTimeTextFieldMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-timeAngleSync-timeSyncInput',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("TimeSyncOnlineHelpTimeSyncInputTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("TimeSyncOnlineHelpTimeSyncInputMessage") }}></div></div>),
        },
    
        {
            selector: '.onlinehelp-timeAngleSync-playCircleIcon',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("TimeAngleSyncOnlineHelpPlayCircleIconTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("TimeAngleSyncOnlineHelpPlayCircleIconMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-timeAngleSync-slider',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("TimeAngleSyncOnlineHelpSliderTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("TimeAngleSyncOnlineHelpSliderMessage") }}></div></div>),
        },
      

    ]

}



