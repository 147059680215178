import { encode } from "base-64";
import { CockpitDeviceAndGroupsResponse } from "./DeviceAndGroupsResponse";
import { DevicesAndGroupsRequest } from "./DevicesAndGroupsRequest";

const simpleFetchCall = async (cockpitRequest: DevicesAndGroupsRequest) => {    
    var cockpitResponse = await fetch('http://' + cockpitRequest.cockpitUrl + ":8089/" + cockpitRequest.cockpitArea + "/api/v1.0/graphviewer/devices", {              
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Basic ' + encode(cockpitRequest.username + ":" + cockpitRequest.password),
            'Content-Type': 'application/json'
        }),
    });        
    
    return cockpitResponse.json();
}

const callCockpitApi = async (cockpitRequest: DevicesAndGroupsRequest) => {
    const htmlContent = await simpleFetchCall(cockpitRequest);

    return {cockpitDevicesAndGroups: htmlContent} as CockpitDeviceAndGroupsResponse;
}

export const getAvailableDeviesAndGroups = async (cockpitRequest: DevicesAndGroupsRequest): Promise<CockpitDeviceAndGroupsResponse> => {
    try {

        var jsonResponse = await callCockpitApi(cockpitRequest);

        return new Promise<any>((resolve, rejected) => {
            if (jsonResponse) {                
                resolve(jsonResponse)
            }
        });

    } catch (error: any) {
        return new Promise<any>((resolve, rejected) => {
            rejected(error.response && error.response.data ? error.response.data.MessageText : error);
        }
        );

    }
}