import { createSlice} from '@reduxjs/toolkit';

export interface TimeSynchronisationSeriesItem {
    chartSeriesTitle: string,
    currentValue: string;
}

export interface TimeSynchronisation {
    timeSychonisationsSeriesItems: TimeSynchronisationSeriesItem[]
}

const initialState : TimeSynchronisation = {
    timeSychonisationsSeriesItems: [] as TimeSynchronisationSeriesItem[]
}

export const timeSynchronisationSlice = createSlice({
    name: "timeSynchronisation",
    initialState,
    reducers: {
        updateTimeSynchronisationInLegend: (state, action) => {    
            const foundSeries = state.timeSychonisationsSeriesItems.findIndex(x=>x.chartSeriesTitle === action.payload.title);
            if (foundSeries === -1){
                state.timeSychonisationsSeriesItems.push({chartSeriesTitle: action.payload.title, currentValue: action.payload.currentValue})
            }
            else{
                state.timeSychonisationsSeriesItems[foundSeries].chartSeriesTitle = action.payload.title;
                state.timeSychonisationsSeriesItems[foundSeries].currentValue = action.payload.currentValue;                            
            }
        }        
    }
});


export const { updateTimeSynchronisationInLegend } = timeSynchronisationSlice.actions;

export default timeSynchronisationSlice.reducer;
