/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { addTheme } from "../SciChartHelper/Theme";
import { shadeColor } from "../Style/ColorChangeProvider";

interface UseAppThemeArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleSwitchAppTheme = ({ sciChart }: UseAppThemeArgs) => {
    const appTheme = useAppSelector(store => store.settings.appTheme);
    const seriesData = useAppSelector(selectSeries);
    const seriesColors = useAppSelector(store => store.chartSeriesColors.colors);
    
    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            const theme = addTheme(appTheme);
            sciChart.sciChartSurface.applyTheme(theme);

            if (sciChart.sciChartSurface.renderableSeries && sciChart.sciChartSurface.renderableSeries.asArray().length > 0) {
                sciChart.sciChartSurface.renderableSeries.asArray().forEach(serie => {
                    let seriesName = serie.getDataSeriesName();
                    var filteredStoredColor = seriesColors.filter(color => color.title === seriesName);
                    var originalColor = seriesData && seriesData.length > 0 ? seriesData[0].ChartSeries.filter(data => data.Title === seriesName)[0].Color : '#32992c';
                    serie.stroke = filteredStoredColor.length > 0 ? filteredStoredColor[0].color : (appTheme === 'dark' ? shadeColor(originalColor, 40) : originalColor)
                })
            }
        }
    }, [appTheme])
}