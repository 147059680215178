import { IconButton, Tooltip } from "@mui/material";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { OnlineHelpIconButtonProp } from "./OnlineHelpIconButtonProps";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { useAppSelector } from "../../Redux/hooks";

export const OnlineHelpIconButton = (props: OnlineHelpIconButtonProp) => {

  const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
  const size = 'small';

  const title = props.title;
  const toggle = props.isOnlineHelpEnabled;
  const onClickFunction = props.onClickFunction;

  const iconToggle = <ContactSupportIcon fontSize={size} />;
  const icon = <ContactSupportIcon fontSize={size} />;

  return (
    <LanguageConsumer>

      {({ getTranslatedText }) =>
       
          <Tooltip title={getTranslatedText(title)} sx={{ display: { xs: 'none', md: 'flex' } }}>
           {props.isInSettings ? 
              <IconButton
                size={size}
                color="success"
                onClick={onClickFunction}
                sx={{
                  color: toggle !== null ? (!toggle ? '#444' : '#32992c') : '#444',
                  background: '#fff'
                }}
              >
                {toggle !== null ?
                  (!toggle ? icon : iconToggle)
                  : icon}
              </IconButton>
                :
              <IconButton
                size={size}
                color="success"
                onClick={onClickFunction}
                sx={{
                  color: toggle !== null ? (!toggle ? (isDarkMode ? '#c2c0c0' : '#757474') : '#32992c') : (isDarkMode ? '#c2c0c0' : '#757474'),
                  background: isDarkMode ? '#424242' : '#DFE7FD'
                }}
              >
                {toggle !== null ?
                  (!toggle ? icon : iconToggle)
                  : icon}
              </IconButton>}
         
          </Tooltip>
       
      }

    </LanguageConsumer>
  );
}