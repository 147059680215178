/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { FeedbackEvents } from "../Model/Categories/FeedbackEnums";
import { reset_analyze_SendFeedback } from "../Redux/AnalyticsSlice";

const PublishFeedbackAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();
  
    const sendFeedbackState = useAppSelector(store => store.analytics.sendFeedbackState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackSendFeedback = TrackEvent(FeedbackEvents.Send.toString(), {})

    useEffect(() => {
        if (sendFeedbackState.toggle) {
            try{
                trackSendFeedback({})
            }
            catch{}
            dispatch(reset_analyze_SendFeedback())
        }
    }, [sendFeedbackState.toggle])

    return (
        <></>
    )
}

export default PublishFeedbackAnalytics;