import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../Api/BaseApi';


export interface DeveloperState {
  status: 'idle' | 'loading' | 'failed';
  isDeveloper: boolean,
  password: string,
}

const developerState: DeveloperState = {
    status: 'idle',
    isDeveloper: false,
    password: "",
};

export const postDeveloperPassword = createAsyncThunk(
    'developer/post',
    async (password: string) => {
        var response = await fetch(baseUrl + "developer", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(
                {
                    Password: password
                }),
        });        
        
      var jsonResponse = await response.json();
      return jsonResponse;
    }
  );

export const developerSlice = createSlice({
  name: 'developer',
  initialState: developerState,
  reducers: {
    setDeveloperPassword: (state, action) => {
        state.password = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postDeveloperPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postDeveloperPassword.fulfilled, (state, action) => {
        state.status = 'idle';
        if(action.payload.success){
            state.isDeveloper = true;
        } else {
            state.isDeveloper = false;
        }
      })
      .addCase(postDeveloperPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.isDeveloper = false;
        state.password = "";
      });
  },
});

export const { setDeveloperPassword } = developerSlice.actions;

export default developerSlice.reducer;
