import React from 'react';
import { Button } from "@mui/material"
import { useAppDispatch, useAppSelector } from '../../Infrastructure/Redux/hooks';
import './Style/LoadingIndicator.css';
import { LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';
import { resetChartRequest } from '../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice';
import { analyze_CancelLoadFiles } from '../../Infrastructure/Analytics/Redux/AnalyticsSlice';
import { resetChartResult } from '../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { useLocation } from 'react-router-dom'

const LoadingIndicator = () => {
    const location = useLocation();
    const isLoadFromControllerEnabled = useAppSelector(store => store.loadAvailableFilesFromController.updateActual.isEnabled);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const abortControllerForGraphViewerBackend = useAppSelector(store => store.graphViewerBackend.abortController);
    const dispatch = useAppDispatch();

    const handleAbortClick = () => {
        dispatch(resetChartRequest());
        dispatch(resetChartResult());
        dispatch(analyze_CancelLoadFiles());
        abortControllerForGraphViewerBackend?.abort();
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>
                    {(!isLoadFromControllerEnabled || (chartRequest && chartRequest.Files.length > 0 && !chartRequest.Files[0].Title.includes("actual"))) && location.pathname === '/' &&
                        <>
                            <div className="loading-container"></div>
                            <div className="whiteBox">
                                {/* <LordIcon type="load" size={160} /> */}
                              
                                    <img alt="logo" style={{ width: "300px", padding: "10px", marginTop: "50px"}} src="JSON/Logo_Animation_compressed.gif" />
                              
                                <Button onClick={handleAbortClick}
                                    variant="text"
                                    color="secondary"
                                    sx={{ mx: 1, flexGrow: 1 }}>
                                    {getTranslatedText("Cancel")}
                                </Button>
                                <div>
                                </div>
                            </div>
                        </>}
                </React.Fragment>
            }</LanguageConsumer >
    );
}

export default LoadingIndicator;