/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { MonitorAnnotation } from "../../Annotations/Annotation";
import { removeAllMonitors, updateOrCreateMonitor } from "../../Annotations/Redux/AnnotationMonitorSlice";
import { ChartSeries } from "../Models/ChartSeries";
import { SingleVisualizationModeDto } from "../Models/SingleVisualizationModeDto";
import { SuperVisualizationModeDto } from "../Models/SuperVisualizationModeDto";

interface UseHandleIsCurveAnimationEnabledArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleIsCurveAnimationEnabled = ({ sciChart }: UseHandleIsCurveAnimationEnabledArgs) => {
    const dispatch = useAppDispatch();

    const seriesData = useAppSelector(selectSeries);
    const isCurveAnimationEnabled = useAppSelector(store => store.settings.isCurveAnimationEnabled);
    const isCurveSuperVisualisationAngle = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Angle ||
        store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Torque);
    const isCurveSuperVisualisationTime = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Time);
    const isCurveVisualisationAngle = useAppSelector(store => store.settings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Angle ||
        store.settings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Torque);
    const seriesColors = useAppSelector(store => store.chartSeriesColors.colors);
    const seriesVisibilities = useAppSelector(store => store.chartSeriesVisibility.seriesVisibilities);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface && isCurveVisualisationAngle) {
            if (isCurveAnimationEnabled) {
                if ((isCurveVisualisationAngle || isCurveSuperVisualisationAngle) && !isCurveSuperVisualisationTime) {
                    if (seriesData && seriesData.length > 0) {
                        seriesData[0].ChartSeries.forEach(series => {
                            enableCurveAnimation(series)
                        })
                    }
                }
            }
            else {
                dispatch(removeAllMonitors());
            }
        }
    }, [isCurveAnimationEnabled, seriesColors, seriesVisibilities])

    const enableCurveAnimation = (series : ChartSeries) => {

        let visible = series.Visible;
        let foundVisible = seriesVisibilities.find(x => x.title === series.Title)
        if (foundVisible) {
            visible = foundVisible.visible;
        }
        let color = series.Color;
        let foundColor = seriesColors.find(x => x.title === series.Title)
        if (foundColor) {
            color = foundColor.color;
        }
        dispatch(updateOrCreateMonitor({
            color: color,
            visible: visible,
            x1: series.XValues[0],
            y1: series.YValues[0],
            name: series.Title
        } as MonitorAnnotation))
    }
}