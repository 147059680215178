import { licenseBaseUrl } from "../../../../Api/BaseApi";
import { LicenceOfferRequest } from "../Models/LicenceOfferRequest";
import { LicenceOfferResponse } from "../Models/LicenceOfferResponse";


const simpleFetchCall = async (request: LicenceOfferRequest) => {
    var response = await fetch(licenseBaseUrl + 'licensing/request-for-offer', {
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(
            {
                CustomerName: request.CustomerName,
                CustomerLastName: request.CustomerLastName,
                CustomerCompany: request.CustomerCompany,
                CustomerEmail: request.CustomerEmail,
                LicenceTypeId: request.LicenceTypeId,
                CustomerNumber: request.CustomerNumber,
                CustomerPhone: request.CustomerPhone,
                CustomerCountry: request.CustomerCountry,
                CustomerAddress: request.CustomerAddress,
                CustomerAddressNumber: request.CustomerAddressNumber,
                LicenceCount: request.LicenceCount,
                Language: request.Language
            }),
        method: 'POST',
    });
    return response.json();
}

const getResponse = async (request: LicenceOfferRequest) => {
    const response = await simpleFetchCall(request);
    return response as LicenceOfferResponse;
}

export const offerLicence = async (request: LicenceOfferRequest): Promise<LicenceOfferResponse> => {
    try {

        var response = await getResponse(request);

        return new Promise<any>((resolve, reject) => {
            if (response && response.success) {
                resolve(response)
            }
            else {
                reject(response.errorMessage)
            }
        });

    } catch (error: any) {
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);
    }
}