import { getNavigationSteps } from "./FilterOnlineHelpNavigationProvider";
import Tour from 'reactour';
import { Button } from "@mui/material";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { useState } from "react";

export const FilterOnlineHelp = (props) => {

    const [currentStep, setCurrentStep] = useState<number>(0);

    return(
        <LanguageConsumer>

            {({ getTranslatedText }) =>
                <Tour
                    disableFocusLock
                    getCurrentStep={(curr: number) => {
                        setCurrentStep(curr)
                    }}
                    startAt={0}
                    disableInteraction={true}
                    showNavigation={false}
                    showNumber={false}
                    showButtons={true}
                    closeWithMask={true}
                    accentColor={'#32992c'}
                    steps={getNavigationSteps()}
                    isOpen={props.showOnlineHelp}
                    onRequestClose={() => {
                        setCurrentStep(0)
                        props.closeOnlineHelp();
                    }}
                    rounded={13}
                    prevButton={currentStep !== 0 ? <Button component="a" fullWidth variant="outlined"  color='inherit' title={getTranslatedText("OnlineHelpPrev")}>{getTranslatedText("OnlineHelpPrev")}</Button> : <div></div>}
                    nextButton={<Button component="a" variant="outlined"  fullWidth color='success' title={getTranslatedText("OnlineHelpNext")}>{getTranslatedText("OnlineHelpNext")}</Button>}
                    lastStepNextButton={<Button component="a" variant="outlined" onClick={() =>{ props.closeOnlineHelp()} } color='success' title={getTranslatedText("OnlineHelpFinish")}>{getTranslatedText("OnlineHelpFinish")}</Button>} 
                    />}
        </LanguageConsumer>
    );
}