import { Button, Drawer, Grid, Typography, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { resetColorPicker } from "../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { SketchPicker } from 'react-color';
import { RootState } from "../../Infrastructure/Redux/store";
import { resetSeriesColorToStandard, setOrUpdateColor } from "../../Infrastructure/ChartSeriesColors/Redux/ChartSeriesColorsSlice";

import { ColorPickerOption } from "./ColorPickerOption";
import { resetDistinctiveValueColorToStandard, setOrUpdateDistinctiveValueColor } from "../../Infrastructure/DistinctiveValueColors/Redux/DistinctiveValueColorsSlice";
import { updateAnnotationColor } from "../Annotations/Redux/AnnotationMonitorSlice";
import { MonitorAnnotation } from "../Annotations/Annotation";
import { resetUserAnnotationColorAsHex, setUserAnnotationColorAsHex } from "../Settings/Redux/SettingsSlice";
import { usePersistedChartSeriesColors } from "../../Infrastructure/PersistedChartSeriesColors/Hooks/usePersistedChartSeriesColors";

export const ColorPicker = () => {
    const isColorPickerActive = useAppSelector((store: RootState) => store.chartModifier.colorPicker.isShown);
    const colorPicker = useAppSelector((store: RootState) => store.chartModifier.colorPicker);
    const [currentColor, setCurrentColor] = useState<string>("");
    const { setPersistedChartSeriesColor, removePersistedChartSeriesColor } = usePersistedChartSeriesColors();

    const dispatch = useAppDispatch();

    useEffect(() => {
        setCurrentColor(colorPicker.color)
    }, [colorPicker])

    const toggleDrawer =
        () =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                dispatch(resetColorPicker());
            };

    const handleChangeComplete = (color: any) => {
        setCurrentColor(color.hex);
    };

    const setSeriesColor = () => {
        if (colorPicker.option === ColorPickerOption.seriesLegend) {
            let colorToUpdate = { title: colorPicker.title, color: currentColor };
            dispatch(setOrUpdateColor(colorToUpdate));            
            setPersistedChartSeriesColor(colorToUpdate)
        }
        else if (colorPicker.option === ColorPickerOption.distinctiveValues) {
            dispatch(setOrUpdateDistinctiveValueColor({ title: colorPicker.title, color: currentColor, stepNumber: colorPicker.stepNumber, seriesIndex: colorPicker.seriesIndex }));
        }
        else if (colorPicker.option === ColorPickerOption.userAnnotations) {
            dispatch(setUserAnnotationColorAsHex(currentColor));
        }

        dispatch(updateAnnotationColor({
            color: currentColor,
            name: colorPicker.title
        } as MonitorAnnotation))

        dispatch(resetColorPicker());
    }

    const resetSeriesColor = () => {
        if (colorPicker.option === ColorPickerOption.seriesLegend) {
            dispatch(resetSeriesColorToStandard(colorPicker.title))
            removePersistedChartSeriesColor({title: colorPicker.title})
        }
        else if (colorPicker.option === ColorPickerOption.distinctiveValues) {
            dispatch(resetDistinctiveValueColorToStandard({ title: colorPicker.title, stepNumber: colorPicker.stepNumber, seriesIndex: colorPicker.seriesIndex }))
        }
        else if (colorPicker.option === ColorPickerOption.userAnnotations) {
            dispatch(resetUserAnnotationColorAsHex());
        }
        dispatch(resetColorPicker());
    }
    
    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if(event.key === "Enter"){
            setSeriesColor();
            event.preventDefault();
        }        
      }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <Drawer
                disableRestoreFocus={true}
                anchor={'right'}
                open={isColorPickerActive}
                onClose={toggleDrawer()}
                onKeyDown={onKeyDown}
                            >
                <div className="onlinehelp-home-sideDrawerViews-colorPicker" style={{ margin: 10, padding: 0 }}>
                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>
                        <Grid item xs={12}>
                            <Tooltip title={getTranslatedText(colorPicker.title)}>
                            <Typography className="onlinehelp-home-sideDrawerViews-colorPicker-titleTypography" variant="h4" component="div" gutterBottom sx={{ fontWeight: "300", maxWidth: 600, overflow: "hidden",  textOverflow: "ellipsis", whiteSpace: "nowrap"  }}>
                                {getTranslatedText("Color")} - {getTranslatedText(colorPicker.title)}
                            </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4 }}>

                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                            <SketchPicker
                                className="onlinehelp-home-sideDrawerViews-colorPicker-sketchPicker"
                                disableAlpha={true}
                                presetColors={[]}
                                width={"400px"}
                                color={currentColor}
                                onChange={handleChangeComplete}
                            />
                        </Grid>

                    </Grid>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 2, pt: 0 }}>
                        <Grid item xs={12} alignContent="center" textAlign={'center'}>
                            <Button className="onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColorToStandard" color="error" variant="outlined" fullWidth size="large" onClick={resetSeriesColor}>
                                {getTranslatedText("SetSeriesColorToStandard")}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pt: 0 }}>
                        <Grid item xs={12} alignContent="center" textAlign={'center'}>
                            <Button type="submit" className="onlinehelp-home-sideDrawerViews-colorPicker-buttonSetSeriesColor" color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={setSeriesColor}>
                                {getTranslatedText("SetSeriesColor")}
                            </Button>
                        </Grid>
                    </Grid>
                </div>

            </Drawer>
        }
    </LanguageConsumer>
    );
}