import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import navigationReducer from '../../Layout/Navigation/Redux/NavigationSlice';
import notificationsReducer from '../../Layout/Notifications/Redux/NotificationsSlice';
import modalReducer from '../../Layout/Modal/Redux/ModalSlice';
import crossLocalStorageReducer from '../LocalStorage/Redux/CrossLocalStorageSlice';
import graphDataReducer from '../ChartResultHandler/Redux/ChartResultSlice';
import chartSeriesReducer from '../ChartSeriesColors/Redux/ChartSeriesColorsSlice';
import graphDataRequestReducer from '../ChartRequestHandler/Redux/ChartRequestSlice';
import loadingIndicatorReducer from '../LoadingIndicator/LoadingIndicatorSlice';
import chartModifierReducer from '../ChartModifier/Redux/ChartModifierSlice';
import settingsReducer, { InformationType } from '../../Features/Settings/Redux/SettingsSlice';
import onlineHelpReducer from '../../Infrastructure/OnlineHelp/Redux/OnlineHelpSlice'
import licenceServiceReducer from '../LicenceService/Redux/LicenceServiceSlice';
import storage from 'redux-persist/lib/storage';
import legendCurrentValueReducer from '../../Features/SeriesLegend/Redux/LegendCurrentValueSlice';
import chartSeriesOrder from '../ChartSeriesOrder/Redux/ChartSeriesOrderSlice';
import loadAvailableFilesFromControllerReducer from "../../Features/LoadFromController/Redux/LoadAvailableFilesFromControllerSlice";
import loadSelectedFilesFromControllerReducer from "../../Features/LoadFromController/Redux/LoadSelectedFilesFromControllerSlice";
import loadFromCockpitReducer from '../../Features/LoadFromCockpit/CurveData/Complete/Redux/LoadFromCockpitCompleteCurveDataSlice';
import timeSynchronisationReducer from '../../Features/TimeSync/Redux/TimeSynchronisationSlice';
import loadFromCockpitFilterReducer from '../../Features/LoadFromCockpit/Filter/Redux/LoadFromCockpitFilterSlice';
import checkCockpitSettingsReducer from '../../Features/LoadFromCockpit/CheckCockpitConnection/Redux/CheckCockpitSettingsSlice';
import loadFromCockpitSimplifiedCurveDataReducer from '../../Features/LoadFromCockpit/CurveData/Simplified/Redux/LoadFromCockpitSimplifiedCurveDataSlice';
import loadFromCockpitDeviceAndGroupsReducer from '../../Features/LoadFromCockpit/DeviceAndGroups/Redux/LoadFromCockpitDeviceAndGroupsSlice';
import languageFileReducer from '../Internationalisation/Redux/LanguageFileSlice';
import advertisingReducer from '../../Layout/Modal/Advertising/Redux/AdvertisingSlice';
import analyticsReducer from '../Analytics/Redux/AnalyticsSlice';
import feedbackReducer from '../../Features/Feedback/Redux/FeedbackSlice';
import popoverReducer from '../../Infrastructure/Popover/Redux/PopoverSlice';
import temporarilyReducer from '../../Infrastructure/Temporarily/Redux/TemporarilySlice';
import customCsvReducer from '../../Features/CustomCsv/Redux/CustomCsvSlice';
import distinctiveValueReducer from '../DistinctiveValueColors/Redux/DistinctiveValueColorsSlice';
import loadFromCockpitDeviceInformationsReducer from '../../Features/LoadFromCockpit/CurveData/Simplified/Redux/LoadFromCockpitDeviceInformationsSlice';
import licenceVerificationReducer from '../../Infrastructure/LicenceService/Verification/Redux/LicenceVerificationSlice';
import defaultSettingsSetterReducer from '../../Infrastructure/DefaultSettingsSetter/Redux/DefaultSettingsSetterSlice';

import {
  persistReducer,
  createMigrate,
} from 'redux-persist';
import setTransformLoadFromController from '../../Features/LoadFromController/SetTransformLoadFromController';
import setTransformLoadFromCockpit from '../../Features/LoadFromCockpit/CurveData/Complete/Redux/SetTransformLoadFromCockpit';
import setTransformLoadFromCockpitLoadSimplifiedCurveData from '../../Features/LoadFromCockpit/CurveData/Simplified/SetTransformLoadFromCockpitLoadSimplifiedCurveData';
import AnnotationUserSlice from '../../Features/Annotations/Redux/AnnotationUserSlice';
import AnnotationStepNumberSlice from '../../Features/Annotations/Redux/AnnotationStepNumberSlice';
import AnnotationTargetAreaSlice from '../../Features/Annotations/Redux/AnnotationTargetAreaSlice';
import AnnotationMonitorSlice from '../../Features/Annotations/Redux/AnnotationMonitorSlice';
import AnnotationDistinctiveValueAreaSlice from '../../Features/Annotations/Redux/AnnotationDistinctiveValueAreaSlice';
import LicenseConsentSlice from '../../Features/LicenseConsent/LicenseConsentSlice';
import graphViewerBackendSlice from '../GraphViewerBackend/GraphViewerBackendSlice';
import licenseModelReducer from '../../Features/LicenseForm/Redux/LicenseFormLicenseModelSlice';
import licenseMasterReducer from '../../Features/Settings/Components/LicenseMasterSettings/Redux/LicenseMasterSettingsSlice';
import licenseMasterInvitationReducer from '../../Features/Settings/Components/LicenseMasterSettings/Redux/LicenseMasterSettingsInvitationSlice';
import developerReducer from '../../Infrastructure/Developer/DeveloperSlice';
import metaDataCompareReducer from '../../Features/MetaDataCompare/Redux/MetaDataCompareSlice';
import persistedChartSeriesColorsReducer from '../PersistedChartSeriesColors/Redux/PersistedChartSeriesColorsSlice';
import persistedChartSeriesOrderReducer from '../PersistedChartSeriesOrder/Redux/PersistedChartSeriesOrderSlice';
import persistedChartSeriesVisibilityReducer from '../PersistedChartSeriesVisibility/Redux/PersistedChartSeriesVisibilitySlice';
import chartSeriesVisibilityReducer from '../ChartSeriesVisibility/Redux/ChartSeriesVisibilitySlice';
import informationModalReducer from '../../Features/Information/Redux/InformationModalSlice';
import shareReducer from '../../Features/Share/Redux/ShareSlice';
import newFeaturesReducer from '../../Features/NewFeatures/Redux/NewFeaturesSlice';


const reducers = combineReducers({
  navigation: navigationReducer,
  crossLocalStorage: crossLocalStorageReducer,
  chartResultData: graphDataReducer,
  chartRequestData: graphDataRequestReducer,
  loadingIndicator: loadingIndicatorReducer,
  chartModifier: chartModifierReducer,
  settings: settingsReducer,
  onlineHelp: onlineHelpReducer,
  notifications: notificationsReducer,
  modal: modalReducer,
  chartSeriesColors: chartSeriesReducer,
  licenceService: licenceServiceReducer,
  customCsv: customCsvReducer,
  legendCurrentValue: legendCurrentValueReducer,
  chartSeriesOrder: chartSeriesOrder,
  loadAvailableFilesFromController: loadAvailableFilesFromControllerReducer,
  timeSynchronization: timeSynchronisationReducer,
  loadFromCockpit: loadFromCockpitReducer,
  loadFromCockpitFilter: loadFromCockpitFilterReducer,
  checkCockpitSettings: checkCockpitSettingsReducer,
  loadFromCockpitDeviceAndGroups: loadFromCockpitDeviceAndGroupsReducer,
  loadFromCockpitSimplifiedCurveDatas: loadFromCockpitSimplifiedCurveDataReducer,
  loadFromCockpitDeviceInformations: loadFromCockpitDeviceInformationsReducer,
  languageFile: languageFileReducer,
  advertising: advertisingReducer,
  feedback: feedbackReducer,
  analytics: analyticsReducer,
  popover: popoverReducer,
  temporarily: temporarilyReducer,
  annotationUser: AnnotationUserSlice,
  annotationStepNumber: AnnotationStepNumberSlice,
  annotationTargetArea: AnnotationTargetAreaSlice,
  annotationLabellingDistinctiveValueArea: AnnotationDistinctiveValueAreaSlice,
  annotationMonitor: AnnotationMonitorSlice,
  distinctiveValueColors: distinctiveValueReducer,
  licenceConsent: LicenseConsentSlice,
  loadSelectedFilesFromController: loadSelectedFilesFromControllerReducer,
  graphViewerBackend: graphViewerBackendSlice,
  licenseModel: licenseModelReducer,
  licenseMaster: licenseMasterReducer,
  licenceVerification: licenceVerificationReducer,
  licenseMasterInvitation: licenseMasterInvitationReducer,
  developer: developerReducer,
  metaDataCompare: metaDataCompareReducer,
  persistedChartSeriesColors: persistedChartSeriesColorsReducer,
  persistedChartSeriesOrder: persistedChartSeriesOrderReducer,  
  persistedChartSeriesVisibility: persistedChartSeriesVisibilityReducer,  
  chartSeriesVisibility: chartSeriesVisibilityReducer,
  defaultSettingsSetter: defaultSettingsSetterReducer,
  informationModal: informationModalReducer,
  share: shareReducer,
  newFeatures: newFeaturesReducer
});

const migrations = {
  1: (state) => {
    return {
      ...state
    }
  },
  2: (state) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        informationType: InformationType.Page
      }
    }
  },
  3: (state) => {
    return {
      ...state,
      annotationUser: {
        ...state.annotationUser,
        keepAnnotations: false
      }
    }
  },
  4: (state) => {
    return {
      ...state,
      licenceService: {
        ...state.licenceService,
        licenceAuthentication: {
          ...state.licenceService.licenceAuthentication,
          isMyDepragApiKey: ''
        }
      }
    }
  }
}

const persistConfig = {
  key: 'root',
  version: 4,
  storage,
  whitelist: ['settings', 'persistedChartSeriesColors', 'persistedChartSeriesVisibility', 'licenceService', 'persistedChartSeriesOrder', 'loadAvailableFilesFromController', 'loadFromCockpit', 'loadFromCockpitFilter', 'feedback', 'popover', 'temporarily', 
    'annotationUser.colorAsHex', 'annotationStepNumber.stepNumberVisibility', 'annotationTargetArea.targetAreaVisibility'],
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [
    setTransformLoadFromController,
    setTransformLoadFromCockpit,
    setTransformLoadFromCockpitLoadSimplifiedCurveData,
  ]
};

const persistedReducer = persistReducer(persistConfig, reducers) as typeof reducers

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //  serializableCheck: {
      //    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //  }
      serializableCheck: false,
      immutableCheck: false
    }),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //  serializableCheck: {
      //    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //  }
      serializableCheck: false,
      immutableCheck: false
    }),  
    preloadedState
  })
}


export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
