/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, FormControlLabel, Grid, LinearProgress, List, ListItem, ListItemButton, ListItemText, ListSubheader, TextField, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toggleResetChart } from "../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { saveSeriesRequestsInStore } from "../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { getSeriesForGraphDataAsync } from "../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { getTranslation, LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { loadAvailableFilesFromController, resetLoadFromControllerVisibility, addActiveFileUrl, removeActiveFileUrl, setLoadFromControllerIpAddress, clearActiveFileUrl, showHelpModal, hideHelpDialog, toggleUpdateEnabled, setUpdateInterval } from "./Redux/LoadAvailableFilesFromControllerSlice";
import { resetLoadingIndicator } from "../../Infrastructure/LoadingIndicator/LoadingIndicatorSlice";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { checkIfAnyFileIsCustom } from "../../Layout/Navigation/Views/Tools";
import { setNotification, NotificationType } from "../../Layout/Notifications/Redux/NotificationsSlice";
import { HelpDialog } from "./HelpDialog/HelpDialog";
import './Style/LoadFromController.css';
import { analyze_LoadFiles, analyze_ShowOnlineHelp } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LoadFilesItems } from "../../Infrastructure/Analytics/Model/Categories/LoadFilesEnums";
import { isIPAddress } from "./IpCheckProvider";
import { loadSelectedFilesFromController } from "./Redux/LoadSelectedFilesFromControllerSlice";
import { convertFileListToPreparedGraphDataForBackendCall } from "../../Infrastructure/FileListConverter/FileListConverter";
import { OnlineHelpItems } from "../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { LoadFromControllerOnlineHelp } from "./LoadFromControllerOnlineHelp";
import { OnlineHelpIconButton } from "../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { SuperVisualizationModeDto } from "../Home/Models/SuperVisualizationModeDto";

export const LoadFromController = () => {

    const dispatch = useAppDispatch();
    const availableFilesFromController = useAppSelector(store => store.loadAvailableFilesFromController);
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const languageIdentifier = useAppSelector(store => store.settings.language);
    const [intervalError, setIntervalError] = useState<boolean>(availableFilesFromController.updateActual.interval < 3000 || availableFilesFromController.updateActual.interval > 30000);
    const [loadAvailableFilesAbortController, setLoadAvailableFilesAbortController] = useState<AbortController>();
    const [loadSelectedFilesAbortController, setOpenDataAbortController] = useState<AbortController>();
    const [ipNotValid, setIpNotValid] = useState<boolean>(false);
    const [onlineHelpVisible, setOnlineHelpVisible] = useState<boolean>();
    const selectedFilesFromController = useAppSelector(store => store.loadSelectedFilesFromController);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);

    const openLoadFromControllerOnlineHelp = () => {
        setOnlineHelpVisible(true)
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.LoadFromCockpitOnlineHelp }))
    }

    const closeOnlineHelp = () => {
        setOnlineHelpVisible(false)
    }

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        fileUrl: string,
    ) => {
        if (availableFilesFromController.activeFileUrls.includes(fileUrl)) {
            dispatch(removeActiveFileUrl(fileUrl));
        } else {
            dispatch(addActiveFileUrl(fileUrl));
        }
    };

    const toggleDrawer =
        () =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                dispatch(resetLoadFromControllerVisibility());
                setOnlineHelpVisible(false);
            };

    const convertRecivedFiles = async () => {

        try {
            var listOfConvertedFiles = await convertFileListToPreparedGraphDataForBackendCall(
                selectedFilesFromController.recivedFiles,
                chartRequestSettings,
                languageIdentifier,
            );

            if (listOfConvertedFiles.Files.length <= 0)
                return

            if (chartRequest && chartRequest.Files &&
                listOfConvertedFiles.Files.length === 1 &&
                chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None) {
                listOfConvertedFiles.Files.push(...chartRequest?.Files);
            }

            if ((listOfConvertedFiles.Files.length > 1 && checkIfAnyFileIsCustom(listOfConvertedFiles.Files))) {
                dispatch(setNotification({ type: NotificationType.info, text: getTranslation("NoSuperVisualisationForCustomFiles") }))
                dispatch(resetLoadingIndicator())
            } else {
                dispatch(saveSeriesRequestsInStore(listOfConvertedFiles))
                dispatch(getSeriesForGraphDataAsync(listOfConvertedFiles))
                dispatch(analyze_LoadFiles({ source: LoadFilesItems.FromController, nrOfFiles: listOfConvertedFiles.Files.length }))
            }
            dispatch(toggleResetChart());
            dispatch(resetLoadFromControllerVisibility());
        }
        catch (ex) {
            if (typeof ex === "string")
                dispatch(setNotification({ type: NotificationType.error, text: getTranslation(ex) }))
        }
    }

    useEffect(() => {
        if (availableFilesFromController.updateActual.isEnabled && (availableFilesFromController.activeFileUrls.length > 1 || (availableFilesFromController.activeFileUrls.length === 1 && !availableFilesFromController.activeFileUrls[0].includes("actual")))) {
            dispatch(toggleUpdateEnabled());
        }
    }, [dispatch, availableFilesFromController.activeFileUrls, availableFilesFromController.updateActual.isEnabled])

    useEffect(() => {
        setIpNotValid(!isIPAddress(availableFilesFromController.ipAddress))
    }, [availableFilesFromController.ipAddress])

    useEffect(() => {

        if (!loadAvailableFilesAbortController)
            return;

        setIpNotValid(false);
        dispatch(loadAvailableFilesFromController({ ipAddress: availableFilesFromController.ipAddress, hardwareType: availableFilesFromController.hardwareType, controller: loadAvailableFilesAbortController }))
        dispatch(clearActiveFileUrl());

    }, [loadAvailableFilesAbortController])

    useEffect(() => {

        if (!loadSelectedFilesAbortController)
            return;

        dispatch(loadSelectedFilesFromController({ urls: availableFilesFromController.activeFileUrls, abortController: loadSelectedFilesAbortController }))

    }, [loadSelectedFilesAbortController])

    useEffect(() => {
        if (selectedFilesFromController?.recivedFiles.length <= 0 || loadSelectedFilesAbortController?.signal.aborted)
            return

        convertRecivedFiles();

    }, [selectedFilesFromController.recivedFiles])

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <HelpDialog />

                <Dialog
                    open={availableFilesFromController.showHelp}
                    onClose={() => dispatch(hideHelpDialog())}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {getTranslatedText("YouHavetoAdjustYourBrowserSettings")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getTranslatedText("YouHavetoAdjustYourBrowserSettingsBody")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="dark" variant="outlined" onClick={() => {
                            dispatch(hideHelpDialog())
                        }}>{getTranslatedText("NoThankYou")}</Button>
                        <Button color="success" variant="outlined" onClick={() => {
                            dispatch(hideHelpDialog())
                            dispatch(showHelpModal())
                        }} autoFocus>
                            {getTranslatedText("ShowHelpModal")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <LoadFromControllerOnlineHelp
                    showOnlineHelp={onlineHelpVisible}
                    closeOnlineHelp={closeOnlineHelp}
                />

                <Drawer
                    className="onlinehelp-home-load-from-controller"
                    anchor={'right'}
                    open={availableFilesFromController.isShown}
                    onClose={toggleDrawer()}
                    PaperProps={{
                        sx: {
                            maxWidth: 750,
                        }
                    }}>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }}>

                        <Grid item xs={11}>
                            <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: "300" }}>
                                {getTranslatedText("LoadFromControllerTitle")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <OnlineHelpIconButton
                                isInSettings={false}
                                title={"OnlineHelp"}
                                isOnlineHelpEnabled={false}
                                onClickFunction={() => {
                                    openLoadFromControllerOnlineHelp()
                                }} />
                        </Grid>
                    </Grid>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0, pt: 0 }}>
                        <Grid item xs={12} sx={{ mb: 0, pb: 0 }}>
                            <Typography variant="h6" component="div" gutterBottom sx={{ fontWeight: "200", p: 0, m: 0 }}>
                                {getTranslatedText("SupportedControllers")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mt={0} pt={0} sx={{ paddingTop: 0 }}>
                            <Chip label={"AST12"}></Chip>
                        </Grid>
                    </Grid>


                    <Grid
                        container
                        direction={'row'}
                        spacing={2}
                        sx={{ mt: 2, p: 4, pb: 2, pt: 0, minWidth: "700px" }}>
                        <Grid className="onlinehelp-home-load-from-controller-select-ip-address" item xs={12} alignContent="center" textAlign={'center'}>
                            <FormControl fullWidth>
                                <TextField error={ipNotValid} helperText={ipNotValid ? getTranslatedText("IpNotValid") : ""} autoFocus fullWidth id="outlined-basic" value={availableFilesFromController.ipAddress} label={getTranslatedText("IpAdress")} variant="outlined" color="success" onChange={(e) => dispatch(setLoadFromControllerIpAddress(e.target.value))} />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} direction={'row'} sx={{ p: 4, pb: 0, pt: 0 }}>
                        {availableFilesFromController.status !== 'loading' &&
                            <Grid
                                className="onlinehelp-home-load-from-controller-load-available-files"
                                item xs={12}
                                alignContent="center"
                                textAlign={'center'}>
                                <Button disabled={ipNotValid} color="info" variant="outlined" fullWidth size="large" onClick={() => {
                                    setLoadAvailableFilesAbortController(new AbortController());
                                }}>
                                    {getTranslatedText("LoadCurveFilesFromController")}
                                </Button>
                            </Grid>}
                        {availableFilesFromController.status === 'loading' &&
                            <Grid item xs={12} alignContent="center" textAlign={'center'}>
                                <Button color="info" variant="outlined" fullWidth size="large" onClick={() => {
                                    loadAvailableFilesAbortController?.abort();
                                }}>
                                    {getTranslatedText("Cancel")}
                                </Button>
                            </Grid>}
                        {availableFilesFromController.status === 'loading' && <Grid item xs={12}>
                            <Box component={"div"} sx={{ width: '100%' }}>
                                <LinearProgress color="success" />
                            </Box>
                        </Grid>}
                    </Grid>

                    <Grid
                        container
                        direction={'row'}
                        spacing={2}
                        sx={{ mt: 0, p: 4, pb: 2, pt: 0, minWidth: "700px" }}>
                        <Grid
                            item xs={12}>
                            <div
                                className="onlinehelp-home-load-from-controller-select-available-files"
                                style={{ maxHeight: "45vh", overflowX: "hidden" }}>
                                <List dense={true}>
                                    <ListSubheader inset={false} color="inherit">
                                        {getTranslatedText("AvailableFilesFromController")}
                                    </ListSubheader>
                                    {availableFilesFromController.status === 'idle' && availableFilesFromController.availableFiles.length > 0 ? availableFilesFromController.availableFiles.map((file, fileKey) => {
                                        return (
                                            <ListItem
                                                key={fileKey}>
                                                <ListItemButton
                                                    selected={availableFilesFromController.activeFileUrls.includes(file)}
                                                    onClick={(event) => handleListItemClick(event, fileKey, file)}
                                                    classes={{ selected: "depraggreen-bg" }}>
                                                    <ListItemText
                                                        primary={file.split("=")[1]}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })
                                        :
                                        <div>
                                            <ListItem>
                                                <Skeleton variant="rectangular" width={"100%"} height={30} />
                                            </ListItem>
                                            <ListItem>
                                                <Skeleton variant="rectangular" width={"100%"} height={30} />
                                            </ListItem>
                                            <ListItem>
                                                <Skeleton variant="rectangular" width={"100%"} height={30} />
                                            </ListItem>
                                        </div>
                                    }
                                </List>
                            </div>
                        </Grid>

                        {((availableFilesFromController.activeFileUrls.length === 1 && availableFilesFromController.activeFileUrls[0].includes("actual")) || onlineHelpVisible) &&
                            <>
                                <Grid
                                    container
                                    sx={{ p: 4, pr: 0, pb: 2, pt: 2 }}
                                    className="onlinehelp-home-load-from-controller-update-interval">
                                    <Grid
                                        item xs={6} my="auto">
                                        <FormControlLabel control={<Checkbox color="success" checked={availableFilesFromController.updateActual.isEnabled} onChange={() => dispatch(toggleUpdateEnabled())} />} label={getTranslatedText("AutomaticallyUpdateCurveFile")} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField error={intervalError} helperText={intervalError ? getTranslatedText("ValueMustBeBetween3000And30000") : ''} value={availableFilesFromController.updateActual.interval} type={'number'} onChange={(e) => {
                                            var intervalParsed = parseInt(e.target.value) || 0;

                                            setIntervalError(intervalParsed < 3000 || intervalParsed > 30000);
                                            dispatch(setUpdateInterval(parseInt(e.target.value)))
                                        }} fullWidth id="outlined-basic" color="success" label={getTranslatedText("UpdateIntervall")} variant="outlined" />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>

                    <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 2, pt: 0 }}>
                        <Grid
                            className="onlinehelp-home-load-from-controller-load-selected-files"
                            item xs={12}
                            alignContent="center"
                            textAlign={'center'}>
                            <Button disabled={(
                                availableFilesFromController.activeFileUrls.length === 1 &&
                                availableFilesFromController.activeFileUrls[0].includes("actual") &&
                                intervalError) ||
                                availableFilesFromController.activeFileUrls?.length === 0
                            }
                                color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large"
                                onClick={() => { setOpenDataAbortController(new AbortController()) }}>
                                {getTranslatedText("OpenLoadedFile")}
                            </Button>
                        </Grid>
                    </Grid>

                    {
                        selectedFilesFromController.status === 'loading' && <Grid container direction={'row'} spacing={2} sx={{ p: 4, pt: 0 }}>
                            <Grid item xs={12} alignContent="center" textAlign={'center'}>
                                <Box component={"div"} sx={{ width: '100%' }}>
                                    <LinearProgress color="success" />
                                </Box>
                                <Button
                                    sx={{ mt: 1, mr: 1 }}
                                    color="info"
                                    variant="outlined"
                                    fullWidth size="large"
                                    onClick={() => {
                                        if (loadSelectedFilesAbortController) {
                                            loadSelectedFilesAbortController?.abort();
                                        }
                                    }}>
                                    {getTranslatedText("Cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    }

                </Drawer>
            </>
        }
    </LanguageConsumer>
    );
}