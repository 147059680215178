import { baseUrl } from "../../Infrastructure/Api/BaseApi";
import { SeriesOrder } from "../../Infrastructure/ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { ChartRequest } from "../Home/Models/ChartRequest";
import { getRegionIdentifier } from "../Home/SciChartApi";

export const downloadFinalValuesFile = async (chartRequest: ChartRequest) : Promise<any> => {
    var requestPayload = {
        Files: chartRequest.Files,
        Settings: chartRequest.Settings,
        SortedList: [] as SeriesOrder[],
        LanguageIdentifier: chartRequest.LanguageIdentifier
    }
    await downloadFinalValues(requestPayload);        
}

const downloadFinalValues = async (request: ChartRequest) : Promise<any> => {
    var regionIdentifier = getRegionIdentifier(request);

    const url = baseUrl + 'chart/finalvalues';
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
          },
        body: JSON.stringify({...request, RegionIdentifier: regionIdentifier})
    });
  
    let finalValues = await response.blob();

    console.log(finalValues);

    let href = URL.createObjectURL(finalValues);

    let link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "finalvalues-all.csv")

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}