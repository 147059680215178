export const SupportedFileFormats = () :string[] => {
    return [
        ".bin",
        ".BIN",
        ".ast",
        ".ast10",
        ".dfe",
        ".rff",
        "RFF-Datei",
        ".d-graph",
        "d-graph",
        "D-GRAPH-Datei",
        ".csv",
        ".gvc"
    ];
}

export const SupportedFileFormatsDropzone2 = () => {
    return { 'application/octet-stream' : [
        ".bin",
        ".BIN",
        ".ast",
        ".ast10",
        ".dfe",
        ".rff",
        ".csv",
        ".d-graph",
        ".dgraph",
        ".gvc"
    ]};
}

export const SupportedFileFormatsDropzone = () => {
    return { '' : []};
}