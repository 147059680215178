import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { licenceActivation } from "../../LicenceService/Activation/Redux/LicenceActivationServiceSlice"
import { AnnotationItems } from "../Model/Categories/AnnotationEnums"
import { FooterItems } from "../Model/Categories/FooterEnums"
import { LoadFilesItems } from "../Model/Categories/LoadFilesEnums"
import { OnlineHelpItems } from "../Model/Categories/OnlineHelpEnums"
import { DefaultSettingsItems } from "../Model/Categories/SettingsEnums"
import { EditShareItems, ExportShareItems } from "../Model/Categories/ShareEnums"
import { ZoomChartItems } from "../Model/Categories/ZoomChartEnums"
import { AddAnnotationsData, ApplyFilterData, CancelLoadFilesData, ChangeDefaultSettingsData, DeleteAnnotationsData, EditSharePictureData, ExpandViewData, ExportSharePictureData, LicenseActivationData, LoadFilesData, OpenFooterData, ResetFilterData, SendFeebackData, ShowOnlineHelpData, StartCurveSupervisionCurveControlData, ZoomChartData } from "../Model/Datatypes"

export interface AnalyticsState {
    changeDefaultSettingsState: ChangeDefaultSettingsData,
    loadFilesState: LoadFilesData,
    zoomchartState: ZoomChartData,
    addAnnotationsState: AddAnnotationsData,
    deleteAnnotationsState: DeleteAnnotationsData,
    applyFilterState: ApplyFilterData,
    resetFilterState: ResetFilterData,
    startCurveSupervisionCurveControlState: StartCurveSupervisionCurveControlData,
    editSharePictureState: EditSharePictureData,
    exportSharePictureState: ExportSharePictureData,
    expandViewState: ExpandViewData,
    showOnlineHelpState: ShowOnlineHelpData,
    openFooterState: OpenFooterData,
    sendFeedbackState: SendFeebackData,
    cancelLoadFilesState: CancelLoadFilesData,
    licenseActivationData: LicenseActivationData,
}

const initialState: AnalyticsState = {
    changeDefaultSettingsState: { toggle: false, setting: DefaultSettingsItems.Language, newValue: "" },
    loadFilesState: { toggle: false, source: LoadFilesItems.FromCockpit, nrOfFiles: 0 },
    zoomchartState: { toggle: false, action: ZoomChartItems.ResetZoom },
    addAnnotationsState: { toggle: false, action: AnnotationItems.AddDataPoint },
    deleteAnnotationsState: { toggle: false },
    applyFilterState: { toggle: false, types: [] },
    resetFilterState: { toggle: false },
    startCurveSupervisionCurveControlState: { toggle: false },
    editSharePictureState: { toggle: false, action: EditShareItems.Edit },
    exportSharePictureState: { toggle: false, target: ExportShareItems.CopyToClipboard },
    expandViewState: { toggle: false },
    showOnlineHelpState: { toggle: false, source: OnlineHelpItems.MainOnlineHelp },
    openFooterState: { toggle: false, target: FooterItems.DepragHomepage },
    sendFeedbackState: { toggle: false },
    cancelLoadFilesState: { toggle: false },
    licenseActivationData: { toggle: false, isMaster: false }

}

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        analyze_ChangeDefaultSettings: (state, action) => {
            state.changeDefaultSettingsState.toggle = true
            state.changeDefaultSettingsState.setting = action.payload.setting
            state.changeDefaultSettingsState.newValue = action.payload.newValue
        },
        reset_analyze_ChangeDefaultSettings: (state) => {
            state.changeDefaultSettingsState.toggle = false
        },
        analyze_LoadFiles: (state, action) => {
            state.loadFilesState.toggle = true
            state.loadFilesState.source = action.payload.source
            state.loadFilesState.nrOfFiles = action.payload.nrOfFiles
        },
        reset_analyze_LoadFiles: (state) => {
            state.loadFilesState.toggle = false
        },
        analyze_ZoomChart: (state, action) => {
            state.zoomchartState.toggle = true
            state.zoomchartState.action = action.payload.action
        },
        reset_analyze_ZoomChart: (state) => {
            state.zoomchartState.toggle = false
        },
        analyze_AddAnnotations: (state, action) => {
            state.addAnnotationsState.toggle = true
            state.addAnnotationsState.action = action.payload.action
        },
        reset_analyze_AddAnnotations: (state) => {
            state.addAnnotationsState.toggle = false
        },
        analyze_DeleteAnnotations: (state) => {
            state.deleteAnnotationsState.toggle = true
        },
        reset_analyze_DeleteAnnotations: (state) => {
            state.deleteAnnotationsState.toggle = false
        },
        analyze_ApplyFilter: (state, action) => {
            state.applyFilterState.toggle = true
            state.applyFilterState.types = action.payload.types
        },
        reset_analyze_ApplyFilter: (state) => {
            state.applyFilterState.toggle = false
        },
        analyze_ResetFilter: (state) => {
            state.resetFilterState.toggle = true
        },
        reset_analyze_ResetFilter: (state) => {
            state.resetFilterState.toggle = false
        },
        analyze_StartCurveSupervisionCurveControl: (state) => {
            state.startCurveSupervisionCurveControlState.toggle = true
        },
        reset_analyze_StartCurveSupervisionCurveControl: (state) => {
            state.startCurveSupervisionCurveControlState.toggle = false
        },
        analyze_EditSharePicture: (state, action) => {
            state.editSharePictureState.toggle = true
            state.editSharePictureState.action = action.payload.action
        },
        reset_analyze_EditSharePicture: (state) => {
            state.editSharePictureState.toggle = false
        },
        analyze_ExportSharePicture: (state, action) => {
            state.exportSharePictureState.toggle = true
            state.exportSharePictureState.target = action.payload.target
        },
        reset_analyze_ExportSharePicture: (state) => {
            state.exportSharePictureState.toggle = false
        },
        analyze_ExpandView: (state) => {
            state.expandViewState.toggle = true
        },
        reset_analyze_ExpandView: (state) => {
            state.expandViewState.toggle = false
        },
        analyze_ShowOnlineHelp: (state, action) => {
            state.showOnlineHelpState.toggle = true
            state.showOnlineHelpState.source = action.payload.source
        },
        reset_analyze_ShowOnlineHelp: (state) => {
            state.showOnlineHelpState.toggle = false
        },
        analyze_OpenFooter: (state, action) => {
            state.openFooterState.toggle = true
            state.openFooterState.target = action.payload.target
        },
        reset_analyze_OpenFooter: (state) => {
            state.openFooterState.toggle = false
        },
        analyze_SendFeedback: (state) => {
            state.sendFeedbackState.toggle = true
        },
        reset_analyze_SendFeedback: (state) => {
            state.sendFeedbackState.toggle = false
        },
        analyze_CancelLoadFiles: (state) => {
            state.cancelLoadFilesState.toggle = true
        },
        reset_analyze_CancelLoadFiles: (state) => {
            state.cancelLoadFilesState.toggle = false
        },
        reset_analyze_LicenseActivation: (state) => {
            state.licenseActivationData.toggle = false
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(licenceActivation.fulfilled), (state, action) => {
            state.licenseActivationData.toggle = true;
            state.licenseActivationData.isMaster = action.payload.payload.isMasterKey;
        })
    }
})

export const { analyze_AddAnnotations, analyze_ApplyFilter, analyze_ChangeDefaultSettings, analyze_DeleteAnnotations, analyze_EditSharePicture, analyze_ExpandView, analyze_ExportSharePicture, analyze_LoadFiles, analyze_OpenFooter, analyze_ResetFilter, analyze_SendFeedback, analyze_ShowOnlineHelp, analyze_StartCurveSupervisionCurveControl, analyze_ZoomChart,
    reset_analyze_AddAnnotations, reset_analyze_ApplyFilter, reset_analyze_ChangeDefaultSettings, reset_analyze_DeleteAnnotations, reset_analyze_EditSharePicture, reset_analyze_ExpandView, reset_analyze_ExportSharePicture, reset_analyze_LoadFiles, reset_analyze_OpenFooter, reset_analyze_ResetFilter, reset_analyze_SendFeedback, reset_analyze_ShowOnlineHelp, reset_analyze_StartCurveSupervisionCurveControl, reset_analyze_ZoomChart, analyze_CancelLoadFiles, reset_analyze_CancelLoadFiles,
    reset_analyze_LicenseActivation } = analyticsSlice.actions;

export default analyticsSlice.reducer;