/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Grid, Typography, Checkbox, Tooltip, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { PreparedDeviceInformation, PreparedSimplifiedCurveData } from "./Models/PreparedDeviceInformation";
import { prepareDeviceInformations } from "./LoadFromCockpitCurveDataSelection_Logic";
import { loadSimplifiedCurveDatas, setLoadFromCockpitSimplifiedFileAbortController } from "../../CurveData/Simplified/Redux/LoadFromCockpitSimplifiedCurveDataSlice";
import { CreateLoadFromCockpitSimplifiedCurveDatasRequest } from "../Logic/LoadFromCockpit_Logic";
import { updateSelectedCurveFileIds } from "../../Filter/Redux/LoadFromCockpitFilterSlice";
import CachedIcon from '@mui/icons-material/Cached';
import IconButton from '@mui/material/IconButton';
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";

const MaxRecivedSimplifiedCurves = 30;

export const LoadFromCockpitCurveDataSelection = () => {
    const dispatch = useAppDispatch();
    const loadFromCockpit = useAppSelector(store => store.loadFromCockpit);
    const deviceInformationCutOffTime = useAppSelector(state => state.loadFromCockpitDeviceInformations.cutOffDateTime);
    const recivedDeviceInformations = useAppSelector(state => state.loadFromCockpitDeviceInformations.cockpitResponse);
    const recivedSimplifiedCurveDatas = useAppSelector(state => state.loadFromCockpitSimplifiedCurveDatas.cockpitResponse);
    const filterByState = useAppSelector(store => store.loadFromCockpitFilter.FinalResultState);
    const dateRangeFilterItems = useAppSelector(store => store.loadFromCockpitFilter.DateRanges);

    const [preparedDeviceInformations, setPreparedDeviceInformations] = useState<PreparedDeviceInformation[]>([])

    useEffect(() => {
        if (!recivedDeviceInformations)
            return

        let pDeviceInformations = prepareDeviceInformations(recivedDeviceInformations, deviceInformationCutOffTime)
        setPreparedDeviceInformations(pDeviceInformations)

    }, [recivedDeviceInformations]);

    useEffect(() => {
        if (!preparedDeviceInformations || preparedDeviceInformations.length <= 0)
            return

        let idsOfSelectedCurveFiles: number[] = []
        let deviceIdsOfDevicesWhichAreToBeLoaded: number[] = []

        preparedDeviceInformations.forEach((preparedDeviceInformation) => {
            if (preparedDeviceInformation.cuveFileResponseItemDatas.length > 0) {
                preparedDeviceInformation.cuveFileResponseItemDatas.filter(x => x.isChecked === true).forEach(x => idsOfSelectedCurveFiles.push(x.curveFileId))
            }
            else if (preparedDeviceInformation.availableCurveFiles > 0) {
                deviceIdsOfDevicesWhichAreToBeLoaded.push(preparedDeviceInformation.deviceId)
            }
        })

        if (deviceIdsOfDevicesWhichAreToBeLoaded.length > 0) {

            let loadFromCockpitSimplifiedCurveDatasRequest = CreateLoadFromCockpitSimplifiedCurveDatasRequest(
                deviceIdsOfDevicesWhichAreToBeLoaded,
                dateRangeFilterItems,
                loadFromCockpit,
                filterByState,
                deviceInformationCutOffTime,
                0,
                MaxRecivedSimplifiedCurves
            )            
            dispatch(setLoadFromCockpitSimplifiedFileAbortController(new AbortController()));
            dispatch(loadSimplifiedCurveDatas(loadFromCockpitSimplifiedCurveDatasRequest))
        }

        dispatch(updateSelectedCurveFileIds(idsOfSelectedCurveFiles))
    }, [preparedDeviceInformations]);

    useEffect(() => {
        if (!recivedSimplifiedCurveDatas || !preparedDeviceInformations)
            return

        let newPreparedDeviceInformations = [...preparedDeviceInformations]

        newPreparedDeviceInformations?.forEach((device) => {
            let filteredRecivedSimplifiedCurveData = recivedSimplifiedCurveDatas?.filter(x => x.deviceId === device.deviceId)

            filteredRecivedSimplifiedCurveData?.forEach((x) => {
                if (!device.cuveFileResponseItemDatas.some(y => y.curveFileId === x.curveFileId)) {
                    device.cuveFileResponseItemDatas.push({
                        curveFileId: x.curveFileId,
                        curveFileName: x.curveFileName,
                        createdAt: x.createdAt,
                        state: x.state,
                        isChecked: false
                    })
                }
            })

            checkBoxChanged(null, device)
        })

    }, [recivedSimplifiedCurveDatas]);

    const checkBoxChanged = (clickedItem: PreparedDeviceInformation | PreparedSimplifiedCurveData | null, parent: PreparedDeviceInformation | null) => {

        let newPreparedDeviceInformations = [...preparedDeviceInformations]
        if (clickedItem) {
            let newCheckedState = !clickedItem.isChecked
            clickedItem.isChecked = newCheckedState

            if ("isIndeterminate" in clickedItem || "cuveFileResponseItemDatas" in clickedItem) {
                clickedItem.isIndeterminate = false
                clickedItem?.cuveFileResponseItemDatas?.forEach((child) => { child.isChecked = newCheckedState })
            }
        }

        if (parent) {
            let availableChildrenCount = parent.cuveFileResponseItemDatas.length
            let checkedChildrenCount = parent.cuveFileResponseItemDatas.filter(x => x.isChecked === true).length

            if (availableChildrenCount > 0) {

                if (availableChildrenCount === checkedChildrenCount) {
                    parent.isChecked = true
                    parent.isIndeterminate = false
                }
                else if (checkedChildrenCount > 0) {
                    parent.isChecked = false
                    parent.isIndeterminate = true
                }
                else {
                    parent.isChecked = false
                    parent.isIndeterminate = false
                }
            }
        }
        setPreparedDeviceInformations(newPreparedDeviceInformations)
    }

    const loadSimplifiedCurveFiles = (device: PreparedDeviceInformation) => {

        let deviceIds: number[] = [device.deviceId]

        let loadFromCockpitSimplifiedCurveDatasRequest = CreateLoadFromCockpitSimplifiedCurveDatasRequest(
            deviceIds,
            dateRangeFilterItems,
            loadFromCockpit,
            filterByState,
            deviceInformationCutOffTime,
            device.cuveFileResponseItemDatas.length,
            MaxRecivedSimplifiedCurves
        )
        const abortController = new AbortController();
        dispatch(setLoadFromCockpitSimplifiedFileAbortController(abortController));
        dispatch(loadSimplifiedCurveDatas(loadFromCockpitSimplifiedCurveDatasRequest))
    }

    return (<LanguageConsumer>

        {({ getTranslatedText }) =>
            <Grid container direction={'row'} spacing={1}>
                <Grid item xs={12} style={{ overflow: "auto", maxHeight: "60vh" }}>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {preparedDeviceInformations?.map((device, index) => {
                            let showSkeleton =
                                loadFromCockpit.status === 'loading';

                            return (
                                showSkeleton ? <Skeleton key={index} animation={'wave'} variant="rectangular" width={'100%'} height={30} sx={{ mt: 1 }} /> :
                                    <TreeItem
                                        key={index}
                                        label=
                                        {
                                            <Grid container>
                                                <Grid item sx={{ ml: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                    <Checkbox
                                                        id={`checkbox-${device.treeNodeId}`}
                                                        onChange={() => (checkBoxChanged(device, null))}
                                                        onClick={e => (e.stopPropagation())}
                                                        color="success"
                                                        checked={device.isChecked}
                                                        indeterminate={device.isIndeterminate}
                                                        disabled={showSkeleton} />

                                                </Grid>
                                                <Grid item sx={{ ml: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                    <Typography>
                                                        {device.deviceName}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        nodeId={device.treeNodeId}>

                                        {
                                            device.cuveFileResponseItemDatas?.map((simplifiedCurveFile, index) => {
                                                let id = device.treeNodeId + "-" + simplifiedCurveFile.curveFileId.toString();
                                                return (
                                                    <TreeItem
                                                        key={index}
                                                        nodeId={id}
                                                        label={
                                                            <Grid container>
                                                                <Grid item xs={1} sx={{ ml: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                                    <Checkbox
                                                                        id={`checkbox-${id}`}
                                                                        onChange={() => (checkBoxChanged(simplifiedCurveFile, device))}
                                                                        onClick={e => (e.stopPropagation())}
                                                                        color="success"
                                                                        checked={simplifiedCurveFile.isChecked}
                                                                        disabled={showSkeleton} />
                                                                </Grid>
                                                                <Grid item xs={10} sx={{ ml: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                                    <Tooltip title={simplifiedCurveFile.curveFileName}>
                                                                        <Typography noWrap >
                                                                            {simplifiedCurveFile.curveFileName}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>}
                                                    />
                                                )
                                            })}
                                        {
                                            device.availableCurveFiles > device.cuveFileResponseItemDatas.length && <TreeItem key={index.toString()+'loadMore'} nodeId={""} label={
                                                <Grid item sx={{ p: 1, ml: 0 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Tooltip title={getTranslatedText("LoadMoreFiles")}>
                                                        <IconButton sx={{ ml: -2 }} onClick={() => loadSimplifiedCurveFiles(device)} size="large" aria-label="reload">
                                                            <CachedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            }
                                            />
                                        }
                                    </TreeItem>

                            );
                        })}
                    </TreeView>
                </Grid>
            </Grid >
        }
    </LanguageConsumer>
    )
}