import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Redux/hooks";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NotificationType, setNotification } from "../../../../../Layout/Notifications/Redux/NotificationsSlice";

export const LicenseMasterSettingsInfo = ({ childKey, usedLicenses, allLicenses }) => {
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(store => store.settings.appTheme === "dark")
    
    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <div className="licenses-container">
                    <div className="licenses">
                        <div className="licenses-preview">
                            <h6>{getTranslatedText("ChildKeyTitle")}</h6>
                            <h2>{childKey}</h2>
                            <span>{getTranslatedText("ChildKey")}</span>
                        </div>
                        <div className="licenses-info" style={{background: !isDarkMode ? '#f9f9f9' : '#444'}}>
                            <div className="progress-container">
                                
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <LinearProgress color="success" variant="determinate" value={((usedLicenses/allLicenses) * 100)}  />
                                </Box>
                                <span className="progress-text">
                                    {usedLicenses}/{allLicenses} {getTranslatedText("LicensesProgressBarTitle")}
                                </span>
                            </div>
                            <h6>{getTranslatedText("LicensesProgressSubTitle")}</h6>
                            <h2>{getTranslatedText("LicensesProgressTitle")}</h2>
                            <CopyToClipboard text={childKey} onCopy={() => {
                                 dispatch(setNotification({ type: NotificationType.success, text: getTranslatedText("CopiedChildKeySuccessfull") }))
                            }}>
                                <Button className="btn">{getTranslatedText("CopyChildKey")}</Button>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </>
        }
    </LanguageConsumer>
    )
}