import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { Grid, Skeleton } from "@mui/material";

export const LegendSkelekton = () => {
    const seriesData = useAppSelector(selectSeries);
    const isLoading = useAppSelector(store => store.loadingIndicator.isLoading);

    return (
        ((!seriesData || !seriesData[0]) && isLoading) ?
            <Grid container>
                <Grid item xs={1}><Skeleton animation="wave" sx={{ width: "20px", height: "30px" }} /></Grid>
                <Grid item xs={1}><Skeleton animation="wave" sx={{ width: "20px", height: "30px" }} /></Grid>
                <Grid item xs={10}><Skeleton animation="wave" sx={{ width: "100%", height: "30px" }} /></Grid>
                <Grid item xs={1}><Skeleton animation="wave" sx={{ width: "20px", height: "30px" }} /></Grid>
                <Grid item xs={1}><Skeleton animation="wave" sx={{ width: "20px", height: "30px" }} /></Grid>
                <Grid item xs={10}><Skeleton animation="wave" sx={{ width: "100%", height: "30px" }} /></Grid>
                <Grid item xs={1}><Skeleton animation="wave" sx={{ width: "20px", height: "30px" }} /></Grid>
                <Grid item xs={1}><Skeleton animation="wave" sx={{ width: "20px", height: "30px" }} /></Grid>
                <Grid item xs={10}><Skeleton animation="wave" sx={{ width: "100%", height: "30px" }} /></Grid>
            </Grid>
            :
            <></>
    );
}