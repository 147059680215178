import { RolloverModifier } from "scichart/Charting/ChartModifiers/RolloverModifier";
import { HitTestInfo } from "scichart/Charting/Visuals/RenderableSeries/HitTest/HitTestInfo";
import { IRenderableSeries } from "scichart/Charting/Visuals/RenderableSeries/IRenderableSeries";
import { Point } from "scichart/Core/Point";
import { AppStore, RootState } from "../../../Infrastructure/Redux/store";

import { updateCurrentValue } from "../../SeriesLegend/Redux/LegendCurrentValueSlice";


const selectSeries = (state: RootState) => {
    return state.chartResultData.series;
};


const oldYValue = (state: RootState, title: string) => {
    return state.legendCurrentValue.currentValues.filter(x => x.title === title)[0].currentValue;
};

export class RolloverModifierEx extends RolloverModifier {

    public hitTestRadius: number = 1;
    private store : AppStore;

    constructor(props){
        super(props);
        this.store = props.store;
    }

    override hitTestRenderableSeries(rs: IRenderableSeries, mousePoint: Point): HitTestInfo {
        const hitTest = mousePoint && rs.hitTestProvider.hitTestXSlice(mousePoint.x, mousePoint.y, this.hitTestRadius);

        if(hitTest && !hitTest.isEmpty) {

            const state = this.store.getState();
            const series = selectSeries(state);
       
            const fixedFactors = series[0].ChartSeries.filter(x => x.Title === hitTest.dataSeriesName)[0];
            const yValueCalculated = hitTest.yValue / Number(fixedFactors.ScaleFactor);

            var newYValue = yValueCalculated.toFixed(fixedFactors.DigitCount).toString()

            const oldVal = oldYValue(state, hitTest.dataSeriesName);

            if(oldVal !== newYValue)
            {
                this.store.dispatch(updateCurrentValue(
                    {
                        title:  hitTest.dataSeriesName,
                        currentValue: newYValue
                    }));    
            }

        }

        return hitTest;
    }
}


