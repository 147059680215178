import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { TorqueUnit } from "../../../Features/Home/Models/ChartRequest";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { DefaultSettingsItems } from "../../../Infrastructure/Analytics/Model/Categories/SettingsEnums";
import { analyze_ChangeDefaultSettings } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { setActiveTab, setTorqueUnit } from "../Redux/SettingsSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { NavLink } from "react-router-dom";

export const TorqueUnitSelector = () => {
    const dispatch = useAppDispatch();

    const licenceData = useAppSelector(store => store.licenceService);
    const torqueUnit = useAppSelector(store => store.settings.chartRequestSettings.TorqueUnit);

    const handleChangeTorqueUnit = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as number;
        dispatch(setTorqueUnit(value));
        dispatch(analyze_ChangeDefaultSettings({ setting: DefaultSettingsItems.TorqueUnit, newValue: TorqueUnit[value] }))
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl data-testid="torqueunit-selection" fullWidth sx={{ mt: 3 }}>
                    <InputLabel color="success" id="select-label-torque">{getTranslatedText("TorqueUnitLabel")} </InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        className="onlinehelp-settings-torqueUnitSelect"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-torque"
                        id="selectTorque"
                        name="selectTorque"
                        value={torqueUnit}
                        label={getTranslatedText("TorqueUnitLabel")}
                        onChange={handleChangeTorqueUnit}
                    >
                        <MenuItem id="torque-unit-0" value={TorqueUnit.Nm}>{getTranslatedText("TorqueUnitNm")} </MenuItem>
                        <MenuItem id="torque-unit-1" value={TorqueUnit.Ncm}>{getTranslatedText("TorqueUnitNcm")} </MenuItem>
                        <MenuItem id="torque-unit-2" value={TorqueUnit.Ftlb}>{getTranslatedText("TorqueUnitFtlb")} </MenuItem>
                        <MenuItem id="torque-unit-3" value={TorqueUnit.Inlb}>{getTranslatedText("TorqueUnitInlb")} </MenuItem>
                        <MenuItem id="torque-unit-4" value={TorqueUnit.Kgcm}>{getTranslatedText("TorqueUnitKgcm")} </MenuItem>
                        <MenuItem id="torque-unit-5" value={TorqueUnit.Kgm}>{getTranslatedText("TorqueUnitKgm")} </MenuItem>
                    </Select>
                </FormControl>
            }
        </LanguageConsumer>
    )
}