import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSelectedFiles } from '../Api/GetSelectedFilesApi';
import { LoadSelectedFilesFromControllerRequest } from '../Models/LoadSelectedFilesFromControllerRequest';

export interface LoadSelectedFilesFromControllerState {
    selectedFileUrls: string[],
    recivedFiles: File[],
    status: 'idle' | 'loading' | 'failed';
    showHelp: boolean,
}

const initialState: LoadSelectedFilesFromControllerState = {
    selectedFileUrls: [],
    recivedFiles: [],
    status: "idle",
    showHelp: false,
};

export const loadSelectedFilesFromController = createAsyncThunk(
    'loadSelectedFilesFromController',
    async (request: LoadSelectedFilesFromControllerRequest, { rejectWithValue }) => {
        const response = await getSelectedFiles(request);
        return response;
    }
);

export const loadSelectedFilesFromControllerSlice = createSlice({
    name: 'loadSelectedFilesFromController',
    initialState,
    reducers: {
        showHelpDialog: (state) => {
            state.showHelp = true;
        },
        hideHelpDialog: (state) => {
            state.showHelp = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadSelectedFilesFromController.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(loadSelectedFilesFromController.fulfilled, (state, action) => {
                state.status = 'idle';
                state.recivedFiles = [...action.payload]
                state.showHelp = false;
            })
            .addCase(loadSelectedFilesFromController.rejected, (state, action) => {
                state.status = 'failed';
                if ( (action.error.message === "Failed to fetch" || action.error.message === "NetworkError when attempting to fetch resource.")) {
                    state.showHelp = true;
                }
            })
    }
});

export const { showHelpDialog, hideHelpDialog } = loadSelectedFilesFromControllerSlice.actions;

export default loadSelectedFilesFromControllerSlice.reducer;
