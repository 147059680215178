import { MainCategories } from "../AnalyticsCategories";

export enum ShareEvents {
    Edit = MainCategories.Share + "Edit",
    Export = MainCategories.Share + "Export"
}

export enum EditShareItems {
    Edit = "Edit",
    RemoveDrawings = "RemoveDrawings",
}

export enum ExportShareItems{
    CopyToClipboard = "CopyToClipboard",
    SendAsMail = "SendAsMail"
}