import { encode } from "base-64";
import { licenseBaseUrl } from "../../../Infrastructure/Api/BaseApi";
import { Feature } from "../Models/Feature";

export const GetNewFeatures = async (): Promise<Feature[]> => {

    var response = await fetch(licenseBaseUrl + "features", {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
    });

    var jsonResponse = await response.json();
    
    return jsonResponse.payload as Feature[];
}