import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = (props) => {

    return [
        // Navigation - PAGES
        {
            selector: '.onlinehelp-settings-licence-intro',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LicenceOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpIntroMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-settings-licence-isUnlocked',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LicenceOnlineHelpIsUnlockedTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpIsUnlockedMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-settings-licence-isValidUntil',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LicenceOnlineHelpIsValidUntilTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpIsValidUntilMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-settings-licence-getYourLicence',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LicenceOnlineHelpGetYourLicenceTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpGetYourLicenceMessage") }}></div></div>),
        },
        // {
        //     selector: '.onlinehelp-settings-licence-contactDeprag',
        //     position: 'center',
        //     content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LicenceOnlineHelpContactDepragTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpContactDepragMessage") }}></div></div>),
        // },
        {
            selector: '.onlinehelp-settings-licence-emailAddress',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LicenceOnlineHelpEmailAddressTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpEmailAddressMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-settings-licence-licenceNumber',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("LicenceOnlineHelpLicenceNumberTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpLicenceNumberMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-settings-licence-submitRemoveLicence',
            position: 'center',
            content: () => (
                <div>
                    {
                        props.isLicenced && <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{
                            getTranslation("LicenceOnlineHelpRemoveLicenceTitle")}
                        </Typography>
                    }
                    {
                        !props.isLicenced && <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{
                            getTranslation("LicenceOnlineHelpSubmitLicenceTitle")}
                        </Typography>
                    }
                    {
                        props.isLicenced && <div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpRemoveLicenceMessage") }}>
                        </div>
                    }
                    {
                        !props.isLicenced && <div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("LicenceOnlineHelpSubmitLicenceMessage") }}>
                        </div>
                    }

                </div>),
        },

    ]

}



