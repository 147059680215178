/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { Grid, Button } from "@mui/material";
import moment from "moment";
import { showModal, hideModal } from "../../../Layout/Modal/Redux/ModalSlice";
import { removeAllDistinctiveValueAreaAnnotations, addDistinctiveValueArea } from "../../Annotations/Redux/AnnotationDistinctiveValueAreaSlice";
import { removeAllStepNumberAnnotations, addStepNumber } from "../../Annotations/Redux/AnnotationStepNumberSlice";
import { removeAllTargetAreaAnnotations, addTargetArea } from "../../Annotations/Redux/AnnotationTargetAreaSlice";
import { SuperVisualizationModeDto } from "../Models/SuperVisualizationModeDto";
import { addOrUpdateYAxis } from "../SciChartHelper/Axis";
import { drawSeries } from "../SciChartHelper/Series";
import { getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService";
import { updateAnnotationColor } from "../../Annotations/Redux/AnnotationMonitorSlice";
import { MonitorAnnotation } from "../../Annotations/Annotation";
import { HashLink as Link } from 'react-router-hash-link';
import { setActiveTab } from "../../Settings/Redux/SettingsSlice";

interface UseHandleNewSeriesDataChangeInColorOrLicenceArgs {
    sciChart: TWebAssemblyChart;
    didMount: React.MutableRefObject<boolean>;
    setRedrawFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useHandleNewSeriesDataChangeInColorOrLicence = ({ sciChart, didMount, setRedrawFinished }: UseHandleNewSeriesDataChangeInColorOrLicenceArgs) => {
    const dispatch = useAppDispatch();

    const seriesData = useAppSelector(selectSeries);
    const isSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const selectedYAxis = useAppSelector(store => store.settings.selectedYAxisForSingleVisualisation);
    const seriesColors = useAppSelector(store => store.chartSeriesColors.colors);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
    const distinctiveValueColors = useAppSelector(store => store.distinctiveValueColors.colors);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const isDeveloper = useAppSelector(store => store.developer.isDeveloper);
    const apiKey = useAppSelector(store => store.licenceService.licenceAuthentication.isMyDepragApiKey);


    useEffect(() => {
        if (didMount.current && sciChart && sciChart.sciChartSurface) {

            !isLicenced && apiKey.trim().length === 0 && dispatch(showModal({
                bodyText: "YourApplicationIsNotLicencedText", titleText: "YourApplicationIsNotLicenced", buttons: <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 0, mt: 3 }}>
                    <Grid item xs={6}>
                        <Button color="info" variant="outlined" fullWidth size="large" onClick={() => dispatch(hideModal())}>
                            {getTranslation("Cancel")}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Link onClick={() => dispatch(setActiveTab(4))} to="/settings#license">
                            <Button color="success" variant="outlined" fullWidth size="large" onClick={() => dispatch(hideModal())}>
                                {getTranslation("GoToLicenceSettings")}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            }));

            if (seriesData.length === 0) {
                sciChart.sciChartSurface.annotations.clear();
            }

            if (seriesData && seriesData.length && seriesData[0].ChartSeries.length && sciChart.sciChartSurface.xAxes.get(0)) {
                sciChart.sciChartSurface.xAxes.get(0).labelProvider.formatLabel = (dataValue) => {
                    if (seriesData[0]) {
                        if (seriesData[0].XAxisIsDateTime) {
                            var formattedDateTime = moment(dataValue).format(seriesData[0].XAxisDateTimeFormat)
                            return formattedDateTime + " " + seriesData[0].XAxisUnitAbbreviation;
                        }
                        else {
                            return String(dataValue.toFixed(seriesData[0].XAxisDigitCount)) + " " + seriesData[0].XAxisUnitAbbreviation;
                        }
                    }
                    return String(dataValue);
                };
            }
            sciChart.sciChartSurface.renderableSeries.clear();
            addOrUpdateYAxis(seriesData, sciChart, selectedYAxis, isSuperVisualisation, isLicenced);

            dispatch(removeAllDistinctiveValueAreaAnnotations());
            seriesData.forEach((series) => series.LabellingDistinctiveValues.forEach((labellingDistinctiveValueArea, index) => {
                let storedColor = distinctiveValueColors.filter(color => color.title === labellingDistinctiveValueArea.Title && color.stepNumber === labellingDistinctiveValueArea.Stepnumber && color.seriesIndex === index);
                let annotationColor = storedColor.length > 0 ? storedColor[0].color : labellingDistinctiveValueArea.Color;
                dispatch(addDistinctiveValueArea({
                    title: labellingDistinctiveValueArea.Title,
                    seriesTitle: labellingDistinctiveValueArea.SeriesTitle,
                    color: annotationColor,
                    stepnumber: labellingDistinctiveValueArea.Stepnumber,
                    x: labellingDistinctiveValueArea.X,
                    y: labellingDistinctiveValueArea.Y,
                    yscalefactor: labellingDistinctiveValueArea.YScaleFactor,
                }))
            }));

            dispatch(removeAllStepNumberAnnotations());
            dispatch(removeAllTargetAreaAnnotations());

            drawSeries(seriesData, sciChart, seriesColors, dispatch, isLicenced, isDarkMode, isDeveloper);

            seriesData.forEach(series => series.StepAreas.forEach(stepArea =>
                dispatch(addStepNumber({
                    step: parseInt(stepArea.Title),
                    x1: stepArea.X1,
                    x2: stepArea.X2,
                    y1: stepArea.Y1,
                    y2: stepArea.Y2,
                }))));

            sciChart.sciChartSurface.renderableSeries.asArray().forEach(chartSeries =>
                dispatch(updateAnnotationColor({
                    color: chartSeries.stroke,
                    name: chartSeries.dataSeries.dataSeriesName,
                } as MonitorAnnotation)));

            seriesData.forEach(series => series.TargetAreas.forEach(targetArea =>
                dispatch(addTargetArea({
                    minAngle: targetArea.MinAngle,
                    maxAngle: targetArea.MaxAngle,
                    minTorque: targetArea.MinTorque,
                    maxTorque: targetArea.MaxTorque,
                }))));

            setRedrawFinished(true);

        } else {
            didMount.current = true;
        }
    }, [seriesData, isLicenced, distinctiveValueColors, seriesColors, sciChart])
}