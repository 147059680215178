import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Version } from "../../Versioninfo/Version";

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '5211b81b-4b8c-411f-a188-1818372551cc', //'TEST' resource in Azure Portal
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        disableTelemetry: window.location.hostname === "localhost" // do not track in developer mode
        // disableTelemetry:false  // set to false if you want to track
    } 
});
appInsights.loadAppInsights();
appInsights.context.application.ver = Version