import { createSlice } from "@reduxjs/toolkit";

export type DateRange = {
    from: any;
    to: any;
    guid: string;
};

export enum FilterState {
    NoFilter = 0,
    IoFilter = 1,
    NioFilter = 2
}

export interface CockpitFilterState {
    DateRanges: DateRange[],
    FinalResultState: FilterState,
    SelectedCurveFileIds: number[],
}

const initialState: CockpitFilterState = {
    DateRanges: [],
    FinalResultState: FilterState.NoFilter,
    SelectedCurveFileIds: []
}

export const loadFromCockpitFilterSlice = createSlice(
    {
        name: "loadFromCockpitFilter",
        initialState: initialState,
        reducers: {

            updateSelectedCurveFileIds: (state, action) => {
                state.SelectedCurveFileIds = action.payload
            },
            updateFromDateRange: (state, action) => {
                var dateRangeEdited = state.DateRanges.filter((x) => x.guid === action.payload.guid)[0] as DateRange;
                dateRangeEdited.from = action.payload.newDate;
            },
            updateToDateRange: (state, action) => {
                var dateRangeEdited = state.DateRanges.filter((x) => x.guid === action.payload.guid)[0] as DateRange;
                dateRangeEdited.to = action.payload.newDate;
            },
            updateStateFilterSelection: (state, action) => {
                state.FinalResultState = action.payload
            },
            updateDateRanges: (state, action) => {
                state.DateRanges = action.payload.DateRanges
            },

        }
    }
);

export const { updateDateRanges, updateFromDateRange, updateToDateRange, updateStateFilterSelection, updateSelectedCurveFileIds } = loadFromCockpitFilterSlice.actions;

export default loadFromCockpitFilterSlice.reducer;