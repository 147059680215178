import { Annotation, DataPointAnnotation, VerticalAnnotation, HorizontalAnnotation, AnnotationType, ValuePointAnnotation, StepNumberAnnotation, TargetAreaAnnotation, MonitorAnnotation, LabellingDistinctiveValueAnnotation, TextValuePointAnnotation } from "./Annotation";
import { AnnotationBase, ECoordinateMode } from "scichart/Charting/Visuals/Annotations/AnnotationBase";
import { HorizontalLineAnnotation } from "scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation";
import { VerticalLineAnnotation } from "scichart/Charting/Visuals/Annotations/VerticalLineAnnotation";
import { EXyDirection } from "scichart/types/XyDirection";
import { CustomAnnotation } from "scichart/Charting/Visuals/Annotations/CustomAnnotation";
import { EHorizontalAnchorPoint, EVerticalAnchorPoint } from "scichart/types/AnchorPoint";
import { EAnnotationLayer, IAnnotation } from "scichart/Charting/Visuals/Annotations/IAnnotation";
import { ObservableArray } from "scichart/Core/ObservableArray";
import { userAnnotationPrefix } from "./Redux/AnnotationUserSlice";
import { BoxAnnotation } from "scichart/Charting/Visuals/Annotations/BoxAnnotation";
import { TextAnnotation } from "scichart/Charting/Visuals/Annotations/TextAnnotation";

export function createSciChartAnnotation(annotation: Annotation): AnnotationBase[] {

   switch (annotation.type) {
        case AnnotationType.horizontal: {
            let horizontal = createHorizontal(annotation);
            return new Array<AnnotationBase>(horizontal as AnnotationBase)
        }

        case AnnotationType.vertical: {
            const x1 = (annotation as VerticalAnnotation).x1;
            let vertical = createVertical(annotation, x1)
            return new Array<AnnotationBase>(vertical as AnnotationBase);
        }

        case AnnotationType.dataPoint: {
            const x1 = (annotation as DataPointAnnotation).x1;
            const y1 = (annotation as DataPointAnnotation).y1;
            let dataPoint = createDataPoint(x1, y1, annotation)

            return new Array<AnnotationBase>(dataPoint as AnnotationBase)
        }

        case AnnotationType.valuePoint: {
            const x1 = (annotation as ValuePointAnnotation).x1;
            const y1 = (annotation as ValuePointAnnotation).y1;

            let valuePoint = createDataPoint(x1, y1, annotation);
            return new Array<AnnotationBase>(valuePoint as AnnotationBase);

        }

        case AnnotationType.textValuePoint: {
            const annotationText = (annotation as TextValuePointAnnotation).formattedValue;
            const x1 = (annotation as ValuePointAnnotation).x1;
            const y1 = (annotation as ValuePointAnnotation).y1;

            let valueText = createText(annotation.id, annotationText, x1, y1, true,annotation);
            return new Array<AnnotationBase>(valueText as AnnotationBase);

        }


        case AnnotationType.stepNumber: {
            const stepNumber = (annotation as StepNumberAnnotation).stepNumber;
            const x1 = (annotation as StepNumberAnnotation).x1;
            const y1 = (annotation as StepNumberAnnotation).y1;
            const x2 = (annotation as StepNumberAnnotation).x2;
            const y2 = (annotation as StepNumberAnnotation).y2;

            var box = createStepBox(annotation.id, x1, x2, y1, y2, annotation);
            var text = createStepText(annotation.id, stepNumber, x1, x2);
            return [box as AnnotationBase, text as AnnotationBase];
        }

        case AnnotationType.targetArea: {
            let targetArea = createTargetArea(annotation.id, annotation as TargetAreaAnnotation);
            return [targetArea as AnnotationBase];
        }

        case AnnotationType.monitor: {
            let monitor = createMonitor(annotation.id, annotation as MonitorAnnotation);
            return [monitor as AnnotationBase];
        }

        case AnnotationType.labellingDistinctiveValueArea: {
            let labellingDistinctiveValueArea = createLabellingDistinctiveValueArea(annotation.id, annotation as LabellingDistinctiveValueAnnotation);
            return [labellingDistinctiveValueArea as AnnotationBase];
        }
    }
}

export function updateUserAnnotation(annotation: Annotation, chartAnnotation: AnnotationBase) {

    switch (annotation.type) {
        case AnnotationType.horizontal: {
            let horizontal = (annotation as HorizontalAnnotation);
            let chartHorizontal = chartAnnotation as HorizontalLineAnnotation;
            horizontal.y1 = chartHorizontal.y1;
            break;
        }

        case AnnotationType.vertical: {
            let vertical = (annotation as VerticalAnnotation);
            let chartvertical = (chartAnnotation as VerticalLineAnnotation);
            vertical.x1 = chartvertical.x1;
            break;
        }

        case AnnotationType.dataPoint: {
            let dataPoint = (annotation as DataPointAnnotation);
            let chartDataPoint = (chartAnnotation as CustomAnnotation);
            dataPoint.x1 = chartDataPoint.x1;
            dataPoint.y1 = chartDataPoint.y1;
            break;
        }

        case AnnotationType.valuePoint: {
            let valuePoint = (annotation as ValuePointAnnotation);
            let chartValuePoint = (chartAnnotation as CustomAnnotation);
            valuePoint.x1 = chartValuePoint.x1;
            valuePoint.y1 = chartValuePoint.y1;
            break;
        }

        case AnnotationType.textValuePoint: {
            let textPoint = (annotation as TextValuePointAnnotation);
            let chartTextPoint = (chartAnnotation as TextAnnotation);
            textPoint.x1 = chartTextPoint.x1;
            textPoint.y1 = chartTextPoint.y1;
            break;
        }

    }
}


export function getUserAnnotationToRemove(annotations: ObservableArray<IAnnotation>): IAnnotation | null {
    const userAnnoationsIds = annotations.asArray().map(x => x.id);
    const userAnnoation = userAnnoationsIds.filter(x => x.startsWith(userAnnotationPrefix));
    userAnnoation.sort();
    userAnnoation.reverse();
    if (userAnnoation.length > 0) {
        var idToRemove = userAnnoation[0];
        var userAnnoationToRemove = annotations.getById(idToRemove);
        return userAnnoationToRemove;
    }
    return null;
}

export function getAllUserAnnotationToRemove(annotations: ObservableArray<IAnnotation>): string[] | null {
    const userAnnoationsIds = annotations.asArray().map(x => x.id);
    const userAnnoation = userAnnoationsIds.filter(x => x.startsWith(userAnnotationPrefix));
    userAnnoation.sort();
    userAnnoation.reverse();

    if (userAnnoation.length > 0) {

        return userAnnoation;
    }
    return null;
}

export function makeId(id: number) {
    let untrimmed = ("00000" + id.toString());
    let newId = untrimmed.substring(untrimmed.length - "00000".length);
    return newId;
}

function createDataPoint(x1: number, y1: number,
    annotation: Annotation): AnnotationBase | null {
    return new CustomAnnotation({
        x1: x1,
        x2: x1,
        y1: y1,
        y2: y1,
        verticalAnchorPoint: EVerticalAnchorPoint.Center,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        xCoordinateMode: ECoordinateMode.DataValue,
        yCoordinateMode: ECoordinateMode.DataValue,
        isEditable: annotation.isEditable,
        svgString: '<svg class="dataPointAnnotation" width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
            '<circle cx="50%" cy="50%" r="5" fill="' + annotation.color + '"/>' +
            '</svg>',
        id: annotation.id,
        resizeDirections: EXyDirection.XyDirection,
        selectionBoxDelta: 2,
        selectionBoxThickness: 2,
    });
}

function createVertical(annotation: Annotation, x1: number): AnnotationBase | null {
    return new VerticalLineAnnotation({
        stroke: annotation.color, strokeThickness: 3, strokeDashArray: [10, 10],
        xCoordinateMode: ECoordinateMode.DataValue,
        x1: x1,
        isEditable: true,
        resizeDirections: EXyDirection.XDirection,
        id: annotation.id,
        selectionBoxDelta: 2,
        selectionBoxThickness: 2,
    });
}

function createHorizontal(annotation: Annotation): AnnotationBase | null {
    return new HorizontalLineAnnotation({
        stroke: annotation.color, strokeThickness: 3, strokeDashArray: [10, 10],
        yCoordinateMode: ECoordinateMode.DataValue,
        y1: (annotation as HorizontalAnnotation).y1,
        isEditable: true,
        resizeDirections: EXyDirection.YDirection,
        id: annotation.id,
        selectionBoxDelta: 2,
        selectionBoxThickness: 2,
    });
}
function createStepBox(id: string, x1: number, x2: number, y1: number, y2: number, annotation: Annotation): AnnotationBase | null {
    return new BoxAnnotation({
        fill: annotation.color,
        stroke: annotation.color,
        strokeThickness: 1,
        yCoordinateMode: ECoordinateMode.Relative,
        x1: x1,
        x2: x2,
        y1: y1,
        y2: y2,
        annotationLayer: EAnnotationLayer.BelowChart,
        id: id
    });
}

function createTargetArea(id: string, annotation: TargetAreaAnnotation) {
    const x1 = annotation.minAngle;
    const x2 = annotation.maxAngle;
    const y1 = annotation.minTorque;
    const y2 = annotation.maxTorque;

    return new BoxAnnotation({
        fill: annotation.color,
        stroke: annotation.strokeColor,
        strokeThickness: 1,
        x1: x1,
        x2: x2,
        y1: y1,
        y2: y2,
        annotationLayer: EAnnotationLayer.BelowChart,
        id: id,
    });
}

function createLabellingDistinctiveValueArea(id: string, annotation: LabellingDistinctiveValueAnnotation) {
    return new CustomAnnotation({
        x1: annotation.x1,
        y1: annotation.y1,
        verticalAnchorPoint: EVerticalAnchorPoint.Center,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        xCoordinateMode: ECoordinateMode.DataValue,
        yCoordinateMode: ECoordinateMode.DataValue,
        svgString:
            '<svg width="16" height="16">' +
            '<g><ellipse ry="7" rx="7" id="svg_61" cy="8" cx="8" stroke-opacity="null" stroke-width="2" stroke=' + annotation.color + ' fill="none"/></g>' +
            '</svg>',
        id: id,
    });
}

function createStepText(id: string, stepNumber: number, x1: number, x2: number): AnnotationBase | null {

    return new CustomAnnotation({
        x1: (x1 - x2) / 2 + x2,
        y1: 0.005,
        annotationLayer: EAnnotationLayer.BelowChart,
        yCoordinateMode: ECoordinateMode.Relative,
        svgString: '<svg viewBox="0 0 80 80" width="30" height="30" role="img" xmlns="http://www.w3.org/2000/svg">' +
            '<g id="myid">' +
            '<circle stroke="#fff" stroke-width="4px" fill="lightgray"  cx="40" cy="40" r="35"/>' +
            '<text style="font: 3em sans-serif;" fill="#fff" text-anchor="middle" dominant-baseline="central" x="50%" y="50%">'
            + stepNumber.toString() + '</text> ' +
            '</g>' +
            '</svg>',
        id: id
    })
}

function createText(id: string, text: string, x1: number, y1: number, isEditable: boolean,annotation: Annotation): AnnotationBase | null {
    return new TextAnnotation({
        id,
        text: text,
        verticalAnchorPoint: EVerticalAnchorPoint.Center,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
        xCoordinateMode: ECoordinateMode.DataValue,
        yCoordinateMode: ECoordinateMode.DataValue,
        x1: x1,
        y1: y1,
        fontSize: 16,
        fontWeight: "Bold",
        textColor: annotation.color,
        isEditable: isEditable,
        annotationLayer: EAnnotationLayer.AboveChart,
        selectionBoxDelta: 2,
        selectionBoxThickness: 2,
    })
}

function createMonitor(id: string, annotation: MonitorAnnotation) {
    return new CustomAnnotation({
        id: id,
        isHidden: !annotation.visible,
        x1: annotation.x1,
        y1: annotation.y1,
        yCoordinateMode: ECoordinateMode.DataValue,
        verticalAnchorPoint: EVerticalAnchorPoint.Center,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        svgString: '<svg viewBox="0 0 10 10" width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
            '<circle cx="50%" cy="50%" r="5" fill="' + annotation.color + '"/>' +
            '</svg>'
    })
}