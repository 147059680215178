import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import Tour from 'reactour';
import { getNavigationSteps } from "./LoadFromCockpitOnlineHelpNavigationProvider";
import { Button } from "@mui/material";

export interface LoadFromCockpitOnlineHelpProps{
    isOpen: boolean;
    closeOnlineHelp: () => void;    
}

export const LoadFromCockpitOnlineHelp = ( {isOpen, closeOnlineHelp}: LoadFromCockpitOnlineHelpProps) =>{
    
    const [currentStep, setCurrentStep] = useState<number>(0);
    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);
    
    return (
        <LanguageConsumer>

            {({ getTranslatedText }) =>
                <Tour 
                    disableFocusLock
                    getCurrentStep={(curr: number) => {
                        setCurrentStep(curr)
                    }}
                    startAt={0}
                    disableInteraction={true}
                    showNavigation={false}
                    showNumber={false}
                    showButtons={true}
                    closeWithMask={true}
                    accentColor={'#32992c'}
                    steps={getNavigationSteps()}
                    isOpen={isOpen}
                    onRequestClose={() => {
                        setCurrentStep(0)
                        closeOnlineHelp();                        
                    }}
                    rounded={13}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}

                    prevButton={currentStep !== 0 ? 
                        <Button component="a" 
                                fullWidth 
                                variant="outlined" 
                                color='inherit' 
                                title={getTranslatedText("OnlineHelpPrev")}>
                            {getTranslatedText("OnlineHelpPrev")}
                        </Button> : <div></div>}

                    nextButton={
                        <Button component="a" 
                                variant="outlined" 
                                fullWidth 
                                color='success' 
                                title={getTranslatedText("OnlineHelpNext")}>
                            {getTranslatedText("OnlineHelpNext")}
                        </Button>}

                    lastStepNextButton={
                        <Button 
                                component="a" 
                                variant="outlined" 
                                onClick={() => { closeOnlineHelp() }} 
                                color='success' 
                                title={getTranslatedText("OnlineHelpFinish")}>
                            {getTranslatedText("OnlineHelpFinish")}
                        </Button>}
                        
                    nextStep={async () => {
                        let nextStep = currentStep + 1
                        setCurrentStep(nextStep)
                    }}
                    prevStep={async () => {
                        let lastStep = currentStep - 1                     
                        setCurrentStep(lastStep)
                    }}
                    goToStep={currentStep}
                />            
            }
        </LanguageConsumer>
    );    
}