import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ColorPickerOption } from '../../../Features/ColorPicker/ColorPickerOption';
import { changeSingleSeriesData } from "../../../Infrastructure/ChartSeriesVisibility/Redux/ChartSeriesVisibilitySlice";

export interface Annotations {
  addVerticalLine: boolean,
  addHorizontalLine: boolean,
  addText: boolean,
  addArrow: boolean,
  addFreeLine: boolean,
  addDataPoint: boolean,
  addValuePoint: boolean,
  deleteLastAnnotation: boolean,
  deleteAllAnnotations: boolean
}

interface ColorPicker {
  isShown: boolean,
  color: string,
  title: string,
  option: ColorPickerOption,
  stepNumber: 0,
  seriesIndex: 0
}

interface ShareInterface {
  isShown: boolean,
  image: string,
  takeScreenShot: boolean | null;
}

export interface ChartModifierState {
  zoomIn: boolean,
  zoomOut: boolean,
  resetChart: boolean,
  resetChartFromButton: boolean,
  annotations: Annotations,
  filter: boolean,
  colorPicker: ColorPicker,
  share: ShareInterface,
  shouldZoomOut: boolean,
}

export const initialChartModifierState: ChartModifierState = {
  zoomIn: false,
  zoomOut: false,
  resetChart: false,
  resetChartFromButton: false,
  filter: false,
  colorPicker: {
    isShown: false,
    title: "",
    color: "#32992c",
    option: ColorPickerOption.seriesLegend,
    stepNumber: 0,
    seriesIndex: 0
  },
  share: {
    isShown: false,
    image: "",
    takeScreenShot: null
  },
  annotations: {
    addVerticalLine: false,
    addHorizontalLine: false,
    addText: false,
    addArrow: false,
    addFreeLine: false,
    addDataPoint: false,
    addValuePoint: false,
    deleteLastAnnotation: false,
    deleteAllAnnotations: false    
  },
  shouldZoomOut: true,
};

export const chartModifierSlice = createSlice({
  name: 'graphDataRequest',
  initialState: initialChartModifierState,
  reducers: {
    toggleShouldZoomOut: (state) => {
      state.shouldZoomOut = !Boolean(state.shouldZoomOut);
    },
    setShare: (state) => {
      state.share.isShown = true;
    },
    setShareImage: (state,action) => {
      state.share.image = action.payload;
    },
    setTakeScreenShot: (state) => {
      state.share.takeScreenShot = !state.share.takeScreenShot;
    },    
    resetShare: (state) => {
      state.share.isShown = false;
    },
    toggleFilter: (state) => {
      state.filter = !Boolean(state.filter);
    },
    setColorPicker: (state, action) => {
      state.colorPicker.isShown = true;
      state.colorPicker.color = action.payload.color;
      state.colorPicker.title = action.payload.title;
      state.colorPicker.option = action.payload.option;
      state.colorPicker.stepNumber = action.payload.stepNumber;
      state.colorPicker.seriesIndex = action.payload.seriesIndex;
    },
    resetColorPicker: (state) => {
      state.colorPicker.isShown = false;
      state.colorPicker.color = "#32992c";
      state.colorPicker.title = "";
    },
    toggleZoomIn: (state) => {
      state.zoomIn = !Boolean(state.zoomIn);
    },
    toggleZoomOut: (state) => {
      state.zoomOut = !Boolean(state.zoomOut);
    },
    toggleResetChart: (state) => {
      state.resetChart = !Boolean(state.resetChart);
    },
    toggleResetChartFromButton: (state) => {
      state.resetChartFromButton = !Boolean(state.resetChartFromButton);
    },
    toggleAddVerticalLine: (state) => {
      state.annotations.addHorizontalLine = false;
      state.annotations.addText = false;
      state.annotations.addDataPoint = false;
      state.annotations.addArrow = false;
      state.annotations.addFreeLine = false;
      state.annotations.addValuePoint = false;
      state.annotations.addVerticalLine = !Boolean(state.annotations.addVerticalLine);
    },
    toggleAddHorizontalLine: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addText = false;
      state.annotations.addDataPoint = false;
      state.annotations.addArrow = false;
      state.annotations.addFreeLine = false;
      state.annotations.addValuePoint = false;      
      state.annotations.addHorizontalLine = !Boolean(state.annotations.addHorizontalLine);
    },
    toggleAddText: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addDataPoint = false;
      state.annotations.addArrow = false;
      state.annotations.addFreeLine = false;
      state.annotations.addValuePoint = false;      
      state.annotations.addText = !Boolean(state.annotations.addText);
    },
    toggleAddArrow: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addDataPoint = false;
      state.annotations.addText = false;
      state.annotations.addFreeLine = false;
      state.annotations.addValuePoint = false;      
      state.annotations.addArrow = !Boolean(state.annotations.addArrow);
    },
    toggleAddFreeLine: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addDataPoint = false;
      state.annotations.addText = false;
      state.annotations.addArrow = false;
      state.annotations.addValuePoint = false;      
      state.annotations.addFreeLine = !Boolean(state.annotations.addFreeLine);
    },
    toggleAddDataPoint: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addFreeLine = false;
      state.annotations.addText = false;
      state.annotations.addArrow = false;
      state.annotations.addValuePoint = false;      
      state.annotations.addDataPoint = !Boolean(state.annotations.addDataPoint);
    },
    toggleMarkingMode: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addFreeLine = false;
      state.annotations.addText = false;
      state.annotations.addArrow = false;
      state.annotations.addValuePoint = !Boolean(state.annotations.addValuePoint);                  
      state.annotations.addDataPoint = false;
    },    
    toggleDeleteLastAnnotation: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addFreeLine = false;
      state.annotations.addText = false;
      state.annotations.addArrow = false;
      state.annotations.addDataPoint = false;
      state.annotations.addValuePoint = false;      
      state.annotations.deleteLastAnnotation = !Boolean(state.annotations.deleteLastAnnotation);
    },
    toggleDeleteAllAnnotation: (state) => {
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addFreeLine = false;
      state.annotations.addText = false;
      state.annotations.addArrow = false;
      state.annotations.addDataPoint = false;
      state.annotations.addValuePoint = false;
      state.annotations.deleteLastAnnotation = false;      
      state.annotations.deleteAllAnnotations = !Boolean(state.annotations.deleteAllAnnotations);
    },
    resetAllAnnotations: (state) => {      
      state.annotations.addVerticalLine = false;
      state.annotations.addHorizontalLine = false;
      state.annotations.addFreeLine = false;
      state.annotations.addText = false;
      state.annotations.addArrow = false;
      state.annotations.addDataPoint = false;
      state.annotations.deleteLastAnnotation = false;
      state.annotations.deleteAllAnnotations = false;
      state.annotations.addValuePoint = false;      
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(changeSingleSeriesData), (state, action) => {
        state.shouldZoomOut = false;
      })
    }
});

export const { setTakeScreenShot, toggleDeleteAllAnnotation, toggleShouldZoomOut, resetAllAnnotations, setShareImage, setShare, resetShare, toggleZoomIn, toggleZoomOut, toggleResetChart, toggleResetChartFromButton, toggleAddVerticalLine, toggleAddHorizontalLine, toggleAddText, toggleAddArrow, toggleAddFreeLine, toggleAddDataPoint, toggleDeleteLastAnnotation, toggleFilter, setColorPicker, resetColorPicker, toggleMarkingMode } = chartModifierSlice.actions;

export default chartModifierSlice.reducer;
