import { licenseBaseUrl } from "../../../../Api/BaseApi";
import { LicenceRenewalOfferRequest } from "../Models/LicenceRenewalOfferRequest";
import { LicenceRenewalOfferResponse } from "../Models/LicenceRenewalOfferResponse";


const simpleFetchCall = async (request: LicenceRenewalOfferRequest) => {
    var response = await fetch(licenseBaseUrl + 'licensing/offer/renewal', {
        headers: new Headers({
            'Content-Type': 'application/json',
            clientId: request.ClientId
        }),
        body: JSON.stringify(
            {
                MasterKey: request.MasterKey
            }),
        method: 'POST',
    });
    return response.json();
}

const getResponse = async (request: LicenceRenewalOfferRequest) => {
    const response = await simpleFetchCall(request);
    return response as LicenceRenewalOfferResponse;
}

export const offerLicenceRenewal = async (request: LicenceRenewalOfferRequest): Promise<LicenceRenewalOfferResponse> => {
    try {

        var response = await getResponse(request);

        return new Promise<any>((resolve, reject) => {
            if (response && response.success) {
                resolve(response)
            }
            else {
                reject(response.errorMessage)
            }
        });

    } catch (error: any) {
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);
    }
}