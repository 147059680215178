import InfoIcon from '@mui/icons-material/Info';
import AutoGraphTwoToneIcon from '@mui/icons-material/AutoGraphTwoTone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Tooltip, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { LanguageConsumer } from '../../../Infrastructure/Internationalisation/TranslationService';
import { useAppDispatch, useAppSelector } from '../../../Infrastructure/Redux/hooks';
import MenuIcon from '@mui/icons-material/Menu';
import { isCustomMode } from '../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { toggleInformationModal } from '../../../Features/Information/Redux/InformationModalSlice';
import { InformationType } from '../../../Features/Settings/Redux/SettingsSlice';
import { useMediaQuery } from 'react-responsive';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const PageBar = () => {
    const dispatch = useAppDispatch();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const hasInformation = useAppSelector(store => store.chartResultData.series.length > 0);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const customMode = useAppSelector(isCustomMode);
    const isInformationModal = useAppSelector(store => store.settings.informationType === InformationType.Modal);
    const isRunningOnExtraSmallDevice = useMediaQuery({ query: '(max-width: 600px)' })

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (isInfo: boolean) => {
        if(isInfo && isInformationModal){
            dispatch(toggleInformationModal());
        }
        setAnchorElNav(null);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const pages = [
        { title: 'PagesGraphViewer', isInfo: false, route: '/', icon: <AutoGraphTwoToneIcon />, disabled: false, className: "page-home", id:"page-home" },
        { title: 'PagesInformation', isInfo: true, route: !hasInformation || customMode || isInformationModal  ? '#' : '/information', icon: <InfoIcon />, disabled: !hasInformation || customMode, className: "page-information", id:"page-information" },
        { title: 'PagesSettings', isInfo: false, route: '/settings', icon: <SettingsOutlinedIcon />, disabled: false, className: "page-settings", id:"page-settings" }
    ];

    return (<LanguageConsumer>

        {({ getTranslatedText }) => <React.Fragment>
            <Box component="div" sx={{ px: windowDimensions.width <= 1200 ? 1 : 2, flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', borderRight: isDarkMode ? '1px solid #424242' : '1px solid #e1e8e4' }}>
                {pages.map((page, pageKeyBig) => (
                   <NavLink end to={page.route} key={pageKeyBig} className={({ isActive }) => isActive ? (page.isInfo && isInformationModal ? '' : 'link-active') : ''}>
                        <Tooltip title={getTranslatedText(page.title)} arrow>
                            <span className="tooltipSpan">
                                <IconButton
                                    size={windowDimensions.width <= 1200 ? 'small' : 'medium'}
                                    className={page.className}
                                    disabled={page.disabled}
                                    color="success"
                                    id={page.id}
                                    onClick={() => handleCloseNavMenu(page.isInfo)}
                                    sx={{ my: 2, mx: 2, color: isDarkMode ? '#c2c0c0' : '#444', background: isDarkMode ? '#424242' : '#ebf2ee' }}>
                                    {page.icon}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </NavLink>
                ))}
            </Box>
           {!isRunningOnExtraSmallDevice && <Box component="div" sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'flex', md: 'none', lg: 'none', xl: 'none' },
                    }}
                >
                    {pages.map((page, pageKey) => (
                        <NavLink to={page.route} key={pageKey}>
                            <MenuItem onClick={() => handleCloseNavMenu(page.isInfo)} disabled={page.disabled}>
                                <ListItemText sx={{ color: '#444' }}>{getTranslatedText(page.title)}</ListItemText>
                            </MenuItem>
                        </NavLink>
                    ))}
                </Menu>
            </Box>}
        </React.Fragment>}
    </LanguageConsumer>

    );
}

export default PageBar;