import { createTransform } from "redux-persist";
import { CockpitResponse } from "../Api/CompleteCurveDataResponse";

const SetTransformLoadFromCockpit = createTransform(
    
    (inboundState: any, key) => {
      
      return { ...inboundState, 
        status: "idle",
        cockpitResponse: [] as CockpitResponse[],
      };
    },
    
    (outboundState: any, key) => {
      
      return { ...outboundState, 
        status: "idle",
        cockpitResponse: [] as CockpitResponse[],
      };
    },    
    { 
        whitelist: ['loadFromCockpit'] 
    }
  );

export default SetTransformLoadFromCockpit;