import { getNavigationSteps } from "./AnalyticsLegendOnlineHelpNavigationProvider";
import Tour from 'reactour';
import { Button } from "@mui/material";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export const AnalyticsLegendOnlineHelp = (props) => {

    const delayForRenderRefresh = 500;
    const [currentStep, setCurrentStep] = useState<number>(0);
    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);


    return (
        <LanguageConsumer>

            {({ getTranslatedText }) =>
                <Tour
                    disableFocusLock
                    getCurrentStep={(curr: number) => {
                        setCurrentStep(curr)
                    }}
                    startAt={0}
                    disableInteraction={true}
                    showNavigation={false}
                    showNumber={false}
                    showButtons={true}
                    closeWithMask={true}
                    accentColor={'#32992c'}
                    steps={getNavigationSteps(props)}
                    isOpen={props.showOnlineHelp}
                    onRequestClose={async () => {
                        setCurrentStep(0)
                        await props.closeColorPicker();
                        props.closeOnlineHelp();    
                    }}
                    rounded={13}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                    prevButton={currentStep !== 0 ? <Button component="a" fullWidth variant="outlined" color='inherit' title={getTranslatedText("OnlineHelpPrev")}>{getTranslatedText("OnlineHelpPrev")}</Button> : <div></div>}
                    nextButton={<Button  component="a" variant="outlined" fullWidth color='success' title={getTranslatedText("OnlineHelpNext")}>{getTranslatedText("OnlineHelpNext")}</Button>}
                    lastStepNextButton={<Button component="a" variant="outlined" onClick={() => { props.closeOnlineHelp() }} color='success' title={getTranslatedText("OnlineHelpFinish")}>{getTranslatedText("OnlineHelpFinish")}</Button>}
                    nextStep={async () => {
                        let nextStep = currentStep + 1
                        switch (nextStep) {
                            case 3:
                                props.openColorPicker();
                                await new Promise(resolve => setTimeout(resolve, delayForRenderRefresh));
                                break;
                            case 8:
                                props.closeColorPicker();
                                await new Promise(resolve => setTimeout(resolve, delayForRenderRefresh));
                                break;
                            default:
                                break;
                        }
                        setCurrentStep(nextStep)
                    }}
                    prevStep={async () => {
                        let lastStep = currentStep - 1
                        switch (lastStep) {                         
                            case 2:
                                props.closeColorPicker();
                                await new Promise(resolve => setTimeout(resolve, delayForRenderRefresh));
                                break;
                            case 7:
                                props.openColorPicker();
                                await new Promise(resolve => setTimeout(resolve, delayForRenderRefresh));
                                break;
                            default:
                                break;
                        }
                        setCurrentStep(lastStep)
                    }}
                    goToStep={currentStep}
                />}
        </LanguageConsumer>
    );
}