/* eslint-disable react-hooks/exhaustive-deps */
import { setShare, setShareImage } from "../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import * as htmlToImage from 'html-to-image';
import { useEffect } from "react";

export const useHandleShare = (htmlDivElement: HTMLDivElement) => {
    const dispatch = useAppDispatch();    
    const isDarkMode = useAppSelector(store => store.settings.appTheme === "dark"); 
    const takeScreenShotExecute = useAppSelector(store => store.chartModifier.share.takeScreenShot);

    const takeScreenShot = () => {
        if (htmlDivElement){
            htmlDivElement.style.background = isDarkMode ? "#111" : "#fff";

        htmlToImage.toPng(htmlDivElement)
            .then(function (dataUrl) {
                dispatch(setShareImage(dataUrl));   
                dispatch(setShare());            
            })
        }
    }

    useEffect(()=>{
        if (takeScreenShotExecute !== null){
            takeScreenShot();}
    },[takeScreenShotExecute])

    return {
        makeScreenShot: () => {            
            takeScreenShot();         
        }
    }
}