import { createSlice, isAnyOf, nanoid } from '@reduxjs/toolkit';
import { licenceActivation } from '../Activation/Redux/LicenceActivationServiceSlice';
import { deleteUsage } from '../Usage/Delete/Redux/DeleteUsageServiceSlice';
import { licenceVerification } from '../Verification/Redux/LicenceVerificationSlice';

export interface LicenceAuthentication {
  isLicenced: boolean,
  licenceKey: string,
  clientId: string,
  emailAddress: string,
  isMaster: boolean,
  validUntil: string,
  usageId: number,
  privacyAcknowledged: boolean,
  isMyDepragApiKey: string,
}

export interface LicenceRenewal {
  expireDateAcknowledgedAt:string,
  newLicenceOfferRequested:boolean
}

export interface LicenceServiceState {
  licenceAuthentication: LicenceAuthentication,
  licenceRenewal: LicenceRenewal,
  status: 'idle' | 'loading' | 'failed',
  showForm: boolean,
  showMasterSettings: boolean,
}

export const licenceInitialState: LicenceServiceState = {
  licenceAuthentication: {
    isLicenced: false,
    licenceKey: '',
    clientId: '',
    emailAddress: '',
    isMaster: false,
    validUntil: '',
    usageId: 0,
    privacyAcknowledged: false,
    isMyDepragApiKey: '',
  },
  licenceRenewal:{
    expireDateAcknowledgedAt: "",
    newLicenceOfferRequested: false
  },
  status: 'idle',
  showForm: false,
  showMasterSettings: false
};

export const licenceServiceSlice = createSlice({
  name: 'licenceService',
  initialState: licenceInitialState,
  reducers: {
    initializeClientId: (state) => {
      state.licenceAuthentication.clientId = nanoid();
    },
    toggleMasterSettings: (state) => {
      state.showMasterSettings = !Boolean(state.showMasterSettings);
    },
    toggleLicenseForm: (state) => {
      state.showForm = !Boolean(state.showForm);
    },
    setLicence: (state, action) => {
      state.licenceAuthentication.licenceKey = action.payload;
    },
    setEmail: (state, action) => {
      state.licenceAuthentication.emailAddress = action.payload;
    },
    resetLicence: (state) => {
      state.licenceAuthentication = licenceInitialState.licenceAuthentication;
    },
    setPrivacyAcknowledged: (state, action) => {
      state.licenceAuthentication.privacyAcknowledged = action.payload
    },
    setExpireDateAcknowledgedAt:(state, action) => {
      state.licenceRenewal.expireDateAcknowledgedAt = action.payload
    },
    setNewLicenceOfferRequested: (state, action) => {
      state.licenceRenewal.newLicenceOfferRequested = action.payload
    },
    setIsMyDepragApiKey: (state, action) => {
      state.licenceAuthentication.isMyDepragApiKey = action.payload;
    },
    setIsLicensed: (state, action) => {
      state.licenceAuthentication.isLicenced = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(licenceActivation.pending), (state) => {
      state.status = 'loading'
    })
      .addMatcher(isAnyOf(licenceActivation.fulfilled), (state, action) => {
        state.status = 'idle'
        state.licenceAuthentication.isLicenced = true;
        state.licenceAuthentication.validUntil = action.payload.payload.validUntil;
        state.licenceAuthentication.usageId = action.payload.payload.usageId;
        state.licenceAuthentication.isMaster = action.payload.payload.isMasterKey;
        state.licenceRenewal = licenceInitialState.licenceRenewal;
      })
      .addMatcher(isAnyOf(licenceActivation.rejected), (state) => {
        state.status = 'failed';
        state.licenceAuthentication = licenceInitialState.licenceAuthentication;
        state.licenceRenewal = licenceInitialState.licenceRenewal;
      })
      .addMatcher(isAnyOf(deleteUsage.fulfilled), (state) => {
        state.licenceAuthentication = licenceInitialState.licenceAuthentication;
        state.licenceRenewal = licenceInitialState.licenceRenewal;
      })

      .addMatcher(isAnyOf(licenceVerification.fulfilled), (state, action) => {
        if (!action.payload.success) {
          state.licenceAuthentication = licenceInitialState.licenceAuthentication;
          state.licenceRenewal = licenceInitialState.licenceRenewal;
        }
      })
  }
});

export const { setIsLicensed, setIsMyDepragApiKey, toggleMasterSettings, toggleLicenseForm, resetLicence, setLicence, initializeClientId, setEmail, setPrivacyAcknowledged, setExpireDateAcknowledgedAt, setNewLicenceOfferRequested } = licenceServiceSlice.actions;

export default licenceServiceSlice.reducer;
