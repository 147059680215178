import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnotationType, TargetAreaAnnotation } from '../Annotation';
import { makeId } from '../AnnotationService';

export interface TargetArea {
    minAngle: number;
    maxAngle: number;
    minTorque: number;
    maxTorque: number;
}

export const targetAreaAnnotationPrefix: string = "targetAreaAnnotation_";

interface InitialAnnotationTargetAreaState {
    annotations: TargetAreaAnnotation[];
}

export const initialAnnotationTargetAreaState: InitialAnnotationTargetAreaState = {
    annotations: [],
}

const annotationTargetAreaSlice = createSlice({
    name: "AnnotationTargetArea",
    initialState: initialAnnotationTargetAreaState,
    reducers: {
        addTargetArea: (state, action: PayloadAction<TargetArea>) => {
            state.annotations = [...state.annotations,
            {
                color: "#21d92155",
                id: targetAreaAnnotationPrefix + makeId(state.annotations.length + 1),
                minAngle: action.payload.minAngle,
                maxAngle: action.payload.maxAngle,
                minTorque: action.payload.minTorque,
                maxTorque: action.payload.maxTorque,
                strokeColor: "#21d921",
                type: AnnotationType.targetArea,
            } as unknown as TargetAreaAnnotation]
        },
        removeAllTargetAreaAnnotations: (state) => {
            state.annotations = []
        }
    }
})

export const { addTargetArea, removeAllTargetAreaAnnotations } = annotationTargetAreaSlice.actions;
export default annotationTargetAreaSlice.reducer;