import { Grow, Grid, Card, CardContent } from "@mui/material";
import React from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import {Helmet} from "react-helmet";
import { PrivacyLanguage } from "./Languages/PrivacyLanguage";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";

export const Privacy = () => {
    const language = useAppSelector(store => store.settings.language);

    return (
        <LanguageConsumer>
        {({ getTranslatedText }) =>
            <React.Fragment>
                <Helmet>
                    <title>{getTranslatedText("PrivacyGraphViewerTitle")}</title>
                </Helmet>
                <Grow in={true} appear={true}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card elevation={12}>
                                    <CardContent>
                                    <PrivacyLanguage lang={language} />

                                    
                                    </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grow>
            </React.Fragment>}
        </LanguageConsumer>
    );
}