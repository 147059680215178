import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = (props) => {

    return [
        // Navigation - PAGES
        {           
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("InformationOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("InformationOnlineHelpIntroMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-information-switchCompareCurvesOfSameType',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("InformationOnlineHelpCompareCurvesOfSameTypeTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("InformationOnlineHelpCompareCurvesOfSameTypeMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-information-selectCurveData1',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("InformationOnlineHelpSelectCurveData1Title")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("InformationOnlineHelpSelectCurveData1Message") }}></div></div>),
        },
        {
            selector: '.onlinehelp-information-selectCurveData2',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("InformationOnlineHelpSelectCurveData2Title")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("InformationOnlineHelpSelectCurveData2Message") }}></div></div>),
        },
        {
            selector: '.onlinehelp-information-generalInformation',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("InformationOnlineHelpGeneralInformationsTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("InformationOnlineHelpGeneralInformationsMessage") }}></div></div>),
        },
        {
            selector: '.onlinehelp-information-connectionElementInformations',
            position: 'center',
            content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("InformationOnlineHelpConnectionElementInformationsTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("InformationOnlineHelpConnectionElementInformationsMessage") }}></div></div>),
        },
      

    ]

}



