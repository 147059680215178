export enum AnnotationType{
    vertical, horizontal, dataPoint, valuePoint, textValuePoint, stepNumber, targetArea, monitor, labellingDistinctiveValueArea
}

export interface Annotation  {
    id: string;
    color: string; 
    type: AnnotationType;   
    visible: boolean;
    seriesTitle: string;
    dependantAnnoationId?: string;
    isEditable: boolean;
}

export interface VerticalAnnotation extends Annotation{
    x1: number;
}

export interface HorizontalAnnotation extends Annotation{
    y1: number;
}

export interface DataPointAnnotation extends Annotation{
    x1: number;
    y1: number;
}

export interface ValuePointAnnotation extends Annotation{
    x1: number;
    y1: number;    
}

export interface TextValuePointAnnotation extends Annotation{
    x1: number;
    y1: number;
    formattedValue: string;
}

export interface MonitorAnnotation extends Annotation{
    name: string;
    visible: boolean;
    x1: number;
    y1:number;
}

export interface StepNumberAnnotation extends Annotation{        
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    stepNumber: number;
}

export interface TargetAreaAnnotation extends Annotation{        
    minAngle: number;
    maxAngle: number;
    minTorque: number;
    maxTorque: number;     
    strokeColor: string;   
}

export interface LabellingDistinctiveValueAnnotation extends Annotation{
    title: string;
    seriesTitle: string;
    color: string;
    x1: number;
    y1: number;
}