import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { FilterState, updateStateFilterSelection } from "../../../LoadFromCockpit/Filter/Redux/LoadFromCockpitFilterSlice";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";

export const LoadFromCockpitFinalResultStateFilter = () => {

    const dispatch = useAppDispatch();
    const activeState = useAppSelector(state => state.loadFromCockpitFilter.FinalResultState);        

  return (
    <div>
      <LanguageConsumer>
        {({ getTranslatedText }) => (
          <Grid
            container
            direction={"row"}
            spacing={2}
          >
            <Grid item xs={12}>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel color="success" id="select-label-theme">{getTranslatedText("UseFinalResultStateFilter")} </InputLabel>
                <Select
                  className="onlinehelp-settings-appThemeSelect"
                  color="success"
                  labelId="select-label-theme"
                  id="select-theme"
                  value={activeState}
                  label={getTranslatedText("UseFinalResultStateFilter")}
                  onChange={(e) => {
                    dispatch(updateStateFilterSelection(e.target.value));
                  }}
                >
                  <MenuItem value={FilterState.NoFilter}>{getTranslatedText("CockpitNoFilter")} </MenuItem>
                  <MenuItem value={FilterState.IoFilter}>{getTranslatedText("CockpitIoFilter")} </MenuItem>
                  <MenuItem value={FilterState.NioFilter}>{getTranslatedText("CockpitNioFilter")} </MenuItem>
             
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </LanguageConsumer>
    </div>
  );
};
