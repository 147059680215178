import { MainCategories } from "../AnalyticsCategories";

export enum AnnotationsEvent {
    AddAnnotation = MainCategories.Annotations + "AddAnnotation",
    DeleteAnnotation = MainCategories.Annotations + "DeleteAnnotation"
}

export enum AnnotationItems {
    AddVerticalLine = "AddVerticalLine",
    AddHorizontalLine = "AddHorizontalLine",
    AddDataPoint = "AddDataPoint",
    ToggleMarkingMode = "ToggleMarkingMode"
}