import { createAsyncThunk } from '@reduxjs/toolkit';
import { offerLicence } from '../Api/LicenceOffer';
import { LicenceOfferRequest } from '../Models/LicenceOfferRequest';
import { LicenceOfferResponse } from '../Models/LicenceOfferResponse';

export interface LicenceOfferServiceState {
    response: LicenceOfferResponse | null,
}

export const initialState: LicenceOfferServiceState = {
    response: null
};

export const licenceOffer = createAsyncThunk(
    'licenceService/offer', async (request: LicenceOfferRequest) => {
        const response = await offerLicence(request);
        return response;
    }
);
