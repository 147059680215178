import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { useAppDispatch } from "../../Redux/hooks";
import { YesNoDialog } from "../../YesNoDialog/YesNoDialog";

export const DefaultSettingsSetterButton = (props) => {

    const dispatch = useAppDispatch();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)

    const handleYesClick = () => {
        dispatch(props.action)
        closeConfirmDialogOpen()
    }

    const handleNoClick = () => {
        closeConfirmDialogOpen()
    }

    const openConfirmDialogOpen = () => {
        setConfirmDialogOpen(true)
    }

    const closeConfirmDialogOpen = () => {
        setConfirmDialogOpen(false)
    }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <React.Fragment>
                <Grid container justifyContent={"end"} alignContent={"center"} my={1}>
                    <Tooltip className={props.className} title={getTranslatedText("SetToDefault")}>
                        <IconButton onClick={() => { openConfirmDialogOpen() }}  >
                            <SettingsBackupRestoreIcon />
                        </IconButton >
                    </Tooltip>
                </Grid>

                <YesNoDialog
                    title={getTranslatedText("ResetToDefaultSettingsDialogTitle")}
                    content={getTranslatedText("ResetToDefaultSettingsDialogContent")}
                    titleYesButton={getTranslatedText("TEXT_YES")}
                    titleNoButton={getTranslatedText("TEXT_NO")}
                    open={confirmDialogOpen}
                    onNoClick={handleNoClick}
                    onYesClick={handleYesClick}
                />

            </React.Fragment>
        }
    </LanguageConsumer >
    );
}
