import { Grid, TextField, Typography } from "@mui/material";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks"
import { updateCustomCsvSeriesData, updateCustomCsvXAxisData } from "../Redux/CustomCsvSlice";

export const CustomData = () => {
    const dispatch = useAppDispatch();
    const customSeries = useAppSelector(store => store.customCsv.series);
    const customXAxis = useAppSelector(store => store.customCsv.xAxis)

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 2, pb: 2 }}>
                <Grid item xs={12}>
                    <Typography sx={{fontSize: "0.8em"}}>{getTranslatedText("LoadDatasFromExcelColumn")}</Typography>
                </Grid>
                <Grid item xs={3} >
                    <Grid container direction={'row'}>
                        {customSeries && customSeries.length > 0 && customXAxis &&
                            <Grid item xs={12} sx={{ maxWidth: "100%", maxHeight: "400px", overflow: "hidden", borderRight: "2px dashed #f8f8f8", p: 1,pl: 0, pr: 2 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    maxRows={8}
                                    color="success"
                                    inputProps={{maxLength: 1000000}}
                                    value={customXAxis.data && customXAxis.data.split("\n").length > 1000 ? (customXAxis.data.substring(0, 1000) + "\n...") : customXAxis.data}
                                    onChange={(e) => dispatch(updateCustomCsvXAxisData(e.target.value))}
                                    label={customXAxis.name} />
                               {customXAxis.data && customXAxis.data.split("\n").length > 0 && <Typography sx={{ fontSize: "0.8em", textAlign: "right", mr: 0 }}>{customXAxis.data && customXAxis.data.length > 0 && customXAxis.data.split("\n").length} {getTranslatedText("CustomCsvXAxisDataValues")}</Typography>}
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Grid item xs={9}>
                    <Grid container direction={'row'}>
                        {customSeries.map((serie, serieKey) => {
                            return (
                                <Grid key={serieKey} item xs={4} sx={{ maxWidth: "100%", maxHeight: "400px", overflow: "hidden", p: 1, pl: 0 }}>
                                    <TextField
                                        fullWidth
                                        id={serie.name}
                                        multiline
                                        minRows={4}
                                        maxRows={8}
                                        color="success"
                                        value={serie.data && serie.data.split("\n").length > 1000 ? (serie.data.substring(0, 1000) + "\n...") : serie.data}
                                        inputProps={{maxLength: 1000000}}
                                        onChange={(e) => dispatch(updateCustomCsvSeriesData({ iterator: serieKey, data: e.target.value }))}
                                        label={serie.name} />
                                    {serie && serie.data && serie.data.split("\n").length > 0 && <Typography sx={{ fontSize: "0.8em", textAlign: "right", mr: 0 }}>{serie.data && serie.data.length > 0 && serie.data.split("\n").length} {getTranslatedText("CustomCsvDataValues")}</Typography>}
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>

            </Grid>
        }
    </LanguageConsumer>
    )
}