/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { saveSeriesRequestsInStore } from "../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { getSeriesForGraphDataAsync } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { removeAllMonitors } from "../../Annotations/Redux/AnnotationMonitorSlice";
import { ChartRequest } from "../Models/ChartRequest";
import { SingleVisualizationModeDto } from "../Models/SingleVisualizationModeDto";
import { SuperVisualizationModeDto } from "../Models/SuperVisualizationModeDto";

interface UseHandleChangeDeveloperArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeDeveloper = ({ sciChart }: UseHandleChangeDeveloperArgs) => {
    const dispatch = useAppDispatch();

    const isDeveloper = useAppSelector(store => store.developer.isDeveloper);
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const savedOrderedSeries = useAppSelector(store => store.chartSeriesOrder.seriesOrders);
    const languageIdentifier = useAppSelector(store => store.settings.language);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface && isDeveloper) {
          
            dispatch(removeAllMonitors());
            if (!chartRequest) {
                return
            }
    
            if (chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Time ||
                chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Time) {
                dispatch(removeAllMonitors())
            }
    
            if (chartRequest.Files.length > 0) {
                var updatedChartRequestData = {
                    Files: chartRequest.Files,
                    Settings: chartRequestSettings,
                    SortedList: savedOrderedSeries,
                    LanguageIdentifier: languageIdentifier
                } as ChartRequest;
    
                dispatch(saveSeriesRequestsInStore(updatedChartRequestData));
                if (!chartRequestSettings.ImplicitChangeFromResult) {
                    dispatch(getSeriesForGraphDataAsync(updatedChartRequestData));
                }
            }
        }
    }, [isDeveloper])
}