import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sendLoadExampleFileToBackend } from '../../../Features/Home/HeaderInformation/LoadExampleFile/Redux/LoadExampleFileSlice';
import { ChartRequest } from '../../../Features/Home/Models/ChartRequest';
import { ChartResult } from '../../../Features/Home/Models/ChartResult';
import { SuperVisualizationModeDto } from '../../../Features/Home/Models/SuperVisualizationModeDto';
import { RootState } from '../../../Infrastructure/Redux/store';
import { clearChartSeriesColors, createNotExisting } from '../../ChartSeriesColors/Redux/ChartSeriesColorsSlice';
import { clearChartSeriesOrders } from '../../ChartSeriesOrder/Redux/ChartSeriesOrderSlice';
import { setAbortController } from '../../GraphViewerBackend/GraphViewerBackendSlice';
import { getSeriesDataFromBackend } from '../API/ChartResultApi';

export interface GraphDataState {
  series: ChartResult[];
  status: 'idle' | 'loading' | 'failed';
  isCustomMode: boolean;
}

export const chartResultinitialState: GraphDataState = {
  series: [],
  status: 'idle',
  isCustomMode: false,
};

export const getSeriesForGraphDataAsync = createAsyncThunk(
  'graphData/initialize',
  async (request: ChartRequest, thunkAPI) => {    
    
    const abortControllerForThisRequest = new AbortController();    
    thunkAPI.dispatch(setAbortController(abortControllerForThisRequest));
    if (request.Settings.SuperVisualization?.Mode === SuperVisualizationModeDto.None){
      thunkAPI.dispatch(clearChartSeriesColors());     
      thunkAPI.dispatch(clearChartSeriesOrders());     
    }
   
    try {
      const response = await getSeriesDataFromBackend(request, abortControllerForThisRequest);
      for (let n = 0; n < response[0].ChartSeries.length; n++){
        let seriesShouldBeOrdered =  request.SortedList.filter(x => x.title === response[0].ChartSeries[n].Title);
        if (seriesShouldBeOrdered.length > 0){
          response[0].ChartSeries[n].Position = seriesShouldBeOrdered[0].position;
        }
      }      

      if (response[0].Title === "SuperVisualisation"){
        thunkAPI.dispatch(createNotExisting({
          list: response[0].ChartSeries.map(series => ({ title: series.Title, color: series.Color}))
        }));         
      }
    
      var orderedChartSeries =response[0].ChartSeries.sort((a, b) => { return a.Position - b.Position })
      response[0].ChartSeries = orderedChartSeries;
      return response; 
         
    }
    catch(error: any) {      

      if (error.response && error.response.data && error.response.data.MessageText){
        throw new Error(error.response.data.MessageText)
      }

      throw error;     
    }    
  }
);

export const graphDataSlice = createSlice({
  name: 'graphData',
  initialState: chartResultinitialState,
  reducers: {
    resetCustomMode: (state) => {
      state.isCustomMode = false;
    },
    resetChartResult: (state) => {
      state.series = []
    },
    removeSingleSeries: (state, action) => {   
      
      
      if(state.series && state.series.length > 0){
        let seriesToDelete = state.series[0].ChartSeries.filter(x=>x.ChartRequestId === action.payload.idToDelete);
        seriesToDelete.forEach( x => {
          let titleToDelete = x.Title;
          state.series[0].ChartSeries = [...state.series[0].ChartSeries.filter(x=> x.ChartRequestId !== action.payload.idToDelete)];
          state.series[0].MetaData = [...state.series[0].MetaData.filter(x => x.Title !== titleToDelete)]
        });
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeriesForGraphDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSeriesForGraphDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.series = [...action.payload];
        state.isCustomMode = action.payload.length > 0 && action.payload[0].IsCustom
      })
      .addCase(getSeriesForGraphDataAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(sendLoadExampleFileToBackend.fulfilled, (state, action) => {
        state.series = [...action.payload];
        state.isCustomMode = action.payload.length > 0 && action.payload[0].IsCustom
      })
  },
});


export const { resetCustomMode, removeSingleSeries, resetChartResult } = graphDataSlice.actions;

export const isCustomMode = (state: RootState) => state.chartResultData.isCustomMode;
export const selectSeries = (state: RootState) : ChartResult[] => state.chartResultData.series;
export const selectSeriesState = (state: RootState) => state.chartResultData.status;

export default graphDataSlice.reducer;
