import { createSlice } from "@reduxjs/toolkit";

export interface AdvertisingState {
    cockpitAdvertisingIsShown: boolean,
}

const initialState: AdvertisingState = {
    cockpitAdvertisingIsShown: false,
};


export const AdvertisingSlice = createSlice({
    name: 'Advertising',
    initialState,
    reducers: {
        showCockpitAdvertising: (state) => {
            state.cockpitAdvertisingIsShown = true;
        },
        hideCockpitAdvertising: (state) => {
            state.cockpitAdvertisingIsShown = false;
        },

    },
});

export const { showCockpitAdvertising, hideCockpitAdvertising} = AdvertisingSlice.actions;

export default AdvertisingSlice.reducer;
