import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getNavigationSteps = () => {
  return [
    // Navigation - PAGES
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-intro',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpIntroMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-inputBoxMode',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpInputBoxModeTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpInputBoxModeMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-inputBoxFrom',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpInputboxFromTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpInputboxFromMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-inputBoxTo',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpInputBoxToTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpInputBoxToMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-buttonDeleteFilter',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpButtonDeleteFilterTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpButtonDeleteFilterMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-buttonAddFilter',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpButtonAddFilterTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpButtonAddFilterMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-buttonResetAllFilter',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpButtonResetAllFilterTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpButtonResetAllFilterMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-home-sideDrawerView-filter-buttonApplyFilter',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("FilterOnlineHelpButtonApplyFilterTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("FilterOnlineHelpButtonApplyFilterMessage") }}></div></div>)
    },


  ]
}
