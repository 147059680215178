import { IconButton, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, Skeleton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isCustomMode, selectSeries } from "../../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { SeriesOrder, setOrUpdateOrder } from "../../../../Infrastructure/ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { SuperVisualizationModeDto } from "../../../Home/Models/SuperVisualizationModeDto";
import { RootState } from "../../../../Infrastructure/Redux/store";
import { usePersistedChartSeriesOrder } from "../../../../Infrastructure/PersistedChartSeriesOrder/Hooks/usePersistedChartSeriesOrder";
import { setHighlightedSeries, ShowCurrentValue } from "../../../Settings/Redux/SettingsSlice";
import { changeSingleSeriesData } from "../../../../Infrastructure/ChartSeriesVisibility/Redux/ChartSeriesVisibilitySlice";
import { ChartSeries } from "../../../Home/Models/ChartSeries";
import { TimeSynchronisationSeriesItem } from "../../../TimeSync/Redux/TimeSynchronisationSlice";
import { SingleVisualizationModeDto } from "../../../Home/Models/SingleVisualizationModeDto";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { setColorPicker } from "../../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { ColorPickerOption } from "../../../ColorPicker/ColorPickerOption";
import { shadeColor } from "../../../Home/Style/ColorChangeProvider";
import { ActiveCurveFilesLengthIfIsNotLicenced } from "../../Provider/NotLicencedProvider";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { usePersistedChartSeriesVisibility } from "../../../../Infrastructure/PersistedChartSeriesVisibility/Hooks/usePersistedChartSeriesVisibility";
import { RightClickMenue } from "./RightClickMenue";

interface ISeriesLegendContentProps {
    seriesToHighlight: string;
    toggleStrokeWidthOnHover: (parentKey: number, childKey: number, strokeWidth: number, name: string) => void,
}

export const Content = (props: ISeriesLegendContentProps) => {
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const seriesData = useAppSelector(selectSeries);
    const isDeveloper = useAppSelector(store => store.developer.isDeveloper);
    const [orderedSeries, setOrderedSeries] = useState<SeriesOrder[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openRightClickMenu = Boolean(anchorEl);
    const timeSynchronisationItems = useAppSelector(store => store.timeSynchronization.timeSychonisationsSeriesItems);
    const isCurveAnimationEnabled = useAppSelector(store => store.settings.isCurveAnimationEnabled);
    const [open, setOpen] = useState<number[]>([0]);
    const isSuperVisualisation = useAppSelector((store: RootState) => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const seriesColors = useAppSelector(store => store.chartSeriesColors.colors);
    const selectedSeries = useAppSelector(store => store.settings.highlightedSeries);
    const chartRequestSettings = useAppSelector(store => store.settings);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
    const currentValues = useAppSelector(store => store.legendCurrentValue.currentValues);
    const showValuesInLegend = useAppSelector(store => store.settings.showCurrentValuesInChart === ShowCurrentValue.InSeriesLegend);
    const customMode = useAppSelector(isCustomMode);
    const [rightClickedHasValues, setRightClickedHasValues] = useState<boolean>(true);
    const [isHomeLegendSeriesCollapseOpen, setIsHomeLegendSeriesCollapseOpen] = useState(true);
    const [parentKey, setParentKey] = useState(0);
    const savedOrderedSeries = useAppSelector(store => store.chartSeriesOrder.seriesOrders);
    const chartSeriesVisibility = useAppSelector(store => store.chartSeriesVisibility.seriesVisibilities)
    const [rightClickedSeriesTitle, setSeriesTitle] = useState('');
    const [rightClickedSeriesId, setSeriesId] = useState('');

    const { updatePersistedChartSeriesOrder } = usePersistedChartSeriesOrder();
    usePersistedChartSeriesVisibility();

    const handleChange = (key: number) => {
        if (open.includes(key)) {
            setOpen(oldArray => [...oldArray.filter(k => k !== key)])
            setIsHomeLegendSeriesCollapseOpen(false)
        } else {
            setOpen(oldArray => [...oldArray, key])
            setIsHomeLegendSeriesCollapseOpen(true)
        }
    };

    const changeSingleSeriesVisibility = (parentKey: number, seriesTitle: string) => {
        dispatch(changeSingleSeriesData({ parentKey, seriesTitle }));
    }

    const toggleStrokeWidthOnHover = (parentKey: number, childKey: number, strokeWidth: number, name: string) => {
        name === selectedSeries && dispatch(setHighlightedSeries(""));
        anchorEl ?? props.toggleStrokeWidthOnHover(parentKey, childKey, strokeWidth, name);
    }

    const rightOrLeftClickHandler = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, parentKey: number, childKey: number, seriesIdRightClicked: string, seriesTitleRightClicked: string, hasValues: boolean) => {
        ev.preventDefault();

        if (anchorEl) {
            handleClose()
            return
        }

        setParentKey(parentKey);
        setSeriesTitle(seriesTitleRightClicked);
        setSeriesId(seriesIdRightClicked);
        setAnchorEl(ev.currentTarget);
        setRightClickedHasValues(hasValues);
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        let orderedSeriesList = ensureOrderedChartSeriesList();
        let newOrdered = reorder(orderedSeriesList, result.source.index, result.destination.index);
        let copy = newOrdered.map((item, index) => ({ title: item.title, position: index }));

        dispatch(setOrUpdateOrder({ list: copy }));
        updatePersistedChartSeriesOrder(copy);
        setOrderedSeries(copy);
    }

    const reorder = (list: SeriesOrder[], startIndex: number, endIndex: number): SeriesOrder[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getSecondaryElement = (item: ChartSeries, curr) => {
        let secondaryItemForTimeSynchronisation = getCurrentTimeSynchronizationItem(timeSynchronisationItems, item.Title);

        if (!item.HasValues) {
            return <React.Fragment></React.Fragment>
        }

        return <React.Fragment>
            <Typography component="span" style={{ fontWeight: "400" }}>
                {getChartSeriesVisibility(item) && showValuesInLegend && curr.currentValue && curr.currentValue !== "" && ((!isLicenced ? "🔒" : (isNaN(curr.currentValue) ? "---" : curr.currentValue)) + " " + curr.abbrevation)}
            </Typography>
            <br />
            <Typography component="span" style={{ fontWeight: "400" }}>
                {!shouldCurveAnimationBeInactive() && isSuperVisualisation && isCurveAnimationEnabled && getChartSeriesVisibility(item) && timeSynchronisationItems && secondaryItemForTimeSynchronisation && secondaryItemForTimeSynchronisation.currentValue}
            </Typography>
        </React.Fragment>;
    }

    const getChartSeriesVisibility = (series: ChartSeries) => {
        let found = chartSeriesVisibility.find(item => item.title === series.Title);
        return found && found.visible;
    }

    const getChartSeriesItemByTitle = (title: string): ChartSeries => {
        let found = seriesData[0].ChartSeries.find(item => item.Title === title);
        return found ?? {} as ChartSeries;
    }

    const getCurrentTimeSynchronizationItem = (timeSynchronisationItems: TimeSynchronisationSeriesItem[], seriesTitle: string) => {
        let index = timeSynchronisationItems.findIndex(x => x.chartSeriesTitle === seriesTitle);
        if (index !== -1) {
            return timeSynchronisationItems[index];
        }
        return undefined;
    }

    const shouldCurveAnimationBeInactive = () => {
        if (isSuperVisualisation) {
            if (chartRequestSettings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Angle) {
                return false;
            }
            return true;
        } else {
            if (chartRequestSettings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Angle ||
                chartRequestSettings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Torque) {
                return false;
            }
            return true;
        }
    }

    useEffect(() => {
        if (seriesData && seriesData.length > 0 && seriesData[0].ChartSeries.length > 0) {
            let orderedSeriesList = ensureOrderedChartSeriesList();
            setOrderedSeries(orderedSeriesList);
        }
    }, [seriesData])

    const ensureOrderedChartSeriesList = () => {
        let sorted = [] as SeriesOrder[];

        if (savedOrderedSeries.length > 0 && savedOrderedSeries.length && seriesData && seriesData.length > 0) {
            let existing = savedOrderedSeries.filter(x => seriesData[0].ChartSeries.findIndex(y => x.title === y.Title) !== -1);
            let notExisting = seriesData[0].ChartSeries.filter(x => savedOrderedSeries.findIndex(y => x.Title === y.title) === -1);

            existing.sort((a, b) => a.position - b.position);
            sorted = existing.concat(notExisting.map(x => ({ title: x.Title, position: x.Position })))
            sorted = sorted.map((x, index) => ({ title: x.title, position: index }));
        }
        else {
            if (seriesData && seriesData.length > 0) {
                sorted = seriesData[0].ChartSeries
                    .map((series, index) => ({ title: series.Title, position: index }))
                    .sort((a, b) => a.position - b.position);
            }
        }
        return sorted;
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>
                    {seriesData && seriesData.length > 0 && seriesData.map((series, keyParent) => {
                        return (
                            <List
                                className="home-legendSeries"
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                key={keyParent}>
                                <ListItemButton onClick={() => handleChange(keyParent)} className="home-legendSeries-ListItemButton onlinehelp-home-legendSeries-ListItemButton">
                                    <Tooltip arrow title={isSuperVisualisation ? getTranslatedText(series.Title) : series.Title} enterDelay={800} enterNextDelay={800}>
                                        <ListItemText className="fileName" primary={isSuperVisualisation ? getTranslatedText(series.Title) : series.Title} />
                                    </Tooltip>
                                    {isHomeLegendSeriesCollapseOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={isHomeLegendSeriesCollapseOpen} timeout="auto" unmountOnExit className="home-legendSeries-collapse">
                                    <List component="div" disablePadding>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="list" style={{ transform: "none" }}
                                                renderClone={(provided, snapshot, rubric) => (
                                                    <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <Skeleton height={70}></Skeleton>
                                                    </div>
                                                )}>
                                                {provided => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>

                                                        {orderedSeries.map((sortedItem, keyChild) => {
                                                            const item = getChartSeriesItemByTitle(sortedItem.title);
                                                            const legendeItemStyle = {
                                                                opacity: item.HasValues ? 1 : 0.3
                                                            }

                                                            let filteredStoredColor = seriesColors.filter(color => color.title === item.Title);
                                                            const curr = currentValues.filter(x => x.title === item.Title)[0]

                                                            return (!item.IsInternalCurve && !isDeveloper) || (item.IsInternalCurve && isDeveloper) || !item.IsInternalCurve ? (
                                                                <Draggable isDragDisabled={!isLicenced || orderedSeries.length <= 1} draggableId={keyChild.toString()} index={keyChild} key={keyChild}>

                                                                    {provided => (<>
                                                                        <ListItemButton disableRipple={anchorEl} ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps} className="drag-handle onlinehelp-home-legendSeries-serieItem" disabled={!isLicenced && keyChild > ActiveCurveFilesLengthIfIsNotLicenced} sx={{ borderBottom: 1, pl: 2, border: selectedSeries === item.Title && getChartSeriesVisibility(item) ? "2px solid #32992c" : "none" }} key={keyChild}
                                                                            onMouseEnter={() => toggleStrokeWidthOnHover(keyParent, keyChild, 3.5, item.Title)}
                                                                            onMouseLeave={() => toggleStrokeWidthOnHover(keyParent, keyChild, 1.5, item.Title)}
                                                                            onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => rightOrLeftClickHandler(e, keyParent, keyChild, item.ChartRequestId, item.Title, item.HasValues)}
                                                                            autoFocus={selectedSeries === item.Title && getChartSeriesVisibility(item)} >

                                                                            <ListItemIcon>
                                                                                <Tooltip arrow title={getTranslatedText("SetSeriesColorTooltip")}>

                                                                                    <FiberManualRecordIcon className="color-dot-animation onlinehelp-home-legendSeries-serie-colorIcon" sx={{ cursor: "pointer", fontSize: "2em", color: filteredStoredColor.length > 0 ? filteredStoredColor[0].color : (isDarkMode ? shadeColor(item.Color, 40) : item.Color) }} onClick={() => {
                                                                                        dispatch(setColorPicker({ color: filteredStoredColor.length > 0 ? filteredStoredColor[0].color : item.Color, title: item.Title, option: ColorPickerOption.seriesLegend }));
                                                                                    }} />

                                                                                </Tooltip>

                                                                            </ListItemIcon>
                                                                            <Tooltip arrow enterDelay={300} enterNextDelay={300} followCursor={true} title={!isSuperVisualisation ? getTranslatedText(item.Title) : item.Title}>

                                                                                <ListItemText style={legendeItemStyle} className="fileName onlinehelp-home-legendSeries-serie-fileName" sx={{ fontWeight: "light" }} secondary={getSecondaryElement(item, curr)} primary={!isSuperVisualisation && !customMode ? getTranslatedText(item.Title) : item.Title}
                                                                                    aria-controls={openRightClickMenu ? 'basic-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={openRightClickMenu ? 'true' : undefined}  ></ListItemText>
                                                                            </Tooltip>

                                                                            <IconButton size={'small'} sx={{ background: isDarkMode ? '#444' : '#ebf2ee' }} data-testid="visibility-Parent" className="onlinehelp-home-legendSeries-serie-iosSwitch" onClick={() => changeSingleSeriesVisibility(keyParent, item.Title)}>
                                                                                {getChartSeriesVisibility(item) && item.HasValues ?
                                                                                    <VisibilityOutlinedIcon data-testid="visibility-Icon" color="success" /> :
                                                                                    <VisibilityOffOutlinedIcon data-testid="visibility-off-Icon" />}
                                                                            </IconButton>

                                                                            {!isLicenced && keyChild > ActiveCurveFilesLengthIfIsNotLicenced &&
                                                                                <NavLink to='/settings'>
                                                                                    <span className="notLicencedIconSeries">
                                                                                        <LockIcon htmlColor='#fcba03' />
                                                                                    </span>
                                                                                </NavLink>}

                                                                            <RightClickMenue
                                                                                parentKey={parentKey}
                                                                                rightClickedSeriesTitle={rightClickedSeriesTitle}
                                                                                rightClickedSeriesId={rightClickedSeriesId}
                                                                                hasValues={rightClickedHasValues}
                                                                                anchorEl={anchorEl}
                                                                                openRightClickMenu={openRightClickMenu}
                                                                                orderedSeries={orderedSeries}
                                                                                handleClose={handleClose}
                                                                            />

                                                                        </ListItemButton>
                                                                        <Divider variant="middle" component="li" />
                                                                    </>
                                                                    )}
                                                                </Draggable>) : <div key={keyChild}></div>
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </List>
                                </Collapse>
                            </List>);
                    })}
                </React.Fragment>
            }</LanguageConsumer>
    );
}