import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export interface YesNoDialogProps {
  open: boolean;
  title: string;
  content: string | null;
  onYesClick: () => void;
  onNoClick: () => void;
  titleYesButton: string;
  titleNoButton: string;
}

export const YesNoDialog = ({ open, title, titleYesButton, titleNoButton, onYesClick, onNoClick, content }: YesNoDialogProps) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onNoClick}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      {
      content !== null && <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: content }}>
        </div>
      </DialogContent>
      }

      <DialogActions>
        <Button color="info" variant="outlined" fullWidth size="large" onClick={onNoClick}>{titleNoButton}</Button>
        <Button color="success" variant="outlined" fullWidth size="large" onClick={onYesClick}>{titleYesButton}</Button>
      </DialogActions>
    </Dialog>
  );
}