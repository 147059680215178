import { OnlineHelpIconButton } from "../../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton"
import { SeriesLegendOnlineHelp } from "../../../Infrastructure/OnlineHelp/SeriesLegend/SeriesLegendOnlineHelp"
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { analyze_ShowOnlineHelp } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { OnlineHelpItems } from "../../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { resetColorPicker, setColorPicker } from "../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { RootState } from "../../../Infrastructure/Redux/store";
import { getTranslation, LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { SuperVisualizationModeDto } from "../../Home/Models/SuperVisualizationModeDto";

export const Header = () => {
    const dispatch = useAppDispatch();
    const seriesData = useAppSelector(selectSeries);
    const [isOnlineHelpEnabled, setIsOnlineHelpEnabled] = useState(false);
    const [isHomeLegendSeriesCollapseOpen, setIsHomeLegendSeriesCollapseOpen] = useState(true);
    const [isHomeLegendSeriesCollapseOpenBeforeOnlineHelp, setIsHomeLegendSeriesCollapseOpenBeforeOnlineHelp] = useState(isHomeLegendSeriesCollapseOpen);
    const isSuperVisualisation = useAppSelector((store: RootState) => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);

    const openSeriesLegendOnlineHelp = () => {
        setIsOnlineHelpEnabled(true)
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.SeriesLegendOnlineHelp }))
    }

    const closeSeriesLegendOnlineHelp = () => {
        setIsOnlineHelpEnabled(false);
    }

    const openColorPicker = () => {
        if (isSuperVisualisation)
            dispatch(setColorPicker({ color: "#32992c", title: getTranslation("MyCurve") }));
        else
            dispatch(setColorPicker({ color: "#32992c", title: getTranslation("MyMeasurementUnit") }));
    }

    const closeColorPicker = () => {
        dispatch(resetColorPicker());
    }

    const openHomeLegendSeriesCollapseForOnlineHelp = () => {
        setIsHomeLegendSeriesCollapseOpenBeforeOnlineHelp(isHomeLegendSeriesCollapseOpen)
        setIsHomeLegendSeriesCollapseOpen(true)
    }

    const closeHomeLegendSeriesCollapseForOnlineHelp = () => {
        setIsHomeLegendSeriesCollapseOpen(isHomeLegendSeriesCollapseOpenBeforeOnlineHelp)
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>

                    <SeriesLegendOnlineHelp
                        showOnlineHelp={isOnlineHelpEnabled}
                        closeOnlineHelp={closeSeriesLegendOnlineHelp}
                        openColorPicker={openColorPicker}
                        closeColorPicker={closeColorPicker}
                        isSuperVisualisation={isSuperVisualisation}
                        isHomeLegendSeriesCollapseOpen={isHomeLegendSeriesCollapseOpen}
                        openHomeLegendSeriesCollapseForOnlineHelp={openHomeLegendSeriesCollapseForOnlineHelp}
                        closeHomeLegendSeriesCollapseForOnlineHelp={closeHomeLegendSeriesCollapseForOnlineHelp} />

                    {(seriesData && seriesData[0]) ?
                        <Grid container direction={"row"}>
                            <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 14, zIndex: '0 !important' }} color="text.secondary" gutterBottom>
                                    {getTranslatedText("Series")}
                                </Typography>
                            </Grid>

                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                <OnlineHelpIconButton
                                    isInSettings={false}
                                    title={"OnlineHelp"}
                                    isOnlineHelpEnabled={false}
                                    onClickFunction={openSeriesLegendOnlineHelp} />
                            </Grid>
                        </Grid>
                        : <></>}
                </React.Fragment>
            }</LanguageConsumer>
    );
}