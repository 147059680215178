import { createAsyncThunk } from '@reduxjs/toolkit';
import { activateLicence } from '../Api/ActivateLicence';
import { LicenceActivationRequest } from '../Models/LicenceActivationRequest';

export const licenceActivation = createAsyncThunk(
    'licenceService/activation', async (request: LicenceActivationRequest) => {
        const response = await activateLicence(request);
        return response;
    }
);
