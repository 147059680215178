import { createTransform } from "redux-persist";

const setTransformLoadFromController = createTransform(
    
    (inboundState: any, key) => {
      
      return {         
        ...inboundState, 
        isShown: false,
        showHelp: false,        
        showHelpModal: false,
        updateActual: {
          ...inboundState.updateActual,
          isEnabled: false
        },
        status: "idle", 
        availableFiles: [],
        activeFileurls: []
      };
    },
    
    (outboundState: any, key) => {
      
      return { 
        ...outboundState, 
        isShown: false,
        showHelp: false,     
        showHelpModal: false,   
        updateActual: {
          ...outboundState.updateActual,
          isEnabled: false
        },        
        status: "idle", 
        availableFiles: [],
        activeFileurls: [] 
      };
    },    
    { 
        whitelist: ['loadAvailableFilesFromController'] 
    }
  );

  export default setTransformLoadFromController;