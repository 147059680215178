/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import { createRef, useEffect } from 'react';
import { LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';
import { licenceActivation } from "../../Infrastructure/LicenceService/Activation/Redux/LicenceActivationServiceSlice";
import { initializeClientId, resetLicence, setPrivacyAcknowledged } from "../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { PrivacyLanguage } from "../Privacy/Languages/PrivacyLanguage";
import { acceptLicenseConsent, rejectLicenseConsent } from "./LicenseConsentSlice";

export interface LicenseConsentDialogProps {
  open: boolean;
}

export const LicenseConsentDialog = ({ open }: LicenseConsentDialogProps) => {
  const language = useAppSelector(store => store.settings.language);
  const licenceAuthentication = useAppSelector(store => store.licenceService.licenceAuthentication);
  const clientId = useAppSelector(store => store.licenceService.licenceAuthentication.clientId);
  const privacyAcknowledged = useAppSelector(store => store.licenceService.licenceAuthentication.privacyAcknowledged)
  const dispatch = useAppDispatch();
  var scrollRef = createRef<HTMLDivElement>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPrivacyAcknowledged(event.target.checked));
  };

  const handleReject = () => {
    dispatch(rejectLicenseConsent());
    dispatch(resetLicence());
  }

  const handleAccept = () => {
    if (privacyAcknowledged) {
      dispatch(acceptLicenseConsent());
      dispatch(initializeClientId());
    }
  }

  useEffect(() => {
    if (clientId && !licenceAuthentication.isLicenced) {
      dispatch(licenceActivation({
        ClientId: clientId,
        EmailAddress: licenceAuthentication.emailAddress,
        MasterOrChildKey: licenceAuthentication.licenceKey,
        PrivacyAcknowledged: licenceAuthentication.privacyAcknowledged
      }))
    }
  }, [clientId])


  useEffect(() => {
    if (open) {
      setTimeout(() => {
        scrollRef && scrollRef.current && scrollRef.current.scrollTo(0, 0)
      }, 100);
    }
  }, [open])

  return (<LanguageConsumer>
    {({ getTranslatedText }) =>
      <>

        <Dialog onClose={handleReject} open={open} maxWidth={'lg'} PaperProps={{ sx: { height: "80%" } }}>
          <DialogTitle>{getTranslatedText("LicenseConsentDialogTitle")}</DialogTitle>
          <DialogContent ref={scrollRef}>

            <PrivacyLanguage lang={language} />
            <FormGroup>
              <FormControlLabel sx={{ mt: 4 }} control={<Checkbox
                color="success"
                autoFocus
                checked={privacyAcknowledged}
                onChange={handleChange}
                id="name" />}
                label={getTranslatedText("LicenseConsentDialogCheckbox")} />
            </FormGroup>

          </DialogContent>
          <DialogActions>
            <Button color="success" disabled={!privacyAcknowledged} variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={handleAccept}>{getTranslatedText("LicenseConsentDialogAcceptButton")}</Button>
            <Button color="secondary" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={handleReject}>{getTranslatedText("LicenseConsentDialogRejectButton")}</Button>
          </DialogActions>
        </Dialog>
      </>}
  </LanguageConsumer>
  );
}