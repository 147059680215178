/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { LoadFilesEvents } from "../Model/Categories/LoadFilesEnums";
import { reset_analyze_LoadFiles } from "../Redux/AnalyticsSlice";

const PublishLoadFilesAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();

    const loadFilesState = useAppSelector(store => store.analytics.loadFilesState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackLoadFiles = TrackEvent(LoadFilesEvents.Load.toString(), {})

    useEffect(() => {
        if (loadFilesState.toggle) {
            try{
                trackLoadFiles({ Source: loadFilesState.source.toString(), NrOfFiles: loadFilesState.nrOfFiles.toString() })
            }
            catch{}
            dispatch(reset_analyze_LoadFiles())
        }
    }, [loadFilesState.toggle])

    return (
        <></>
    )
}

export default PublishLoadFilesAnalytics;