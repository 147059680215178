export enum HardwareType {
    Ast5S,
    Ast5,
    Ast6,
    Asti6,
    Ast10,
    Ast11,
    Ast12,
    Ast40,
    Interface330,
    ComCenterEd,
}