import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { Color } from "../../../Infrastructure/ChartSeriesColors/Redux/ChartSeriesColorsSlice";
import { ChartResult } from "../Models/ChartResult";

export const useChangeSeriesColor = (sciChart: TWebAssemblyChart, seriesColors: Color[], chartResult: ChartResult[]) =>{

    if (sciChart && sciChart.sciChartSurface){
        var seriesAsArray = sciChart.sciChartSurface.renderableSeries.asArray();

        if (chartResult && chartResult.length > 0){
            seriesAsArray.forEach(x => { 
                var defaultColor = chartResult[0].ChartSeries.find(s => x.getDataSeriesName() === s.Title);
                if (defaultColor){
                    x.stroke = defaultColor.Color;
                }
            });
        }
        
        seriesColors.forEach(color => {
            var foundSerie = seriesAsArray.find(serie => serie.getDataSeriesName() === color.title);
            if (foundSerie){
                foundSerie.stroke = color.color;
            }
        });
    }
}