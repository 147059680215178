import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DevicesAndGroupsRequest } from "../Api/DevicesAndGroupsRequest";
import { getAvailableDeviesAndGroups as getAvailableDevicesAndGroups } from "../Api/GetAvailableDevicesAndGroups";
import { getDevicesAndGroupsAsList, getParsedDeviceAndGroups, setCheckedStateForChildrenOf, setCheckedStateForParentOf, getListOfIdsFromCheckedDevices, findDeviceOrGroupById, sortDevicesAndGroups } from "./LoadFromCockpitDeviceAndGroups_Logic";

export interface CockpitDeviceOrGroupTreeViewModel {
    id: string,
    deviceOrGroupId: number,
    displayPosition: number,
    isDevice: boolean,
    isOnline: boolean,
    name: string,
    remoteAddress: string,
    supportedDeviceTypeId: number,
    isChecked: boolean,
    indeterminateState: boolean
    children: CockpitDeviceOrGroupTreeViewModel[],
    parentId: string | null
}

export interface LoadFromCockpitState {
    cockpitResponse: CockpitDeviceOrGroupTreeViewModel[] | null,
    status: 'idle' | 'loading' | 'failed';
    DeviceSelectedIds: number[],

}

export const initialStateLoadDeviceAndGroups: LoadFromCockpitState = {
    cockpitResponse: [],
    status: "idle",
    DeviceSelectedIds: [],
};

export const loadDevicesAndGroups = createAsyncThunk('LoadFromCockpitDevicesAndGroups', async (request: DevicesAndGroupsRequest, { rejectWithValue }) => {
    const response = await getAvailableDevicesAndGroups(request);    
    return response;
}
);

export const LoadFromCockpitDeviceAndGroupsSlice = createSlice({
    name: 'LoadFromCockpitDeviceAndGroups',
    initialState: initialStateLoadDeviceAndGroups,
    reducers: {
        updateCheckedStateOfDevice: (state, action) => {
            if (!state.cockpitResponse)
                return

            var deviceId = action.payload.deviceId
            var checkedState = action.payload.checkedState

            var device = findDeviceOrGroupById(deviceId, state.cockpitResponse) as CockpitDeviceOrGroupTreeViewModel
            device.isChecked = checkedState
            device.indeterminateState = false

            setCheckedStateForChildrenOf(device, checkedState)
            setCheckedStateForParentOf(device, state.cockpitResponse)
            state.DeviceSelectedIds = getListOfIdsFromCheckedDevices(state.cockpitResponse)
        },
        cleanDevicesAndGroupsCache: (state) => {
            state.cockpitResponse = []
            state.DeviceSelectedIds = []
        }
    },
    extraReducers: builder => {
        builder.addCase(loadDevicesAndGroups.pending, (state) => {             
            state.status = 'loading';
        })
            .addCase(loadDevicesAndGroups.fulfilled, (state, action) => {                
                state.status = 'idle';                
                var parsedDevicesAndGroups = getParsedDeviceAndGroups(action.payload)
                var sortedDevicesAndGroups = sortDevicesAndGroups(parsedDevicesAndGroups)
                var flatListOfParsedDevicesAndGroups = getDevicesAndGroupsAsList(sortedDevicesAndGroups)

                flatListOfParsedDevicesAndGroups?.forEach((x) => {
                    if (state.DeviceSelectedIds.some(y => y === x.deviceOrGroupId && x.isDevice)) {
                        x.isChecked = true
                        setCheckedStateForParentOf(x, sortedDevicesAndGroups)
                    }
                })
                state.cockpitResponse = sortedDevicesAndGroups

            })
            .addCase(loadDevicesAndGroups.rejected, (state, action) => {                      
                state.cockpitResponse = []
                state.status = 'failed';
            })
    }
});

export const { updateCheckedStateOfDevice,cleanDevicesAndGroupsCache } = LoadFromCockpitDeviceAndGroupsSlice.actions;

export default LoadFromCockpitDeviceAndGroupsSlice.reducer;


