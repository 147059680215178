import { LoadSelectedFilesFromControllerRequest } from "../Models/LoadSelectedFilesFromControllerRequest";

const simpleFetchCall = async (url: string, abortController: AbortController) => {

    return fetch(url, { signal: abortController.signal })
}

export const getSelectedFiles = async (downloadSelectedFilesFromControllerRequest: LoadSelectedFilesFromControllerRequest): Promise<File[]> => {
    const urlFileNameRegEx = /[^/|^=|^?]*$/;
    try {

        var awaitedResponse = await callCockpitApi(downloadSelectedFilesFromControllerRequest);
        var fileList = awaitedResponse.map((blobResponse) => {
            let fileName = urlFileNameRegEx.exec(blobResponse.url);
            return new File([blobResponse.blob], fileName !== null ? fileName[0] : blobResponse.url);
        })

        return new Promise<any>((resolve, rejected) => {
            if (fileList && fileList.length > 0) {
                resolve(fileList)
            }
        });

    } catch (error: any) {
        return new Promise<any>((resolve, rejected) => {
            rejected(error.response && error.response.data ? error.response.data.MessageText : error);
        })
    }
}

const callCockpitApi = async (downloadSelectedFilesFromControllerRequest: LoadSelectedFilesFromControllerRequest) => {

    let responsePromises = downloadSelectedFilesFromControllerRequest.urls.map(async (url) => {
        return simpleFetchCall(url, downloadSelectedFilesFromControllerRequest.abortController);
    })
    var awaitedPromises = await Promise.all(responsePromises);

    let response = awaitedPromises.map(async (x) => {
        return {
            blob: await x.blob(),
            url: x.url
        };
    })

    var awaitedResponse = await Promise.all(response);
    return awaitedResponse
}