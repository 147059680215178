/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Drawer, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toggleFilter } from "../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { FilterSettings, FilterMode } from "../../Features/Home/Models/FilterSettings";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { setFilterSettings } from "../Settings/Redux/SettingsSlice";
import { OnlineHelpIconButton } from "../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { FilterOnlineHelp } from "../../Infrastructure/OnlineHelp/Filter/FilterOnlineHelp";
import { analyze_ApplyFilter, analyze_ResetFilter } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";

export const Filter = () => {

    const [filterSettingsEditor, setFilterSettingsEditor] = useState<FilterSettings[]>([]);
    const [isOnlineHelpEnabled, setIsOnlineHelpEnabled] = useState(false);

    const dispatch = useAppDispatch();
    const isFilterActive = useAppSelector(store => store.chartModifier.filter);
    const filterSettingsRequest = useAppSelector(store => store.settings.chartRequestSettings.FilterSettings);

    useEffect(() => {
        filterSettingsRequest && setFilterSettingsEditor(filterSettingsRequest);

        if (!filterSettingsEditor || filterSettingsEditor.length === 0) {
            setFilterSettingsEditor(oldState => [...oldState, { Range1: "", Range2: "", Mode: FilterMode.Torque }])
        }

    }, [filterSettingsRequest])


    const toggleDrawer =
        () =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                dispatch(toggleFilter());
            };

    const updateRange1 = (key: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var newListOfFilter = filterSettingsEditor?.map(((filter, index) => {
            if (key === index) {
                return {
                    ...filter,
                    Range1: !event.target.value.replace(/\s/g, '').length ? "" : event.target.value
                }
            } else {
                return filter;
            }
        }));
        setFilterSettingsEditor(newListOfFilter);
    }

    const updateRange2 = (key: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var newListOfFilter = filterSettingsEditor?.map(((filter, index) => {
            if (key === index) {
                return {
                    ...filter,
                    Range2: !event.target.value.replace(/\s/g, '').length ? "" : event.target.value
                }
            } else {
                return filter;
            }
        }));
        setFilterSettingsEditor(newListOfFilter);
    }

    const updateMode = (key: number) => (event: SelectChangeEvent<FilterMode>) => {
        var newListOfFilter = filterSettingsEditor?.map(((filter, index) => {
            if (key === index) {
                return {
                    ...filter,
                    Mode: event.target.value as FilterMode
                }
            } else {
                return filter;
            }
        }));
        setFilterSettingsEditor(newListOfFilter);
    }

    const addEmptyFilter = () => {
        if (filterSettingsEditor.length < 5) {
            setFilterSettingsEditor(oldArray => [...oldArray, { Range1: "", Range2: "", Mode: FilterMode.Torque }]);
        }
    }

    const removeSingleFilter = (index: number) => {
        if (filterSettingsEditor.length > 1) {
            setFilterSettingsEditor(oldArray => [...oldArray.filter((item, itemIndex) => itemIndex !== index)]);
        }
    }

    const checkIfApplyFilterButtonShouldBeActive = (): boolean => {
        return filterSettingsEditor.some(filter => Object.values(filter).some(val => val === null || val === ""));
    }

    const setFilterEditorSettingsToChartRequestSettings = () => {
        dispatch(setFilterSettings(filterSettingsEditor));
        const selectedFilters = filterSettingsEditor.map(x => FilterMode[x.Mode]);
        dispatch(analyze_ApplyFilter({ types: selectedFilters }));
        dispatch(toggleFilter());
    }

    const resetFilterEditorSettingsToChartRequestSettings = () => {
        setFilterSettingsEditor([{ Range1: "", Range2: "", Mode: FilterMode.Torque }])
        dispatch(setFilterSettings(null))
        dispatch((analyze_ResetFilter()))
    }

    const openFilterOnlineHelp = () => {
        setIsOnlineHelpEnabled(true);
    }
    const closeFilterOnlineHelp = () => {
        setIsOnlineHelpEnabled(false);
    }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <Drawer
                anchor={'right'}
                open={isFilterActive}
                onClose={toggleDrawer()}
                className="onlinehelp-home-sideDrawerView-filter-intro"
            >
                <FilterOnlineHelp
                    showOnlineHelp={isOnlineHelpEnabled}
                    closeOnlineHelp={closeFilterOnlineHelp} />

                <Grid container direction={'row'} spacing={2} sx={{ p: 2, pb: 0 }}>
                    <Grid item xs={11}>
                        <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: "300" }}>
                            {getTranslatedText("FilterSettingsTitle")}
                        </Typography>
                    </Grid>

                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', }}>
                        <OnlineHelpIconButton
                            isInSettings={false}
                            title={"OnlineHelp"}
                            isOnlineHelpEnabled={isOnlineHelpEnabled}
                            onClickFunction={openFilterOnlineHelp} />
                    </Grid>

                </Grid>

                {filterSettingsEditor.length > 0 && filterSettingsEditor.map((filter, filterKey) => {
                    return (<Grid container direction={'row'} spacing={2} sx={{ p: 2 }} key={filterKey}>
                        <Grid item xs={5}>
                            <FormControl fullWidth>
                                <InputLabel color={'success'} id="demo-simple-select-label">{getTranslatedText("FilterMode")}</InputLabel>
                                <Select
                                    className="onlinehelp-home-sideDrawerView-filter-inputBoxMode"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter.Mode}
                                    label={getTranslatedText("FilterMode")}
                                    onChange={updateMode(filterKey)}
                                    color="success"
                                >
                                    <MenuItem value={FilterMode.Torque}>{getTranslatedText("FileFormatTorqueSeries")}</MenuItem>
                                    <MenuItem value={FilterMode.StepNumber}>{getTranslatedText("FileFormatStepNumber")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField className="onlinehelp-home-sideDrawerView-filter-inputBoxFrom" id="outlined-basic" type={'number'} value={filter.Range1} label={getTranslatedText("Range1")} variant="outlined" color="success" onChange={updateRange1(filterKey)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField className="onlinehelp-home-sideDrawerView-filter-inputBoxTo" id="outlined-basic" type={'number'} value={filter.Range2} label={getTranslatedText("Range2")} variant="outlined" color="success" onChange={updateRange2(filterKey)} />
                        </Grid>
                        <Grid item xs={1} my="auto">
                            <Tooltip title={getTranslatedText("RemoveSingleFilter")}>
                                <IconButton className="onlinehelp-home-sideDrawerView-filter-buttonDeleteFilter" disabled={filterSettingsEditor.length === 1 && filterKey === 0} color="error" aria-label="" size="small" onClick={() => removeSingleFilter(filterKey)}>
                                    <DeleteOutlineIcon fontSize={"medium"} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>);
                })}

                <Grid container direction={'row'} spacing={2} sx={{ p: 2, pt: 0 }}>
                    <Grid item xs={12} alignContent="center" textAlign={'center'}>
                        <Tooltip title={getTranslatedText("AddFilter")}>
                            <IconButton className="onlinehelp-home-sideDrawerView-filter-buttonAddFilter" disabled={filterSettingsEditor.length === 5} aria-label="" size="small" onClick={addEmptyFilter}>
                                <AddCircleOutlineIcon fontSize={"large"} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>


                <Grid container direction={'row'} spacing={2} sx={{ p: 2, pt: 0 }}>
                    <Grid item xs={12} alignContent="center" textAlign={'center'}>
                        <Button className="onlinehelp-home-sideDrawerView-filter-buttonResetAllFilter" color="inherit" fullWidth variant="outlined" size="large" onClick={resetFilterEditorSettingsToChartRequestSettings}>
                            {getTranslatedText("ResetAllFilter")}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container direction={'row'} spacing={2} sx={{ p: 2, pt: 0 }}>
                    <Grid item xs={12} alignContent="center" textAlign={'center'}>
                        <Button className="onlinehelp-home-sideDrawerView-filter-buttonApplyFilter" disabled={checkIfApplyFilterButtonShouldBeActive()} color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={setFilterEditorSettingsToChartRequestSettings}>
                            {getTranslatedText("ApplyFilter")}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container direction={'row'} spacing={1} sx={{ p: 4, pt: 0 }}>
                    <Grid item xs={12}>
                        <Typography color={'#f03e3e'} sx={{ maxWidth: "700px" }}>
                            {getTranslatedText("FilterDoesDeleteAllAnnotations")}
                        </Typography>
                    </Grid>

                </Grid>

            </Drawer>
        }
    </LanguageConsumer>
    );
}