import { createTheme} from "@mui/material";
import "typeface-fira-sans";

export const themeLight = (isStandalone: boolean = false) => createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1670,
    },
  },
  typography: {
    fontFamily: [
      'Fira sans',
    ].join(','),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
          tooltip: {
              fontSize: '.9em'
          }
      }
  },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: isStandalone ? 'transparent' : "rgba(235,235,235,.5)"
        }
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "10px !important",
          backgroundColor: "rgba(255,255,255,1) !important",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1) !important",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'rgba(255,255,255,0.85)',
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1) !important",
          backdropFilter: "blur(5px)",
          webkitBackdropFilter: "blur(5px)",
          border: "1px solid rgba(255, 255, 255, 0.3)"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
       root: {
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: "2px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1) !important",

       },
      }
    },
    MuiCard: {
      styleOverrides: {
       root: {
        backgroundColor: 'rgba(255,255,255,0.79)',
        borderRadius: "8px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1) !important",
       }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderWidth: "2px",
          "&:hover":{
            borderWidth: "2px",
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingTop: "18px",
          paddingBottom: "18px",
          maxHeight: "100px"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root:{
          "&.Mui-selected": {
           // color: "#32992c",
          }
        }
      }
    }
  },
  palette: {
    mode: 'light',
    background: {
      default: isStandalone ? 'transparent' : 'radial-gradient(circle, rgba(255,255,255,1) 15%, rgba(242,252,242,1) 71%)',
    },
    primary: {
       main: '#fff',
       contrastText: '#444',
    },
    secondary: {
      main: '#062036',
      contrastText: '#444',
    },
    success: {
      main: '#32992c',
      contrastText: '#444'
    },
    lightgreen: {
      main: '#f2fcf2',
    },
    dark: {
      main: '#777',
      contrastText: '#fff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});



  export const themeDark = (isStandalone: boolean = false) => createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1400,
        xl: 1670,
      },
    },
    typography: {
      fontFamily: [
        'Fira sans',
      ].join(','),
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: '.9em'
            }
        }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "10px !important",
          backgroundColor: "rgba(0,0,0,0.93) !important",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },
      MuiCard: {
        styleOverrides: {
         root: {
          borderRadius: "8px"
         }
        }
      },
      MuiTab: {
        styleOverrides: {
          root:{
            "&.Mui-selected": {
            // color: "#32992c",
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderWidth: "2px",
            "&:hover":{
              borderWidth: "2px",
            }
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            paddingTop: "18px",
            paddingBottom: "18px",
            maxHeight: "100px"
          }
        }
      },
    },
    
    palette: {
      mode: 'dark',
      background: {
        default: '#000',
      },
      primary: {
         main: '#fff',
         contrastText: '#444',
      },
      secondary: {
        main: '#062036',
        contrastText: '#444',
      },
      success: {
        main: '#32992c',
        contrastText: '#444'
      },
      lightgreen: {
        main: '#f2fcf2',
      },
      dark: {
        main: '#777',
        contrastText: '#fff',
            
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  });

  declare module '@mui/material/styles' {

    interface Palette {
        lightgreen: Palette['primary'];
        dark: Palette['primary'];
    }
  
    // allow configuration using `createTheme`
    interface PaletteOptions {
        lightgreen?: PaletteOptions['primary'];
        dark?: PaletteOptions['primary'];
    }
  }
  
  // Update the Button's color prop options
  declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        lightgreen: true;
        dark: true;
    }
  }

  declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        lightgreen: true;
        dark: true;
    }
  }

  