import { createTransform } from "redux-persist";

const setTransformLoadFromCockpitLoadSimplifiedCurveData = createTransform(
    
    (inboundState: any, key) => {
      
      return { ...inboundState, status: "idle"};
    },
    
    (outboundState: any, key) => {
      
      return { ...outboundState, status: "idle" };
    },    
    { 
        whitelist: ['LoadFromCockpitLoadSimplifiedCurveDatas'] 
    }
  );

  export default setTransformLoadFromCockpitLoadSimplifiedCurveData;