import { useState, useEffect } from "react";
import { getTranslation, LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { Chip } from "@mui/material"
import { useAppDispatch } from "../../../Infrastructure/Redux/hooks";
import { NotificationType, setNotification } from "../../Notifications/Redux/NotificationsSlice";
import { InstallDesktop } from "@mui/icons-material";

const isPwa = () => {
  return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  );
}

export const InstallPwaButton = () => {
    const dispatch = useAppDispatch();
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState<any>(null);
  
    useEffect(() => {
      const handler = e => {
        e.preventDefault();
        
        if (isPwa()) {
          setSupportsPWA(false);
        } else {
          setSupportsPWA(true);
          setPromptInstall(e);
        }
       
      };
      (window as any).addEventListener("beforeinstallprompt", handler);
  
      return () => (window as any).removeEventListener("transitionend", handler);
    }, []);
  
    const onClick = evt => {
      evt.preventDefault();
      if (!promptInstall) {
        dispatch(setNotification({ type: NotificationType.info, text: getTranslation("AppAllreadyInstalled") }))
        return;
      }
      promptInstall.prompt();
    };
    if (!supportsPWA) {
      return null;
    }
    return (<LanguageConsumer>
        {({getTranslatedText}) =>
        <>
         <Chip icon={<InstallDesktop />} sx={{ opacity: 0.5, userSelect: 'none', ml: 1 }} onClick={onClick} label={getTranslatedText("InstallPwa")}></Chip>
        </>
        }
    </LanguageConsumer>
    )
  };
  