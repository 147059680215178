/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { ExpandViewEvents } from "../Model/Categories/ExpandViewEnums";
import { reset_analyze_ExpandView } from "../Redux/AnalyticsSlice";

const PublishExpandViewAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();
  
    const expandViewState = useAppSelector(store => store.analytics.expandViewState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackExpandView = TrackEvent(ExpandViewEvents.Expand.toString(), {})

    useEffect(() => {
        if (expandViewState.toggle) {
            try{
                trackExpandView({})
            }
            catch{}
            dispatch(reset_analyze_ExpandView())
        }
    }, [expandViewState.toggle])


    return (
        <></>
    )
}

export default PublishExpandViewAnalytics;