import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setIsLicensed } from "../../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import { licenseBaseUrl } from "../../../Infrastructure/Api/BaseApi";

interface CheckApiKeyHookResponse {
    isExternalUser: boolean
}

export const useCheckApiKey = () =>{
    const dispatch = useAppDispatch();
    const location = useLocation();
    const apiKey = useAppSelector(store => store.licenceService.licenceAuthentication.isMyDepragApiKey);
    const auth = useAppSelector(store => store.licenceService.licenceAuthentication);
    const [isExternalUser, setIsExternalUser] = useState<boolean>(apiKey.trim().length > 0 && auth.isLicenced && auth.licenceKey.trim().length === 0);

    const checkApiKey = async () => {
        const url = licenseBaseUrl + 'freetrial/check';
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
              },
            body: JSON.stringify({FreeTrialKey: apiKey})
        });

        var jsonResponse = await response.json();
    
        if(jsonResponse && jsonResponse.success){
            setIsExternalUser(true);
            dispatch(setIsLicensed(true));
        } else {
            setIsExternalUser(false);
            dispatch(setIsLicensed(false))
        }
    }

    useEffect(() => {
        if(apiKey.trim().length > 0 && auth.licenceKey.trim().length === 0){
            checkApiKey()
        }
    }, [location, apiKey])

    return {
        isExternalUser: isExternalUser
    } as CheckApiKeyHookResponse
}