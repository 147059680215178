import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteUsageInBackend } from "../Api/DeleteUsage";
import { DeleteUsageRequest } from "../Models/DeleteUsageRequest";
import { DeleteUsageResponse } from "../Models/DeleteUsageResponse";

export interface DeleteUsageServiceState {
    response: DeleteUsageResponse | null,
}

export const initialState: DeleteUsageServiceState = {
    response: null
};

export const deleteUsage = createAsyncThunk(
    'licenceService/deleteUsage', async (request: DeleteUsageRequest) => {
        const response = await deleteUsageInBackend(request);
        return response;
    }
);
