
import { encode } from "base-64";
import { SimplifiedCurveDataRequest } from "./SimplifiedCurveDataRequest";
import { SimplifiedCurveDataResponse } from "./SimplifiedCurveDataResponse";

const simpleFetchCall = async (cockpitRequest: SimplifiedCurveDataRequest) => {
    let cockpitResponse = await fetch('http://' + cockpitRequest.cockpitUrl + ":8089/" + cockpitRequest.cockpitArea + "/api/v1.0/graphviewer/simplifiedCurveFiles", {
        signal: cockpitRequest.abortController?.signal,
        body: JSON.stringify(
            {
                SelectedDeviceIds: cockpitRequest.DeviceIds,
                Filter: cockpitRequest.CurveFileFilter    , 
                StartIndex: cockpitRequest.CurveFilesToSkip  ,
                MaxRecivedFiles: cockpitRequest.MaxRecivedFiles  ,
                CurveFilesToSkip:cockpitRequest.CurveFilesToSkip,
                CutOffDateTime: cockpitRequest.CutOffTime
            }),
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Basic ' + encode(cockpitRequest.username + ":" + cockpitRequest.password),
            'Content-Type': 'application/json'
        }),
    });

    return cockpitResponse.json();
}

const callCockpitApi = async (cockpitRequest: SimplifiedCurveDataRequest) => {
    const htmlContent = await simpleFetchCall(cockpitRequest);

    return htmlContent as SimplifiedCurveDataResponse[];
}

export const getSimplifiedCurveFilesFromCockpit = async (cockpitRequest: SimplifiedCurveDataRequest): Promise<SimplifiedCurveDataResponse[]> => {
    try {

        let jsonResponse = await callCockpitApi(cockpitRequest);

        return new Promise<any>((resolve, rejected) => {
            if (jsonResponse) {
                resolve(jsonResponse)
            }
        });

    } catch (error: any) {
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);

    }
}