import { messagePackClient } from "../../../Infrastructure/Api/BaseApi";
import { MetaDataDto } from "../../Home/Models/MetaDataDto";

export interface MetadataCompareRequest{
    Source: MetaDataDto;
    Targets: MetaDataDto[];  
}

export interface MetadataCompareResponse{    
    success: boolean;
    canCompareDetails: boolean;
    content: MetaDataDto | null;
    titles: string[] | null;
}

export  const metaDataCompareApiCall = async (request: MetadataCompareRequest) : Promise<MetadataCompareResponse> => {
    var compareResultRaw = await messagePackClient.post("chart/compare", request);        

        var metaDataCompareResponse : MetadataCompareResponse = {
            canCompareDetails: compareResultRaw.data.CanCompareDetails,
            success: compareResultRaw.data.Success,
            content: compareResultRaw.data.Content,
            titles: compareResultRaw.data.Titles,
        } as MetadataCompareResponse;

    return metaDataCompareResponse;
}