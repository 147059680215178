import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import { NumberParam, StringParam, useQueryParam, useQueryParams } from "use-query-params";
import { setLicence, setEmail, setIsMyDepragApiKey } from "../../Infrastructure/LicenceService/Redux/LicenceServiceSlice";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { resetIsMyDeprag, resetStandalone, setIsMyDeprag, setStandalone } from "../../Layout/Navigation/Redux/NavigationSlice";
import { showLicenseConsent } from "../LicenseConsent/LicenseConsentSlice";
import { setAppTheme, setLanguage } from "../Settings/Redux/SettingsSlice";

export const DefaultPath = (params) => {
  const dispatch = useAppDispatch();
  const curLocation = useLocation()
  const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);
  
  const [query] = useQueryParams({
    email: StringParam,
    licenceKey: StringParam,
  });

  const [apiKeyQuery] = useQueryParams({
    myDeprag: NumberParam,
    apiKey: StringParam,
    language: StringParam,
    theme: StringParam
  })

  const { apiKey, theme, language } = apiKeyQuery;
  const { email, licenceKey } = query;

  useEffect(() => {
    
    if(curLocation.search.includes("standalone") || curLocation.search.includes("myDeprag"))
    {
      curLocation.search.includes("myDeprag") ?  dispatch(setIsMyDeprag()) :  dispatch(setStandalone())
    }
    else{
      if ( window.location === window.parent.location ) {
        dispatch(resetIsMyDeprag())
        dispatch(resetStandalone())
      }
    }

  }, [curLocation])

  useEffect(() => {
    if (email !== undefined && licenceKey !== undefined) {

      if (!isLicenced) {
        dispatch(setLicence(licenceKey));
        dispatch(setEmail(email));

        setTimeout(() => {
          dispatch(showLicenseConsent());
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, licenceKey, isLicenced])

  useEffect(() => {
    if(apiKey !== undefined && apiKey && apiKey.trim().length > 0){
      dispatch(setIsMyDepragApiKey(apiKey))
    }

    if(theme !== undefined && theme && theme.trim().length > 0){
      dispatch(setAppTheme(theme))
    }

    if(language !== undefined && language && language.trim().length > 0){
      dispatch(setLanguage(language))
    }
  }, [apiKey, theme, language]);


  return (
    <React.Fragment>
    </React.Fragment>
  )
}