import { Card, CardContent, Grid, Slider, TextField } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { ChartSeries } from "../../Features/Home/Models/ChartSeries";
import { ReSamplingDataDto } from "../../Features/Home/Models/ReSamplingDataDto";
import { SampledValueDto } from "../../Features/Home/Models/SampledValueDto";
import { SampledValueListItemDto } from "../../Features/Home/Models/SampledValueListItemDto";
import { SingleVisualizationModeDto } from "../../Features/Home//Models/SingleVisualizationModeDto";
import { SuperVisualizationModeDto } from "../../Features/Home//Models/SuperVisualizationModeDto";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import './Style/TimeAngleSync.css';
import { isCustomMode } from "../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { updateCurrentValue } from "../SeriesLegend/Redux/LegendCurrentValueSlice";
import { ChartResult } from "../../Features/Home/Models/ChartResult";
import { updateTimeSynchronisationInLegend } from "./Redux/TimeSynchronisationSlice";
import { OnlineHelpIconButton } from "../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { TimeAngleSyncOnlineHelp } from "../../Infrastructure/OnlineHelp/TimeAngleSync/TimeAngleSyncOnlineHelp";
import { analyze_StartCurveSupervisionCurveControl } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { updatePosition } from '../Annotations/Redux/AnnotationMonitorSlice';
import { MonitorAnnotation } from '../Annotations/Annotation';
import { getInputFieldAbbreviation, getLanguekeyForInputfield, getLanguekeyForInputfieldSecondary } from "./TimeAngleSyncMethods";
import debounce from "lodash/debounce";

export interface TimeAngleSyncProps {
    isRedrawn: boolean;
}

enum SourceOfChange {
    fromTimeSlider, fromTimeTextEdit, fromPrimaryTextEdit, fromSecondaryTextEdit
}

export const TimeAngleSync = ({ isRedrawn }: TimeAngleSyncProps) => {

    const dispatch = useAppDispatch();

    const selectedTorqueUnit = useAppSelector(store => store.settings.chartRequestSettings.TorqueUnit);
    const isNotSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.None);
    const appTheme = useAppSelector(store => store.settings.appTheme);
    const superVisualisationMode = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode);
    const singleVisualisationMode = useAppSelector(store => store.settings.chartRequestSettings.SingleVisualization?.Mode);
    const customMode = useAppSelector(isCustomMode);
    const isLicenced = useAppSelector(store => store.licenceService.licenceAuthentication.isLicenced);

    const allSeries = useAppSelector(store => store.chartResultData.series[0]);
    const [sourceOfChange, setSourceOfChange] = useState<SourceOfChange>(SourceOfChange.fromTimeTextEdit);
    const [primaryCurrentValue, setPrimaryCurrentValue] = useState("0");
    const [secondaryCurrentValue, setSecondaryCurrentValue] = useState("0");
    const [isPlaying, setPlaying] = useState(false);
    const increment = useRef<NodeJS.Timer>();
    const [isOnlineHelpEnabled, setIsOnlineHelpEnabled] = useState(false);
    const [time, setTime] = useState("0");

    const changeVisibleAnnotationsForTime = useCallback((currentTime: string) => {

        const getSecondaryValueForTime = (resamplingData: ReSamplingDataDto, currentTime: string) => {
            var timeAsInteger = parseInt(currentTime);

            if (resamplingData.Values.length === 0) {
                return null;
            }

            if (resamplingData.Values.some(x => x.X === timeAsInteger))
                return adjustByOffset(resamplingData, timeAsInteger);

            var maxTime = resamplingData.Values[resamplingData.Values.length - 1].X;

            while (timeAsInteger >= 0 && timeAsInteger <= maxTime) {
                let curretnTimeAsInteger = timeAsInteger;
                if (curretnTimeAsInteger === 0)
                    return adjustByOffset(resamplingData, resamplingData.Values[getMaxLengthIndex(allSeries)].X);

                timeAsInteger--;

                if (resamplingData.Values.some(x => x.X === curretnTimeAsInteger))
                    return adjustByOffset(resamplingData, timeAsInteger);
            }

            return adjustByOffset(resamplingData, maxTime);
        }

        const getPrimaryValueForTime = (resamplingData: ReSamplingDataDto, currentTime: string) => {

            if (resamplingData?.Values?.length <= 0)
                return null

            var timeAsInteger = parseInt(currentTime);

            if (resamplingData.Values.some(x => x.X === timeAsInteger))
                return adjustByOffset(resamplingData, timeAsInteger);

            var maxTime = resamplingData.Values[resamplingData.Values.length - 1].X;

            while (timeAsInteger >= 0 && timeAsInteger <= maxTime) {
                let curretnTimeAsInteger = timeAsInteger;
                if (curretnTimeAsInteger === 0)
                    return adjustByOffset(resamplingData, resamplingData.Values[getMaxLengthIndex(allSeries)].X);

                timeAsInteger--;

                if (resamplingData.Values.some(x => x.X === curretnTimeAsInteger))
                    return adjustByOffset(resamplingData, timeAsInteger);
            }

            return adjustByOffset(resamplingData, maxTime);
        }

        if (allSeries && allSeries.ChartSeries && allSeries.ChartSeries.length > 0 &&
            allSeries.ChartSeries[getMaxLengthIndex(allSeries)] &&
            allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData) {

            let firstSeries = allSeries.ChartSeries[0]

            var primaryValue = getPrimaryValueForTime(firstSeries.ReSamplingData, currentTime);
            var secondaryValue = getSecondaryValueForTime(firstSeries.ReSamplingDataSecondary, currentTime);

            if (sourceOfChange === SourceOfChange.fromTimeSlider || sourceOfChange === SourceOfChange.fromTimeTextEdit) {
                if (secondaryValue !== null) {
                    setSecondaryCurrentValue(secondaryValue.Y.toFixed(allSeries.SecondarySamplingDataDigitCount))
                }

                if (primaryValue !== null) {
                    setPrimaryCurrentValue(primaryValue.X.toFixed(allSeries.XAxisDigitCount).toString())
                }
            }

            if (sourceOfChange === SourceOfChange.fromPrimaryTextEdit) {
                if (secondaryValue !== null) {
                    setSecondaryCurrentValue(secondaryValue.Y.toFixed(allSeries.SecondarySamplingDataDigitCount))
                }
            }

            if (sourceOfChange === SourceOfChange.fromSecondaryTextEdit) {
                if (primaryValue !== null) {
                    setPrimaryCurrentValue(primaryValue.X.toFixed(allSeries.XAxisDigitCount).toString())
                }
            }

            if (sourceOfChange !== SourceOfChange.fromPrimaryTextEdit && primaryValue) {
                setPrimaryCurrentValue(primaryValue.X.toFixed(allSeries.XAxisDigitCount).toString())
            }

            allSeries.ChartSeries.forEach((series: ChartSeries) => {
                if (series.ReSamplingData.Values.length > 0) {
                    let yValueCalculated: number | null = null;
                    let currentYValueAsString: string = "---";

                    let primaryValueForThisSeries = getPrimaryValueForTime(series.ReSamplingData, currentTime);

                    if (primaryValueForThisSeries && primaryValueForThisSeries.Y !== null) {
                        yValueCalculated = primaryValueForThisSeries.Y / Number(series.ScaleFactor);
                        currentYValueAsString = String(yValueCalculated.toFixed(series.DigitCount));
                    }

                    const currentXValueAsString = String(primaryValueForThisSeries?.X.toFixed(allSeries.XAxisDigitCount)) + " " + allSeries.XAxisUnitAbbreviation;

                    dispatch(updateCurrentValue({ title: series.Title, currentValue: currentYValueAsString }));                    
                    dispatch(updateTimeSynchronisationInLegend({ title: series.Title, currentValue: currentXValueAsString }));                    

                    dispatch(updatePosition({
                        name: series.Title,
                        x1: primaryValueForThisSeries?.X,
                        y1: primaryValueForThisSeries?.Y,
                    } as MonitorAnnotation));

                }
            })
        }
    }, [allSeries, dispatch, sourceOfChange, isRedrawn])

    const getMaxLengthIndex = (allSeries: ChartResult) => {

        var reSamplingData = allSeries.ChartSeries.map(x => x.ReSamplingData?.Values?.length);
        if (reSamplingData) {
            var maxValue = Math.max(...reSamplingData);
            const index = reSamplingData.indexOf(maxValue);
            return index;
        }
        return 0;
    }

    useEffect(() => {
        changeVisibleAnnotationsForTime(time);
    }, [changeVisibleAnnotationsForTime, time])

    const startAnimation = async () => {
        setSourceOfChange(SourceOfChange.fromTimeSlider)

        if (customMode)
            return;

        if (parseInt(time) >= (allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData.Values.length - 1)) {
            setTime("0")
        }

        setPlaying(current => !current);

        dispatch(analyze_StartCurveSupervisionCurveControl())

        increment.current = setInterval(() => {
            setTime(oldTime => (parseInt(oldTime) + 6).toString());
        }, 50)
    }


    useEffect(() => {
        if (allSeries && allSeries.ChartSeries && allSeries.ChartSeries[getMaxLengthIndex(allSeries)] && allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData) {
            if (parseInt(time) >= (allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData.Values.length - 1)) {
                setTime((allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData.Values.length - 1).toString());
                stopAnimation();
            } else {
                changeVisibleAnnotationsForTime(time);
            }
        }
    }, [allSeries, time, changeVisibleAnnotationsForTime]);

    const stopAnimation = () => {
        clearInterval(increment.current);
        setPlaying(false);
    }

    const setTimeAndChangeAnnotation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSourceOfChange(SourceOfChange.fromTimeTextEdit)
        setTime(e.target.value);
    }

    const handleChangePrimaryValueFromTextEdit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSourceOfChange(SourceOfChange.fromPrimaryTextEdit)
        if (allSeries && allSeries.ChartSeries && allSeries.ChartSeries.length > 0 && allSeries.ChartSeries[getMaxLengthIndex(allSeries)] && allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData) {
            var series = allSeries.ChartSeries[0];
            var result = getValueForPrimary(series.ReSamplingData, e.target.value);
            setTime(result.X.toString());
        }
        setPrimaryCurrentValue(e.target.value)
    }

    const handleChangeSecondaryValueFromTextEdit = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSourceOfChange(SourceOfChange.fromSecondaryTextEdit)
        if (allSeries && allSeries.ChartSeries && allSeries.ChartSeries.length > 0 && allSeries.ChartSeries[getMaxLengthIndex(allSeries)] && allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData) {
            var series = allSeries.ChartSeries[0];
            var resultFromSecondary = getValueForSecondary(series.ReSamplingDataSecondary, e.target.value);
            setTime(resultFromSecondary.X.toString());
        }
        setSecondaryCurrentValue(e.target.value)
    }

    const [stateDebounceSetTimeAndChangeAnnotationFromSlider] = useState(() =>
        debounce(setTime, 0.1)
    );

    const handleTimeAndChangeAnnotationFromSlider = (e: Event, value: number | number[], activeThumb: number) => {
        setSourceOfChange(SourceOfChange.fromTimeSlider)
        stateDebounceSetTimeAndChangeAnnotationFromSlider(value.toString())
    }

    const getValueForPrimary = (resamplingData: ReSamplingDataDto, currentPrimaryValue: string) => {
        var primaryValueAsFloat = parseFloat(currentPrimaryValue);

        var nearestPrimaryValue = resamplingData.Values.reduce((a: SampledValueListItemDto, b: SampledValueListItemDto) => {
            return Math.abs(b.SampledValue.X - primaryValueAsFloat) < Math.abs(a.SampledValue.X - primaryValueAsFloat) ? b : a
        });

        return nearestPrimaryValue;
    }

    const getValueForSecondary = (resamplingData: ReSamplingDataDto, currentSecondaryValue: string) => {
        var secondaryValueAsFloat = parseFloat(currentSecondaryValue);

        var nearestPrimaryValue = resamplingData.Values.reduce((a: SampledValueListItemDto, b: SampledValueListItemDto) => {
            return Math.abs(b.SampledValue.Y - secondaryValueAsFloat) < Math.abs(a.SampledValue.Y - secondaryValueAsFloat) ? b : a
        });

        return nearestPrimaryValue;
    }

    const adjustByOffset = (resamplingData: ReSamplingDataDto, timeAsInteger: number): SampledValueDto => {
        var entry = resamplingData.Values[timeAsInteger];
        var correctX = entry.SampledValue.X - resamplingData.SyncOffset;
        var newInstance = { X: correctX, Y: entry.SampledValue.Y } as SampledValueDto;

        return newInstance;
    }
    if (!isLicenced) {
        return <></>
    }

    let languekeyForInputfield = getLanguekeyForInputfield(singleVisualisationMode, superVisualisationMode, isNotSuperVisualisation);
    let inputFieldAbbreviation = "";

    let languekeyForInputfieldSecondary = "";
    let inputFieldAbbreviationSecondary = "";

    if (isNotSuperVisualisation) {
        if (SingleVisualizationModeDto.Angle === singleVisualisationMode) {

            languekeyForInputfieldSecondary = getLanguekeyForInputfieldSecondary(singleVisualisationMode)
            inputFieldAbbreviationSecondary = getInputFieldAbbreviation(selectedTorqueUnit)
        }
        if (SingleVisualizationModeDto.Torque === singleVisualisationMode) {
            languekeyForInputfieldSecondary = getLanguekeyForInputfieldSecondary(singleVisualisationMode)
            inputFieldAbbreviation = getInputFieldAbbreviation(selectedTorqueUnit)             
        }
    }

    const closeOnlineHelp = () => {
        setIsOnlineHelpEnabled(false);

    };
    const openOnlineHelp = () => {
        setIsOnlineHelpEnabled(true);
    };

    return (<React.Fragment>
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Card sx={{ mt: 2, width: '100%' }} elevation={12} className="onlinehelp-curveMonitoring-intro">
                    <CardContent>
                        <Grid container direction={'row'} sx={{ mt: 1 }} spacing={2} >
                            <React.Fragment>
                                <TimeAngleSyncOnlineHelp
                                    closeOnlineHelp={closeOnlineHelp}
                                    showOnlineHelp={isOnlineHelpEnabled}
                                    isNotSuperVisualisation={isNotSuperVisualisation} />



                                <Grid item textAlign={'center'} sx={{ pl: 2, pr: 2 }} xs={isNotSuperVisualisation ? 6 : 12}>
                                    <TextField disabled={!isLicenced}
                                        fullWidth
                                        id="monitoring-input-time"
                                        data-testid="monitoring-input-time"
                                        className="timeAngleSyncInput onlinehelp-timeAngleSync-timeTextField"
                                        type={'number'}
                                        value={time}
                                        label={getTranslatedText("Time")}
                                        variant="outlined"
                                        color="success"
                                        onChange={(e) => setTimeAndChangeAnnotation(e)} />
                                </Grid>

                                {isNotSuperVisualisation && <React.Fragment>
                                    <Grid item xs={6} >
                                        <Grid container className="onlinehelp-timeAngleSync-timeSyncInput" sx={{ pr: 2 }} spacing={2}>
                                            <Grid item textAlign={'center'} xs={6}>
                                                <TextField disabled={!isLicenced}
                                                    fullWidth
                                                    id="monitoring-input-primary"
                                                    data-testid="monitoring-input-primary"
                                                    type={'number'}
                                                    value={primaryCurrentValue}
                                                    label={getTranslatedText(languekeyForInputfield) + " " + inputFieldAbbreviation}
                                                    variant="outlined"
                                                    color="success"
                                                    onChange={(e) => handleChangePrimaryValueFromTextEdit(e)}
                                                />
                                            </Grid>
                                            <Grid item textAlign={'center'} xs={6}>
                                                <TextField disabled={!isLicenced}
                                                    fullWidth
                                                    id="monitoring-input-secondary"
                                                    data-testid="monitoring-input-secondary"
                                                    type={'number'}
                                                    value={secondaryCurrentValue}
                                                    label={getTranslatedText(languekeyForInputfieldSecondary) + " " + inputFieldAbbreviationSecondary}
                                                    variant="outlined"
                                                    color="success"
                                                    onChange={(e) => {
                                                        handleChangeSecondaryValueFromTextEdit(e)
                                                    }
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </React.Fragment>
                                }

                                <Grid item xs={1} my={'auto'} textAlign="center" sx={{ mt: 2 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', }}>
                                    {!isPlaying ? <PlayCircleIcon className="onlinehelp-timeAngleSync-playCircleIcon"
                                        sx={{ fontSize: "40px" }}
                                        color="success"
                                        onClick={startAnimation} /> :
                                        <PauseCircleIcon onClick={stopAnimation}
                                            color="error"
                                            sx={{ fontSize: "40px" }} />
                                    }
                                </Grid>
                                <Grid item my={'auto'} sx={{ mt: 3, pl: 2, pr: 2 }} xs={10}>
                                    <Slider className="onlinehelp-timeAngleSync-slider"
                                        disabled={!isLicenced}
                                        color={appTheme === 'light' ? "secondary" : "primary"}
                                        valueLabelFormat={(value: number) => {
                                            return `${value} ms`
                                        }}
                                        getAriaValueText={(value: number) => {
                                            return `${value}ms`
                                        }}
                                        value={parseInt(time)}
                                        aria-label="Default"
                                        step={1}
                                        max={allSeries && allSeries.ChartSeries && allSeries.ChartSeries[getMaxLengthIndex(allSeries)] && allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData ? (allSeries.ChartSeries[getMaxLengthIndex(allSeries)].ReSamplingData.Values.length - 1) : 500}
                                        onChange={handleTimeAndChangeAnnotationFromSlider}
                                        valueLabelDisplay="on" />
                                </Grid>
                                <Grid item xs={1} my={'auto'} sx={{ pl: 2, pr: 2, mt: 2, }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', }}>
                                    <OnlineHelpIconButton
                                        isInSettings={false}
                                        title={"OnlineHelp"}
                                        isOnlineHelpEnabled={false}
                                        onClickFunction={() => { openOnlineHelp() }} />

                                </Grid>
                            </React.Fragment>

                        </Grid>
                    </CardContent>
                </Card>

            }
        </LanguageConsumer>
    </React.Fragment>
    );
    

}
