/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { checkForUserInteractionModifiers } from "../SciChartHelper/ChartModifier";

interface UseHandleChangeChartModifiersArgs {
    sciChart: TWebAssemblyChart;
    isRedrawFinished: boolean;
    setRedrawFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useHandleChangeChartModifiers = ({ sciChart, isRedrawFinished, setRedrawFinished }: UseHandleChangeChartModifiersArgs) => {
    const dispatch = useAppDispatch();

    const chartModifier = useAppSelector(store => store.chartModifier);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {

            checkForUserInteractionModifiers(sciChart, chartModifier, dispatch, isRedrawFinished, setRedrawFinished);
        }
    }, [chartModifier, isRedrawFinished])

}