import { MainCategories } from "../AnalyticsCategories";

export enum LoadFilesEvents {
    Load = MainCategories.LoadFiles + "Load"
}

export enum LoadFilesItems {
    FromDragAndDrop = "FromDragAndDrop",
    FromFileSystem = "FromFileSystem",
    FromController = "FromController",
    FromCockpit = "FromCockpit",
    FromExternal = "FromExternal"
}

