/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { selectSeries } from "../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { MonitorAnnotation } from "../../Annotations/Annotation";
import { updateOrCreateMonitor } from "../../Annotations/Redux/AnnotationMonitorSlice";
import { SingleVisualizationModeDto } from "../Models/SingleVisualizationModeDto";
import { SuperVisualizationModeDto } from "../Models/SuperVisualizationModeDto";

interface UseHandleChangeSeriesDataArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeSeriesData = ({ sciChart }: UseHandleChangeSeriesDataArgs) => {
    const dispatch = useAppDispatch();

    const seriesData = useAppSelector(selectSeries);
    const isCurveAnimationEnabled = useAppSelector(store => store.settings.isCurveAnimationEnabled);
    const isCurveSuperVisualisationAngle = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Angle ||
        store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Torque);
    const isCurveSuperVisualisationTime = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.Time);
    const isCurveVisualisationAngle = useAppSelector(store => store.settings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Angle ||
        store.settings.chartRequestSettings.SingleVisualization?.Mode === SingleVisualizationModeDto.Torque);
    const seriesColors = useAppSelector(store => store.chartSeriesColors.colors);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface && isCurveVisualisationAngle) {
            if (isCurveAnimationEnabled) {
                if ((isCurveVisualisationAngle || isCurveSuperVisualisationAngle) && !isCurveSuperVisualisationTime) {
                    if (seriesData && seriesData.length > 0) {
                        seriesData[0].ChartSeries.forEach(series => {
                            let color = series.Color;
                            let found = seriesColors.find(x=> x.title === series.Title)
                            if(found){
                                color = found.color;
                            }                    
                            dispatch(updateOrCreateMonitor({
                                color: color,
                                visible: series.Visible,
                                name: series.Title
                            } as MonitorAnnotation))
                        })
                    }
                }
            }
        }
    }, [seriesData, seriesColors, sciChart])
}