import { createSlice } from '@reduxjs/toolkit';


export interface InformationModalState {
  isShown: boolean,
}

const initialState: InformationModalState = {
  isShown: false,
};


export const InformationModalSlice = createSlice({
  name: 'informationModal',
  initialState,
  reducers: {
    toggleInformationModal: (state) => {
      state.isShown = !Boolean(state.isShown);
    }
  },
});

export const { toggleInformationModal } = InformationModalSlice.actions;

export default InformationModalSlice.reducer;
