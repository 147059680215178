export enum MainCategories {
    Settings = "Settings_",
    LoadFiles = "LoadFiles_",
    ZoomChart = "ZoomChart_",
    Annotations = "Annotations_",
    Filter = "Filter_",
    CurveControl = "CurveControl_",
    Share = "Share_",
    ExpandView = "ExpandView",
    OnlineHelp = "OnlineHelp_",
    SeriesLegend = "SeriesLegend_",
    Footer = "Footer_",
    Feedback = "Feedback_",
    License = "License_"
}

