/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { ZoomChartEvents } from "../Model/Categories/ZoomChartEnums";
import { reset_analyze_ZoomChart } from "../Redux/AnalyticsSlice";

const PublishZoomChartAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();

    const zoomchartState = useAppSelector(store => store.analytics.zoomchartState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackZoomChart = TrackEvent(ZoomChartEvents.Zoom.toString(), {})
    
    useEffect(() => {
        if (zoomchartState.toggle) {
            try{
                trackZoomChart({ Action: zoomchartState.action.toString() })
            }
            catch{}
            dispatch(reset_analyze_ZoomChart())
        }
    }, [zoomchartState.toggle])

    return (
        <></>
    )
}

export default PublishZoomChartAnalytics;