import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getDefaultSettingsOnlineHelpSteps = (props) => {
  return [
    // Navigation - PAGES
    {
      selector: '.onlinehelp-settings-intro',
      position: 'center',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpIntroMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-languageSelect',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectLanguageTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectLanguageMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-torqueUnitSelect',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectTorqueUnitTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectTorqueUnitMessage") }}></div></div>),
    },
    {
      selector: '.onlinehelp-settings-appThemeSelect',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectAppThemeTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectAppThemeMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-settings-shareType',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpShareTypeTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpShareTypeMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-settings-informationType',
      position: 'bottom',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpInformationTypeTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpInformationTypeMessage") }}></div></div>)
    },
    {
      selector: '.onlinehelp-settings-resetAll',
      position: 'left',
      content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpResetGeneralTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpResetGeneralMessage") }}></div></div>)
    },
  ]
}
