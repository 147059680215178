import { Grid, Typography, Tooltip, IconButton, TextField, Skeleton } from "@mui/material";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { addCustomCsvYAxis, removeCustomCsvYAxis, updateCustomCsvYAxis } from "../Redux/CustomCsvSlice";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export const CustomYAxis = () => {
    const dispatch = useAppDispatch();
    const customYAxis = useAppSelector(store => store.customCsv.yAxis);

    const emptyYAxis = {id: "Y" + (customYAxis.length + 1), name: "", abbrevation: "", digitCount: 1 }

    return( <LanguageConsumer>
        {({ getTranslatedText }) =>
        <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 1, pb: 2 }}>
        <Grid item xs={12}>
            <Typography variant="h6" component="h6" sx={{fontWeight: 200}}>
                {getTranslatedText("AddYaxis")}

                <Tooltip title={getTranslatedText("AddYaxis")}>
                    <IconButton sx={{ml: 1, top: "-2px"}} onClick={() => dispatch(addCustomCsvYAxis(emptyYAxis))}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Typography>
        </Grid>
        <Grid item xs={12}>
        {customYAxis && customYAxis.length > 0 ? customYAxis.map((axis, axisIterator) => {
           return (
               <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 1 }}>
                   <Grid item xs={2}>
                       <TextField size={'small'} disabled fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvId")} value={axis.id}></TextField>
                   </Grid>
                   <Grid item xs={4}>
                       <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvYAxis({iterator: axisIterator, id: axis.id, name: e.target.value, abbrevation: axis.abbrevation, digitCount: axis.digitCount}))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvName")} value={axis.name}></TextField>
                   </Grid>
                   <Grid item xs={2}>
                       <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvYAxis({iterator: axisIterator, id: axis.id, name: axis.name, abbrevation: e.target.value, digitCount: axis.digitCount}))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvAbbrevation")} value={axis.abbrevation}></TextField>
                   </Grid>
                   <Grid item xs={3}>
                       <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvYAxis({iterator: axisIterator, id: axis.id, name: axis.name, abbrevation: axis.abbrevation, digitCount: e.target.value}))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvDigitCount")} value={axis.digitCount}></TextField>
                   </Grid>
                   <Grid item xs={1}>
                      <IconButton onClick={() => dispatch(removeCustomCsvYAxis(axis.id))}>
                        <DeleteOutlineIcon />
                      </IconButton>
                   </Grid>
               </Grid>
            )
        })
            :
            <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 1 }}>
                <Grid item xs={3}>
                    <Skeleton  onClick={() => dispatch(addCustomCsvYAxis(emptyYAxis))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton onClick={() => dispatch(addCustomCsvYAxis(emptyYAxis))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton onClick={() => dispatch(addCustomCsvYAxis(emptyYAxis))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton onClick={() => dispatch(addCustomCsvYAxis(emptyYAxis))} variant="rectangular" width={"100%"} height={30} />
                </Grid>
                <Grid item xs={1}>
                    <Skeleton onClick={() => dispatch(addCustomCsvYAxis(emptyYAxis))} variant="circular" width={30} height={30} />
                </Grid>
            </Grid>
        }
        </Grid>

    </Grid>
}
</LanguageConsumer>
    );
}