import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { licenseBaseUrl } from "../../../Infrastructure/Api/BaseApi";
import { LicenceModel } from "../Models/LicenseModels";

interface LicenseModelState{
    status: 'idle' | 'loading' | 'failed';
    licenseModels: LicenceModel[];
}

export const initialLicenseModelState: LicenseModelState = {
    status: 'idle',
    licenseModels: []
}

export const getLicenseModels = createAsyncThunk(
    'licenseModel/get',
    async () => {    
        var response = await fetch(licenseBaseUrl + "licensing/types", {              
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });        
        
      var jsonResponse = await response.json();
      return jsonResponse;
    }
  );


export const licenceModelSlice = createSlice({
    name: 'licenseModel',
    initialState: initialLicenseModelState,
    reducers: {
                      
    },
    extraReducers: builder => {
        builder.addCase(getLicenseModels.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getLicenseModels.fulfilled, (state, action) => {
            state.status = 'idle';
            if(action.payload.success){
                state.licenseModels = action.payload.payload.items;
            } else {
                state.status = 'failed';
            }

        })
        .addCase(getLicenseModels.rejected, (state) => {
            state.status = 'failed';
        })
    }
});




export default licenceModelSlice.reducer;