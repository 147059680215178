import { MainCategories } from "../AnalyticsCategories";

export enum OnlineHelpEvents {
    Show = MainCategories.OnlineHelp + "Show"
}

export enum OnlineHelpItems {
    MainOnlineHelp = "MainOnlineHelp",
    SeriesLegendOnlineHelp = "SeriesLegendOnlineHelp",
    LegendDistinctiveValuesOnlineHelp = "LegendDistinctiveValuesOnlineHelp",
    ShareOnlineHelp = "ShareOnlineHelp",
    DefaultSettingsOnlineHelp = "DefaultSettingsOnlineHelp",
    CockpitOnlineHelp = "CockpitOnlineHelp",
    LicenceOnlineHelp = "LicenceOnlineHelp",
    AnalyseOnlineHelp = "AnalyseOnlineHelp",
    InformationsOnlineHelp = "InformationsOnlineHelp",
    LoadFromCockpitOnlineHelp = "LoadFromCockpitOnlineHelp",
    AnnotationsOnlineHelp = "AnnotationsOnlineHelp"
}