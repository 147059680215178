import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { Grid, IconButton, Tooltip} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { memo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { YesNoDialog } from "../../../../Infrastructure/YesNoDialog/YesNoDialog";
import { resetChartRequest } from "../../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { resetChartResult, resetCustomMode } from "../../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { resetSynchronizationSettings, setSingleVisualisation, setSuperVisualisation } from "../../../Settings/Redux/SettingsSlice";
import { clearChartSeriesOrders } from "../../../../Infrastructure/ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { clearChartSeriesColors } from "../../../../Infrastructure/ChartSeriesColors/Redux/ChartSeriesColorsSlice";
import { clearChartSeriesVisibility } from "../../../../Infrastructure/ChartSeriesVisibility/Redux/ChartSeriesVisibilitySlice";
import { AppDispatch } from "../../../../Infrastructure/Redux/store";
import { SeriesTypeDto } from "../../Models/SeriesTypeDto";
import { SingleVisualizationModeDto } from "../../Models/SingleVisualizationModeDto";
import { SuperVisualizationModeDto } from "../../Models/SuperVisualizationModeDto";

interface ClearGraphViewerProps {
    onClear: () => void;
}

export const resetGraphViewerForNewFiles = (dispatch: AppDispatch, supervisualisationSeriesType: SeriesTypeDto | undefined) => {
    dispatch(resetChartRequest());
    dispatch(resetChartResult());
    dispatch(setSuperVisualisation({
        mode: SuperVisualizationModeDto.None,
        seriestype: supervisualisationSeriesType
    }));
    dispatch(setSingleVisualisation(SingleVisualizationModeDto.Angle));
    dispatch(clearChartSeriesOrders());
    dispatch(clearChartSeriesColors());
    dispatch(clearChartSeriesVisibility());
    dispatch(resetSynchronizationSettings());
    dispatch(resetCustomMode());
}

export const ClearGraphViewer = memo(({ onClear }: ClearGraphViewerProps) => {
    const dispatch = useAppDispatch();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const supervisualisationSeriesType = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.SeriesType);

    const handleNoClick = () => {
        setConfirmDialogOpen(false);
    }

    const handleYesClick = () => {
        setConfirmDialogOpen(false);
        resetGraphViewerForNewFiles(dispatch, supervisualisationSeriesType);
        onClear();
    }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <Grid item xs={'auto'}>
                <Tooltip title={getTranslatedText("ClearGraphViewer")}>
                    <IconButton onClick={() => setConfirmDialogOpen(true)} className="clearGraphViewer" sx={{ transition: "all 0.3s ease-in-out" }}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                <YesNoDialog
                            title={getTranslatedText("ResetGraphViewerDialogTitle")}
                            content={getTranslatedText("ResetGraphViewerDialogContent")}
                            titleYesButton={getTranslatedText("TEXT_YES")}
                            titleNoButton={getTranslatedText("TEXT_NO")}
                            open={confirmDialogOpen}
                            onNoClick={handleNoClick}
                            onYesClick={handleYesClick}
                        />
            </Grid>
        }
    </LanguageConsumer>
    );
})