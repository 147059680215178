import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction } from 'react';
import { LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';
import { useAppSelector } from "../../Infrastructure/Redux/hooks";
import { PrivacyLanguage } from "../Privacy/Languages/PrivacyLanguage";

export interface PassivePrivacyConsentDialogProps {
  open: boolean;
  setopen: Dispatch<SetStateAction<boolean>>;
}

export const PassivePrivacyConsentDialog = ({ open, setopen }: PassivePrivacyConsentDialogProps) => {
  const language = useAppSelector(store => store.settings.language);

  
  const handleAccept = () => {
    setopen(false)
  }

  const handleClose = () => {
    setopen(false)
  }

  return (<LanguageConsumer>
    {({ getTranslatedText }) =>
      <>
          <Dialog onClose={handleClose} open={open} maxWidth={'lg'} PaperProps={{sx:{height:"80%"}}}>
            <DialogTitle>{getTranslatedText("LicenseConsentDialogTitle")}</DialogTitle>
            <DialogContent>
            <PrivacyLanguage lang={language} />
              
            </DialogContent>
            <DialogActions>
              <Button color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large" onClick={handleAccept} >{getTranslatedText("LicenseConsentDialogAcceptButton")}</Button>
            </DialogActions>
          </Dialog>
      </>}
  </LanguageConsumer>
  );
}