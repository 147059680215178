import { Button, Tooltip, IconButton, Card, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Grow, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { OnlineHelpIconButton } from "../../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { useState, useEffect } from "react";
import { LoadFromCockpitOnlineHelp } from "../../../Infrastructure/OnlineHelp/Settings/LoadFromCockpit/LoadFromCockpitOnlineHelp";
import LockIcon from '@mui/icons-material/Lock';
import { checkSettings, hideCockpitConnectionHelpDialog } from "../../LoadFromCockpit/CheckCockpitConnection/Redux/CheckCockpitSettingsSlice";
import { CockpitAvailable, setCockpitAvailable, setLoadFromCockpitArea, setLoadFromCockpitUrl, setLoadFromControllerPassword, setLoadFromControllerUsername } from "../../../Features/LoadFromCockpit/CurveData/Complete/Redux/LoadFromCockpitCompleteCurveDataSlice";
import { cleanDevicesAndGroupsCache } from "../../../Features/LoadFromCockpit/DeviceAndGroups/Redux/LoadFromCockpitDeviceAndGroupsSlice";
import { HelpDialog } from "../../LoadFromController/HelpDialog/HelpDialog";
import { showHelpModal } from "../../LoadFromController/Redux/LoadAvailableFilesFromControllerSlice";
import { OnlineHelpItems } from "../../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { analyze_ShowOnlineHelp } from "../../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { LicenseCardHeader } from "./LicenseCardHeader/LicenseCardHeader";
import { NavLink } from "react-router-dom";
import { setActiveTab } from "../Redux/SettingsSlice";
import { toogleCockpitDefaultReset } from "../../../Infrastructure/DefaultSettingsSetter/Redux/DefaultSettingsSetterSlice";
import { DefaultSettingsSetterButton } from "../../../Infrastructure/DefaultSettingsSetter/Components/DefaultSettingsSetterButton";


export const LoadFromCockpitSettings = () => {
    const dispatch = useAppDispatch();
    const loadFromCockpit = useAppSelector(store => store.loadFromCockpit);
    const checkCockpitSettings = useAppSelector(store => store.checkCockpitSettings);
    const licenceData = useAppSelector(store => store.licenceService);

    const [showOnlineHelp, setShowOnlineHelp] = useState(false);
    const [showAllComponentsForOnlineHelp, setShowAllComponentsForOnlineHelp] = useState(false);

    useEffect(() => {

        if (!loadFromCockpit?.cockpitUrl || loadFromCockpit.cockpitUrl == "" ||
            !loadFromCockpit?.cockpitArea || loadFromCockpit.cockpitArea == "" ||
            !loadFromCockpit?.credentials?.username || loadFromCockpit.credentials.username == "" ||
            !loadFromCockpit?.credentials?.password || loadFromCockpit.credentials.password == "")
            return

        const timer = setTimeout(() => {
            if (loadFromCockpit && loadFromCockpit.cockpitAvailable === CockpitAvailable.CockpitAvailableYes) {
                dispatch(checkSettings({
                    cockpitUrl: loadFromCockpit.cockpitUrl,
                    cockpitArea: loadFromCockpit.cockpitArea,
                    username: loadFromCockpit.credentials.username,
                    password: loadFromCockpit.credentials.password
                }))
            }
        }, 2000);


        return () => clearTimeout(timer);
    }, [loadFromCockpit, dispatch]);

    const closeOnlineHelp = () => {
        setShowAllComponentsForOnlineHelp(false)
        setShowOnlineHelp(false);
    }
    const openOnlineHelp = () => {
        setShowOnlineHelp(true)
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.CockpitOnlineHelp }))
    }
    const setShowAllComponentsForOnlineHelpToTrue = () => {
        setShowAllComponentsForOnlineHelp(true)
    }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <HelpDialog />

                <Dialog
                    open={checkCockpitSettings.showHelp}
                    onClose={() => dispatch(hideCockpitConnectionHelpDialog())}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {getTranslatedText("CockpitConnectionCouldNotBeEstablished")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getTranslatedText("CockpitConnectionCouldNotBeEstablishedBody")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="dark" variant="outlined" onClick={() => dispatch(hideCockpitConnectionHelpDialog())}>{getTranslatedText("NoThankYou")}</Button>
                        <Button color="success" variant="outlined" onClick={() => {
                            dispatch(hideCockpitConnectionHelpDialog())
                            dispatch(showHelpModal())
                        }} autoFocus>
                            {getTranslatedText("ShowHelpModal")}
                        </Button>
                    </DialogActions>
                </Dialog>

                < Grow in={true} appear={true} id="loadFromCockpitSettings">
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={0} md={1}></Grid>
                        <Grid item xs={12} md={10}>
                            <Card elevation={12} className="onlinehelp-settings-loadFromCockpit-intro">
                                <LicenseCardHeader onlineHelpComponent={<OnlineHelpIconButton
                                    isInSettings={true}
                                    title={"OnlineHelp"}
                                    isOnlineHelpEnabled={false} onClickFunction={() => { openOnlineHelp() }} />} title={getTranslatedText("LoadFromCockpitTitle")} subtitle={getTranslatedText("LicenseCardHeaderSub")} image={`SettingsImages/Cockpit/LicenseCardHeaderCockpit${checkCockpitSettings.success && loadFromCockpit.cockpitAvailable === CockpitAvailable.CockpitAvailableYes ? '' : '_r'}.jpg`} />
                                <CardContent sx={{ pt: loadFromCockpit.cockpitAvailable === CockpitAvailable.CockpitAvailableYes ? 2 : 0 }} style={{ paddingBottom: 0 }}>

                                    <LoadFromCockpitOnlineHelp
                                        showOnlineHelp={showOnlineHelp}
                                        closeOnlineHelp={closeOnlineHelp}
                                        setShowAllComponentsForOnlineHelpToTrue={setShowAllComponentsForOnlineHelpToTrue}
                                        isCockpitAvailableBeforeOnlineHelp={loadFromCockpit.cockpitAvailable} />
                                    <Grid container direction={"row"}>
                                        <Grid item xs={12}>
                                            {(loadFromCockpit.cockpitAvailable === CockpitAvailable.CockpitAvailableYes || showAllComponentsForOnlineHelp) && <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                {checkCockpitSettings.status !== 'loading' ?
                                                    (checkCockpitSettings.success ?
                                                        <Chip className="onlinehelp-settings-loadFromCockpit-isUnlocked" label={getTranslatedText("HasCockpitConnection")} color="success" sx={{ color: '#fff' }} icon={<CheckCircleOutlineIcon sx={{ fontSize: 20, color: '#fff' }} />}></Chip>
                                                        :
                                                        <Chip className="onlinehelp-settings-loadFromCockpit-isUnlocked" color="error" label={getTranslatedText("HasNoCockpitConnection")} sx={{ color: '#fff' }} icon={<ErrorOutlineIcon sx={{ fontSize: 20 }} />}></Chip>

                                                    )
                                                    :

                                                    <Chip className="onlinehelp-settings-loadFromCockpit-isUnlocked" label={getTranslatedText("CheckCockpitConnection")} icon={<CircularProgress color="success" size={20} />}></Chip>
                                                }
                                                <div>
                                                    <Tooltip title={getTranslatedText("OpenConnectionProblemHelpDialog")} sx={{ display: { xs: 'none', md: 'inline' }, mr: "3px" }}>
                                                        <IconButton color="success" onClick={() => { dispatch(showHelpModal()) }}>
                                                            <LiveHelpIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Button className="onlinehelp-settings-loadFromCockpit-retry"
                                                        style={{ alignSelf: "end" }}
                                                        color="success"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            dispatch(checkSettings({
                                                                cockpitUrl: loadFromCockpit.cockpitUrl,
                                                                cockpitArea: loadFromCockpit.cockpitArea,
                                                                username: loadFromCockpit.credentials.username,
                                                                password: loadFromCockpit.credentials.password
                                                            }))
                                                        }}
                                                        autoFocus>
                                                        {getTranslatedText("RetryCockpitConnectionTestButtonText")}
                                                    </Button>
                                                </div>
                                            </div>}

                                        </Grid>
                                    </Grid>


                                    <Grid data-testid="cockpit-section" container direction={'row'} spacing={2} sx={{ mt: loadFromCockpit.cockpitAvailable === CockpitAvailable.CockpitAvailableYes ? 1 : 3 }}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel color="success" id="select-label-theme">{getTranslatedText("CockpitAvailable")} </InputLabel>
                                                {!licenceData.licenceAuthentication.isLicenced &&
                                                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                                                <Select
                                                    className="onlinehelp-settings-loadFromCockpit-selectCockpitAvailable"
                                                    disabled={!licenceData.licenceAuthentication.isLicenced}
                                                    color="success"
                                                    labelId="select-label-theme"
                                                    id="select-cockpitavailable"
                                                    name="select-cockpitavailable"
                                                    value={loadFromCockpit.cockpitAvailable}
                                                    label={getTranslatedText("CockpitAvailable")}
                                                    onChange={(e) => {
                                                        dispatch(setCockpitAvailable(e.target.value))
                                                        dispatch(cleanDevicesAndGroupsCache())
                                                    }}
                                                >
                                                    <MenuItem id="cockpitavailable0" value={CockpitAvailable.CockpitAvailableYes}>{getTranslatedText("CockpitAvailableYes")} </MenuItem>
                                                    <MenuItem id="cockpitavailable1" value={CockpitAvailable.CockpitAvailableNo}>{getTranslatedText("CockpitAvailableNo")} </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {(loadFromCockpit.cockpitAvailable === CockpitAvailable.CockpitAvailableYes || showAllComponentsForOnlineHelp) && <>
                                            <Grid item xs={12} alignContent="center" textAlign={'center'}>
                                                <FormControl fullWidth>
                                                    {!licenceData.licenceAuthentication.isLicenced &&
                                                        <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                                                    <TextField className="onlinehelp-settings-loadFromCockpit-cockpitServer" disabled={!licenceData.licenceAuthentication.isLicenced} fullWidth id="cockpitServer" value={loadFromCockpit.cockpitUrl} label={getTranslatedText("CockpitUrl")} variant="outlined" color="success" onChange={(e) => { dispatch(setLoadFromCockpitUrl(e.target.value)); dispatch(cleanDevicesAndGroupsCache()) }} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} alignContent="center" textAlign={'center'}>
                                                <FormControl fullWidth>
                                                    {!licenceData.licenceAuthentication.isLicenced &&
                                                        <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                                                    <TextField className="onlinehelp-settings-loadFromCockpit-customerArea" disabled={!licenceData.licenceAuthentication.isLicenced} fullWidth id="customerArea" value={loadFromCockpit.cockpitArea} label={getTranslatedText("CockpitArea")} variant="outlined" color="success" onChange={(e) => { dispatch(setLoadFromCockpitArea(e.target.value)); dispatch(cleanDevicesAndGroupsCache()) }} />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} alignContent="center" textAlign={'center'}>
                                                <FormControl fullWidth>
                                                    {!licenceData.licenceAuthentication.isLicenced &&
                                                        <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                                                    <TextField className="onlinehelp-settings-loadFromCockpit-userName" disabled={!licenceData.licenceAuthentication.isLicenced} fullWidth id="userName" value={loadFromCockpit.credentials.username} label={getTranslatedText("CockpitUsername")} variant="outlined" color="success" onChange={(e) => { dispatch(setLoadFromControllerUsername(e.target.value)); dispatch(cleanDevicesAndGroupsCache()) }} />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} alignContent="center" textAlign={'center'}>
                                                <FormControl fullWidth>
                                                    {!licenceData.licenceAuthentication.isLicenced &&
                                                        <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                                                    <TextField className="onlinehelp-settings-loadFromCockpit-password" disabled={!licenceData.licenceAuthentication.isLicenced} type={'password'} fullWidth id="password" value={loadFromCockpit.credentials.password} label={getTranslatedText("CockpitPassword")} variant="outlined" color="success" onChange={(e) => { dispatch(setLoadFromControllerPassword(e.target.value)); dispatch(cleanDevicesAndGroupsCache()) }} />
                                                </FormControl>
                                            </Grid>
                                        </>}
                                    </Grid>
                                    <DefaultSettingsSetterButton className={'onlinehelp-settings-resetAllCockpit'} action={toogleCockpitDefaultReset()} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grow>
            </>
        }
    </LanguageConsumer>
    )
}