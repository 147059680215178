/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { createSciChartAnnotation } from "../../Annotations/AnnotationService";
import { targetAreaAnnotationPrefix } from "../../Annotations/Redux/AnnotationTargetAreaSlice";
import { AnnotationVisibilityEnum } from "../../Settings/Models/AnnotationSettings";

interface UseHandleChangeTargetAreaAnnotationsArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeTargetAreaAnnotations = ({ sciChart }: UseHandleChangeTargetAreaAnnotationsArgs) => {
    const targetAreaAnnotations = useAppSelector(store => store.annotationTargetArea.annotations);
    const targetAreaVisibility = useAppSelector(store => store.settings.annotationSettings.targetAreaVisibility);

    useEffect(() => {
        if (sciChart && sciChart.sciChartSurface) {
            var annotationsToDelete = sciChart.sciChartSurface.annotations.asArray().filter(a => a.id.startsWith(targetAreaAnnotationPrefix));
            for (let annotation of annotationsToDelete) {
                var annotationToRemove = sciChart.sciChartSurface.annotations.getById(annotation.id);
                sciChart.sciChartSurface.annotations.remove(annotationToRemove);
            }
            if (targetAreaVisibility === AnnotationVisibilityEnum.Visible) {
                targetAreaAnnotations.forEach(annotation => {
                    let annotationToAdd = createSciChartAnnotation(annotation);

                    if (annotationToAdd) {
                        annotationToAdd.forEach(x => sciChart.sciChartSurface.annotations.add(x));
                    }
                });
            }
        }
    }, [targetAreaAnnotations, targetAreaVisibility])
}