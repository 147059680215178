import { createSlice } from '@reduxjs/toolkit';


export interface ModalState {
  isShown: boolean,
  bodyText: string,
  titleText: string,
  buttons?: JSX.Element | JSX.Element[],
  isCustomShown: boolean,
  customDevice: string,
}

const initialState: ModalState = {
  isShown: false,
  bodyText: "",
  titleText: "",
  isCustomShown: false,
  customDevice: ""
};


export const ModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.isShown = true;
      state.titleText = action.payload.titleText;
      state.bodyText = action.payload.bodyText;
      state.buttons = action.payload.buttons;
    },
    hideModal: (state) => {
        state.isShown = false;
        state.titleText = "";
        state.bodyText = "";
    },
    showCustomModal: (state, action) => {
      state.isCustomShown = true;
      state.customDevice = action.payload;
    },
    hideCustomModal: (state) => {
      state.isCustomShown = false;
      state.customDevice = ""
    }
  },
});

export const { showCustomModal, hideCustomModal,showModal, hideModal } = ModalSlice.actions;

export default ModalSlice.reducer;
