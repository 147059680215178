import { AxisBase2D } from "scichart/Charting/Visuals/Axis/AxisBase2D";
import { DpiHelper } from "scichart/Charting/Visuals/TextureManager/DpiHelper";

export function CalculateXValue(
  mouseEvent: MouseEvent,
  xAxis: AxisBase2D,
  Offset: number  
): number {
  var calculator = xAxis.getCurrentCoordinateCalculator();
 
  const xValue = (mouseEvent.offsetX - Offset) * DpiHelper.PIXEL_RATIO;
  const clickedXValue = calculator.getDataValue(xValue);
  return clickedXValue;
}

export function CalculateYValue(
  mouseEvent: MouseEvent,
  yAxis: AxisBase2D,
  Offset: number,  
): number {
  var calculator = yAxis.getCurrentCoordinateCalculator();

  const yValue = (mouseEvent.offsetY - Offset) * DpiHelper.PIXEL_RATIO;  
  const clickedYValue = calculator.getDataValue(yValue);
  return clickedYValue;
}
