/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { ShareEvents } from "../Model/Categories/ShareEnums";
import { reset_analyze_EditSharePicture, reset_analyze_ExportSharePicture } from "../Redux/AnalyticsSlice";

const PublishShareAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();

    const editSharePictureState = useAppSelector(store => store.analytics.editSharePictureState)
    const exportSharePictureState = useAppSelector(store => store.analytics.exportSharePictureState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackEditSharePicture = TrackEvent(ShareEvents.Edit.toString(), {})
    const trackExportSharePicture = TrackEvent(ShareEvents.Export.toString(), {})

    useEffect(() => {
        if (editSharePictureState.toggle) {
            try{
                trackEditSharePicture({Action: editSharePictureState.action.toString()})
            }
            catch{}
            dispatch(reset_analyze_EditSharePicture())
        }
    }, [editSharePictureState.toggle])

    useEffect(() => {
        if (exportSharePictureState.toggle) {
            try{
                trackExportSharePicture({Target: exportSharePictureState.target.toString()})
            }
            catch{}
            dispatch(reset_analyze_ExportSharePicture())
        }
    }, [exportSharePictureState.toggle])

    return (
        <></>
    )
}

export default PublishShareAnalytics;