/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { LicenseActivationEvents } from "../Model/Categories/LicenseItem";
import { reset_analyze_LicenseActivation } from "../Redux/AnalyticsSlice";

const PublishLicenseActivationAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();

    const licenseActivationState = useAppSelector(store => store.analytics.licenseActivationData)
    const licenceKey = useAppSelector(store => store.licenceService.licenceAuthentication.licenceKey)    

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackIsLicensed = TrackEvent(LicenseActivationEvents.Activate.toString(), { })
    
    useEffect(() => {
        if (licenseActivationState.toggle) {
            try{
                trackIsLicensed({ isMaster: licenseActivationState.isMaster, licenceKey: licenceKey.toString() })
            }
            catch{}
            dispatch(reset_analyze_LicenseActivation())
        }
    }, [licenseActivationState.toggle])

    return (
        <></>
    )
}

export default PublishLicenseActivationAnalytics;