import { licenseBaseUrl } from "../../../Api/BaseApi";
import { LicenceVerificationRequest } from "../Models/LicenceVerificationRequest";
import { LicenceVerificationResponse } from "../Models/LicenceVerificationResponse";

const simpleFetchCall = async (request: LicenceVerificationRequest) => {
    var response = await fetch(licenseBaseUrl + 'licensing/usages', {
        headers: new Headers({
            'ClientId': request.ClientId,
            'MasterOrChildKey': request.licenceKey,
            'TrialKey': request.trialKey.trim().length > 0 ? request.trialKey : 'nokey',
            'Content-Type': 'application/json'
        }),        
        method: 'POST',
    });
    return response.json();
}

const getResponse = async (request: LicenceVerificationRequest) => {
    const response = await simpleFetchCall(request);
    return response as LicenceVerificationResponse
}

export const verificateLicence = async (request: LicenceVerificationRequest): Promise<LicenceVerificationResponse> => {
    try {

        var response = await getResponse(request);

        return new Promise<any>((resolve, reject) => {
            if (response) {
                resolve(response)
            }
        });

    } catch (error: any) {
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);
    }
}