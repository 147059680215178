import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { sendLoadExampleFileToBackend } from '../../Features/Home/HeaderInformation/LoadExampleFile/Redux/LoadExampleFileSlice';
import { RootState } from '../../Infrastructure/Redux/store';
import { getSeriesForGraphDataAsync } from '../ChartResultHandler/Redux/ChartResultSlice';

export interface LoadingIndicatorState {
  isLoading: boolean;
}

const initialState: LoadingIndicatorState = {
    isLoading: false,
};

export const loadingIndicatorSlice = createSlice({
  name: 'loadingindicator',
  initialState,
  reducers: {
    setLoadingIndicator: (state) => {
      state.isLoading = true;
    },
    resetLoadingIndicator: (state) => {
      state.isLoading = false;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(getSeriesForGraphDataAsync.pending), (state) => {
        state.isLoading = true;
      }
    )
      .addMatcher(
        isAnyOf(getSeriesForGraphDataAsync.fulfilled), (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        isAnyOf(getSeriesForGraphDataAsync.rejected), (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        isAnyOf(sendLoadExampleFileToBackend.pending), (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(sendLoadExampleFileToBackend.fulfilled), (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        isAnyOf(sendLoadExampleFileToBackend.rejected), (state) => {
          state.isLoading = false;
        }
      )
  }
});

export const { setLoadingIndicator, resetLoadingIndicator } = loadingIndicatorSlice.actions;

export const isLoadingActive = (state: RootState) => state.loadingIndicator.isLoading;

export default loadingIndicatorSlice.reducer;
