import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getSeriesForGraphDataAsync } from '../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { sendLoadExampleFileToBackend } from '../../Home/HeaderInformation/LoadExampleFile/Redux/LoadExampleFileSlice';

export interface CurrentValue {
    title: string,
    currentValue: string,
    abbrevation: string,
}

export interface LegendCurrentValueState {
  currentValues: CurrentValue[];
}

const initialState: LegendCurrentValueState = {
    currentValues: [],
};

export const legendCurrentValueSlice = createSlice({
  name: 'legendCurrentValue',
  initialState,
  reducers: {
    updateCurrentValue: (state, action) => {
        state.currentValues = state.currentValues.map((curr) => {
            if(curr.title === action.payload.title){
                return {
                    ...curr,
                    currentValue: action.payload.currentValue
                }
            } else {
                return curr
            }
        })
      },
  },
  extraReducers: builder => {
      builder.addMatcher(
        isAnyOf(getSeriesForGraphDataAsync.fulfilled, sendLoadExampleFileToBackend.fulfilled), (state,action) => {
            if(action.payload && action.payload[0] && action.payload[0].ChartSeries.length > 0) {
                state.currentValues = action.payload[0].ChartSeries.map(serie => {
                    return {
                        title: serie.Title,
                        abbrevation: serie.UnitAbbreviation,
                        currentValue: ''
                    } as CurrentValue;
                })
            }
          }
      )
  }
});

export const { updateCurrentValue } = legendCurrentValueSlice.actions;

export default legendCurrentValueSlice.reducer;
