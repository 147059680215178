import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { setActiveTab, setShareType, ShareType } from "../Redux/SettingsSlice";
import { NavLink } from "react-router-dom";

export const ShareTypeSelect = () => {
    const dispatch = useAppDispatch();

    const shareType = useAppSelector(store => store.settings.shareType);
    const licenceData = useAppSelector(store => store.licenceService);

    const handleChangeTheme = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as ShareType;
        dispatch(setShareType(value));
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel color="success" id="select-label-theme">{getTranslatedText("ShareType")}</InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        className="onlinehelp-settings-shareType"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-theme"
                        id="select-share-type"
                        name="select-share-type"
                        value={shareType}
                        label={getTranslatedText("ShareType")}
                        onChange={handleChangeTheme}
                    >
                        <MenuItem id="share-type-0" value={ShareType.Chart}>{getTranslatedText("ShareTypeChart")} </MenuItem>
                        <MenuItem id="share-type-1" value={ShareType.ChartAndLegend}>{getTranslatedText("ShareTypeChartAndLegend")} </MenuItem>
                    </Select>
                </FormControl>
            }
        </LanguageConsumer>
    )
}