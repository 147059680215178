import { Box, Card, CardContent } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";
import './Style/SeriesLegend.css';
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { AddCurveFiles } from "./Components/AddCurveFiles";
import { LegendSkelekton } from "./Components/LegendSkeleton";
import { Header } from "./Components/Header";
import { SelectBox } from "./Components/SelectBox";
import { Content } from "./Components/Content/Content";

interface ISeriesLegendProps {
    seriesToHighlight: string;
    toggleStrokeWidthOnHover: (parentKey: number, childKey: number, strokeWidth: number, name: string) => void,
}

export const SeriesLegend = (props: ISeriesLegendProps) => {
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');

    return (
        <React.Fragment>
            <Card elevation={12} className="verticalScroller home-legendSeries onlinehelp-home-legendSeries" sx={{ zIndex: '0 !important', width: '100%' }}>
                <CardContent sx={{ paddingTop: "0" }}>
                    <Box component="div" sx={{ position: "sticky", top: "0px", paddingTop: "16px", zIndex: "2", backgroundColor: isDarkMode ? '#333' : "white" }} />
                    <Box component="div" sx={{ position: "sticky", top: "16px", zIndex: "1", backgroundColor: isDarkMode ? '#333' : "white" }}>
                        <Header />
                        <LegendSkelekton />
                        <AddCurveFiles />
                        <SelectBox />
                    </Box>
                    <Content seriesToHighlight={props.seriesToHighlight} toggleStrokeWidthOnHover={props.toggleStrokeWidthOnHover}/>
                </CardContent>
            </Card>
            <ColorPicker />
        </React.Fragment>
    );
}