import { Grid, TextField, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { CustomCsvXAxisMode } from "../Models/CustomCsvXAxisMode";
import { updateCustomCsvXAxisName, updateCustomCsvXAxisAbbrevation, updateCustomCsvXAxisMode, updateCustomCsvTimeModeConversion, updateCustomCsvTimeModeDateTimeFormat } from "../Redux/CustomCsvSlice";

export const CustomXAxis = () => {
    const dispatch = useAppDispatch();
    const customXAxis = useAppSelector(store => store.customCsv.xAxis);

    return(<LanguageConsumer>
        {({ getTranslatedText }) =>
        <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 1, pb: 2 }}>
        <Grid item xs={12}>
        {customXAxis &&  
          
               <Grid container direction={'row'} spacing={2} sx={{ p: 0, pt: 2 }}>
                   <Grid item xs={4}>
                       <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvXAxisName(e.target.value))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvXAxisName")} value={customXAxis.name}></TextField>
                   </Grid>
                   <Grid item xs={4}>
                       <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvXAxisAbbrevation(e.target.value))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvXAxisAbbrevation")} value={customXAxis.abbrevation}></TextField>
                   </Grid>
                   <Grid item xs={4}>
                   <FormControl fullWidth>
                        <InputLabel color="success" id="demo-simple-select-helper-label">{getTranslatedText("CustomCsvXAxisMode")} </InputLabel>
                        <Select
                            size="small"
                            fullWidth
                            color="success"
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={customXAxis.mode}
                            label={getTranslatedText("CustomCsvXAxisMode")}
                            onChange={(e) => dispatch(updateCustomCsvXAxisMode(e.target.value))}
                            displayEmpty>

                           <MenuItem value={CustomCsvXAxisMode.Value}>{getTranslatedText("CustomCsvXAxisModeValue")} </MenuItem>
                           <MenuItem value={CustomCsvXAxisMode.Time}>{getTranslatedText("CustomCsvXAxisModeTime")} </MenuItem>
                        </Select>
                    </FormControl>
                   </Grid>
                        {customXAxis.mode === CustomCsvXAxisMode.Time && <>
                            <Grid item xs={6}>
                                <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvTimeModeDateTimeFormat(e.target.value))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvXAxisTimeDateTimeFormat")} value={customXAxis.timeMode.xAxisDateTimeFormat}></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField size={'small'} onChange={(e) => dispatch(updateCustomCsvTimeModeConversion(e.target.value))} fullWidth variant="outlined" color="success" label={getTranslatedText("CustomCsvXAxisTimeConversion")} value={customXAxis.timeMode.xAxisConversion}></TextField>
                            </Grid>
                        </>}
               </Grid>
        }
        </Grid>

    </Grid>
}
</LanguageConsumer>);
}