import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../../Infrastructure/Redux/hooks';
import { hideHelpModal } from "../Redux/LoadAvailableFilesFromControllerSlice";
import { Box, Tabs, } from '@mui/material';
import Tab from '@mui/material/Tab';
import { LanguageConsumer } from '../../../Infrastructure/Internationalisation/TranslationService';
import { FaEdge, FaChrome, FaFirefox  } from "react-icons/fa";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  
  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 1, p: 0 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box component={'div'} sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


export const HelpDialog = () => {
    const dispatch = useAppDispatch();
    const open = useAppSelector(store => store.loadAvailableFilesFromController.showHelpModal );
    const lang = useAppSelector(store => store.settings.language);
    const [value, setValue] = React.useState(0);

    const handleClose = () => {
        dispatch(hideHelpModal());
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };


    return (<LanguageConsumer>
        {({getTranslatedText}) =>  
    <BootstrapDialog maxWidth={"md"} fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box component={'div'}>
                <Tabs 
                  sx={{
                    "& button": {paddingBottom: 0, paddingTop: 0},
                    "& button.Mui-selected": {color:"#32992c", borderBottom: 0, paddingBottom: 0, paddingTop: 0 },
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#32992c",
                      height: "4px"
                    },
                  }}
                value={value} onChange={handleChange}>
                    <Tab icon={<FaEdge />} iconPosition="start" label="Microsoft Edge" {...a11yProps(0)} />
                    <Tab icon={<FaChrome />} iconPosition="start" label="Google Chrome" {...a11yProps(1)} />
                    <Tab icon={<FaFirefox />} iconPosition="start" label="Mozilla Firefox" {...a11yProps(2)} />
                </Tabs>
            </Box>
        </BootstrapDialogTitle>
        <DialogContent className="verticalScroller">
            
            <TabPanel value={value} index={0}>
                <Typography sx={{mt: 2, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpHeader")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Edge' src={`/loadFromController/edge1_${lang}.png`} />
                <Typography sx={{mt: 1, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpEdgeClickOnSecurity")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Edge' src={`/loadFromController/edge2_${lang}.png`} />

                <Typography sx={{mt: 1, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpEdgeSetTheOption")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Edge' src={`/loadFromController/edge3_${lang}.png`} />

                <Typography sx={{mt: 1, mb: 2}}>
                    {getTranslatedText("LoadCuveFilesFromHttpEdgeRefreshThePage")}
                </Typography>
               
            </TabPanel>
            <TabPanel value={value} index={1}>
               
                <Typography sx={{mt: 2, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpHeader")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Chrome' src={`/loadFromController/chrome1_${lang}.png`} />
                <Typography sx={{mt: 1, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpChromeClickOnSecurity")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Chrome' src={`/loadFromController/chrome2_${lang}.png`} />

                <Typography sx={{mt: 1, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpChromeSetTheOption")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Chrome' src={`/loadFromController/chrome3_${lang}.png`} />

                <Typography sx={{mt: 1, mb: 2}}>
                    {getTranslatedText("LoadCuveFilesFromHttpChromeRefreshThePage")}
                </Typography>
                
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Typography sx={{mt: 2, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpHeader")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Firefox' src={`/loadFromController/firefox1_${lang}.png`} />
                <Typography sx={{mt: 1, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpFirefoxClickOnSecurity")}
                </Typography>

                <img alt="" style={{maxWidth: "100%"}} title='Firefox' src={`/loadFromController/firefox2_${lang}.png`} />

                <Typography sx={{mt: 1, mb: 3}}>
                    {getTranslatedText("LoadCuveFilesFromHttpFirefoxSetTheOption")}
                </Typography>

                <Typography sx={{mt: 1, mb: 3, color: '#f03e3e'}}>
                    {getTranslatedText("LoadCuveFilesFromHttpFirefoxSetTheOptionHint")}
                </Typography>

            </TabPanel>
        </DialogContent>
      </BootstrapDialog>
}
</LanguageConsumer>);
}