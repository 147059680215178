import { Grid, Skeleton } from "@mui/material"

export const PlaceholderForLoadingDevices = () => {
    return (        
            <Grid item xs={12} data-testid="load-device-and-group-from-cockpit-placeholder">
                <Grid container direction={'row'} spacing={2}>
                    <Grid item xs={2}>
                        <Skeleton animation={'wave'} variant="rectangular" width={'100%'} height={50} />
                    </Grid>
                    <Grid item xs={10}>
                        <Skeleton animation={'wave'} variant="rectangular"  width={'100%'} height={50} />
                    </Grid>
                </Grid>
            </Grid>           
    )
}