import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { deleteUsage } from "../../Infrastructure/LicenceService/Usage/Delete/Redux/DeleteUsageServiceSlice";

interface LicenseConsentState{
    showLicenseConsentDialog: boolean;
    accepted: boolean;
}

export const initialLicenseConsentState: LicenseConsentState = {
    showLicenseConsentDialog: false,
    accepted: false
}

export const licenceConsentSlice = createSlice({
    name: 'licenceConsent',
    initialState: initialLicenseConsentState,
    reducers: {
        acceptLicenseConsent: (state) => {
            state.accepted =  true;
            state.showLicenseConsentDialog = false;
        },
        rejectLicenseConsent: (state) => {
            state.accepted = false;
            state.showLicenseConsentDialog = false;
        }, 
        showLicenseConsent: (state) => {          
            state.accepted = false;
            state.showLicenseConsentDialog = true;
        }                 
    },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(deleteUsage.rejected), (state) => {
            state.accepted = false;
            state.showLicenseConsentDialog = false;
          })
        }
});


export const { acceptLicenseConsent, rejectLicenseConsent, showLicenseConsent } = licenceConsentSlice.actions;

export default licenceConsentSlice.reducer;