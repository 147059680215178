import { SeriesInfo } from "scichart/Charting/Model/ChartData/SeriesInfo";
import { XyDataSeries } from "scichart/Charting/Model/XyDataSeries";
import { FastLineRenderableSeries } from "scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { ChartResult } from "../Models/ChartResult";
import { getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService";
import { Color } from "../../../Infrastructure/ChartSeriesColors/Redux/ChartSeriesColorsSlice";
import { setHighlightedSeries } from "../../../Features/Settings/Redux/SettingsSlice";
import { shadeColor, pickTextColorBasedOnBgColorAdvanced } from "../Style/ColorChangeProvider";

export const drawSeries = ( seriesData: ChartResult[], sciChart: TWebAssemblyChart, colors: Color[], 
  dispatch: (action: {payload: undefined; type: string;}) => {payload: undefined;type: string;}, isLicenced: boolean, isDarkMode: boolean, isDeveloper: boolean = false) => {

    for(let j = 0; j < seriesData.length; j++){
        
       for (let i = 0; i < seriesData[j].ChartSeries.length; i++) {

            var xyDataSeries = new XyDataSeries(sciChart.wasmContext, {
                xValues: seriesData[j].ChartSeries[i].XValues,                   
                yValues: seriesData[j].ChartSeries[i].YValues,
                dataSeriesName: seriesData[j].ChartSeries[i].Title,
                containsNaN: true,
            });
          
            if((isDeveloper) || !seriesData[j].ChartSeries[i].IsInternalCurve){
              const renderableSeries = createRenderableSeries(sciChart, i, seriesData[j], xyDataSeries, colors, dispatch, isLicenced, isDarkMode);
              sciChart.sciChartSurface.renderableSeries.add(renderableSeries);
            }
             
        }                
    }
}

function createRenderableSeries(sciChart: TWebAssemblyChart, i: number, seriesData: ChartResult, xyDataSeries: XyDataSeries, colors: Color[],
    dispatch: (action: {payload: undefined; type: string;}) => {payload: undefined;type: string;}, isLicenced: boolean, isDarkMode: boolean): FastLineRenderableSeries{
    
    var filteredStoredColor = colors.filter(color => color.title === seriesData.ChartSeries[i].Title);

    var renderableSeries = new FastLineRenderableSeries(sciChart.wasmContext, { 
      onSelectedChanged: sourceSeries => {
        sourceSeries.strokeThickness = sourceSeries.isSelected ? 4 : 1.5;
        dispatch(setHighlightedSeries(sourceSeries.isSelected ? sourceSeries.getDataSeriesName() : ""))
      } });

    
    renderableSeries.isDigitalLine = false;
    renderableSeries.dataSeries = xyDataSeries;
    renderableSeries.strokeThickness = seriesData.ChartSeries[i].StrokeWidth!;
    renderableSeries.isVisible = seriesData.ChartSeries[i].Visible;
    renderableSeries.stroke = filteredStoredColor.length > 0 ? filteredStoredColor[0].color : (isDarkMode ? shadeColor(seriesData.ChartSeries[i].Color, 40) : seriesData.ChartSeries[i].Color);  
    renderableSeries.rolloverModifierProps.tooltipTextColor = pickTextColorBasedOnBgColorAdvanced(filteredStoredColor.length > 0 
                                                ? filteredStoredColor[0].color : seriesData.ChartSeries[i].Color, "#ffffff", "#000000");                                                       
    renderableSeries.rolloverModifierProps.tooltipDataTemplate = 
    ( seriesInfo: SeriesInfo, tooltipTitle: string, tooltipLabelX: string, tooltipLabelY: string) =>{

            const yValueCalculated = seriesInfo.yValue / Number(seriesData.ChartSeries[i].ScaleFactor);
            return [getTranslation(tooltipTitle), (!isLicenced ? "🔒" : String(yValueCalculated.toFixed(seriesData.ChartSeries[i].DigitCount))) + " " + seriesData.ChartSeries[i].UnitAbbreviation]
    } 

    return renderableSeries;
}
