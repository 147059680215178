import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { sendLoadExampleFileToBackend } from '../../../Features/Home/HeaderInformation/LoadExampleFile/Redux/LoadExampleFileSlice';
import { ChartRequest } from '../../../Features/Home/Models/ChartRequest';
import { SuperVisualizationModeDto } from '../../../Features/Home/Models/SuperVisualizationModeDto';
import { RootState } from '../../../Infrastructure/Redux/store';
import { getSeriesForGraphDataAsync } from '../../ChartResultHandler/Redux/ChartResultSlice';

export interface GraphDataRequestState {
  series: ChartRequest | null;
}

export const initialStateGraphDataRequest: GraphDataRequestState = {
  series: null,
};

export const graphDataRequestSlice = createSlice({
  name: 'graphDataRequest',
  initialState: initialStateGraphDataRequest,
  reducers: {
    saveSeriesRequestsInStore: (state, action) => {
      state.series = action.payload;
    },
    setSeriesType: (state, action) => {
      if (state.series && state.series.Settings.SuperVisualization) {
        state.series.Settings.SuperVisualization.SeriesType = action.payload;
      }
    },
    resetChartRequest: (state) => {
      state.series = null;
    },
    removeSeriesFromRequest: (state, action) => {
      if (state.series && state.series.Files && state.series.Files.length > 0) {
        state.series.Files = [...state.series.Files.filter(f => f.Id !== action.payload)];        
      }
    },
    removeSeriesFromRequestSortOrder: (state, action) => {
      if (state.series && state.series.Files && state.series.Files.length > 0) {        
        state.series.SortedList = [...state.series.SortedList.filter(f => f.title !== action.payload)];
      }
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(getSeriesForGraphDataAsync.fulfilled, sendLoadExampleFileToBackend.fulfilled), (state, action) => {
        if (state.series && state.series.Settings.SuperVisualization && state.series?.Settings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None) {
          state.series.Settings.SuperVisualization.SeriesType = action.payload[0].SelectedSeriesType;
        }

        if (action.payload && action.payload[0] && action.payload[0].IsCustom &&
          state.series && state.series.Settings.SuperVisualization && state.series.Settings.SuperVisualization.Mode !== SuperVisualizationModeDto.None) {
          state.series.Settings.SuperVisualization.Mode = SuperVisualizationModeDto.None
        }

        if (action.payload.length > 1 && state.series && state.series.Settings.SuperVisualization && state.series?.Settings.SuperVisualization?.Mode === SuperVisualizationModeDto.None) {
          state.series.Settings.SuperVisualization.Mode = action.payload.some(x => x.IsCustom) ? SuperVisualizationModeDto.None : SuperVisualizationModeDto.Angle
        }
      }
    ).addMatcher(
      isAnyOf(getSeriesForGraphDataAsync.rejected), (state, action) => {
        if (action.error.message !== "FilterSettingsNotValid") {
          state.series = null;
        }
      })
  }
});

export const { removeSeriesFromRequest, saveSeriesRequestsInStore, setSeriesType, resetChartRequest, removeSeriesFromRequestSortOrder } = graphDataRequestSlice.actions;

export const selectChartRequestSeries = (state: RootState) => state.chartRequestData.series;

export default graphDataRequestSlice.reducer;
