import { DateRangeFilter, CurveFileFilter } from "../../CurveData/Simplified/Api/CurveFileFilter";
import { DevicesInformationRequest } from "../../CurveData/Simplified/Api/DevicesInformationRequest";
import { SimplifiedCurveDataRequest } from "../../CurveData/Simplified/Api/SimplifiedCurveDataRequest";

export const CreateLoadFromCockpitSimplifiedCurveDatasRequest = (
    deviceIds,
    dateRangeFilterItems,
    loadFromCockpit,
    filterByState,
    cutOffTime,
    curveFilesToSkip,
    maxRecivedSimplifiedCurves) => {

    let dateRanges = dateRangeFilterItems.map((dateRanges) => {
        return ({
            from: dateRanges.from,
            to: dateRanges.to
        })
    }) as DateRangeFilter[];

    let curveFileFilter: CurveFileFilter = {
        DateRanges: dateRanges,
        State: filterByState,
    }

    let devicesInformationRequest : SimplifiedCurveDataRequest = {
        cockpitUrl: loadFromCockpit.cockpitUrl,
        cockpitArea: loadFromCockpit.cockpitArea,
        username: loadFromCockpit.credentials.username,
        password: loadFromCockpit.credentials.password,
        DeviceIds: deviceIds,
        CurveFileFilter: curveFileFilter,
        CurveFilesToSkip: curveFilesToSkip,
        MaxRecivedFiles: maxRecivedSimplifiedCurves,
        CutOffTime: cutOffTime
    }

    return devicesInformationRequest;
}


export const CreateLoadFromCockpitDeviceInformationsRequest = (
    selectedDeviceIds,
    dateRangeFilterItems,
    loadFromCockpit,
    filterByState,
    loadCurvesAbortController,
    cutOffDateTime) => {   

    let dateRanges = dateRangeFilterItems.map((dateRanges) => {
        return ({
            from: dateRanges.from,
            to: dateRanges.to
        })
    }) as DateRangeFilter[];

    let curveFileFilter: CurveFileFilter = {
        DateRanges: dateRanges,
        State: filterByState,
    }

    let devicesInformationRequest = {
        cockpitUrl: loadFromCockpit.cockpitUrl,
        cockpitArea: loadFromCockpit.cockpitArea,
        username: loadFromCockpit.credentials.username,
        password: loadFromCockpit.credentials.password,
        SelectedDeviceIds: selectedDeviceIds,
        AbortController: loadCurvesAbortController,
        CurveFileFilter: curveFileFilter,
        CutOffDateTime : cutOffDateTime
    } as DevicesInformationRequest

    return devicesInformationRequest;
}