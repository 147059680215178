import { Grid, Typography, Card, CardContent, Container } from "@mui/material";
import { useMediaQuery } from 'react-responsive'
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import { useAppSelector } from "../../Infrastructure/Redux/hooks";

export const ResponsiveDesign = ({ children }) => {
  const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark')
  const isRunningOnExtraSmallDevice = useMediaQuery({ query: '(max-width: 600px)' })

  if (isRunningOnExtraSmallDevice) {
    return (<Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
      <Card>
        <CardContent>
          <Grid container direction={'row'} spacing={2}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <MobileScreenShareIcon sx={{ color: isDarkMode ? '#fff' : '#999', fontSize: "200px" }} />
            </Grid>
            <Grid item xs={12}>

              <Typography>To have the best experience with the DEPRAG GraphViewer Cloud please use a device with at least 600 pixels width.</Typography>

            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </Container>)
  }
  else {
    return <>
      {children}
    </>
  }
}