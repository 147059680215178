import { createSlice } from '@reduxjs/toolkit';

export interface PersistedVisibility {
    title: string,
    visible: boolean,
}

export interface PersistedChartSeriesVisibilityState {
    visibilities: PersistedVisibility[];
  }
  
  const initialState: PersistedChartSeriesVisibilityState = {
      visibilities: [],
  };

export const persistedChartSeriesVisibilitySlice = createSlice({
    name: 'persistedChartSeriesVisibility',
    initialState,
    reducers: {
        setPersitedChartSeriesVisibility: (state, action) => {
            let isUpdated = false;
            const newVisibility = state.visibilities.map(visible => {
                if(visible.title === action.payload.title){
                    isUpdated = true;
                    return {
                        ...visible,
                        visible: action.payload.visible
                    };
                } else {
                    return visible;
                }
            });
    
            !isUpdated && newVisibility.push(action.payload);
    
            state.visibilities = newVisibility;
          }                
    }
})

export const { setPersitedChartSeriesVisibility } = persistedChartSeriesVisibilitySlice.actions;
export default persistedChartSeriesVisibilitySlice.reducer;