import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { Chip } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { setActiveTab } from "../../../Settings/Redux/SettingsSlice";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { SuperVisualizationModeDto } from "../../Models/SuperVisualizationModeDto";
import { selectSeries } from "../../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { memo } from "react";

export const VisualizationMode = memo(() => {
    const dispatch = useAppDispatch();
    const isSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark')

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>            
                <Chip
                    component={NavLink}
                    onClick={() => dispatch(setActiveTab(1))}
                    to={"/settings#analysis"}
                    className="headerInformation-curveMonitoringType headerInformation"
                    sx={{ zIndex: 999, background: 'rgba(255,255,255,1)', cursor: "pointer", mr: 2, color: isDarkMode ? '#444' : '#444' }}
                    icon={isSuperVisualisation ? <SsidChartIcon /> : <ShowChartIcon />}
                    label={getTranslatedText(isSuperVisualisation ? "SuperVisualisation" : "SingleVisualisation")}
                    variant="filled" />          
        }
    </LanguageConsumer>
    );
})