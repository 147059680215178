import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { verificateLicence } from "../Api/VerificateLicence";
import { LicenceVerificationRequest } from "../Models/LicenceVerificationRequest";

export interface LicenceVerificationState {
    status: 'idle' | 'loading' | 'failed';
}

export const initialState: LicenceVerificationState = {
    status: 'idle',
};

export const licenceVerification = createAsyncThunk(
    'licenceService/verification', async (request: LicenceVerificationRequest) => {
        const response = await verificateLicence(request);
        return response;
    }
);

export const licenceServiceSlice = createSlice({
    name: 'licenceService',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(licenceVerification.pending, (state) => {
            state.status = "loading"

        }).addCase(licenceVerification.fulfilled, (state) => {
            state.status = "idle"
        })
            .addCase(licenceVerification.rejected, (state) => {
                state.status = "failed"
            })
    }
});


export default licenceServiceSlice.reducer;


