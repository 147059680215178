import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getSeriesForGraphDataAsync } from '../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice';
import { getTranslation } from '../../../Infrastructure/Internationalisation/TranslationService';
import { loadAvailableFilesFromController } from "../../../Features/LoadFromController/Redux/LoadAvailableFilesFromControllerSlice";
import { sendFeedbackFormToBackend } from '../../../Features/Feedback/Redux/FeedbackSlice';
import { loadSelectedFilesFromController } from '../../../Features/LoadFromController/Redux/LoadSelectedFilesFromControllerSlice';
import { loadDeviceInformations } from '../../../Features/LoadFromCockpit/CurveData/Simplified/Redux/LoadFromCockpitDeviceInformationsSlice';
import { loadSimplifiedCurveDatas } from '../../../Features/LoadFromCockpit/CurveData/Simplified/Redux/LoadFromCockpitSimplifiedCurveDataSlice';
import { loadAvailableFilesFromCockpit } from '../../../Features/LoadFromCockpit/CurveData/Complete/Redux/LoadFromCockpitCompleteCurveDataSlice';
import { loadMasterSettings } from '../../../Features/Settings/Components/LicenseMasterSettings/Redux/LicenseMasterSettingsSlice';
import { licenceActivation } from '../../../Infrastructure/LicenceService/Activation/Redux/LicenceActivationServiceSlice';
import { deleteUsage } from '../../../Infrastructure/LicenceService/Usage/Delete/Redux/DeleteUsageServiceSlice';
import { postMasterSettingsInvitation } from '../../../Features/Settings/Components/LicenseMasterSettings/Redux/LicenseMasterSettingsInvitationSlice';
import { licenceVerification } from '../../../Infrastructure/LicenceService/Verification/Redux/LicenceVerificationSlice';
import { postDeveloperPassword } from '../../../Infrastructure/Developer/DeveloperSlice';
import { licenceOffer } from '../../../Infrastructure/LicenceService/Offer/Regular/Redux/LicenceOfferServiceSlice';
import { licenceRenewalOffer } from '../../../Infrastructure/LicenceService/Offer/Renewal/Redux/LicenceRenewalOfferServiceSlice';

export enum NotificationType {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success"
}

export interface NotificationState {
  isShown: boolean;
  type: NotificationType,
  text: string,
  autoHide: boolean,
}

const initialState: NotificationState = {
  isShown: false,
  type: NotificationType.error,
  text: "",
  autoHide: true,
};


export const NotificationsSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.isShown = true;
      state.type = action.payload.type;
      state.text = action.payload.text;
    },
    hideNotifications: (state) => {
      state.isShown = false;
      state.text = "";
      state.autoHide = true;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(sendFeedbackFormToBackend.fulfilled), (state, action) => {
        if (action.payload.success) {
          state.isShown = true;
          state.type = NotificationType.success;
          state.text = getTranslation("SuccessfullySendFeedback")
        }
      }
    ).addMatcher(
      isAnyOf(getSeriesForGraphDataAsync.rejected), (state, action) => {
        if (action.error.code !== "ERR_CANCELED") {

          let fileCouldNotBeInterpratedMessage = getTranslation("FileCouldNotBeInterprated");
          state.text = fileCouldNotBeInterpratedMessage

          if (action.error && action.error.message){
            let transalteableMessage = getTranslation(action.error.message);
            if (messageDoesHaveTranslation(transalteableMessage, action.error.message)){
              state.text = transalteableMessage
            }
          }

          state.isShown = true;
          state.type = NotificationType.error          
        }
    }
    ).addMatcher(
      isAnyOf(loadAvailableFilesFromController.rejected), (state, action) => {

        if (action.error && action.error.name === "AbortError") {
          return;
        }

        if (action.error && action.error.message === "Failed to fetch") {
          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation("FailedToFetch");
          return;
        }
        state.isShown = true;
        state.type = action.error.message === "NoDataFoundOnAST" ? NotificationType.info : NotificationType.error
        state.text = action.error.message && action.error.name !== "RangeError" ? (getTranslation(action.error.message) !== "" ? getTranslation(action.error.message) : action.error.message) : getTranslation("FileCouldNotBeInterprated")
      }
    )
      .addMatcher(
        isAnyOf(loadSelectedFilesFromController.rejected), (state, action) => {

          if (action.error && action.error.name === "AbortError") {
            return;
          }

          if (action.error && action.error.message === "Failed to fetch") {
            state.isShown = true;
            state.type = NotificationType.error;
            state.text = getTranslation("FailedToFetch");
            return;
          }
          state.isShown = true;
          state.type = action.error.message === "NoDataFoundOnAST" ? NotificationType.info : NotificationType.error
          state.text = action.error.message && action.error.name !== "RangeError" ? (getTranslation(action.error.message) !== "" ? getTranslation(action.error.message) : action.error.message) : getTranslation("FileCouldNotBeInterprated")
        }
      )
      .addMatcher(
        isAnyOf(loadDeviceInformations.rejected), (state, action) => {

          if (action.error && action.error.name === "AbortError") {
            return;
          }

          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation("LoadFromCockptDeviceInformationsCouldNotBeLoaded")

        })
      .addMatcher(
        isAnyOf(loadSimplifiedCurveDatas.rejected), (state, action) => {

          if (action.error && action.error.name === "AbortError") {
            return;
          }

          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation("LoadFromCockptSimplifiedFilesCouldNotBeLoaded")

        })
      .addMatcher(
        isAnyOf(loadAvailableFilesFromCockpit.rejected), (state, action) => {

          if (action.error && action.error.name === "AbortError") {
            return;
          }

          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation("LoadFromCockptFileContentCouldNotBeLoaded")

        })
      .addMatcher(
        isAnyOf(loadMasterSettings.fulfilled), (state, action) => {
          if (!action.payload.success) {
            state.isShown = true;
            state.type = NotificationType.error;
            state.text = getTranslation(action.payload.errorMessage)
          }
        }
      )
      .addMatcher(isAnyOf(licenceActivation.rejected), (state, action) => {

        if (action.error.message) {
          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation(action.error.message)

        }
      })
      .addMatcher(isAnyOf(licenceOffer.fulfilled), (state, action) => {

        state.isShown = true;
        state.type = NotificationType.success;
        state.autoHide = true
        state.text = getTranslation("OfferResponseSuccessfullyCreated")
      })
      .addMatcher(isAnyOf(licenceOffer.rejected), (state, action) => {

        if (action.error.message) {
          state.isShown = true;
          state.type = NotificationType.info;
          state.autoHide = false
          state.text = getTranslation(action.error.message)

        }
      })
      .addMatcher(isAnyOf(deleteUsage.fulfilled), (state) => {

        state.isShown = true;
        state.type = NotificationType.success;
        state.text = getTranslation("DeleteUsageSuccessfully")
      })
      .addMatcher(isAnyOf(deleteUsage.rejected), (state, action) => {

        if (action.error.message) {
          state.isShown = true;
          state.type = NotificationType.info;
          state.text = getTranslation(action.error.message)

        }
      })
      .addMatcher(isAnyOf(postMasterSettingsInvitation.fulfilled), (state, action) => {
        if (action.payload.success) {
          state.isShown = true;
          state.type = NotificationType.success;
          state.text = getTranslation("SuccessfullySendInvitation");
        } else {
          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation((action.payload as any).errorMessage);
        }
      })
      .addMatcher(isAnyOf(postMasterSettingsInvitation.rejected), (state, action) => {

        if (action.error.message) {
          state.isShown = true;
          state.type = NotificationType.info;
          state.text = getTranslation(action.error.message)

        }
      })
      .addMatcher(isAnyOf(licenceVerification.fulfilled), (state, action) => {
        if (!action.payload.success && action.payload.errorMessage !== ''){
          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation(action.payload.errorMessage)
        }
      })
      .addMatcher(isAnyOf(licenceRenewalOffer.fulfilled), (state, action) => {
        if (action.payload.success){
          state.isShown = true;
          state.type = NotificationType.success;
          state.text = getTranslation("SuccessfullyRequestLicenceRenewal")
        }
      })
      .addMatcher(isAnyOf(licenceRenewalOffer.fulfilled), (state, action) => {
        if (!action.payload.success && action.payload.errorMessage !== ''){
          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation(action.payload.errorMessage)
        }
      })
      .addMatcher(isAnyOf(postDeveloperPassword.fulfilled), (state, action) => {
        if(action.payload.success){
          state.isShown = true;
          state.type = NotificationType.success;
          state.text = getTranslation("YouAreDeveloper");
        } else {
          state.isShown = true;
          state.type = NotificationType.error;
          state.text = getTranslation(action.payload.errorMessage);
        }
      })
      .addMatcher(isAnyOf(postDeveloperPassword.rejected), (state, action) => {
        if (action.error.message) {
          state.isShown = true;
          state.type = NotificationType.info;
          state.text = getTranslation(action.error.message)
        }
      })
  }
})

function messageDoesHaveTranslation(transalteableMessage: string, originalErrorMessage: string) {
  return transalteableMessage !== originalErrorMessage;
}

export const { setNotification, hideNotifications } = NotificationsSlice.actions;

export default NotificationsSlice.reducer;
