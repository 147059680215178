import { ChartRequest } from './Models/ChartRequest';
import { ChartResult } from './Models/ChartResult';
import {messagePackClient } from '../../Infrastructure/Api/BaseApi'
import { ChartSeries } from './Models/ChartSeries';

export const getChart = async (request: ChartRequest, abortController: AbortController)  =>  {
    
    var regionIdentifier = getRegionIdentifier(request);

    var chartResultRaw = await messagePackClient.post("chart/convert", {
            ...request,
            RegionIdentifier: regionIdentifier
        }, {
        signal: abortController.signal,
    });        
    let chartResultArray : ChartResult[] = mapChartResult(chartResultRaw);
    return chartResultArray;
}

export function mapChartResult(chartResultRaw: any) : ChartResult[]{
    let chartResultArray : ChartResult[] = [];
    chartResultRaw.data.forEach((element: ChartResult) => {
    
        let chartSeries: ChartSeries[] = element.ChartSeries.map((series: any, key: number) => ({
            XValues: series.XValues.map((value: any) => value ?? 0),
            YValues: series.YValues.map((value: any) => value ?? NaN),
            Color: series.Color,
            Priority: series.Priority,
            ScaleFactor: series.ScaleFactor,  
            Visible: series.Visible,
            Title: series.Title,
            StrokeWidth: 1.5,
            UnitAbbreviation: series.UnitAbbreviation,
            DigitCount: series.DigitCount,
            ReSamplingData: series.ReSamplingData,
            ReSamplingDataSecondary: series.ReSamplingDataSecondary,
            CurrentValue: "",
            Position: series.Priority * 1000,
            HasValues: series.YValues.length > 0,
            ChartRequestId:series.ChartRequestId,
            IsInternalCurve: series.IsInternalCurve
        }));
    
        let chartResult: ChartResult = {
            Title: element.Title,
            ChartSeries: chartSeries,
            SeriesTypes: element.SeriesTypes,
            TargetAreas: element.TargetAreas,
            SelectedSeriesType: element.SelectedSeriesType,
            MetaData: element.MetaData,
            StepAreas: element.StepAreas,
            XAxisUnitAbbreviation: element.XAxisUnitAbbreviation,
            XAxisDigitCount: element.XAxisDigitCount,
            XAxisDateTimeFormat: element.XAxisDateTimeFormat,
            IsCustom: element.IsCustom,
            XAxisIsDateTime: element.XAxisIsDateTime,
            SecondarySamplingDataDigitCount: element.SecondarySamplingDataDigitCount,
            LabellingDistinctiveValues: element.LabellingDistinctiveValues,
        };
        chartResultArray.push(chartResult);  
    });

    return chartResultArray;
}

export function getRegionIdentifier(request: ChartRequest) {
    return request.LanguageIdentifier === "de" ? "DE" : "US";
}
