import { licenseBaseUrl } from "../../../Api/BaseApi";
import { LicenceActivationRequest } from "../Models/LicenceActivationRequest";
import { LicenceActivationResponse } from "../Models/LicenceActivationResponse";

const simpleFetchCall = async (request: LicenceActivationRequest) => {
    var response = await fetch(licenseBaseUrl + 'licensing/activation', {
        headers: new Headers({
            'ClientId': request.ClientId,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(
            {
                MasterOrChildKey: request.MasterOrChildKey,
                EmailAddress: request.EmailAddress,
                PrivacyAcknowledged: request.PrivacyAcknowledged
            }),
        method: 'POST',
    });
    return response.json();
}

const getResponse = async (request: LicenceActivationRequest) => {
    const response = await simpleFetchCall(request);
    return response as LicenceActivationResponse
}

export const activateLicence = async (request: LicenceActivationRequest): Promise<LicenceActivationResponse> => {
    try {

        var response = await getResponse(request);
        return new Promise<any>((resolve, reject) => {
            if (response && response.success) {
                resolve(response)
            }
            else {
                reject(response.errorMessage)
            }
        });

    } catch (error: any) {
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);
    }
}