import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DefaultSettingsSetterState {
    generalDefaultResetToogled: boolean,
    generalDefaultResetWithoutLanguageToogled: boolean,
    curveAnalysisDefaultResetToogled: boolean,
    annotationsDefaultResetToogled: boolean,
    cockpitDefaultResetToogled: boolean,
}

export interface DefaultSettingsSetterAction {
    ResetLanguage: boolean,
}

export const initialState: DefaultSettingsSetterState = {
    generalDefaultResetToogled: false,
    generalDefaultResetWithoutLanguageToogled: false,
    curveAnalysisDefaultResetToogled: false,
    annotationsDefaultResetToogled: false,
    cockpitDefaultResetToogled: false,
};

export const defaultSettingsSetterSlice = createSlice({
    name: 'defaultSettingsSetter',
    initialState: initialState,
    reducers: {
        toogleGeneralDefaultReset: (state) => {
            state.generalDefaultResetToogled = !state.generalDefaultResetToogled;
        },
        toogleGeneralDefaultResetWithoutLanguage: (state) => {
            state.generalDefaultResetWithoutLanguageToogled = !state.generalDefaultResetWithoutLanguageToogled;
        },
        toogleAnalysisDefaultReset: (state) => {
            state.curveAnalysisDefaultResetToogled = !state.curveAnalysisDefaultResetToogled;
        },
        toogleAnnotationsDefaultReset: (state) => {
            state.annotationsDefaultResetToogled = !state.annotationsDefaultResetToogled;
        },
        toogleCockpitDefaultReset: (state) => {
            state.cockpitDefaultResetToogled = !state.cockpitDefaultResetToogled;
        },
        toogleAllReset: (state, action: PayloadAction<DefaultSettingsSetterAction>) => {
            const resetLanguage = action.payload.ResetLanguage ?? true;
            if (resetLanguage) {
                state.generalDefaultResetToogled = !Boolean(state.generalDefaultResetToogled)
            }
            else {
                state.generalDefaultResetWithoutLanguageToogled = !Boolean(state.generalDefaultResetWithoutLanguageToogled)
            }
            state.curveAnalysisDefaultResetToogled = !Boolean(state.curveAnalysisDefaultResetToogled);
            state.annotationsDefaultResetToogled = !Boolean(state.annotationsDefaultResetToogled);
            state.cockpitDefaultResetToogled = !Boolean(state.cockpitDefaultResetToogled);
        }
    }
});

export const { toogleGeneralDefaultReset, toogleGeneralDefaultResetWithoutLanguage, toogleAnalysisDefaultReset, toogleAnnotationsDefaultReset, toogleCockpitDefaultReset, toogleAllReset } = defaultSettingsSetterSlice.actions;

export default defaultSettingsSetterSlice.reducer;
