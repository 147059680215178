import { ChartRequest, ChartRequestSettings } from "../../Features/Home/Models/ChartRequest";
import { convertFileListToPreparedGraphDataForBackendCall } from "../FileListConverter/FileListConverter";
import { LocalStorageState } from "../LocalStorage/Redux/CrossLocalStorageSlice";

export const downloadAndConvertFiles = async (list: LocalStorageState,
    chartRequestSettings: ChartRequestSettings,
    languageIdentifier: string): Promise<ChartRequest> => {
    const parsedFileArray = JSON.parse(list.keyValue) as string[];
    var fileList = [] as any[];

    try {
        if (parsedFileArray && parsedFileArray.length > 0) {
            const promises = parsedFileArray.map(async file => {
                const realFile = await downloadFile(file);
                fileList.push(realFile);
            })
            await Promise.all(promises);

            var listOfConvertedFiles = await convertFileListToPreparedGraphDataForBackendCall(fileList, chartRequestSettings, languageIdentifier);

            return new Promise(resolve => {
                resolve(listOfConvertedFiles)
            })
        } else {
            return new Promise((resolve, reject) => {
                reject()
            })
        }
    }
    catch {
        return new Promise((resolve, reject) => {
            reject()
        })
    }
}

export const downloadAndConvertSingleFile = async (files: string[], chartRequestSettings: ChartRequestSettings,
    languageIdentifier: string): Promise<ChartRequest> => {
    var fileList = [] as any[];

    try {
        if (files.length > 0) {
            const promises = files.map(async file => {
                const realFile = await downloadFile(file);
                fileList.push(realFile);
            })
            await Promise.all(promises);

            var listOfConvertedFiles = await convertFileListToPreparedGraphDataForBackendCall(fileList,
                chartRequestSettings, languageIdentifier);

            return new Promise(resolve => {
                resolve(listOfConvertedFiles)
            })
        } else {
            return new Promise((resolve, reject) => {
                reject()
            })
        }
    }
    catch {
        return new Promise((resolve, reject) => {
            reject()
        })
    }
}


async function downloadFile(url: string): Promise<File> {
    let response = await fetch(url);
    var content = await response.blob();
    var fileNameWithoutQuotes = "";
    
    if (response.headers.has('Content-Disposition')){
        const header = response.headers.get('Content-Disposition');
        const parts = header!.split(';');
        var filename = parts[1].split('=')[1];
        fileNameWithoutQuotes = filename.replace("\"", "");
        fileNameWithoutQuotes = fileNameWithoutQuotes.replace("\"", "");
    }
    else{      
        const urlFileNameRegEx = /[^/|^=|^?]*$/;
        let fileName = urlFileNameRegEx.exec(url);
        fileNameWithoutQuotes = fileName !== null ? fileName[0] : url   
    }
    var file = new File([content], fileNameWithoutQuotes);   

    return new Promise(resolve => { resolve(file); });
}

