import { nanoid } from "@reduxjs/toolkit";
import { Annotation } from "../../Features/Annotations/Annotation";
import { ChartRequest, ChartRequestFile, ChartRequestSettings } from "../../Features/Home/Models/ChartRequest";
import { SettingsState } from "../../Features/Settings/Redux/SettingsSlice";
import { ExportModel } from "../ExportProvider/FileExportGvc";

export interface GvcImportModel {
    ChartRequest: ChartRequest,
    Settings: SettingsState,
    UserAnnotations: Annotation[]
}

export const convertGvcFileListToPreparedGraphDataForBackendCall = async (fileList: FileList | File[], 
    settings: ChartRequestSettings,
    languageIdentifier: string) : Promise<GvcImportModel> => {
        var listOfFilesForRequest = [] as ChartRequestFile[];
        var savedSettings = {} as SettingsState;
        var userAnnotations = [] as Annotation[];

        const allConvertedFiles = async ()  => {
            const arrayOfFileList =  [...fileList];
    
            const promises = arrayOfFileList.map(async file => {
                listOfFilesForRequest = await getListOfChartRequestFilesFromGvc(file);
                savedSettings = await getSavedSettings(file);
                userAnnotations = await getSavedUserAnnotations(file);
            })
            await Promise.all(promises);
    
             var chartRequest : ChartRequest = {
                 Settings: savedSettings.chartRequestSettings,
                 Files: listOfFilesForRequest,
                 SortedList: [],
                 LanguageIdentifier: languageIdentifier
             }
             
                 
            return {
                ChartRequest: chartRequest,
                Settings: savedSettings,
                UserAnnotations: userAnnotations
              } as GvcImportModel;
    
          }
          var list = await allConvertedFiles();

          return new Promise(resolve => {
              resolve(list)
          })
}

export const convertFileListToPreparedGraphDataForBackendCall = async (
    fileList: FileList | File[], 
    settings: ChartRequestSettings,
    languageIdentifier: string) : Promise<ChartRequest> => {
    var listOfFilesForRequest = [] as ChartRequestFile[];
   
    const allConvertedFiles = async ()  => {
        const arrayOfFileList =  [...fileList];

        const promises = arrayOfFileList.map(async file => {
           
                const byteArrayFile = await fileToByteArray(file);
            
                listOfFilesForRequest.push({
                    Title: file.name, 
                    CurveRawContent: JSON.stringify(byteArrayFile),
                    Id: nanoid()} as ChartRequestFile);
            
        })

        await Promise.all(promises);

         var chartRequest : ChartRequest = {
             Settings: settings,
             Files: listOfFilesForRequest,
             SortedList: [],
             LanguageIdentifier: languageIdentifier
         }
             
        return chartRequest;

      }
      var list = await allConvertedFiles();
      return new Promise(resolve => {
          resolve(list)
      })
}

export const getListOfChartRequestFilesFromGvc = (file: File): Promise<ChartRequestFile[]> => {
    return new Promise((resolve, reject) => {
        try {
            var listOfFilesForRequest = [] as ChartRequestFile[];
            var fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onloadend = (evt) => {
                if (evt && evt.target && evt.target.result && evt.target.readyState === FileReader.DONE) {
                    var parsedJson = JSON.parse(evt.target.result as string) as ExportModel;
                    
                    parsedJson.Files.forEach(file => {
                        listOfFilesForRequest.push({
                            Title: file.Title, 
                            CurveRawContent:  file.CurveRawContent,
                            Id: nanoid()} as ChartRequestFile);
                    });

                    resolve(listOfFilesForRequest);
                }
            };

        }
        catch (e) {
            reject(e);
        }
    })
}

export const getSavedSettings = (file: File): Promise<SettingsState> => {
    return new Promise((resolve, reject) => {
        try {
            var settingsToReturn = {} as SettingsState;
            var fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onloadend = (evt) => {
                if (evt && evt.target && evt.target.result && evt.target.readyState === FileReader.DONE) {
                    var parsedJson = JSON.parse(evt.target.result as string) as ExportModel;
                    settingsToReturn = parsedJson.Settings;
                    resolve(settingsToReturn);
                }
            };
        }
        catch (e) {
            reject(e);
        }
    })
}

export const getSavedUserAnnotations = (file: File): Promise<Annotation[]> => {
    return new Promise((resolve, reject) => {
        try {
            var annotationsToReturn = [] as Annotation[];
            var fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onloadend = (evt) => {
                if (evt && evt.target && evt.target.result && evt.target.readyState === FileReader.DONE) {
                    var parsedJson = JSON.parse(evt.target.result as string) as ExportModel;
                    annotationsToReturn = parsedJson.UserAnnotations;
                    resolve(annotationsToReturn);
                }
            };
        }
        catch (e) {
            reject(e);
        }
    })
}

export const fileToByteArray = (file: File) : Promise<number[]> => {
    return new Promise((resolve, reject) => {
        try {
            let reader = new FileReader();
            let fileByteArray = [] as number[];
            if (file.size > 20000000){
                reject("InvalidFileSize")
                return;
            }
            reader.readAsArrayBuffer(file);
            reader.onloadend = (evt) => {
                if (evt && evt.target && evt.target.result && evt.target.readyState === FileReader.DONE) {
                    let arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer as ArrayBuffer);
                    for (let byte of array) {
                        fileByteArray.push(byte);
                    }
                }
                resolve(fileByteArray);
            }
        }
        catch (e) {
            reject(e);
        } 
    })
}