import { createSlice } from '@reduxjs/toolkit';


export interface Color {
    title: string,
    color: string,
    stepNumber: number,
    seriesIndex: number
}

export interface DistinctiveValueColorsState {
  colors: Color[];
}

const initialState: DistinctiveValueColorsState = {
    colors: [],
};

export const distinctiveValueColorsSlice = createSlice({
  name: 'distinctivevaluecolors',
  initialState,
  reducers: {
    setOrUpdateDistinctiveValueColor: (state, action) => {
        let isUpdated = false;
        const newColors = state.colors.map(color => {
            if(color.title + "_" + color.stepNumber + "_" + color.seriesIndex === action.payload.title + "_" + action.payload.stepNumber + "_" + action.payload.seriesIndex){
                isUpdated = true;
                return {
                    ...color,
                    color: action.payload.color
                };
            } else {
                return color;
            }
        });

        !isUpdated && newColors.push(action.payload);

        state.colors = newColors;
      },
      resetDistinctiveValueColorToStandard: (state, action) => {
          const newColors = state.colors.filter(color => (color.title + "_" + color.stepNumber + "_" + color.seriesIndex !== action.payload.title + "_" + action.payload.stepNumber + "_" + action.payload.seriesIndex));
          state.colors = newColors;
      }
  }
});

export const { setOrUpdateDistinctiveValueColor, resetDistinctiveValueColorToStandard } = distinctiveValueColorsSlice.actions;

export default distinctiveValueColorsSlice.reducer;
