
import { encode } from "base-64";
import { DeviceInformationResponseItem } from "./DeviceInformationResponse";
import { DevicesInformationRequest } from "./DevicesInformationRequest";

const simpleFetchCall = async (cockpitRequest: DevicesInformationRequest) => {

    var cockpitResponse = await fetch('http://' + cockpitRequest.cockpitUrl + ":8089/" + cockpitRequest.cockpitArea + "/api/v1.0/graphviewer/deviceInformationsForSimplifiedCurveFiles", {
        signal: cockpitRequest.AbortController.signal,
        body: JSON.stringify(
            {
                SelectedDeviceIds: cockpitRequest.SelectedDeviceIds,
                Filter: cockpitRequest.CurveFileFilter,
                CutOffDateTime: cockpitRequest.CutOffDateTime
            }),
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Basic ' + encode(cockpitRequest.username + ":" + cockpitRequest.password),
            'Content-Type': 'application/json'
        }),
    });
    return cockpitResponse.json()
}

const callCockpitApi = async (cockpitRequest: DevicesInformationRequest) => {
    
    const htmlContent = await simpleFetchCall(cockpitRequest);

    return htmlContent as DeviceInformationResponseItem[]
}


export const getDeviceInformationsFromCockpit = async (cockpitRequest: DevicesInformationRequest): Promise<DeviceInformationResponseItem[]> => {
    try {

        var jsonResponse = await callCockpitApi(cockpitRequest);

        return new Promise<any>((resolve, rejected) => {
            if (jsonResponse) {
                resolve(jsonResponse)
            }
        });

    } catch (error: any) {
        return new Promise<any>((resolve, rejected) => {
            rejected(error.response && error.response.data ? error.response.data.MessageText : error);
        }
        );

    }
}