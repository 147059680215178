/* eslint-disable react-hooks/exhaustive-deps */
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { CurveControlEvents } from "../Model/Categories/CurveControlEnums";
import { reset_analyze_StartCurveSupervisionCurveControl } from "../Redux/AnalyticsSlice";

const PublishCurveControlAnalytics = () =>{
    const appInsights = useAppInsightsContext();
    const dispatch = useAppDispatch();
  
    const startCurveSupervisionCurveControlState = useAppSelector(store => store.analytics.startCurveSupervisionCurveControlState)

    const TrackEvent = (eventName: string, properties: any) => {
        return useTrackEvent(appInsights, eventName, properties, true)
    }

    const trackStartCurveSupervision = TrackEvent(CurveControlEvents.StartCurveSupervision.toString(), {})
   
    useEffect(() => {
        if (startCurveSupervisionCurveControlState.toggle) {
            try{
                trackStartCurveSupervision({})
            }
            catch{}
            dispatch(reset_analyze_StartCurveSupervisionCurveControl())
        }
    }, [startCurveSupervisionCurveControlState.toggle])

    return (
        <></>
    )
}

export default PublishCurveControlAnalytics;