import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAvailableFiles } from '../Api/GetAvailableFilesApi';
import { HardwareType } from '../Models/HardwareType';
import { LoadFromControllerRequest } from '../Models/LoadFromControllerRequest';

export interface LoadAvailableFilesFromControllerState {
  isShown: boolean,
  ipAddress: string,
  hardwareType: HardwareType,
  availableFiles: string[],
  activeFileUrls: string[],
  status: 'idle' | 'loading' | 'failed';
  showHelp: boolean,
  showHelpWasShown: boolean,
  showHelpModal: boolean,
  updateActual: {
    isEnabled: boolean,
    interval: number
  }
}

const initialState: LoadAvailableFilesFromControllerState = {
  isShown: false,
  ipAddress: "",
  availableFiles: [],
  hardwareType: HardwareType.Ast12,
  activeFileUrls: [],
  status: "idle",
  showHelp: false,
  showHelpWasShown: false,
  showHelpModal: false,
  updateActual: {
    isEnabled: false,
    interval: 10000
  }
};

export const loadAvailableFilesFromController = createAsyncThunk(
  'loadFromController/initialize',
  async (request: LoadFromControllerRequest, { rejectWithValue }) => {
    const response = await getAvailableFiles(request);
    return response;
  }
);

export const loadFromControllerSlice = createSlice({
  name: 'loadFromController',
  initialState,
  reducers: {
    toggleUpdateEnabled: (state) => {
      state.updateActual.isEnabled = !Boolean(state.updateActual.isEnabled)
    },
    setUpdateInterval: (state, action) => {
      state.updateActual.interval = action.payload
    },
    showHelpDialog: (state) => {
      state.showHelp = true;
    },
    hideHelpDialog: (state) => {
      state.showHelp = false;
    },
    showHelpModal: (state) => {
      state.showHelpModal = true;
    },
    hideHelpModal: (state) => {
      state.showHelpModal = false;
    },
    addActiveFileUrl: (state, action) => {
      state.activeFileUrls = [...state.activeFileUrls, action.payload];
    },
    removeActiveFileUrl: (state, action) => {
      state.activeFileUrls = [...state.activeFileUrls.filter(x => x !== action.payload)];
    },
    clearActiveFileUrl: (state) => {
      state.activeFileUrls = [];
    },
    setHardwareType: (state, action) => {
      state.hardwareType = action.payload;
    },
    setLoadFromControllerVisibility: (state) => {
      state.isShown = true;
    },
    resetLoadFromControllerVisibility: (state) => {
      state.isShown = false;
    },
    setLoadFromControllerIpAddress: (state, action) => {
      state.ipAddress = action.payload;
    },
    setAvailableFiles: (state, action) => {
      state.availableFiles = [...action.payload.files];
    },
  },
  extraReducers: builder => {
    builder.addCase(loadAvailableFilesFromController.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(loadAvailableFilesFromController.fulfilled, (state, action) => {
        state.status = 'idle';
        state.availableFiles = [...action.payload]
        state.showHelp = false;
      })
      .addCase(loadAvailableFilesFromController.rejected, (state, action) => {
        state.status = 'failed';
        if (!state.showHelpWasShown && (action.error.message === "Failed to fetch" || action.error.message === "NetworkError when attempting to fetch resource.")) {
          state.showHelp = true;
          state.showHelpWasShown = true;
        }
      })
  }
});

export const { toggleUpdateEnabled, setUpdateInterval, showHelpDialog, hideHelpDialog, showHelpModal, hideHelpModal, clearActiveFileUrl, removeActiveFileUrl, addActiveFileUrl, setHardwareType, setLoadFromControllerVisibility, resetLoadFromControllerVisibility, setLoadFromControllerIpAddress, setAvailableFiles } = loadFromControllerSlice.actions;

export default loadFromControllerSlice.reducer;
