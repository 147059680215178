import { Annotation } from "../../Features/Annotations/Annotation";
import { ChartRequestFile } from "../../Features/Home/Models/ChartRequest";
import { SettingsState } from "../../Features/Settings/Redux/SettingsSlice";

export interface ExportModel {
    Files: ChartRequestFile[],
    Settings: SettingsState,
    UserAnnotations: Annotation[]
}

export const exportRequestToGvc = async (files: ChartRequestFile[] | undefined, settings: SettingsState, userAnnotations: Annotation[], fileName: string = "test", isFirefox: boolean = false) => {
    if (files !== undefined) {
        var jsonToStore = {
            Files: files,
            Settings: settings,
            UserAnnotations: userAnnotations

        } as ExportModel;

        const fileData = JSON.stringify(jsonToStore);
        const blob = new Blob([fileData], { type: "text/plain" });

        if (isFirefox) {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = `${fileName}.gvc`;
            link.href = url;
            link.click();
        } else {
            const newHandle = await getNewFileHandle(fileName);
            const writableStream = await newHandle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();
        }
    }
}

async function getNewFileHandle(fileName: string) {
    const opts = {
      types: [{
        description: 'GraphViewer Cloud',
        accept: {'text/gvc': ['.gvc']},
      }],
      suggestedName: `${fileName}.gvc`
    };
    return await (window as any).showSaveFilePicker(opts);
  }