import { createSlice } from '@reduxjs/toolkit';
import { CustomCsvSeries } from '../Models/CustomCsvSeries';
import { CustomCsvXAxis } from '../Models/CustomCsvXAxis';
import { CustomCsvXAxisMode } from '../Models/CustomCsvXAxisMode';
import { CustomCsvYAxis } from '../Models/CustomCsvYAxis';

export interface CustomCsvState {
  isShown: boolean,
  yAxis: CustomCsvYAxis[],
  series: CustomCsvSeries[],
  xAxis: CustomCsvXAxis,
}

export const initialStateCustomCsv: CustomCsvState = {
    isShown: false,
    yAxis: [],
    series: [],
    xAxis: {name: "", abbrevation: "", data: "", mode: CustomCsvXAxisMode.Value, timeMode: { xAxisConversion: "X", xAxisDateTimeFormat: "DD.MM.yyyy HH:mm:ss" }}
};

export const customCsvSlice = createSlice({
  name: 'customCsv',
  initialState: initialStateCustomCsv,
  reducers: {
    updateCustomCsvTimeModeConversion: (state, action) => {
      state.xAxis.timeMode.xAxisConversion = action.payload
    },
    updateCustomCsvTimeModeDateTimeFormat: (state, action) => {
      state.xAxis.timeMode.xAxisDateTimeFormat = action.payload
    },
    updateCustomCsvXAxisName: (state, action) => {
      state.xAxis.name = action.payload
    },
    updateCustomCsvXAxisAbbrevation: (state, action) => {
      state.xAxis.abbrevation = action.payload
    },
    updateCustomCsvXAxisMode: (state, action) => {
      state.xAxis.mode = action.payload
    },
    updateCustomCsvXAxisData: (state, action) => {
      state.xAxis.data = action.payload.replaceAll(",", "\n")
    },
    addCustomCsvYAxis: (state,action) => {
      state.yAxis = state.yAxis.length > 0 ? [...state.yAxis, action.payload] : [action.payload]
    },
    updateCustomCsvYAxis: (state,action) => {
      state.yAxis[action.payload.iterator] = {
        ...state.yAxis[action.payload.iterator],
        id: action.payload.id,
        name: action.payload.name,
        abbrevation: action.payload.abbrevation,
        digitCount: action.payload.digitCount
      }
    },
    removeCustomCsvYAxis: (state,action) => {
      state.yAxis = [...state.yAxis.filter(x => x.id !== action.payload)]
    },
    addCustomCsvSeries: (state,action) => {
      if(state.yAxis.length > 0){
        state.series = state.series.length > 0 ? [...state.series, action.payload] : [action.payload]
      }
      
    },
    updateCustomCsvSeries: (state,action) => {
      state.series[action.payload.iterator] = {
        ...state.series[action.payload.iterator],
        name: action.payload.name,
        color: action.payload.color,
        isVisible: action.payload.isVisible,
        yAxisId: action.payload.yAxisId
      }
    },
    updateCustomCsvSeriesData: (state,action) => {
      state.series[action.payload.iterator] = {
        ...state.series[action.payload.iterator],
        data: action.payload.data.replaceAll(",", "\n")
      }
    },
    removeCustomCsvSeries: (state,action) => {
      state.series = [...state.series.filter(x => x.name !== action.payload)]
    },
    toggleCustomCsvIsShown: (state) => {
      state.isShown = !Boolean(state.isShown);
    }
  }
});

export const { updateCustomCsvTimeModeConversion, updateCustomCsvTimeModeDateTimeFormat, updateCustomCsvXAxisName, updateCustomCsvXAxisAbbrevation, updateCustomCsvXAxisMode, updateCustomCsvXAxisData, updateCustomCsvSeriesData, addCustomCsvYAxis, updateCustomCsvYAxis, removeCustomCsvYAxis, addCustomCsvSeries, updateCustomCsvSeries, removeCustomCsvSeries, toggleCustomCsvIsShown } = customCsvSlice.actions;

export default customCsvSlice.reducer;
