import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadFromCockpitRequest } from '../Api/CompleteCurveDataRequest';
import { CockpitResponse } from '../Api/CompleteCurveDataResponse';
import { getFileFromCockpit } from '../Api/GetAvailableFileDataApi';


export enum CockpitAvailable {
  CockpitAvailableYes,
  CockpitAvailableNo
}

export interface LoadFromCockpitState {
  cockpitAvailable: CockpitAvailable
  isShown: boolean,
  cockpitUrl: string,
  cockpitArea: string,
  cockpitResponse: CockpitResponse[] | null,
  credentials: {
    username: string,
    password: string,
  }
  status: 'idle' | 'loading' | 'failed';
  showHelp: boolean,
  showHelpWasShown: boolean,
  showHelpModal: boolean,
}

export const loadFromCockpitInitialState: LoadFromCockpitState = {
  cockpitAvailable: CockpitAvailable.CockpitAvailableNo,
  isShown: false,
  cockpitUrl: "",
  cockpitArea: "default",
  credentials: {
    username: "",
    password: ""
  },
  cockpitResponse: null,
  status: "idle",
  showHelp: false,
  showHelpWasShown: false,
  showHelpModal: false,
};


export const loadAvailableFilesFromCockpit = createAsyncThunk('loadFromCockpit/initialize', async (request: LoadFromCockpitRequest) => {
  const response = await getFileFromCockpit(request);
  return response;
}
);

export const loadFromCockpitSlice = createSlice({
  name: 'loadFromCockpit',
  initialState: loadFromCockpitInitialState,
  reducers: {
    setCockpitAvailable: (state, action) => {
      state.cockpitAvailable = action.payload
    },
    showCockpitHelpDialog: (state) => {
      state.showHelp = true;
    },
    hideCockpitHelpDialog: (state) => {
      state.showHelp = false;
    },
    showCockpitHelpModal: (state) => {
      state.showHelpModal = true;
    },
    hideCockpitHelpModal: (state) => {
      state.showHelpModal = false;
    },
    setLoadFromCockpitVisibility: (state) => {
      state.isShown = true;
    },
    resetLoadFromCockpitVisibility: (state) => {
      state.isShown = false;
    },
    setLoadFromCockpitUrl: (state, action) => {
      state.cockpitUrl = action.payload;
    },
    setLoadFromCockpitArea: (state, action) => {
      state.cockpitArea = action.payload;
    },
    setLoadFromControllerUsername: (state, action) => {
      state.credentials.username = action.payload;
    },
    setLoadFromControllerPassword: (state, action) => {
      state.credentials.password = action.payload;
    },
    resetCockpitSettings: (state) => {
      state.cockpitAvailable = loadFromCockpitInitialState.cockpitAvailable;
      state.cockpitUrl = loadFromCockpitInitialState.cockpitUrl;
      state.cockpitArea = loadFromCockpitInitialState.cockpitArea;
      state.credentials = loadFromCockpitInitialState.credentials;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadAvailableFilesFromCockpit.pending, (state) => {
      state.status = 'loading';
    })
      .addCase(loadAvailableFilesFromCockpit.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cockpitResponse = action.payload
        state.showHelp = false;
      })
      .addCase(loadAvailableFilesFromCockpit.rejected, (state, action) => {
        state.status = 'failed';
        if ((action.error.message === "Failed to fetch" || action.error.message === "NetworkError when attempting to fetch resource.") && !state.showHelpWasShown) {
          state.showHelp = true;
          state.showHelpWasShown = true;
        }
      })
  }
});

export const { setCockpitAvailable, setLoadFromControllerPassword, setLoadFromControllerUsername, setLoadFromCockpitArea, setLoadFromCockpitUrl, resetLoadFromCockpitVisibility, setLoadFromCockpitVisibility, hideCockpitHelpModal, showCockpitHelpModal, hideCockpitHelpDialog, showCockpitHelpDialog,resetCockpitSettings } = loadFromCockpitSlice.actions;

export default loadFromCockpitSlice.reducer;
