import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks"
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Divider } from "@mui/material";
import { createRef, useEffect } from "react";
import { hideNewFeatures } from "./Redux/NewFeaturesSlice";
import {
    SideBySideMagnifier,
  } from "react-image-magnifiers";

export const NewFeaturesDialog = () => {
    const dispatch = useAppDispatch();
    const open = useAppSelector(store => store.newFeatures.showNewFeatures);
    const newFeatures = useAppSelector(store => store.newFeatures.newFeatures);
    const isDe = useAppSelector(store => store.settings.language === 'de');
    var scrollRef = createRef<HTMLDivElement>();

    const handleClose = () => {
        dispatch(hideNewFeatures())
    }

    useEffect(() => {
        if (open) {
          setTimeout(() => {
            scrollRef && scrollRef.current && scrollRef.current.scrollTo(0, 0)
          }, 100);
        }
      }, [open])

    return (
        <LanguageConsumer>
            {({getTranslatedText}) => 
            <>
                <Dialog onClose={handleClose} open={open} maxWidth={'lg'} PaperProps={{ sx: { borderRadius: 3, height: "80%", minWidth: '60%' } }}>
                    <DialogTitle>🔥 {getTranslatedText("WeHaveNewFeaturesTitle")} 🔥</DialogTitle>
                    <DialogContent ref={scrollRef} sx={{overflowX: 'hidden'}}>
                        {newFeatures && newFeatures.length > 0 && newFeatures.map((feature, featureKey) => {
                            return(<div key={`feature-${featureKey}`}>
                                <Grid  container direction={'row'} spacing={2}>
                                    <Grid item xs={8} my={'auto'} sx={{textAlign: 'left'}}>
                                        <Typography sx={{textAlign: 'left !important'}}><div  dangerouslySetInnerHTML={{__html: isDe ? feature.de : feature.en}}></div></Typography>
                                    </Grid>
                                    <Grid item xs={4} my={'auto'}>
                                      {((isDe && feature.imgDe && feature.imgDe.length > 0) ||
                                       (!isDe && feature.imgEn && feature.imgEn.length > 0)) && 
                                        <SideBySideMagnifier fillAvailableSpace={true} imageSrc={`data:image;base64,${isDe ? feature.imgDe : feature.imgEn}`} alwaysInPlace={true}  />
                                       }
                                    </Grid>
                                </Grid>
                                <Divider sx={{my: 2}} />
                                </div>
                            )
                        })}
                       
                    </DialogContent>
                    <DialogActions>
                        <Button color="success" variant="outlined"  fullWidth size="large" onClick={handleClose}>{getTranslatedText("CommonClose")}</Button>
                    </DialogActions>
                </Dialog>
            </>
            }
        </LanguageConsumer>
    )
}