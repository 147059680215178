import { createAsyncThunk } from '@reduxjs/toolkit';
import { offerLicenceRenewal } from '../Api/LicenceRenewalOffer';
import { LicenceRenewalOfferRequest } from '../Models/LicenceRenewalOfferRequest';
import { LicenceRenewalOfferResponse } from '../Models/LicenceRenewalOfferResponse';

export interface LicenceRenewalOfferServiceState {
    response: LicenceRenewalOfferResponse | null,
}

export const initialState: LicenceRenewalOfferServiceState = {
    response: null
};

export const licenceRenewalOffer = createAsyncThunk(
    'licenceService/offer/renewal', async (request: LicenceRenewalOfferRequest) => {
        const response = await offerLicenceRenewal(request);
        return response;
    }
);
