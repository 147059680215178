import { getNavigationSteps } from "./LicenceOnlineHelpNavigationProvider";
import Tour from 'reactour';
import { Button } from "@mui/material";
import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import {  useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export const LicenceOnlineHelp = (props) => {

    const [currentStep, setCurrentStep] = useState<number>(0);
    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);


    return (
        <LanguageConsumer>

            {({ getTranslatedText }) =>
                <Tour
                    disableFocusLock
                    getCurrentStep={(curr: number) => {
                        setCurrentStep(curr)
                    }}
                    startAt={0}
                    disableInteraction={true}
                    showNavigation={false}
                    showNumber={false}
                    showButtons={true}
                    closeWithMask={true}
                    accentColor={'#32992c'}
                    steps={getNavigationSteps(props)}
                    isOpen={props.showOnlineHelp}
                    onRequestClose={() => {
                        setCurrentStep(0)
                        props.closeOnlineHelp();
                    }}
                    rounded={13}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                    prevButton={currentStep !== 0 ? <Button component="a" fullWidth variant="outlined" color='inherit' title={getTranslatedText("OnlineHelpPrev")}>{getTranslatedText("OnlineHelpPrev")}</Button> : <div></div>}
                    nextButton={<Button component="a" variant="outlined" fullWidth color='success' title={getTranslatedText("OnlineHelpNext")}>{getTranslatedText("OnlineHelpNext")}</Button>}
                    lastStepNextButton={<Button component="a" variant="outlined" onClick={() => { props.closeOnlineHelp() }} color='success' title={getTranslatedText("OnlineHelpFinish")}>{getTranslatedText("OnlineHelpFinish")}</Button>}
                    nextStep={() => {
                        let nextStep = currentStep + 1
                        switch (nextStep) {
                            case 2:
                                if (!props.isLicenced) {
                                    nextStep++;
                                }
                                break;
                            default:
                                break;
                        }
                        setCurrentStep(nextStep)
                    }}
                    prevStep={async () => {
                        let lastStep = currentStep - 1
                        switch (lastStep) {
                            case 2:
                                if (!props.isLicenced) {
                                    lastStep--;
                                }
                                break;
                            default:
                                break;
                        }
                        setCurrentStep(lastStep)
                    }}
                    goToStep={currentStep}

                />}
        </LanguageConsumer>
    );
}