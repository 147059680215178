
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { LanguageConsumer } from '../../../../Infrastructure/Internationalisation/TranslationService';
import { ReactElement } from 'react';

interface LicenseCardHeaderProps {
    image: string,
    title: string,
    subtitle?: string,
    buttonComponent?: ReactElement<any, any>,
    onlineHelpComponent?: ReactElement<any, any>
}

export const LicenseCardHeader = ({image, title, subtitle = "", buttonComponent = <></>, onlineHelpComponent = <></>} : LicenseCardHeaderProps) => {

    return (
        <LanguageConsumer>
    {({ getTranslatedText }) =>
        <Box component={'div'} sx={{backgroundImage: `url('${image}')`, backgroundSize: 'cover', width: "100%", maxWidth: '100%', minHeight: '190px',  margin: 0, padding: 0}}>
     
            <Typography sx={{m: 3, mt: 0, pt: subtitle !== "" ? 5 : 8, mb: 0, color: '#fff'}} variant={'h4'}>
                {title} <Box component={'span'} sx={{position: "relative", top: -2}}>{onlineHelpComponent}</Box>
            </Typography>

            {subtitle !== "" &&  <Typography sx={{m: 3, mt: 0, pt: 1, color: '#f9f9f9', fontWeight: 200}} variant={'h6'}>
                {subtitle}  
            </Typography>}

            <Box component={'div'} sx={{m: 3, mt: 1}}>
                {buttonComponent}
            </Box>
        </Box>
    }
    </LanguageConsumer>
    );
}