import { messagePackClient } from "../../../Infrastructure/Api/BaseApi";
import { FeedbackRequest } from "../Models/FeedbackRequest";
import { FeedbackResponse } from "../Models/FeedbackResponse";

export const postFeedback = async (request: FeedbackRequest): Promise<FeedbackResponse>  =>  {
    var feedbackResult = await messagePackClient.post("feedback", request); 
    
    var mappedResult : FeedbackResponse = mapFeedbackResult(feedbackResult);
    return mappedResult;
}

const mapFeedbackResult = (feedbackResponse: any) : FeedbackResponse => {
    let feedbackResultToReturn: FeedbackResponse = { success: false};

    feedbackResultToReturn.success = feedbackResponse.data.Success;

    return feedbackResultToReturn;
}