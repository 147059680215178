import { Card, CardContent, Grid, List, ListItemButton, Divider, ListItemIcon, ListItemText, Tooltip, Typography, Box} from "@mui/material"
import './Style/AnalyticsLegend.css'
import { setColorPicker, resetColorPicker } from "../../Infrastructure/ChartModifier/Redux/ChartModifierSlice"
import { isCustomMode, selectSeries } from "../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice"
import { getTranslation, LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks"
import { LabellingDistinctiveValuesModeDto } from "../Home/Models/LabellingDistinctiveValuesModeDto"
import { ColorPickerOption } from "../ColorPicker/ColorPickerOption"
import { SingleVisualizationModeDto } from "../Home/Models/SingleVisualizationModeDto"
import { TorqueUnit } from "../Home/Models/ChartRequest"
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { SuperVisualizationModeDto } from "../Home/Models/SuperVisualizationModeDto"
import { AnalyticsLegendOnlineHelp } from "../../Infrastructure/OnlineHelp/AnalyticsLegend/AnalyticsLegendOnlineHelp"
import { useState } from "react"
import { OnlineHelpIconButton } from "../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton"
import { analyze_ShowOnlineHelp } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice"
import { OnlineHelpItems } from "../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums"

interface IAnalyticsLegendProps {
    valueToHighlight: string;
    toggleValueWidthOnHover: (X: number, Y: number, mouseOver: boolean) => void,
}

export const AnalyticsLegend = (props: IAnalyticsLegendProps) => {
    const shouldShowAnalyticsLegend = useAppSelector(store => store.settings.chartRequestSettings?.LabellingDistinctiveValues?.Mode !== LabellingDistinctiveValuesModeDto.None)
    const singleVisualisationMode = useAppSelector(store => store.settings.chartRequestSettings?.SingleVisualization?.Mode)
    const superVisualisationMode = useAppSelector(store => store.settings.chartRequestSettings?.SuperVisualization?.Mode)
    const isValidVisualistionMode = checkIfValidVisualisationModeIsActive()
    const dispatch = useAppDispatch();
    const seriesData = useAppSelector(selectSeries);
    const distinctiveValueColors = useAppSelector(store => store.distinctiveValueColors.colors);
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const isCustom = useAppSelector(isCustomMode);
    const [isOnlineHelpEnabled, setIsOnlineHelpEnabled] = useState(false);
    const labellingMode = useAppSelector(store => store.settings.chartRequestSettings.LabellingDistinctiveValues?.Mode);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');

    function checkIfValidVisualisationModeIsActive() {
        let result = false;
        if (superVisualisationMode === SuperVisualizationModeDto.None) {
            result = (singleVisualisationMode === SingleVisualizationModeDto.Angle || singleVisualisationMode === SingleVisualizationModeDto.Time)
        }
        else {
            result = (superVisualisationMode === SuperVisualizationModeDto.Angle || superVisualisationMode === SuperVisualizationModeDto.Time)
        }
        return result;
    }

    const toggleValueWidthOnHover = (X: number, Y: number, mouseOver: boolean) => {
        props.toggleValueWidthOnHover(X, Y, mouseOver);
    }

    const openColorPicker = () => {
        dispatch(setColorPicker({ color: "#32992c", title: getTranslation("MyMeasurementUnit") }));
    }

    const closeColorPicker = () => {
        dispatch(resetColorPicker());
    }

    const openDistinctiveValueLegendOnlineHelp = () => {
        setIsOnlineHelpEnabled(true)
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.LegendDistinctiveValuesOnlineHelp }))
    }

    const closeDistinctiveValueLegendOnlineHelp = () => {
        setIsOnlineHelpEnabled(false);
    }

    function isVisibleInList(labellingMode, title, seriesTitle) {
        
        if (superVisualisationMode) {
            let visibleSeriesTitle = seriesData[0].ChartSeries.filter(x => x.Visible).map(x => { return x.Title });
            let seriesWithValues = seriesData[0].ChartSeries.filter(x => x.HasValues).map(x => { return x.Title });
            if (visibleSeriesTitle.indexOf(seriesTitle) <= -1 || seriesWithValues.indexOf(seriesTitle) <= -1)
                return false;
        }

        if (labellingMode === LabellingDistinctiveValuesModeDto.ThresholdTorque) {
            return (title === 'TEXT_ProgParamStartTorqueSpeed2' || title === 'TEXT_ProgParamSwitchTorque');
        }
        else if (labellingMode === LabellingDistinctiveValuesModeDto.SeatingPoint) {
            return (title === 'TEXT_DrehmomentKopfauflage_Zurueckgerechnet');
        }
        else if (labellingMode === LabellingDistinctiveValuesModeDto.ThresholdTorqueAndSeatingPoint) {
            return true;
        }
        else {
            return false;
        }
    }

    const findAnyDistinctiveValues = () => {
        let  valuesAreAvailable: boolean = false;
        seriesData
        .forEach( series => series.LabellingDistinctiveValues.forEach( 
            distinctiveValue => { valuesAreAvailable = valuesAreAvailable || isVisibleInList(labellingMode, distinctiveValue.Title, distinctiveValue.SeriesTitle)} ))                    

        return valuesAreAvailable;
    }

    const distinctiveValuesVisible = () => {
        let seriesAreLoaded = seriesData && seriesData.length > 0;
        let distinctiveValuesCanBeRenderIfAvailable = seriesAreLoaded && isValidVisualistionMode && seriesData[0] && !isCustom;

        if (!distinctiveValuesCanBeRenderIfAvailable)
            return false;

        let valuesAreAvailable = false;
        if (distinctiveValuesCanBeRenderIfAvailable){        
            valuesAreAvailable = findAnyDistinctiveValues();
        }

        return valuesAreAvailable
    }

    const shouldRenderNothing = () => {
        return !shouldShowAnalyticsLegend || !isValidVisualistionMode || !seriesData[0] || isCustom;
    }

    if (shouldRenderNothing()){
        return (<></>)
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Card elevation={12} className="verticalScroller home-legendDistinctiveValues onlinehelp-home-legendDistinctiveValues" sx={{ mt: 2, zIndex: '0 !important', width: '100%', minHeight: '10vw', overflowY: 'auto' }} >
                    <CardContent sx={{paddingTop:"0"}}>
                    <Box component="div" sx={{position: "sticky", top:"0px", paddingTop: "16px", zIndex: "2", backgroundColor: isDarkMode ? '#333' : "white" }}/>    
                    <Box component="div" sx={{position: "sticky", top: "16px", zIndex: "1", backgroundColor: isDarkMode ? '#333' : "white" }}>
                        <AnalyticsLegendOnlineHelp
                            showOnlineHelp={isOnlineHelpEnabled}
                            closeOnlineHelp={closeDistinctiveValueLegendOnlineHelp}
                            openColorPicker={openColorPicker}
                            closeColorPicker={closeColorPicker}
                            valuesAvailable={distinctiveValuesVisible()}
                        />

                        <Grid container direction={"row"}>

                            <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
                                {(seriesData && seriesData[0]) &&
                                    <Typography sx={{ fontSize: 14, zIndex: '0 !important' }} color="text.secondary" gutterBottom>
                                        {getTranslatedText("DistinctiveValues")}
                                    </Typography>
                                }
                            </Grid>
                            {(seriesData && seriesData[0]) &&
                                <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                    <OnlineHelpIconButton
                                        isInSettings={false}
                                        title={"OnlineHelp"}
                                        isOnlineHelpEnabled={false}
                                        onClickFunction={() => {
                                            openDistinctiveValueLegendOnlineHelp()
                                        }} />
                                </Grid>
                            }
                            </Grid>
                            </Box>
                            <Grid item xs={12}>
                                <List sx={{ width: '100%', bgcolor: "transparent" }}>
                                    {seriesData && seriesData.length > 0 && seriesData.map((series, idx) => {
                                        let distinctiveValues = series.LabellingDistinctiveValues.map((ser, keyChild) => {

                                            if (!isVisibleInList(labellingMode, ser.Title, ser.SeriesTitle)) 
                                                return <div key={idx}></div>

                                            let storedColor = distinctiveValueColors.filter(color => color.title === ser.Title && color.stepNumber === ser.Stepnumber && color.seriesIndex === keyChild);

                                            return (
                                                <div key={keyChild} >
                                                    <ListItemButton sx={{ pl: 1 }}
                                                        onMouseEnter={() => toggleValueWidthOnHover(ser.X, ser.Y, true)}
                                                        onMouseLeave={() => toggleValueWidthOnHover(ser.X, ser.Y, false)}
                                                        className="home-legendDistinctiveValues-ListItemButton onlinehelp-home-legendDistinctiveValues-ListItemButton"
                                                    >

                                                        <Tooltip arrow title={getTranslatedText("SetSeriesColorTooltip")}>
                                                            <ListItemIcon sx={{ justifyContent: "center", cursor: 'pointer' }} onClick={() => {
                                                                dispatch(setColorPicker({ color: storedColor.length > 0 ? storedColor[0].color : ser.Color, title: ser.Title, stepNumber: ser.Stepnumber, option: ColorPickerOption.distinctiveValues, seriesIndex: keyChild }));
                                                            }}>
                                                                <RadioButtonUncheckedIcon className="onlinehelp-home-legendDistinctiveValues-colorIcon" sx={{ fontSize: "1.2em", fill: storedColor.length > 0 ? storedColor[0].color : ser.Color, stroke: storedColor.length > 0 ? storedColor[0].color : ser.Color, strokeWidth: 1 }} />
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                        <ListItemText
                                                            sx={{ paddingLeft: "6px" }}
                                                            primary={<Typography sx={{ display: 'inline' }} component="span" variant="body1" fontWeight={"bold"} color="text.primary">
                                                                {getTranslatedText(ser.Title)}
                                                            </Typography>
                                                            }
                                                            secondary={
                                                                <>
                                                                    <Typography component="span" variant="body2" color="text.primary">
                                                                        {getTranslatedText("Torque")} {' [' + TorqueUnit[chartRequestSettings.TorqueUnit != null ? chartRequestSettings.TorqueUnit : 0] + '] ' + ser.Torque.toFixed(2)}<br/>
                                                                    </Typography>
                                                                    

                                                                    <Typography component="span" variant="body2" color="text.primary">
                                                                        {getTranslatedText("Angle")}  {' ' + ser.Angle.toFixed(2)}<br/>
                                                                    </Typography>
                                                                </>
                                                            }>
                                                        </ListItemText>                                                                                                             
                                                    </ListItemButton>
                                                    <Divider />                                                    
                                                </div>
                                            )
                                        });

                                        return findAnyDistinctiveValues() ? distinctiveValues :
                                            <Grid key={idx} item xs={12} textAlign={'center'} sx={{ mt: 2 }}>
                                                <TroubleshootIcon sx={{ fontSize: "3em", color: "#dbd9d9" }} />
                                                <Typography sx={{ mt: 1, color: "#dbd9d9" }}>{getTranslatedText("CurrentlyThereAreNoDistinctiveValues")}</Typography>
                                            </Grid>
                                    })}
                                </List>
                            </Grid>
                    </CardContent>
                </Card>
            }
        </LanguageConsumer >
    )
}