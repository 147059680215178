import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { setSynchronizationIndividualTorque } from "../Redux/SettingsSlice";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { TorqueUnit } from "../../Home/Models/ChartRequest";
import React from "react";

export const SynchronizationIndividualTorqueInput = () => {
  const dispatch = useAppDispatch();
  const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
  const inputValueFromRedux = String(useAppSelector(store => store.settings.chartRequestSettings.SynchronizationSettings?.IndividualTorque));
  const [inputValue, setInputValue] = React.useState(inputValueFromRedux);
  let invalidInput = isNumeric(inputValue);

  const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    invalidInput = isNumeric(value);
    setInputValue(value);
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function buttonClickHandleChangeSynchronization() {
    dispatch(setSynchronizationIndividualTorque(Number(inputValue)));
  }

  function enterButtonHandleChangeSynchronization(e) {
    if (e.keyCode === 13 && invalidInput) {
      dispatch(setSynchronizationIndividualTorque(Number(inputValue)));
    }
  }

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) =>
        <Grid sx={{ mt: 1 }} container spacing={2} >
          <Grid item xs>
            <TextField fullWidth value={inputValue} onChange={handleChangeTextField} label={getTranslatedText("SynchronisationModeIndividualTorque")} variant="outlined" color="success" error={!invalidInput}
              InputProps={{ endAdornment: <InputAdornment position="end">{TorqueUnit[chartRequestSettings.TorqueUnit !== null ? chartRequestSettings.TorqueUnit : 0]}</InputAdornment> }} onKeyDown={enterButtonHandleChangeSynchronization}
              className="onlinehelp-analyse-synchronizationIndividualTorqueInput" />
          </Grid>
          <Grid item width={180}>
            <Button fullWidth disabled={!invalidInput} variant="contained" disableElevation color="success" sx={{ color: '#fff', height: 55 }} size="large" onClick={() => { buttonClickHandleChangeSynchronization() }}
            className="onlinehelp-analyse-synchronizationIndividualTorqueInputButton" >
              {getTranslatedText("TEXT_SAVE")}
            </Button>
          </Grid>
        </Grid>
      }
    </LanguageConsumer>
  )
}