import { FormControl, InputLabel, Select } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { Circle } from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment';
import { setColorPicker } from "../../../../Infrastructure/ChartModifier/Redux/ChartModifierSlice";
import { ColorPickerOption } from "../../../ColorPicker/ColorPickerOption";
import { NavLink } from "react-router-dom";
import { setActiveTab } from "../../Redux/SettingsSlice";

export const UserAnnotationColor = () => {
    const dispatch = useAppDispatch();

    const licenceData = useAppSelector(store => store.licenceService);
    const color = useAppSelector(store => store.settings.annotationSettings.userAnnotationColorAsHex);

    const onUserAnnotationColorClick = () => {
        if (!licenceData.licenceAuthentication.isLicenced)
            return
            
        dispatch(setColorPicker({
            isShown: true,
            color: color,
            title: "Userannotation",
            option: ColorPickerOption.userAnnotations,
        }))
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <FormControl fullWidth sx={{ mt: 3 }} className="onlinehelp-annotations-userAnnotationsColorSelect">

                    <InputLabel color="success" id="select-label-UserAnnotationColor">{getTranslatedText("UserAnnotationColor")}</InputLabel>
                    {!licenceData.licenceAuthentication.isLicenced && 
                    <NavLink to='/settings' onClick={() => dispatch(setActiveTab(4))}><span className="tool notLicencedIcon"><LockIcon htmlColor='#fcba03' /></span></NavLink>}
                    <Select
                        data-testid="settings-annotations-userColorSelect"
                        fullWidth
                        className="onlinehelp-settings-UserAnnotationColorSelect"
                        disabled={!licenceData.licenceAuthentication.isLicenced}
                        color="success"
                        labelId="select-label-UserAnnotationColor"
                        id="select-UserAnnotationColor"
                        name="select-UserAnnotationColor"
                        value={color}
                        label={getTranslatedText("UserAnnotationColor")}
                        renderValue={(value) => (value)}
                        open={false}
                        startAdornment={
                            <InputAdornment position="start">
                                <Circle sx={{ color: color }} />
                            </InputAdornment>
                        }
                        onClick={onUserAnnotationColorClick}

                    >

                    </Select>
                </FormControl>
            }
        </LanguageConsumer>
    )
}