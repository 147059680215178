import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { VisibilityTwoTone, ShowChart } from "@mui/icons-material";
import React, { useState } from "react";
import { getTranslation, LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { changeSingleSeriesDataToOnlyShowSelected } from "../../../../Infrastructure/ChartSeriesVisibility/Redux/ChartSeriesVisibilitySlice";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { RootState } from "../../../../Infrastructure/Redux/store";
import { SuperVisualizationModeDto } from "../../../Home/Models/SuperVisualizationModeDto";
import { setNotification, NotificationType } from "../../../../Layout/Notifications/Redux/NotificationsSlice";
import { removeSingleSeries, selectSeries } from "../../../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { ChartRequestFile } from "../../../Home/Models/ChartRequest";
import { removeSeriesFromRequest, removeSeriesFromRequestSortOrder } from "../../../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { ConfirmDialog } from "../../../../Infrastructure/ConfirmDialog/ConfirmDialog";
import { setSingleUseChartRequestFile } from "../../../../Infrastructure/Temporarily/Redux/TemporarilySlice";
import { YesNoDialog } from "../../../../Infrastructure/YesNoDialog/YesNoDialog";
import { SeriesOrder } from "../../../../Infrastructure/ChartSeriesOrder/Redux/ChartSeriesOrderSlice";

interface IRightClickMenueProps {
    parentKey: number,
    rightClickedSeriesTitle: string,
    rightClickedSeriesId: string,
    hasValues: boolean,
    anchorEl: HTMLElement | null,
    openRightClickMenu: boolean,
    orderedSeries: SeriesOrder[],
    handleClose: () => void,
}

export const RightClickMenue = (props: IRightClickMenueProps) => {
    const dispatch = useAppDispatch();
    const seriesData = useAppSelector(selectSeries);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const chartRequestSeries = useAppSelector(store => store.chartRequestData.series);
    const isSuperVisualisation = useAppSelector((store: RootState) => store.settings.chartRequestSettings.SuperVisualization?.Mode !== SuperVisualizationModeDto.None);
    const selectedYAxis = useAppSelector((store: RootState) => store.settings.selectedYAxisForSingleVisualisation);

    const [confirmDeletionDialogOpen, setConfirmDeletionDialogOpen] = useState<boolean>(false);
    const [seriesWithinContainer, setSeriesWithinContainer] = useState<string[]>([]);
    const [confirmDeletionNotPossibleDialogOpen, setConfirmDeletionNotPossibleDialogOpen] = useState<boolean>(false);
    const [confirmOpeningDGraphInSingleTabDialogOpen, setConfirmOpeningDGraphInSingleTabDialogOpen] = useState<boolean>(false);
    const [chartRequestToOpenInInSingleTab, setChartRequestToOpenInInSingleTabDialogOpen] = useState<ChartRequestFile | null>(null);
    const [seriesInContainerToOpenInInSingleTab, setSeriesInContainerToOpenInInSingleTabDialogOpen] = useState<string[]>([]);

    const onlyShowThisSeriesClick = () => {
        props.handleClose();
        dispatch(changeSingleSeriesDataToOnlyShowSelected({ parent: props.parentKey, seriesTitle: props.rightClickedSeriesTitle }));
    }

    const openSerieInNewTabAsSingleVisualizationClick = () => {
        if (chartRequestSeries) {
            let selectedChartRequest = chartRequestSeries?.Files.find(x => x.Id === props.rightClickedSeriesId);
            if (selectedChartRequest) {
                setChartRequestToOpenInInSingleTabDialogOpen(selectedChartRequest);
                let selectedSeries = seriesData[0].ChartSeries.filter(x => x.ChartRequestId === props.rightClickedSeriesId)
                if (selectedSeries.length > 1) {
                    let selectedSeriesAsStringArray = selectedSeries.map(x => (x.Title));
                    setSeriesInContainerToOpenInInSingleTabDialogOpen(selectedSeriesAsStringArray);
                    props.handleClose();
                    setConfirmOpeningDGraphInSingleTabDialogOpen(true)
                }
                else {
                    openSerieInNewTab(selectedChartRequest);
                }
            }
        }
    }

    const removeThisSeries = () => {
        if (!isSuperVisualisation && props.rightClickedSeriesTitle === selectedYAxis) {
            props.handleClose();
            dispatch(setNotification({ type: NotificationType.info, text: getTranslation("SeriesCanNotBeDeletedBecauseItIsSelectedYAxis") }))
            return;
        }

        props.handleClose();

        let seriesToDelete = seriesData[0].ChartSeries.filter(series => series.ChartRequestId === props.rightClickedSeriesId);
        if (seriesToDelete.length === 1) {
            deleteSeriesFromLegende();
        }
        else {

            if (seriesToDelete.length === seriesData[0].ChartSeries.length) {
                setConfirmDeletionNotPossibleDialogOpen(true);
            }
            else {
                setSeriesWithinContainer(seriesToDelete.map(x => x.Title));
                setConfirmDeletionDialogOpen(true);
            }
        }
    }

    const openSerieInNewTab = (chartRequest: ChartRequestFile) => {
        dispatch(setSingleUseChartRequestFile(chartRequest))
        window.open('/', '_blank')
        props.handleClose();
    }

    const deleteSeriesFromLegende = () => {
        dispatch(removeSingleSeries({ idToDelete: props.rightClickedSeriesId }));
        dispatch(removeSeriesFromRequest(props.rightClickedSeriesId));
        dispatch(removeSeriesFromRequestSortOrder(props.rightClickedSeriesTitle));
    }

    const handleClickYesConfirmOpeningnDGraphInSingleTab = () => {
        setConfirmOpeningDGraphInSingleTabDialogOpen(false);
        openSerieInNewTab(chartRequestToOpenInInSingleTab!);
    }

    const handleClickNoConfirmOpeningnDGraphInSingleTab = () => {
        setConfirmOpeningDGraphInSingleTabDialogOpen(false);
        setSeriesInContainerToOpenInInSingleTabDialogOpen([]);
        setChartRequestToOpenInInSingleTabDialogOpen(null);
    }

    const handleNoClick = () => {
        setConfirmDeletionDialogOpen(false);
    }

    const handleYesClick = () => {
        setConfirmDeletionDialogOpen(false);
        deleteSeriesFromLegende();
    }

    const handleOkClick = () => {
        setConfirmDeletionNotPossibleDialogOpen(false);
        setSeriesWithinContainer([]);
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>

                    <Menu
                        className="ContextRightClickMenu"
                        id="basic-menu"
                        anchorEl={props.anchorEl}
                        open={props.openRightClickMenu}
                        onClose={props.handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >

                        <MenuItem onClick={onlyShowThisSeriesClick} disabled={!props.hasValues}>
                            <ListItemIcon>
                                <VisibilityTwoTone fontSize="small" />
                            </ListItemIcon>
                            <ListItemText style={{ color: isDarkMode ? "#fff" : "#444" }}> {getTranslatedText("OnlyShowThisSeries")}</ListItemText>
                        </MenuItem>

                        {isSuperVisualisation && <MenuItem disabled={!props.hasValues} onClick={() => {
                            openSerieInNewTabAsSingleVisualizationClick()
                        }}>
                            <ListItemIcon>
                                <ShowChart fontSize="small" />
                            </ListItemIcon>
                            <ListItemText style={{ color: isDarkMode ? "#fff" : "#444" }}> {getTranslatedText("OpenSerieInNewTab")}</ListItemText>
                        </MenuItem>}
                        {isSuperVisualisation &&
                            <div>
                                <Divider />

                                <MenuItem disabled={props.orderedSeries.length <= 1} onClick={removeThisSeries}>
                                    <ListItemIcon>
                                        <DeleteOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText> {getTranslatedText("RemoveThisSeries")}</ListItemText>
                                </MenuItem>
                            </div>}
                    </Menu>

                    <YesNoDialog
                        title={getTranslatedText("RemoveSeriesFromContainerTitle")}
                        content={getTranslatedText("RemoveSeriesFromContainerContent") + "<b><br /><br />" + seriesWithinContainer.join('<br /> <b> ')}
                        titleYesButton={getTranslatedText("TEXT_YES")}
                        titleNoButton={getTranslatedText("TEXT_NO")}
                        open={confirmDeletionDialogOpen}
                        onNoClick={handleNoClick}
                        onYesClick={handleYesClick}
                    />

                    <YesNoDialog
                        title={getTranslatedText("OpenDGraphInSingleTabTitle")}
                        content={getTranslatedText("OpenDGraphInSingleTabContent") + "<b><br /><br />" + seriesInContainerToOpenInInSingleTab.join('<br /> <b> ')}
                        titleYesButton={getTranslatedText("TEXT_YES")}
                        titleNoButton={getTranslatedText("TEXT_NO")}
                        open={confirmOpeningDGraphInSingleTabDialogOpen}
                        onNoClick={handleClickNoConfirmOpeningnDGraphInSingleTab}
                        onYesClick={handleClickYesConfirmOpeningnDGraphInSingleTab}
                    />

                    <ConfirmDialog
                        title={getTranslatedText("RemoveSeriesFromContainerNotPossibleTitle")}
                        content={getTranslatedText("RemoveSeriesFromContainerNotPossibleContainer") + "<b><br /><br />" + seriesWithinContainer.join('<br /> <b> ')}
                        titleOkButton={getTranslatedText("TEXT_OK")}
                        open={confirmDeletionNotPossibleDialogOpen}
                        onOkClick={handleOkClick}
                    />

                </React.Fragment>
            }</LanguageConsumer>
    );
}