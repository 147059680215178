/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { TWebAssemblyChart } from "scichart/Charting/Visuals/SciChartSurface";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Redux/hooks";
import { createSciChartAnnotation } from "../../Annotations/AnnotationService";
import { updateUserAnnotationPosition, userAnnotationPrefix } from "../../Annotations/Redux/AnnotationUserSlice";

interface UseHandleChangeUserAnnotationsArgs {
    sciChart: TWebAssemblyChart;
}

export const useHandleChangeUserAnnotations = ({ sciChart }: UseHandleChangeUserAnnotationsArgs) => {
    const userAnnotations = useAppSelector(store => store.annotationUser.annotations);  
    const dispatch = useAppDispatch();
    const loadingState = useAppSelector(store => store.chartResultData.status);

    useEffect(() => {
       
            if (sciChart && sciChart.sciChartSurface && loadingState === 'idle') {
                var annotationsToDelete = sciChart.sciChartSurface.annotations.asArray().filter(a => a.id.startsWith(userAnnotationPrefix));
                for (let annotation of annotationsToDelete) {
                    var annotationToRemove = sciChart.sciChartSurface.annotations.getById(annotation.id);
                    sciChart.sciChartSurface.annotations.remove(annotationToRemove);
                }
                userAnnotations.filter(x => x.visible).forEach(userAnnotation => {  
                    
                    let annotationToAdd = createSciChartAnnotation(userAnnotation);
    
                    if (annotationToAdd) {
                        annotationToAdd.forEach(x => {
                            x.onDragEnded = () => {
                                dispatch(
                                    updateUserAnnotationPosition(x))
                            };
                            sciChart.sciChartSurface.annotations.add(x);
                        })
                    }
                });
            }
        
    }, [userAnnotations]);
}