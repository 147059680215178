/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Button, Drawer, Grid, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { toggleCustomCsvIsShown } from "./Redux/CustomCsvSlice";
import './Style/CustomCsv.css';
import { useState } from "react";
import { CustomYAxis } from "./Components/CustomYAxis";
import { CustomSeries } from "./Components/CustomSeries";
import { CustomData } from "./Components/CustomData";
import { CustomXAxis } from "./Components/CustomXAxis";
import { CustomCsvStepIcon } from "./Style/CustomCsvStepper";
import { ChartRequest, ChartRequestFile } from "../Home/Models/ChartRequest";
import { nanoid } from "@reduxjs/toolkit";
import { getSeriesForGraphDataAsync } from "../../Infrastructure/ChartResultHandler/Redux/ChartResultSlice";
import { saveSeriesRequestsInStore } from "../../Infrastructure/ChartRequestHandler/Redux/ChartRequestSlice";
import { generateExcelSheet } from "./Provider/CustomCsvProvider";



export const CustomCsv = () => {
    const dispatch = useAppDispatch();
    const isCustomCsvActive = useAppSelector(store => store.customCsv.isShown);

    const customSeries = useAppSelector(store => store.customCsv.series);
    const customYAxis = useAppSelector(store => store.customCsv.yAxis);
    const customXAxis = useAppSelector(store => store.customCsv.xAxis);
    const chartRequestSettings = useAppSelector(store => store.settings.chartRequestSettings);
    const languageIdentifier = useAppSelector(store => store.settings.language);    

    const [activeStep, setActiveStep] = useState<number>(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        activeStep !== 0 && setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        dispatch(toggleCustomCsvIsShown());
    };

  

    const openCsv = async() => {
        var csvData = generateExcelSheet(customXAxis, customYAxis, customSeries);
        var listOfFilesForRequest = [] as ChartRequestFile[];

        var byteBuffer = await csvData.arrayBuffer();
        let fileByteArray = [] as number[];
        let array = new Uint8Array(byteBuffer as ArrayBuffer);
        for (let byte of array) {
            fileByteArray.push(byte);
        }

        listOfFilesForRequest.push({
            Title: `Custom_${((new Date()).toLocaleString())}.csv`,
            CurveRawContent: JSON.stringify(fileByteArray),
            Id: nanoid()
        } as ChartRequestFile);


        var chartRequest: ChartRequest = {
            Settings: chartRequestSettings,
            Files: listOfFilesForRequest,
            SortedList: [],
            LanguageIdentifier: languageIdentifier
        }

        dispatch(saveSeriesRequestsInStore(chartRequest));
        dispatch(getSeriesForGraphDataAsync(chartRequest));
        dispatch(toggleCustomCsvIsShown());
    }

    const saveCsv = () => {
        var csvData = generateExcelSheet(customXAxis, customYAxis, customSeries);

        var csvURL: string | null = null;
        if ((navigator as any).msSaveBlob) {
            csvURL = (navigator as any).msSaveBlob(csvData, 'download.csv');
        }
        else {
            csvURL = window.URL.createObjectURL(csvData);
        }

        var tempLink = document.createElement('a');
        tempLink.href = csvURL as string;
        tempLink.setAttribute('download', `Custom_${((new Date()).toLocaleString())}.csv`);
        tempLink.click();
    }

    const isAllowedToSaveOrOpenFile = () : boolean => {
        return customSeries.length > 0 && 
        customSeries.some(x => x.name.trim().length > 0 && x.color.trim().length > 0 && x.data && x.data.trim().length > 0) && 
        customXAxis.name.trim().length > 0 && 
        customXAxis.data.trim().length > 0 &&
        customYAxis.length > 0 && 
        customYAxis.some(x => x.name.trim().length > 0 && x.abbrevation.trim().length > 0 && x.digitCount !== 0);
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Drawer
                    anchor={'right'}
                    open={isCustomCsvActive}
                    onClose={toggleDrawer()}
                    className="onlinehelp-home-sideDrawerView-share-intro"
                >
                    <Box component={'div'} sx={{ minWidth: "600px", width: "750px" }}>
                        <Grid container direction={'row'} spacing={2} sx={{ p: 4, pb: 0 }} >
                            <Grid item xs={11}>
                                <Typography variant="h4" component="div" gutterBottom sx={{ fontWeight: "300" }}>
                                    {getTranslatedText("CustomCsv")}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{ p: 4,pt: 2, pb: 2 }} >
                            <Grid item xs={12}>
                                <Stepper alternativeLabel activeStep={activeStep} orientation="vertical" connector={null} >

                                    <Step sx={{ width: "100%", textAlign: "left", flexDirection: 'row', alignItems: 'left' }}>
                                        <StepLabel StepIconComponent={CustomCsvStepIcon} onClick={() => setActiveStep(0)}>
                                            {getTranslatedText("DefineXAxis")}
                                        </StepLabel>
                                        <StepContent>

                                            <CustomXAxis />

                                            <Box component={'div'} sx={{ mb: 2, mt: 1 }}>
                                                <div>
                                                    <Button
                                                        fullWidth
                                                        disabled={customXAxis.name.trim().length === 0}
                                                        variant="outlined"
                                                        color="success"
                                                        onClick={handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {getTranslatedText('Continue')}
                                                    </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>

                                    <Step sx={{ width: "100%", textAlign: "left", flexDirection: 'row', alignItems: 'left' }}>
                                        <StepLabel StepIconComponent={CustomCsvStepIcon} onClick={() => customXAxis.name.trim().length > 0 && setActiveStep(1)}>
                                            {getTranslatedText("DefineYAxis")}
                                        </StepLabel>
                                        <StepContent>

                                            <CustomYAxis />

                                            <Box component={'div'} sx={{ mb: 2, mt: 1 }}>
                                                <div>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={handleBack}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {getTranslatedText('Back')}
                                                    </Button>
                                                    <Button
                                                        fullWidth
                                                        disabled={customYAxis.length === 0 || customYAxis.some(x => x.name.trim().length === 0 || x.abbrevation.trim().length === 0 || !x.digitCount)}
                                                        variant="outlined"
                                                        color="success"
                                                        onClick={handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {getTranslatedText('Continue')}
                                                    </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>

                                    <Step sx={{ width: "100%", textAlign: "left", flexDirection: 'row', alignItems: 'left' }}>
                                        <StepLabel StepIconComponent={CustomCsvStepIcon} onClick={() => customYAxis.length > 0 && !customYAxis.some(x => x.name.trim().length === 0 || x.abbrevation.trim().length === 0 || !x.digitCount) && setActiveStep(2)}>
                                            {getTranslatedText("DefineCustomSeries")}
                                        </StepLabel>
                                        <StepContent>

                                            <CustomSeries />

                                            <Box component={'div'} sx={{ mb: 2, mt: 1 }}>
                                                <div>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={handleBack}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {getTranslatedText('Back')}
                                                    </Button>
                                                    <Button
                                                        fullWidth
                                                        disabled={customXAxis.name.trim().length === 0 || customSeries.length === 0 || (customSeries.some(x => x.name.trim().length === 0 || x.color.trim().length === 0))}
                                                        variant="outlined"
                                                        color="success"
                                                        onClick={handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {getTranslatedText('Continue')}
                                                    </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>

                                    <Step sx={{ width: "100%", textAlign: "left", flexDirection: 'row', alignItems: 'left' }}>
                                        <StepLabel StepIconComponent={CustomCsvStepIcon} onClick={() =>customSeries && customSeries.length > 0 && customSeries.some(x => x.name.trim().length > 0 && x.color.trim().length > 0) && setActiveStep(3)}>
                                            {getTranslatedText("DefineCustomSeriesData")}
                                        </StepLabel>
                                        <StepContent>

                                            <CustomData />

                                            <Box component={'div'} sx={{ mb: 2, mt: 1 }}>
                                                <div>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={handleBack}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {getTranslatedText('Back')}
                                                    </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>


                                </Stepper>
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{ p: 4, pt: 0 }}>
                            <Grid item xs={12}>
                                <Button disabled={!isAllowedToSaveOrOpenFile()} onClick={saveCsv} color="dark" variant="contained" sx={{ color: '#fff' }} fullWidth size="large">
                                    {getTranslatedText("SaveCsv")}
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Button disabled={!isAllowedToSaveOrOpenFile()} onClick={openCsv} color="success" variant="contained" sx={{ color: '#fff' }} fullWidth size="large">
                                    {getTranslatedText("OpenCsv")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Drawer>
            }
        </LanguageConsumer>
    );
}

