import { useEffect } from "react";
import { resetCockpitSettings } from "../../../Features/LoadFromCockpit/CurveData/Complete/Redux/LoadFromCockpitCompleteCurveDataSlice";
import { resetAnnotationSettings, resetCurveAnalysis, resetGeneralSettings, resetGeneralSettingsWithoutLanguage } from "../../../Features/Settings/Redux/SettingsSlice";
import { resetChartRequest } from "../../ChartRequestHandler/Redux/ChartRequestSlice";
import { resetChartResult } from "../../ChartResultHandler/Redux/ChartResultSlice";
import { clearChartSeriesColors } from "../../ChartSeriesColors/Redux/ChartSeriesColorsSlice";
import { clearChartSeriesOrders } from "../../ChartSeriesOrder/Redux/ChartSeriesOrderSlice";
import { clearChartSeriesVisibility } from "../../ChartSeriesVisibility/Redux/ChartSeriesVisibilitySlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { toogleAnalysisDefaultReset, toogleAnnotationsDefaultReset, toogleCockpitDefaultReset, toogleGeneralDefaultReset, toogleGeneralDefaultResetWithoutLanguage, } from "../Redux/DefaultSettingsSetterSlice";

export const DefaultSettingsSetter = () => {

    const dispatch = useAppDispatch();

    const generalDefaultResetToogled = useAppSelector(store => store.defaultSettingsSetter.generalDefaultResetToogled);
    const generalDefaultResetWithoutLanguageToogled = useAppSelector(store => store.defaultSettingsSetter.generalDefaultResetWithoutLanguageToogled);
    const curveAnalysisDefaultResetToogled = useAppSelector(store => store.defaultSettingsSetter.curveAnalysisDefaultResetToogled);
    const annotationsDefaultResetToogled = useAppSelector(store => store.defaultSettingsSetter.annotationsDefaultResetToogled);
    const cockpitDefaultResetToogled = useAppSelector(store => store.defaultSettingsSetter.cockpitDefaultResetToogled);

    const resetGraphViewer = () => {
        dispatch(resetChartRequest());
        dispatch(resetChartResult()); 
        dispatch(clearChartSeriesOrders());
        dispatch(clearChartSeriesColors());
        dispatch(clearChartSeriesVisibility());  
    }

    useEffect(() => {
        if (!generalDefaultResetToogled)
            return
        
        resetGraphViewer()
        dispatch(resetGeneralSettings())
        dispatch(toogleGeneralDefaultReset())
    }, [generalDefaultResetToogled])

    useEffect(() => {
        if (!generalDefaultResetWithoutLanguageToogled)
            return
        
        resetGraphViewer()
        dispatch(resetGeneralSettingsWithoutLanguage())
        dispatch(toogleGeneralDefaultResetWithoutLanguage())
    }, [generalDefaultResetWithoutLanguageToogled])

    useEffect(() => {
        if (!curveAnalysisDefaultResetToogled)
            return
        resetGraphViewer()
        dispatch(resetCurveAnalysis())
        dispatch(toogleAnalysisDefaultReset())
    }, [curveAnalysisDefaultResetToogled])

    useEffect(() => {
        if (!annotationsDefaultResetToogled)
            return
        resetGraphViewer()
        dispatch(resetAnnotationSettings())
        dispatch(toogleAnnotationsDefaultReset())
    }, [annotationsDefaultResetToogled])

    useEffect(() => {
        if (!cockpitDefaultResetToogled)
            return
        resetGraphViewer()
        dispatch(resetCockpitSettings())
        dispatch(toogleCockpitDefaultReset())
    }, [cockpitDefaultResetToogled])

    return (null);
}