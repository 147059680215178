import { Grid, IconButton, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../../../Infrastructure/Redux/hooks";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export type DateRangeFilterProps = {
  from: Dayjs;
  to: Dayjs;
  guid: string;
  deleteCallback: (guid: string) => void;
  updateFromDateCallback: (guid: string, date: any) => void;
  updateToDateCallback: (guid: string, date: any) => void;
};

export const DateRangeItem = ({
  from,
  to,
  guid,
  deleteCallback,
  updateFromDateCallback,
  updateToDateCallback,
}: DateRangeFilterProps) => {

  const selectedLanguage = useAppSelector(store => store.settings.language);  

  var formText: string = "FromDateRangeFilterItem";
  var toText: string = "ToDateRangeFilterItem";  

  const handleChangeFrom = (e) => {    
    updateFromDateCallback(guid, e);
  };

  const handleChangeTo = (e) => {
    updateToDateCallback(guid, e);
  };

  const handleDeleteClick = () => {
    deleteCallback(guid);
  };

  return (
    <LanguageConsumer>      
      {({ getTranslatedText }) => (
        <>
        <LocalizationProvider adapterLocale={selectedLanguage} dateAdapter={AdapterDayjs}>        
          <Grid
            container
            direction={"row"}
            spacing={2}
            sx={{ p: 0, pb: 2, pt: 0 }}
          >
            <Grid item xs={5} alignContent="center" textAlign={"center"}>
              <DateTimePicker
                maxDate={to}              
                label={getTranslatedText(formText)}
                value={from}
                onChange={handleChangeFrom}
                renderInput={(params) => <TextField fullWidth id="outlined-basic" variant="outlined" {...params} />}
              />
            </Grid>
            <Grid item xs={5} alignContent="center" textAlign={"center"}>
              <DateTimePicker
                minDate={from}              
                label={getTranslatedText(toText)}
                value={to}
                onChange={handleChangeTo}
                renderInput={(params) => <TextField fullWidth id="outlined-basic" variant="outlined" {...params} />}
              />
            </Grid>
            <Grid item xs={2} alignContent="center" textAlign={"center"}>
              <IconButton
                color="error"
                size="large"
                onClick={handleDeleteClick}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
          </LocalizationProvider>           
        </>                
      )}      
    </LanguageConsumer>
  );
};
