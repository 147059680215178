import { Typography } from "@mui/material"
import { getTranslation } from "../../Internationalisation/TranslationService"

export const getAnalyseOnlineHelpSteps = (props) => {
    return [
      // Navigation - PAGES
      {
        selector: '.onlinehelp-analyse-intro',
        position: 'center',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("AnalyseOnlineHelpIntroTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("AnalyseOnlineHelpIntroMessage") }}></div></div>),
      },
      {
        selector: '.onlinehelp-analyse-syncronizationPointSelect',
        position: 'bottom',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectPointTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectPointMessage") }}></div></div>),
      },
      {
        selector: '.onlinehelp-analyse-synchronizationIndividualTorqueInput',
        position: 'bottom',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSynchronizationIndividualTorqueTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSynchronizationIndividualTorqueMessage") }}></div></div>),
      },
      {
        selector: '.onlinehelp-analyse-synchronizationIndividualTorqueInputButton',
        position: 'bottom',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSynchronizationIndividualTorqueTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSynchronizationIndividualTorqueButtonMessage") }}></div></div>),
      },
      {
        selector: '.onlinehelp-analyse-supervisualisationSelect',
        position: 'bottom',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectSuperVisualisationTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectSuperVisualisationMessage") }}></div></div>)
      },
      {
        selector: '.onlinehelp-analyse-singlevisualisationSelect',
        position: 'bottom',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectSingleVisualisationTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectSingleVisualisationMessage") }}></div></div>)
      },
      {
        selector: '.onlinehelp-analyse-currentValuesSelect',
        position: 'bottom',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpSelectCurrentValuesTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpSelectCurrentValuesMessage") }}></div></div>)
      },
      {
        selector: '.onlinehelp-settings-resetAllAnalysis',
        position: 'left',
        content: () => (<div><Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("SettingsOnlineHelpResetAnalysisTitle")}</Typography><div style={{ color: '#444' }} dangerouslySetInnerHTML={{ __html: getTranslation("SettingsOnlineHelpResetAnalysisMessage") }}></div></div>)
      },
  ]
}



