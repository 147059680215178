import * as React from 'react';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import PublicIcon from '@mui/icons-material/Public';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
  
  export const CockpitStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: "22px"
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        top: "10px",
        backgroundImage:
          'linear-gradient(180deg, rgba(50,153,44,1) 0%, rgba(79,147,77,1) 50%, rgba(43,124,86,1) 87%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(180deg, rgba(50,153,44,1) 0%, rgba(79,147,77,1) 50%, rgba(43,124,86,1) 87%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      width: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const CockpitStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient(180deg, rgba(50,153,44,1) 0%, rgba(79,147,77,1) 50%, rgba(43,124,86,1) 87%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient(180deg, rgba(50,153,44,1) 0%, rgba(79,147,77,1) 50%, rgba(43,124,86,1) 87%)',
    }),
  }));
  
  export const CockpitStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;
  
    const icons: { [index: string]: React.ReactElement } = {
      1: <PublicIcon />,
      2: <FilterAltIcon />,
      3: <QueryStatsIcon />,
    };
  
    return (
      <CockpitStepIconRoot sx={{textAlign: "left", display: 'flex', flexDirection: 'row', marginRight: "12px", top: "0px", left: "-8px", position: "relative"}} ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </CockpitStepIconRoot>
    );
  }